import { combineReducers } from 'redux';

import authReducer, {
    initialState as authInitialState
} from './authReducer';
import completedReducer, {
    initialState as completedInitialState
} from './completedReducer';
import giftReducer, {
    initialState as giftInitialState
} from './giftReducer';
import globalReducer, {
    initialState as globalInitialState
} from './globalReducer';
import profileReducer, {
    initialState as profileInitialState
} from './profileReducer';
import waveReducer, {
    initialState as waveInitialState
} from './waveReducer';

export const initialState = {
    auth: authInitialState,
    completed: completedInitialState,
    gift: giftInitialState,
    global: globalInitialState,
    profile: profileInitialState,
    wave: waveInitialState
}

export const Reducers = combineReducers<AppReducer>({
    auth: authReducer,
    completed: completedReducer,
    gift: giftReducer,
    global: globalReducer,
    profile: profileReducer,
    wave: waveReducer
});