import axios from './axios';

const getContent = (id: string) => {
    const url = `/producer/gifts/${id}/content`;

    return new Promise<SocialMediaContent[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as SocialMediaContent[]);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getInfluencerPosts = (
    giftId: string,
    influencerId: string
) => {
    const url = `producer/gifts/${giftId}/content/influencer/${influencerId}`;

    return new Promise<SocialMedia[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as SocialMedia[]);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

export const completedAPI = {
    getContent,
    getInfluencerPosts
};
