import {PropsWithChildren, useMemo, useState} from 'react';
import styled from 'styled-components';

interface Props {
    changeFilterItems: (frequency: SubscriptionFrequency) => void;
    currentlySelected: SubscriptionFrequency;
}

export function PaymentIntervalFilterButtons({
    changeFilterItems,
    currentlySelected,
}: PropsWithChildren<Props>) {

    const [selected, setSelected] = useState(currentlySelected);
    const [selectedIndex, setSelectedIndex] = useState<number|undefined>(undefined);

    const buttonData: SubscriptionFrequency[] = ['MONTH', 'YEAR']

    const handleClick = (frequency: SubscriptionFrequency, index: number) => {
        setSelected(frequency);
        setSelectedIndex(index);
        changeFilterItems(buttonData[index]);
    }

    const leftValue = useMemo(() => {

        let selectedPlanIndex = 0;
        buttonData.forEach((frequency, index) => {
            if (selected === frequency) {
                selectedPlanIndex = index;
            }
        })

        const buttonCount = buttonData.length;
        const increment = 100 / buttonCount;
        const left = increment * (selectedIndex || selectedPlanIndex);
        return selectedIndex === 0 ? left + 2 : left;
    }, [selectedIndex, buttonData]);

    return (
        <Container>
            <div className="filter-btn-bkg" style={{left: `${leftValue}%`}}></div>

            {buttonData.map((frequency, index) => (
                <button key={`filter-btn ${index}`} className={selected === frequency ? 'active' : ''}  onClick={() => handleClick(frequency, index)}>{frequency.toLowerCase()}ly {frequency === 'YEAR' ? '(Save 16%)' : ''}</button>
            ))}

        </Container>
    );
}


const Container = styled.div`
    position: relative;
    margin-right: 12px;
    background: var(--grey-1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px;
    height: 44px;
    color: var(--grey-9);
    font-size: 14px;

    button {
        width: 145px;
        background: none;
        border: none;
        z-index: 1;
        cursor: pointer;
        transition: all 250ms ease;
        will-change: color;
        color: var(--blue);
        margin-right: 8px;
        text-transform: capitalize;

        &.active {
            color: white;
            font-weight: bold;
        }
    }

    .filter-btn-bkg {
        border-radius: 20px;
        height: 75%;
        width: 145px;
        position: absolute;
        left: 0;
        background: #74d7c2;
        z-index: 0;
        transition: all 250ms ease;
        will-change: transform;
    }
`;
