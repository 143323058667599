import {
    PropsWithChildren,
    useEffect,
    useMemo,
    useState
} from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import {
    NoResult
} from 'app/components';
import { GiftReviewList } from '../GiftReviewList';

interface State {
    list: any[];
    loading: boolean;
}

interface Props {
    paramsStatus: string;
}

const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 28px;
    row-gap: 28px;
    padding: 16px 0;
    margin-right: -6px;
    margin-left: -6px;
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;
    padding: 24px 52px;
    overflow: hidden auto;
    overflow: hidden overlay;
`;

const StyledGiftReviewList = styled(GiftReviewList)`
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap !important;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 12px 0 24px;
    overflow: hidden auto;
    overflow: hidden overlay;
`;

const RightContent = styled.div`
    grid-area: right-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-left: 1px solid var(--grey-4);

    > span {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        padding: 24px 0 12px;
        letter-spacing: -0.75px;
        color: var(--grey-9);
    }
`;

export function GiftReviews({
    paramsStatus,
    children
}: PropsWithChildren<Props>) {
    const [state, setState] = useState<State>({
        list: [],
        loading: true
    });

    const { list, loading } = state;

    const showList = useMemo(() => {
        return ['active', 'past'].includes(paramsStatus);
    }, [paramsStatus]);

    useEffect(() => {
        setTimeout(() => {
            setState({
                list: Array(5).fill('').map(() => ({
                    uid: Helpers.generateId(),
                    name: 'Jacob Jones',
                    image: '',
                    title: 'Awsome product!',
                    review: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`,
                    rating: Helpers.generateInt(3, 5),
                    media: Array(Helpers.generateInt(1, 3)).fill(''),
                    status: 'pending',
                    reviewed_at: new Date()
                })),
                loading: false
            });
        }, 1500);
    }, []);


    const renderEmpty = () => {
        let image: string;
        let message: string;

        if (showList) {
            if (!loading && list.length === 0) {
                image = 'search';
                message = `Sorry, we didn't find any\ninfluencers reviews.`;
            } else {
                return null;
            }
        } else {
            image = 'sandwatch';

            switch (paramsStatus) {
                case 'draft':
                    message = 'Please submit your gift.\nOnce we approve, you can get reviews.';
                    break;

                case 'submitted':
                    message = 'We are reviewing your gift.\nOnce we approve, you can get reviews.';
                    break;

                case 'rejected':
                    message = 'Please re-submit your gift.\nOnce we approve, you can get it.';
                    break;

                default: message = '';
            }
        }

        return (
            <NoResult
                image={image as any}
                title={message}
            />
        );
    }

    return (
        <>
            <LeftContent>
                <Body>
                    {children}

                    <GiftReviewList
                        items={list}
                        status={'pending'}
                        loading={loading}
                    />

                    {renderEmpty()}
                </Body>
            </LeftContent>

            <RightContent>
                <span>Approved Review</span>

                <StyledGiftReviewList
                    items={list}
                    status={'approved'}
                    loading={loading}
                />
            </RightContent>
        </>
    );
}
