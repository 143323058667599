/**
 * Returns the value of a CSS variable.
 *
 * @param variable the variable name (e.g. --primary)
 * @returns variable value
 */
const getCSSVarValue = (variable: string) => {
    const style = getComputedStyle(document.documentElement);
    return style.getPropertyValue(variable);
}

/**
 * Converts the hex color variable
 * to RGB triplets with the given opacity.
 *
 * @param hexColorVar the hex color variable
 * @param opacity opacity value from 0 to 1
 * @returns the rgba(r, g, b, a) color value
 */
const hexVarToRGBA = (hexColorVar: string, opacity: number) => {
    const hex = getCSSVarValue(hexColorVar).trim();
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        const triplets = result
            .slice(1, 4)
            .map((value) => parseInt(value, 16))
            .join(',');
        return `rgba(${triplets}, ${opacity})`;
    }
    console.error('could not convert color');
}

export const Color = { hexVarToRGBA };
