import {
    ChangeEvent,
    PropsWithChildren,
    useEffect,
    useRef,
} from 'react';
import styled from 'styled-components';

import { FormLabel } from '../FormLabel';

interface Props {
    name: string;
    label: string;
    value: number,
    min?: number;
    max?: number;
    step?: number;
    required?: boolean;
    className?: string;
    onChange(name: string, value: number): void;
}

const Input = styled.input`
    font-size: 14px;
    width: 100%;
    height: 2px;
    border: none;
    background: transparent;
    outline: none;
    z-index: 5;
    appearance: none;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
        height: 16px;
        width: 16px;
        border: 6px solid var(--blue);
        border-radius: 50%;
        background-color: var(--background-alt);
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
        z-index: 1;
    }
    
    &::-moz-range-thumb {
        height: 16px;
        width: 16px;
        border: 6px solid var(--blue);
        box-shadow: none;
        background-color: var(--background-alt);
        z-index: 1;
    }
`;

const Track = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    width: 100px;
    height: 2px;
    background-color: var(--blue);
    z-index: 2;
`;

const Range = styled(Track)`
    width: 100%;
    background-color: var(--grey-3);
    z-index: 1;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    height: 56px;
    border-radius: 14px;
    color: var(--grey-9);
    transition: border-color 300ms ease;
    overflow: hidden;

    svg {
        flex-shrink: 0;
        margin-left: -1.5px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

export function FormSlider({
    name,
    label,
    value,
    min = 0,
    max = 10,
    step,
    required,
    className,
    children,
    onChange
}: PropsWithChildren<Props>) {
    const id = `slider-input-${name}`;

    const trackRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const tractEl = trackRef.current;

        if (tractEl) {
            tractEl.style.width = `${(Number(value) - min) / (max - min) * 100}%`;
        }
    }, [min, max, value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        onChange(name, Number(value));
    }

    return (
        <Container
            data-testid={'form-select-component'}
            className={className}
        >
            {label && (
                <FormLabel required={required}>
                    {label}
                </FormLabel>
            )}

            <Content>
                <Input
                    type={'range'}
                    id={id}
                    name={name}
                    value={value}
                    min={min}
                    max={max}
                    step={step}
                    onChange={handleChange}
                />

                <Track ref={trackRef} />

                <Range />
            </Content>

            {children}
        </Container>
    );
}