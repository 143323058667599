import { useEffect, useState } from 'react';

interface Props {
    seconds: number;
    className?: string;
}

export function Timer({
    seconds,
    className
}: Props) {
    const [timer, setTimer] = useState(seconds);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer((t) => t - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    if (timer <= 0) return null

    return (
        <span className={className}>
            You will be redirected in {timer}s
        </span>
    );
}