import styled from 'styled-components';

import { CheckIcon } from 'app/icons';
import { PropsWithChildren } from 'react';

interface Props {
    checked?: boolean;
    className?: string;
    onChange?(checked?: boolean): void;
}

const Container = styled.span<{
    $checked?: boolean;
}>`
    display: flex;
    align-items: center;
    column-gap: 8px;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;

    svg {
        width: 12px;
        
        path {
            fill: transparent;
            stroke-width: 52px;
        }
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
            width: 20px;
            height: 20px;
            border: 1px solid var(--grey-6);
            border-radius: 4px;
            transition: background-color 150ms ease,
                border-color 150ms ease;
        }
    }

    ${(props) => props.$checked && `
        svg path {
            fill: var(--text-alt);
            stroke: var(--text-alt);
            stroke-width: 1.5px;
        }

        span {
            &:first-child {
                background-color: var(--blue);
                border-color: var(--blue);
            }
        }
    `}
`;

export function Checkbox({
    checked,
    className,
    onChange,
    children
}: PropsWithChildren<Props>) {
    const handleClick = () => {
        onChange?.(!checked);
    }

    return (
        <Container
            data-testid={'checkbox-component'}
            $checked={checked}
            className={className}
            onClick={handleClick}
        >
            <span>
                <CheckIcon />
            </span>

            <span>{children}</span>
        </Container>
    );
}