import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import { Button, GiftModal } from 'app/components';
import {
    CodeVerificationForm,
    ForgotPasswordForm,
    NewPasswordForm
} from 'app/forms';
import { AngleLeftIcon } from 'app/icons';
import { PublicLayout } from 'app/layouts';
import { ForgotPasswordHeader } from './Header';

const GoBack = styled(Button)`
    position: absolute;
    top: 32px;
    left: 32px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: auto;

    p {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }
`;

export function ForgotPasswordPage() {
    const navigate = useNavigate();

    const dispatcher = useAppDispatch();

    const {
        auth: { forgotPassword: { step, email }, loading },
        global: { alert }
    } = useAppSelector((state) => state);

    const [modalState, setModalState] = useState<Dynamic>({ show: false });

    useEffect(() => {
        dispatcher(Actions.App.setAlert(null));

        return () => {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [dispatcher, step]);

    const handleModalClose = () => {
        // go back to login page
        // note:
        //   there are 3 steps (0,1,2) in the
        //   forgot password flow, setting the
        //   step below 0 will redirect the
        //   user to the login page
        //   e.g step 1 - 3, step 2 - 3
        //   will both redirect to the login page
        // see inner function
        setForgotPasswordStep(-3);
        setModalState({ show: false });
    }

    const handleForgotPassword = (form: ForgotPasswordForm) => {
        dispatcher(Actions.Auth.forgotPassword(form)).catch((error: any) => {throw error});
    }

    const handleForgotPasswordUpdate = (form: NewPasswordForm) => {
        dispatcher(Actions.Auth.fotgotPasswordUpdate(form, () => {
            setModalState({ show: true });
        })).catch((error: any) => {throw error});
    }

    const handleForgotPasswordVerify = (form: CodeVerificationForm) => {
        dispatcher(Actions.Auth.forgotPasswordVerify(form)).catch((error: any) => {throw error});
    }

    const setForgotPasswordStep = (incDec: number) => {
        const nextStep = step + incDec;

        if (nextStep < 0) {
            // redirect the user to the login page
            dispatcher(Actions.Auth.clearForgotPassword());
            navigate('/', { replace: true });
        } else {
            // since code is one-time use
            // going back from step 2 to step 1
            // will not revalidate the code
            // upon submit, so we need to start
            // from the the beginning (step 0)
            const gotoFirst = step === 2 && nextStep === 1;
            dispatcher(Actions.Auth.setForgotPasswordStep(gotoFirst ? 0 : nextStep));
        }
    }

    const renderForm = () => {
        switch (step) {
            case 0:
                return (
                    <ForgotPasswordForm
                        data={{ email: email || '' }}
                        errors={alert?.errors}
                        loading={loading}
                        onSubmit={handleForgotPassword}
                    >
                        <ForgotPasswordHeader
                            title={'Forgot Password'}
                            desc={'Verify your email & change password'}
                        />
                    </ForgotPasswordForm>
                );

            case 1:
                return (
                    <CodeVerificationForm
                        hasError={alert?.type === 'error'}
                        loading={loading}
                        onSubmit={handleForgotPasswordVerify}
                    >
                        <ForgotPasswordHeader
                            title={'Verification'}
                            desc={`We have sent code to your email\n${email || ''}`}
                        />
                    </CodeVerificationForm>
                );

            case 2:
                return (
                    <NewPasswordForm
                        errors={alert?.errors}
                        loading={loading}
                        onSubmit={handleForgotPasswordUpdate}
                    >
                        <ForgotPasswordHeader
                            title={'Add new password'}
                            desc={'Please enter your new password'}
                        />
                    </NewPasswordForm>
                );

            default: return null;
        }
    }

    return (
        <PublicLayout>
            <Container data-testid={'forgot-password-page'}>
                <GoBack
                    theme={'aqua'}
                    size={'medium'}
                    iconOnly={true}
                    onClick={() => setForgotPasswordStep(-1)}
                >
                    <AngleLeftIcon />
                </GoBack>

                <Body>
                    {renderForm()}
                </Body>
            </Container>

            <GiftModal
                {...modalState}
                type={'password-changed'}
                onClose={handleModalClose}
                primaryAction={{
                    name: 'Log in',
                    action: handleModalClose
                }}
            />
        </PublicLayout>
    );
}
