import {FormEvent, MouseEvent, PropsWithChildren, useMemo, useState} from 'react';
import styled from 'styled-components';

import {Helpers} from 'utils';
import {Button, EmptyPlaceholder} from 'app/components';
import {GiftTypeList} from './GiftTypeList';
import {useAppSelector} from "../../../store";
import {ConnectShopifyForm} from "../ConnectShopifyForm";
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';

interface Props {
    data?: GiftTypeForm;
    status?: string;
    disabledActions?: boolean;
    disabledInputs?: boolean;
    loadingGift?: boolean;
    loadingGiftTypes?: boolean;
    primaryAction: GiftFormAction;
}

export function GiftTypeForm({
    data,
    status,
    disabledActions,
    disabledInputs,
    loadingGift,
    loadingGiftTypes,
    children,
    primaryAction,
}: PropsWithChildren<Props>) {

    const {
        profile: { brand },
        global: { settings },
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const [form, setForm] = useState<GiftTypeForm>(data || initialData);

    const disabledSubmit = useMemo(() => {
        const disabled = !Helpers.isNullOrUndefined(disabledActions) &&
            Helpers.isEqual(data, form);

        return disabled || loadingGift || loadingGiftTypes;
    }, [data, disabledActions, loadingGift, loadingGiftTypes, form]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        primaryAction.action(form);
    }

    const handlePrimaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (primaryAction.type === 'reset') {
            setForm(data || initialData);
        }
        analyticsEvents.giftCreateType({ gift_type: form.gift_type });
        primaryAction.action(form);
    }

    const handleChange = (
        name: string,
        value: string | number
    ) => {
        setForm((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    }

    return (
        <>
            <LeftContent>
                {children}

                <Form onSubmit={handleSubmit}>
                    <GiftTypeList
                        name={'gift_type'}
                        selected={form.gift_type}
                        loading={loadingGiftTypes}
                        onClick={handleChange}
                    />

                    {(!disabledActions && !disabledInputs) && (
                        <FormActions>
                            <StyledButton
                                size={'medium'}
                                disabled={disabledSubmit}
                                loading={loadingGift &&
                                    ['create-gift-type', 'set-gift-type'].includes(status || '')}
                                onClick={handlePrimaryActionClick}
                            >
                                {primaryAction.name}
                            </StyledButton>
                        </FormActions>
                    )}
                </Form>
            </LeftContent>

            <RightContent>
                {(brand?.shopify?.status === 'CONNECTED' || brand?.doesnt_shopify)? <EmptyPlaceholder image={'girl-1'} /> : (
                    <ShopBox>
                        <ConnectShopifyForm setShopifyProfile={() => {}} loading={false} onGiftFlow={true}>
                            <h3 className="shop-title">Connect your Shopify Store</h3>
                            <FeatureLine>Gift products directly from your Shopify store.</FeatureLine>
                            <FeatureLine>Orders are automatically created in your store when you select the Creator for gifting and they confirm options.</FeatureLine>
                        </ConnectShopifyForm>
                    </ShopBox>
                )}
            </RightContent>
        </>
    );
}

const StyledButton = styled(Button)`
    width: 100%;
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    margin-top: auto;
    width: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;
    padding: 24px 52px 40px;

    @media (max-width:900px) and (min-width:0px) {
        padding: 24px 12px;
    }
`;

const RightContent = styled.div`
    grid-area: right-content;
    border-left: 1px solid var(--grey-4);

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const ShopBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    height: 100%;
    
    .shop-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 24px;
        -webkit-letter-spacing: -0.75px;
        -moz-letter-spacing: -0.75px;
        -ms-letter-spacing: -0.75px;
        letter-spacing: -0.75px;
        text-align: center;
        color: var(--grey-9);
        width: 100%;
    }
    
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        -webkit-letter-spacing: -0.75px;
        -moz-letter-spacing: -0.75px;
        -ms-letter-spacing: -0.75px;
        letter-spacing: -0.75px;
        text-align: center;
        color: var(--grey-9);
        width: 100%;        
    }
    
`

const initialData: { gift_type: GiftType } = {
    gift_type: 'PRODUCT',
};

const FeatureLine = styled.p`
    line-height: 28px !important;
    margin-top: 12px;
    margin-bottom: 12px;
`;
