import amplitude from 'amplitude-js';
import { AnalyticsProfile } from "./analyticsService";

amplitude.getInstance().init('ad9be02088f64c3b1af32d32fffd7a04');

class AmplitudeService {
    private profile: AnalyticsProfile | null = null;

    async identify(profile: AnalyticsProfile) {
        this.profile = profile;
        try {
            const identify = new amplitude.Identify();
            for (const [key, value] of Object.entries(this.profile)) {
                if (typeof value === 'number' || typeof value == 'string') {
                    identify.append(key, value);
                }
            }
            amplitude.setUserId(this.profile.id);
            await amplitude.identify(identify);
        } catch (error: any) {
            console.warn('Could not identify. Amplitude may be blocked by ad blocker', error);
        }
    }

    unidentify() {
        this.profile = null;
    }

    async track(eventName: string, props: any) {
        try {
            await amplitude.logEvent(eventName, props);
        } catch (error: any) {
            console.warn('Could not publish event. Amplitude may be blocked by ad blocker', error);
        }
    }
}

const amplitudeService = new AmplitudeService();
export default amplitudeService;
