import axiosService from './axios';
import axios from 'axios';

export enum WaveStatus {
    SUBMITTED = 'SUBMITTED',
    SHORTLISTED = 'SHORTLISTED',
    NEGOTIATION = 'NEGOTIATION',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    COMPLETED = 'COMPLETED',
}

const updateStatus = (
    giftId: string,
    waveId: string,
    status: string
) => {
    const url = `/producer/gifts/${giftId}/waves/${waveId}/${status}`;
    return new Promise<WaveItem>((resolve, reject) => {
        axiosService.post(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as WaveItem);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => {
                reject(err)
            });
    });
}

const getAnalytics = (id: string, platform: string) => {
    const url = `/producer/influencer/${id}/analytics?platform=${platform}`;

    return new Promise<Analytics>((resolve, reject) => {
        axiosService.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Analytics);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

let cancelStatusUpdate: any;
const getByStatuses = (
    id: string,
    statuses: Array<WaveStatus>,
    page = 1,
    sort = 'newest',
    filter = 'all',
    search: string,
) => {
    console.log('get by statuses called')
    let query = '?';
    for (const status of statuses) {
        query += 'status[]=' + status.toString() + '&';
    }
    const url = `/producer/gifts/${id}/waves${query}sort=${sort}&filter=${filter}&page=${page}&q=${search}&limit=10`;

    if (cancelStatusUpdate) {
        console.log('canceling request');
        cancelStatusUpdate();
    }

    return new Promise<PaginatedResponse<Wave>>((resolve, reject) => {
        axiosService.get(url, {
            cancelToken: new axios.CancelToken((canceler) => {
                cancelStatusUpdate = canceler;
            })
        })
            .then((response) => {
                console.log('got request ' , response.data as any);
                if (response.status === 200) {
                    resolve(response.data as PaginatedResponse<Wave>);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getCompleted = (id: string) => {
    const url = `/producer/gifts/${id}/content`;

    return new Promise<Wave[]>((resolve, reject) => {
        axiosService.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Wave[]);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

let cancelGetItem: any;
const getItem = (giftId: string, waveId: string, markAsSeen: boolean) => {
    const url = `/producer/gifts/${giftId}/waves/${waveId}?markAsSeen=${markAsSeen}`;

    if (cancelGetItem) {
        cancelGetItem();
    }

    return new Promise<WaveItem>((resolve, reject) => {
        axiosService.get(url,  {
            cancelToken: new axios.CancelToken((canceler) => {
                cancelGetItem = canceler;
            })})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as WaveItem);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const sendAttachment = (
    giftId: string,
    waveId: string,
    attachment: File
) => {
    const url = `/producer/gifts/${giftId}/waves/${waveId}/attachment`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const data = new FormData();
    data.append('attachment', attachment);

    return new Promise<void>((resolve, reject) => {
        axiosService.post(url, data, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const sendMessage = (
    giftId: string,
    waveId: string,
    message: string
) => {
    const url = `/producer/gifts/${giftId}/waves/${waveId}/message`;

    return new Promise<void>((resolve, reject) => {
        axiosService.post(url, JSON.stringify({ message }))
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const sendNudge = (giftId: string, waveId: string, brand: BrandProfile) => {
    const url = `/producer/gifts/${giftId}/waves/${waveId}/nudge`;

    return new Promise<void>((resolve, reject) => {
        axiosService.post(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve();

                    // maybe need to create a message to send here? idk...
                    // sendMessage(giftId, waveId, `${brand.name} has sent a nudge. Please respond ASAP :)`).then(() => {
                    //     resolve();
                    // }).catch((err) => reject(err));
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const submitReview = (
    giftId: string,
    influencerId: string,
    data: ReviewForm
) => {
    const url = `/producer/reviews/gifts/${giftId}/influencer/${influencerId}`;

    if (data.anonymous) {
        // @ts-ignore
        data.is_anonymous = true;
    }

    return new Promise<number>((resolve, reject) => {
        axiosService.post(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

export const waveAPI = {
    updateStatus,
    getAnalytics,
    getByStatuses,
    getCompleted,
    getItem,
    sendAttachment,
    sendMessage,
    submitReview,
    sendNudge
};
