import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

interface Props {
    changeFilterItems: (val: string) => void;
}

const Container = styled.div`
    position: relative;
    margin-right: 12px;
    background: var(--grey-1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px;
    height: 44px;
    color: var(--grey-9);
    font-size: 14px;

    button {
        width: 75px;
        background: none;
        border: none;
        z-index: 1;
        cursor: pointer;
        transition: all 250ms ease;
        will-change: color;
        color: var(--blue);

        &.active {
            color: white;
        }
    }

    .filter-btn-bkg {
        border-radius: 20px;
        height: 75%;
        width: 75px;
        position: absolute;
        left: 0;
        background: #74d7c2;
        z-index: 0;
        transition: all 250ms ease;
        will-change: transform;
    }

    @media (max-width:900px) and (min-width:0px) {
        margin-right: 8px !important;
        button {
            width: 50px;
            font-size: 12px;
        }

        .filter-btn-bkg {
            width: 50px;
        }
    }
`;

export function FilterButtons({
    changeFilterItems
}: PropsWithChildren<Props>) {

    const [selected, setSelected] = useState(0)
    const [selectedLeftValue, setSelectedLeftValue] = useState(2)

    const buttonData = [
        {
            text: 'All',
            callback: () => {},
            index: 0
        },
        {
            text: 'Read',
            callback: () => {},
            index: 1
        },
        {
            text: 'Unread',
            callback: () => {},
            index: 2
        }
    ]

    const handleClick = (index: number, callback: any) => {
        setSelected(index)
        const indexDict: {[prop: number]: number} = {
            0: 2,
            1: 33,
            2: 66
        }
        setSelectedLeftValue(indexDict[index])
        changeFilterItems(buttonData[index].text)
        if (callback) {
            callback()
        }
    }


    return (
        <Container>
            <div className="filter-btn-bkg" style={{left: `${selectedLeftValue}%`}}></div>

            {buttonData.map((btn, index) => (
                <button key={`filter-btn ${index}`} className={selected === btn.index ? 'active' : ''}  onClick={() => handleClick(btn.index, btn.callback)}>{btn.text}</button>
            ))}

        </Container>
    );
}
