import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-areas: 'left-content right-content';
    grid-template-columns: minmax(0, 1fr) 480px;
    row-gap: 40px;
    width: 1100px;
    min-height: 780px;
    margin: auto;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background: var(--background-alt);
    overflow: hidden;

    @media (max-width:900px) and (min-width:0px) {
        width: 98vw;
        display: flex;
        flex-direction: column;
    }
`;

export function GiftFlowCard({
    children
}: PropsWithChildren<any>) {
    return (
        <Container data-testid={'gift-flow-card-component'}>
            {children}
        </Container>
    );
}
