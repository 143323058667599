import { motion } from 'framer-motion';
import styled from 'styled-components';

import { CompletedContentListItem } from '../CompletedContentListItem';

interface Props {
    items: SocialMediaContent[];
    loading?: boolean;
    onClick(content: SocialMediaContent): void;
}

const Container = styled(motion.div) <{
    $loading?: boolean;
}>`
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;

    ${(props) => props.$loading && `
        pointer-events: none;
    `}
`;

//@ts-ignore
const placeholder: Array<SocialMediaContent> = Array(7).fill('').map((value, index) => {
    return {
        influencer: {
            address: "",
            attributes: [],
            bio: "",
            city: {
                id: 1, name: ""
            },
            country: {
                id: 1, name: ""
            },
            dob: "",
            headshot_image: null,
            insights: [],
            last_seen_at: null,
            mobile: "",
            name: "",
            reviews: [],
            social_profiles: [],
            state: {
                id: 1, name: ""
            },
            status: "",
            tags: [],
            uid: `fake-influencer-${index}`,
            rating: 0
        }, socialMedia: {
            external_id: "",
            influencer: "",
            insights: {
                comments: 0, dislikes: 0, engagement_rate: 0, favourites: 0, likes: 0, shares: 0, views: 0
            },
            type: 'POST',
            items: [],
            platform: "",
            posted_at: "",
        }
    }
});

export function CompletedContentList({
    items,
    loading,
    onClick
}: Props) {
    const animation = {
        initial: loading ? 'loading' : 'hidden',
        animate: loading ? 'loading' : 'show',
        variants: {
            show: {
                transition: {
                    staggerChildren: 0.1
                }
            }
        }
    };

    const list = loading ? placeholder : items;

    return (
        <Container
            {...animation}
            $loading={loading}
        >
            {list.map((content, i) => (
                <CompletedContentListItem
                    key={i}
                    content={content}
                    loading={loading}
                    onClick={() => onClick(content)}
                />
            ))}
        </Container>
    )
}
