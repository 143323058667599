import styled from 'styled-components';

interface Props {
    title: string;
    desc: string;
    extraDesc?: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    text-align: center;

    span {
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: var(--grey-6);

        &:first-child {
            font-size: 40px;
            font-weight: 300;
            line-height: 48px;
            letter-spacing: -1.25px;
            color: var(--grey-9);
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        span {

            &:first-child {
                font-size: 24px;
            }

            &:last-child {
                font-size: 14px;
            }
        }
    }    
`;

export function InvitePageHeader({
    title,
    desc,
    extraDesc
}: Props) {
    return (
        <Container>
            <span>{title}</span>

            <span>{desc}</span>
            {extraDesc ? <span>{extraDesc}</span> : null}
        </Container>
    );
}