import {
    FormEvent,
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import styled from 'styled-components';
import { useDebouncyEffect } from 'use-debouncy';

import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import { Helpers } from 'utils';
import {
    Button,
    FormInput,
    FormLabel,
    MobilePreview,
    NoResult,
} from 'app/components';
import { NextLineIcon, SearchIcon } from 'app/icons';
import { ProductList } from './ProductList';
import {BundleProductList} from "./BundleProductList";
import {CreativeForm} from "../CreativeForm";
import { Loader } from 'app/components/Loader';
import { Alert } from '../../components';
import HyperLink from 'app/components/HyperLink';

export type ShopifyMode = 'PRODUCT' | 'GIFT_CARD';

interface State {
    form: CreativeForm;
    medias: Media[];
    selectedItems: ShopifyItem[]
    options: ShopifyOptions;
}

interface Props {
    mode: ShopifyMode,
    data?: CreativeForm;
    status?: string;
    showProductLink?: boolean;
    disabledActions?: boolean;
    disabledInputs?: boolean;
    loading?: boolean;
    primaryAction: GiftFormAction;
    secondaryAction: GiftFormAction;
}

const StyledInput = styled(FormInput)`
    flex: 1;

    div {
        border: none;
        background-color: var(--background-alt);
    }
`;

const Search = styled.div`
    align-self: center;
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 12px;
    border: 1px solid var(--grey-6);
    border-radius: 12px;
    background-color: var(--background-alt);
    transition: border-color 300ms ease;
    overflow: hidden;

    &:focus-within {
        border-color: var(--grey-9);
    }
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const StyledButton = styled(Button)`
    width: 186px;
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    margin-top: auto;

    ${StyledButton} {
        width: 100%;
    }

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column;

        ${StyledButton} {
            margin-top: 12px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;

    p {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        margin: 0;
        color: var(--grey-6);

        span {
            text-decoration: underline;
            text-underline-offset: 1px;
            text-underline-position: under;
            color: var(--info-active);
            cursor: pointer;
        }
    }
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 52px 40px;
    row-gap: 24px;

    a {
        text-align: center;
    }
`;

const RightContent = styled.div`
    grid-area: right-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--grey-4);
    padding: 24px 0px;
    height: 780px;
    overflow-y: scroll;

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const LoadingGift = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;

    h1 {
        color: var(--blue);
        text-align: center;
        font-size: 24px;
    }
`

const initialData = {
    name: '',
    description: '',
    value: 0,
    selectedItems: [],
    medias: [],
};

export function ShopifyForm({
    mode,
    data,
    status,
    showProductLink,
    disabledActions,
    loading,
    disabledInputs,
    children,
    primaryAction,
    secondaryAction
}: PropsWithChildren<Props>) {
    const dispatcher = useAppDispatch();

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const {
        gift: { item, shopifyItems, loadingShopifyItems, shopifyIsDisconnected }
    } = useAppSelector((state) => state);

    const rightContent = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<State>({
        form: data || {
            ...initialData,
            ...(showProductLink && { productLink: '' }),
        },
        medias: initialData.medias,
        selectedItems: initialData.selectedItems,
        options: { search: '' }
    });

    const [shownError, setShownError] = useState('')

    const {
        form,
        options,
        medias,
        selectedItems
    } = state;

    const disabledSubmit = useMemo(() => {
        if (selectedItems.length === 0) {
            return true;
        }

        const disabled = !Helpers.isNullOrUndefined(disabledActions) &&
            Helpers.isEqual(data, form);

        return disabled || loading;
    }, [data, disabledActions, loading, form]);

    useEffect(() => {
        const rightContentEl = rightContent.current;
        const parentEl = rightContentEl?.parentElement;

        if (rightContentEl && parentEl && rightContentEl) {
            const rightContentRect = rightContentEl.getBoundingClientRect();
            const parentRect = parentEl.getBoundingClientRect();

            if (rightContentRect.height > parentRect.height) {
                parentEl.style.height = '780px';
            }
        }

        return () => {
            if (parentEl) {
                parentEl.style.removeProperty('height');
            }
        }
    }, []);

    useEffect(() => {
        if (item) {
            const options = {
                search: '',
                filter: mode === 'GIFT_CARD' ?
                    'GIFTCARD' : 'PRODUCT'
            };

            setState((prev) => ({
                ...prev,
                form: initialData,
                media: undefined,
                selectedId: undefined,
                selectedVariant: undefined,
                options
            }));

            if (item.gift_type !== 'SHOPIFY') {
                dispatcher(Actions.Gift.getShopifyProducts(item.uid, options)).catch((error: any) => {
                    throw error
                })
            }
        }
    }, [dispatcher, item, mode]);

    useDebouncyEffect(() => {
        if (item) {
            setState((prev) => ({
                ...prev,
                form: initialData,
                media: undefined,
                selectedId: undefined,
                selectedVariant: undefined,
                options
            }));


            if (options.search.length || item.gift_type === 'PRODUCT') {
                dispatcher(Actions.Gift.getShopifyProducts(item.uid, options)).catch((error: any) => {throw error})
            }
        }
    }, 500, [dispatcher, item, options.search]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        primaryAction.action({
            selections: selectedItems?.map((item) => {
                return {
                    selectedId: item?.id,
                    selectedVariant: item?.variant
                }
            })
        });
    }

    const handlePrimaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (primaryAction.type === 'reset') {
            setState({
                form: data || {
                    ...initialData,
                    ...(showProductLink && { productLink: '' })
                },
                medias: initialData.medias,
                selectedItems: initialData.selectedItems,
                options: { search: '' }
            });
        }

        if (!item) {
            throw new Error('Missing item');
        }

        dispatcher(Actions.Gift.setGiftType(item?.uid, { gift_type: 'SHOPIFY' }, {
            callback() {
                if (selectedItems) {
                    primaryAction.action({
                        selections: selectedItems.map((item) => {
                            return {
                                selectedId: item?.id,
                                selectedVariant: item?.variant
                            }
                        })
                    });
                }
            }
        })).catch((error: any) => {throw error})
    }

    const handleSecondaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        secondaryAction.action();
    };

    /**
     * Matches items
     * @param itemA
     * @param itemB
     */
    const isItemEqual = (itemA: ShopifyItem, itemB: ShopifyItem): boolean => {
        return itemA.id === itemB.id && itemA.variant === itemB.variant;
    }

    /**
     * Recalculate the selection on click
     * @param item selected changed
     * @param items selected items
     */
    const addOrRemoveItem = (item: ShopifyItem, items: ShopifyItem[]): ShopifyItem[] => {
        const length = items.length;
        items = items?.filter(candidate => !isItemEqual(candidate, item));
        if (items.length === length) {
            items.push(item);
        }
        return items;
    }

    const handleShopifyItemClick = (item: ShopifyItem) => {
        setShownError('')
        setState((prev) => {
            const items = mode === 'PRODUCT'  ? addOrRemoveItem(item, prev.selectedItems ? prev.selectedItems : []) : [item];
            return ({
                ...prev,
                form: {
                    ...prev.form,
                    name: item.title,
                    description: '',
                    value: item.variant_price ? item.variant_price : item.price?.minVariantPrice?.amount,
                },
                medias: items.map(value => Helpers.formatMedia(value.image)),
                selectedItems: items
            });
        });
    }

    const name = useMemo(() => {
        return mode === 'PRODUCT' ? 'product' : 'gift card';
    }, [mode]);

    const description = useMemo(() => {
        return mode === 'PRODUCT' ? 'Choose one or more products from your Shopify store:' : 'Choose a gift card from your Shopify store:';
    }, [mode]);

    const reconnectShopify = () => {
        const newTab = window.open('', '_blank');
        const storeUrl = settings?.shopify.storeUrl;
        if (newTab && storeUrl) {
            newTab.location.href = storeUrl;
        }
    }

    return (
        <>
            <LeftContent>
                {children}
                <HyperLink target="_blank" href="https://hashgifted.com/help/shopify-integration">Learn more about #gifted and Shopify</HyperLink>

                {loading || item?.gift_type === 'SHOPIFY' ? (
                    <>
                        <LoadingGift>
                            <Loader status="loading" />
                            <h1>Creating your gift from Shopify</h1>
                        </LoadingGift>

                        <FormActions>
                            <StyledButton
                                size={'medium'}
                                disabled={disabledSubmit}
                                loading={true}
                                onClick={handlePrimaryActionClick}
                            >
                                {primaryAction.name}
                            </StyledButton>

                            <StyledButton
                                size={'medium'}
                                theme={'outline'}
                                disabled={true}
                                onClick={handleSecondaryActionClick}
                            >
                                {secondaryAction.name}
                            </StyledButton>
                        </FormActions>

                    </>
                ) : (
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <FormLabel>
                            {description}
                        </FormLabel>

                        {mode === 'PRODUCT' && (
                            <Search>
                                <StyledInput
                                    type={'search'}
                                    name={'search'}
                                    placeholder='Search for a product'
                                    label={''}
                                    value={options.search}
                                    onChange={(_, value) => setState((prev) => ({
                                        ...prev,
                                        options: {
                                            ...prev.options,
                                            search: value
                                        }
                                    }))}
                                >
                                    <SearchIcon />
                                </StyledInput>

                                <NextLineIcon />
                            </Search>
                        )}
                    </FormGroup>

                    <ProductList
                        items={shopifyItems}
                        mode={mode}
                        selectedItems={selectedItems}
                        loading={loadingShopifyItems}
                        onShopifyItemClick={handleShopifyItemClick}
                    />

                    {(!loadingShopifyItems && shopifyItems.length === 0 && options.search !== '') && (!shopifyIsDisconnected) && (
                        <NoResult
                            image={'search'}
                            title={`We didn't find a ${name} matching '${options.search}'`}
                        />
                    )}

                    {(!loadingShopifyItems && shopifyItems.length === 0 && options.search === '') && (!shopifyIsDisconnected) && (
                        <NoResult
                            image={'search'}
                            title={`We didn't find any ${name}'s in your Shopify store`}
                        />
                    )}

                    {(shopifyIsDisconnected) && (
                        <>
                            <NoResult
                                image={'search'}
                                title={`Shopify has been disconnected!`}
                            />

                            <Button onClick={() => reconnectShopify()} theme="aqua" size="small" >Reconnect Shopify</Button>

                        </>
                    )}


                    {shownError.length ? <p style={{color: 'red', textAlign: 'center', fontSize: '14px'}}>{shownError}</p> : null}

                    {(!disabledActions && !disabledInputs) && (
                        <FormActions>
                            <StyledButton
                                size={'medium'}
                                disabled={disabledSubmit}
                                loading={loading &&
                                    status === 'set-gift-creative-shopify'}
                                onClick={handlePrimaryActionClick}
                            >
                                {primaryAction.name}
                            </StyledButton>

                            <StyledButton
                                size={'medium'}
                                theme={'outline'}
                                disabled={loading}
                                onClick={handleSecondaryActionClick}
                            >
                                {secondaryAction.name}
                            </StyledButton>
                        </FormActions>
                    )}
                </Form>

                )}
            </LeftContent>

            <RightContent ref={rightContent}>
                {selectedItems.length <= 1 && (
                    <MobilePreview
                        name={selectedItems[0] ? selectedItems[0]?.title : ''}
                        price={selectedItems[0] ? mode === 'PRODUCT' ? selectedItems[0].price.minVariantPrice.amount : selectedItems[0].variant_price : 0}
                        media={medias.length > 0 ? medias[0].gallery_path : undefined}
                        image={medias.length > 0 ? medias[0].gallery_path : undefined}
                    />
                )}
                {selectedItems.length > 1 && (
                    <>
                        <Alert 
                            style={{
                                width: '80%',
                            }}
                            type="info" 
                            title={`Creators will get ${selectedItems.length} items when accepted`}
                            message="Upon accepting this gift, creators will receive all items listed. They can then choose from variants like size, colour, and material for individual items."
                        />
                        <BundleProductList items={selectedItems} onShopifyItemClick={handleShopifyItemClick}/>
                    </>
                )}
            </RightContent>
        </>
    );
}
