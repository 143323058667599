import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
    labelFor?: string;
    required?: boolean;
    className?: string;
}

const Container = styled.label`
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    line-height: 24px;
    color: var(--grey-9);

    span {
        &:first-of-type {
            order: 5;
        }

        &:last-of-type {
            order: 4;
            color: var(--error-active);
        }
    }
`;

export function FormLabel({
    labelFor,
    required,
    className,
    children
}: PropsWithChildren<Props>) {
    return (
        <Container
            data-testid={'form-label-component'}
            htmlFor={labelFor}
            className={className}
        >
            {children}

            {required && (
                <span>*</span>
            )}
        </Container>
    );
}