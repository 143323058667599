import {CSSProperties, useMemo} from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { UserIcon } from 'app/icons';
import completedContentService from "../../../../services/completedContentService";
import {useAppSelector} from "../../../../store";

interface Props {
    content: SocialMediaContent
    loading?: boolean;
    style?: CSSProperties;
    onClick(id: string): void;
}

const StyledIcon = styled(UserIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: auto;
    transform: translate(-50%, -50%);

    path {
        fill: var(--text-alt);
    }
`;

const Image = styled.div<{
    image?: string;
}>`
    position: relative;
    height: 128px;
    background-color: var(--blue);
   
    ${(props) => props.image && `
        background-image: url(${props.image});
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        ${StyledIcon} {
            display: none;
        }
    `}
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px 12px;
    user-select: none;

    span {
        &:first-child {
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.5px;
            color: var(--grey-9);
        }

        &:last-child {
            font-size: 12px;
            line-height: 16px;
            color: var(--grey-6);
        }
    }
`;

const Container = styled(motion.div) <{
    $loading?: boolean;
}>`
    flex-shrink: 0;
    width: 244px;
    border-radius: 20px;
    background-color: var(--grey-2);
    overflow: hidden;
    cursor: pointer;

    &:active:not(:focus-within) {
        transition: transform 300ms ease;
        transform: scale(0.95) !important;
    }

    ${(props) => props.$loading && `
        pointer-events: none;
        
        ${Image},
        ${Desc} span {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '##############';
                visibility: hidden;
            }
        }

        ${Desc} span:last-child {
            margin-top: 1px;
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }
`;

export function CompletedContentListItem({
    content,
    loading,
    onClick
}: Props) {

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const animation = {
        transition: {
            type: 'spring',
            duration: 0.8
        },
        variants: {
            loading: { opacity: 1, scale: 1, y: 0 },
            hidden: { opacity: 0, scale: 0.8, y: 80 },
            show: { opacity: 1, scale: 1, y: 0 },
        }
    };

    const imageUrl = useMemo(() => {
        if (content.socialMedia.items.length <= 0 || !settings) {
            return null;
        }
        const mediaItem = content.socialMedia.items[0];
        return completedContentService.getThumbnail(settings, mediaItem);
    }, [content, settings])

    return (
        <Container
            {...animation}
            $loading={loading}
            onClick={() => onClick(content.influencer.uid)}
        >
            <Image image={imageUrl || ''}>
                {!loading && (
                    <StyledIcon />
                )}
            </Image>

            <Desc>
                <span>{content.influencer.name}</span>

                <span>
                    {!loading && `Last post on ${Helpers.formatDate(content.socialMedia.posted_at)}`}
                </span>
            </Desc>
        </Container>
    );
}
