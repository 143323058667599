import {
    applyMiddleware,
    CombinedState,
    compose,
    createStore, Middleware
} from 'redux';
import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector
} from 'react-redux';
import reduxThunk from 'redux-thunk';
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

import { Constants } from './constants';
import { Actions } from './actions';
import { Middlewares } from './middlewares';
import { Reducers, initialState } from './reducers';
import { PayloadAction } from "@reduxjs/toolkit";

const stateSyncConfig = {
    whitelist: [
        Constants.Auth.Login,
        Constants.Auth.Logout,
        Constants.Profile.Shopify,
        Constants.Auth.Verify
    ]
};

const composeEnhancers = ((window as any)
    .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
        trace: true,
        traceLimit: 25
    }) || compose) as typeof compose || compose;

const rootReducers = (
    state: CombinedState<AppReducer> | undefined,
    action: PayloadAction<any>
) => {
    if (action.type === Constants.Auth.Logout) {
        const newState = {
            ...initialState,
            global: {
                ...initialState.global,
                booting: false,
                settings: state?.global.settings
            },
        } as CombinedState<AppReducer>

        return Reducers(newState, action);
    }

    return Reducers(state, action);
}

const useAppDispatch = () => useDispatch<AppDispatch>();

const useAppSelector: TypedUseSelectorHook<AppReducer> = useSelector;

//  Middleware<{}, any, Dispatch<AnyAction>>
const stateSyncMiddleware = createStateSyncMiddleware(stateSyncConfig);

const appliedMiddleware = applyMiddleware< Middleware<any>>(
    reduxThunk,
    stateSyncMiddleware,
    ...[Middlewares.Auth]
);
const enhancers = composeEnhancers(appliedMiddleware);
const Store = createStore(
    rootReducers,
    enhancers
);

initMessageListener(Store);

export {
    Actions,
    Constants,
    Store,
    useAppDispatch,
    useAppSelector
};
