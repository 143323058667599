import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import MultiRangeSlider from './@multirangeslider';
import { FormLabel } from '../FormLabel';
import ReactTooltip from "react-tooltip";

export type RangeValue = number[];

interface Props {
    name: string;
    label: string;
    value: RangeValue,
    min?: number;
    max?: number;
    required?: boolean;
    disabled?: boolean;
    onChange(name: string, value: RangeValue): void;
    increment?: number;
    tooltip?: string;
}

const Slider = styled.div<{
    $disabled?: boolean;
}>`
    position: relative;
    width: 100%;
    height: 16px;

    .container {
        width: 100%;
        height: 100%;

        input {
            width: 100%;
            z-index: 3;
            
            &::-webkit-slider-thumb {
                height: 16px;
                width: 16px;
                margin-top: 2px;
                border: 6px solid var(--blue);
                box-shadow: none;
                background-color: var(--background-alt);
            }
            
            &::-moz-range-thumb {
                height: 16px;
                width: 16px;
                margin-top: 2px;
                border: 6px solid var(--blue);
                box-shadow: none;
                background-color: var(--background-alt);
            }
        }

        .slider {
            width: calc(100% - 1px);

            .slider__track {
                width: calc(100% - 1px);
                height: 2px;
                border-radius: 12px;
                background-color: var(--grey-3);
            }
    
            .slider__range {
                height: 2px;
                border-radius: 12px;
                background-color: var(--blue);
            }

            .slider__left-value,
            .slider__right-value {
                display: none;
            }
        }
    }

    ${(props) => props.$disabled && `
        pointer-events: none;

        .container {
            pointer-events: none;
            
            input {
                &::-webkit-slider-thumb {
                    border-color: var(--grey-6);
                }
                
                &::-moz-range-thumb {
                    border-color: var(--grey-6);
                }
            }
    
            .slider {
                .slider__range {
                    background-color: var(--grey-6);
                }
            }
        }
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

export function FormMultiSlider({
    name,
    label,
    value,
    min,
    max,
    required,
    disabled,
    children,
    onChange,
    increment,
    tooltip
}: PropsWithChildren<Props>) {
    const handleChange = (range: number[]) => {
        if (!Helpers.arrayEquals(range, value)) {
            onChange(name, range);
        }
    }

    return (
        <Container data-testid={'form-multislider-component'} data-tip={tooltip}>
            {!!tooltip && (
                <ReactTooltip />
            )}
            {label && (
                <FormLabel required={required}>
                    {label}
                </FormLabel>
            )}

            <Slider $disabled={disabled}>
                <MultiRangeSlider
                    min={min || 0}
                    max={max || 100}
                    value={value}
                    disabled={disabled}
                    increment={increment}
                    onChange={({ min, max }: Dynamic) =>
                        !disabled && handleChange([min as number, max as number])}
                />
            </Slider>

            {children}
        </Container>
    );
}
