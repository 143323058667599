import { combineReducers } from 'redux';

import { Constants } from '../constants';
import {AnyAction, PayloadAction} from "@reduxjs/toolkit";

export const initialState: ProfileReducer = {
    brand: null,
    team: [],
    representative: null,
    billing: null,
    invoices: [],
    notifications: [],
    status: 'idle',
    loading: false,
    loadingBilling: false,
    loadingInvoices: false,
    loadingSubscription: false,
    loadingNotifications: false
}

const brand = (
    state = initialState.brand,
    action: AnyAction|PayloadAction<BrandProfile>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.Brand:
            return payload as BrandProfile;
        case Constants.Profile.Shopify:
            return { ...state, shopify: payload } as BrandProfile;
        case Constants.Profile.Clear:
            return initialState.brand;
        default: return state;
    }
}

const team = (
    state = initialState.team,
    action: AnyAction | PayloadAction<Team[]>
): Team[] => {    const { payload, type } = action;
    switch (type) {
        case Constants.Profile.Team:
            return payload as Team[];
        default: return state as [];
    }
}


const representative = (
    state = initialState.representative,
    action: AnyAction|PayloadAction<RepresentativeProfile>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.Representative:
            return payload as RepresentativeProfile;
        case Constants.Profile.Clear:
            return initialState.representative;
        default: return state;
    }
}

const billing = (
    state = initialState.billing,
    action: PayloadAction<SubscriptionBilling>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.Billing:
            return payload;
        case Constants.Profile.Clear:
            return initialState.billing;
        default: return state;
    }
}

const invoices = (
    state = initialState.invoices,
    action: PayloadAction<Invoice[]>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.Invoices:
            return payload;
        case Constants.Profile.Clear:
            return initialState.invoices;
        default: return state;
    }
}

const notifications = (
    state = initialState.notifications,
    action: AnyAction|PayloadAction<AppNotification[]>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.Notifications:
            return payload as AppNotification[];
        case Constants.Profile.ReceivedNotification:
            return state.concat([payload]);
        case Constants.Profile.Clear:
            return initialState.notifications;
        default: return state;
    }
}

const status = (
    state = initialState.status,
    action: PayloadAction<string>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.Status:
            return payload;
        case Constants.Profile.Clear:
            return initialState.status;
        default: return state;
    }
}

const loading = (
    state = initialState.loading,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.Loading:
            return payload;
        case Constants.Profile.Clear:
            return initialState.loading;
        default: return state;
    }
}

const loadingBilling = (
    state = initialState.loadingBilling,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.LoadingBilling:
            return payload;
        case Constants.Profile.Clear:
            return initialState.loadingBilling;
        default: return state;
    }
}

const loadingInvoices = (
    state = initialState.loadingBilling,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.LoadingInvoices:
            return payload;
        case Constants.Profile.Clear:
            return initialState.loadingInvoices;
        default: return state;
    }
}

const loadingSubscription = (
    state = initialState.loadingSubscription,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.LoadingSubscription:
            return payload;
        case Constants.Profile.Clear:
            return initialState.loadingSubscription;
        default: return state;
    }
}

const loadingNotifications = (
    state = initialState.loadingNotifications,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Profile.LoadingNotifications:
            return payload;
        case Constants.Profile.Clear:
            return initialState.loadingNotifications;
        default: return state;
    }
}

export default combineReducers<ProfileReducer>({
    brand,
    team,
    representative,
    billing,
    invoices,
    notifications,
    status,
    loading,
    loadingBilling,
    loadingInvoices,
    loadingSubscription,
    loadingNotifications
});
