import styled from 'styled-components';

import {PropsWithChildren} from "react";

import Check from '../../../../images/check.png'
import Cross from '../../../../images/cross.png'
import StarterStar from '../../../../images/starter-star.png'
import Megaphone from '../../../../images/megaphone.png'
import Trophy from '../../../../images/trophy.png'
import { PlanType } from 'services/subscriptionService';

interface Props {
    title: string;
    plan: PlanType;
    description: string;
    selectedPlanFrequency: SubscriptionFrequency;
    alreadyActive: boolean;
    onNext(): void;
    setPlanAndInterval(plan: PlanType, interval: SubscriptionFrequency): void
}


export function PlanColumn({
    title,
    plan,
    description,
    selectedPlanFrequency,
    alreadyActive,
    onNext,
    setPlanAndInterval
}: PropsWithChildren<Props>) {

    const priceData = {
        Starter: {
            'MONTH': 'A$99',
            'YEAR': 'A$997',
            yearSavings: 'Save $191'
        },
        Grow: {
            'MONTH': 'A$149',
            'YEAR': 'A$1501',
            yearSavings: 'Save $287'
        },
        Pro: {
            'MONTH': 'A$349',
            'YEAR': 'A$3517',
            yearSavings: 'Save $671'
        }
    }

    const textMap = {
        Starter: {
            campaigns: '1 campaign',
            accepteds: '10 creators',
            matches: '30 creators'
        },
        Grow: {
            campaigns: '3 campaigns',
            accepteds: '30 creators',
            matches: '90 creators'
        },
        Pro: {
            campaigns: 'Unlimited campaigns',
            accepteds: 'Unlimited creators',
            matches: 'Unlimited creatorss'
        }
    }

    const choosePlanIcon = () => {
        switch(plan) {
            case PlanType.STARTER:
                return StarterStar
            case PlanType.GROW:
                return Megaphone
            case PlanType.PRO:
                return Trophy
            default:
                return
        }

    }

    const handleCtaBtn = () => {
        setPlanAndInterval(plan, selectedPlanFrequency)
        onNext();
    }

    return (
        <>
            <Container className={`${alreadyActive ? 'already-active' : null}`}>

                {alreadyActive ? (
                    <AlreadyActivePlan>You are on this plan</AlreadyActivePlan>
                ) : null}


                <h1 className="center-align">
                    <img className="plan-icon" src={choosePlanIcon()} />
                    {title}
                </h1>
                <p>{description}</p>

                <PriceRow>
                    <h2>{priceData[plan][selectedPlanFrequency]}</h2>
                    <p>/{selectedPlanFrequency.toLowerCase()}</p>
                    {selectedPlanFrequency === 'YEAR' ? <Savings>{priceData[plan].yearSavings}</Savings> : null}
                </PriceRow>

                <button className={`above-btn ${alreadyActive ? 'already-active' : null}`} onClick={() => alreadyActive ? null : handleCtaBtn()}>{alreadyActive ? 'You are on this plan' : `Sign up for ${title}`}</button>

                <h3>Features you'll love:</h3>

                <FeatureItem>
                    <img src={Check} />
                    <p>Post <strong>{textMap[plan].campaigns}</strong> per month</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={Check} />
                    <p>Gift up to <strong>{textMap[plan].accepteds}</strong> per campaign</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={Check} />
                    <p>Match with up to <strong>{textMap[plan].matches}</strong> per campaign</p>
                </FeatureItem>

                {/* <FeatureItem>
                    <img src={Check} />
                    <p>Shopify integration</p>
                </FeatureItem> */}

                {/* <FeatureItem>
                    <img src={Check} />
                    <p>Auto capture of Instagram posts & reels</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={Check} />
                    <p>Auto capture of TikTok videos</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={Check} />
                    <p>Auto capture of YouTube videos & shorts</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={plan !== PlanType.STARTER ? Check : Cross} />
                    <p>Auto Capture of Instagram stories</p>
                </FeatureItem> */}

                <FeatureItem>
                    <img src={plan !== PlanType.STARTER ? Check : Cross} />
                    <p>Granular targeting of creators (age, gender, location etc)</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={plan === PlanType.PRO ? Check : Cross} />
                    <p>Content licensing (ability to use content for ads & amplification)</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={plan === PlanType.PRO ? Check : Cross} />
                    <p>Measure post performance (reach, engagement, likes)</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={plan === PlanType.PRO ? Check : Cross} />
                    <p>Dedicated session with an expert</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={plan === PlanType.PRO ? Check : Cross} />
                    <p>Priority customer support</p>
                </FeatureItem>

                <FeatureItem>
                    <img src={plan === PlanType.PRO ? Check : Cross} />
                    <p>Exposure boost - creators see your campaign first when they open the app!</p>
                </FeatureItem>

                <button className={`${alreadyActive ? 'already-active' : null}`} onClick={() => alreadyActive ? null : handleCtaBtn()}>{alreadyActive ? 'You are on this plan' : `Sign up for ${title}`}</button>
            </Container>
        </>
    );
}

const AlreadyActivePlan = styled.div`
    position: absolute;
    top: -35px;
    left: -8.75px;
    height: 40px;
    width: calc(100% + 17px);
    background: rgb(116, 215, 194);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    font-weight: 600;
    color: var(--blue);
`

const Container = styled.div`
    position: relative;
    height: auto;
    box-shadow: 0 0 2px rgb(65 62 62 / 63%);
    background: white;
    border-radius: 14px;
    padding: 40px;
    width: 32%;
    margin-top: 16px;

    &.already-active {
        border: 8px solid rgb(116, 215, 194);
    }

    h1 {
        color: var(--blue);
        font-size: 28px;
        margin: 0;
        padding: 0;
    }

    h3 {
        color: #707070;
        font-size: 14px;
        font-weight: 600;    
        margin: 0;
        padding: 0;
        margin-top: 10px;
    }

    p {
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 13px;
        color: #707070;
    }

    .plan-icon {
        height: 60px;
        width: 60px;
    }

    .center-align {
        display: flex;
        align-items: center;
    }

    button {
        width: 100%;
        box-shadow: none;
        color: #262262;
        text-align: center;
        white-space: nowrap;
        background-color: #75d5c3;
        border: 1.5px solid #262262;
        border-radius: 4px;
        padding: 11px 20px;
        font-family: Mont, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.3;
        text-decoration: none;
        transition: color .2s, background-color .2s, box-shadow .3s, transform .3s;
        margin-top: 10px;
        cursor: pointer;

        &:hover {
            color: #fff;
            background-color: #262262;
            transform: translate(0, -4px);
            box-shadow: 0 6px 16px -6px rgb(150 1 22 / 39%);        
        }

        &.above-btn {
            margin-bottom: 12px;
        }

        &.already-active {
            background-color: var(--grey-4);
            color: black;
            cursor: not-allowed;

            &:hover {
                transform: none;
                background-color: var(--grey-4);
                box-shadow: none;
            }
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
    }
`

const PriceRow = styled.div`
    display: flex;
    align-items: baseline;

    h2 {
        color: var(--blue);
        font-size: 29px;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 14px;
    }
`

const FeatureItem = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;

    p {
        margin: 0;
        padding: 0;
        color: var(--blue);
        font-size: 13px;
    }

    img {
        height: 36px;
        width: 36px;
        margin-right: 10px;
    }
`

const Savings = styled.div`
    color: #fff;
    background-color: #ffb800;
    border-radius: 6px;
    margin-left: 10px;
    padding: 5px 16px;
    font-size: 14px;
    font-weight: 700;
`
