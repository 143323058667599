import styled from 'styled-components';

interface Props {
    status: string;
    className?: string;
    thick?: boolean;
}

const Status = styled.span``;

const Container = styled.div<{
    $status?: string;
    $thick?: boolean;
}>`
    font-size: 4px;
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    vertical-align: top;
    border: 1px solid var(--grey-3);
    border-left-color: var(--blue);
    animation-name: loader-spin;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &,
    &:after {
        width: 8em;
        height: 8em;
        border-radius: 50%;
    }

    ${(props) => props.$thick && `
        border-width: 3px;
        

        animation-duration: .75s !important;
    `}

    ${(props) => props.$status?.includes('success') && `
        transition: border, opacity 500ms ease-out;
        animation: none;
        border-color: var(--success-active);

        ${Status} {
            &:after {
                content: '';
                position: absolute;
                top: 4em;
                left: 1.8095238095em;
                width: 2em;
                height: 4em;
                border-top: 1px solid var(--success-active);
                border-right: 1px solid var(--success-active);
                transform: scaleX(-1) rotate(135deg);
                transform-origin: left top;
                animation-name: checkmark;
                animation-duration: 1.2s;
                animation-timing-function: ease;
                opacity: 1;
            }
        }
    `}

    ${(props) => props.$status?.includes('error') && `
        transition: border, opacity 500ms ease-out;
        animation: none;
        border-color: var(--error-active);

        ${Status} {
            position: absolute;
            top: 50%;
            left: 50%;

            &:before,
            &:after {
                position: absolute;
                width: 2em;
                height: 2em;
                content: '';
                transform-origin: left top;
                opacity: 1;
                border-top: 1px solid var(--error-active);
                border-right: 1px solid var(--error-active);
                animation-name: crossmark;
                animation-duration: 1.2s;
                animation-timing-function: ease;
            }

            &:before {
                top: -6px;
                left: 5px;
                transform: scaleX(-1) rotate(45deg);
            }

            &:after {
                transform: scaleX(-1) rotate(225deg);
                top: 5px;
                left: -5px;
            }
        }
    `}

    @keyframes loader-spin {
        0%
        {
            transform: rotate(0deg);
        }
        100%
        {
            transform: rotate(360deg);
        }
    }
    
    @keyframes checkmark {
        0%
        {
            width: 0;
            height: 0;
            opacity: 1;
        }
        20%
        {
            width: 2em;
            height: 0;
            opacity: 1;
        }
        40%
        {
            width: 2em;
            height: 4em;
            opacity: 1;
        }
        100%
        {
            width: 2em;
            height: 4em;
            opacity: 1;
        }
    }

    @keyframes crossmark {
        0%
        {
            width: 0;
            height: 0;
            opacity: 1;
        }
        20%
        {
            width: 2em;
            height: 0;
            opacity: 1;
        }
        40%
        {
            width: 2em;
            height: 2em;
            opacity: 1;
        }
        100%
        {
            width: 2em;
            height: 2em;
            opacity: 1;
        }
    }
`;

export function Loader({
    status,
    className,
    thick
}: Props) {
    return (
        <Container
            $status={status}
            $thick={thick || false}
            className={className}
        >
            <Status />
        </Container>

    );
}