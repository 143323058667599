import styled from 'styled-components';

export const FormRadio = styled.span<{
    checked?: boolean;
}>`
    width: 20px;
    height: 20px;
    border: 1px solid var(--grey-6);
    border-radius: 50%;
    background-color: var(--background-alt);
    transition: border 150ms ease;

    ${(props) => props.checked && `
        border: 7px solid var(--blue);
    `}
`;