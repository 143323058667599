import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import {BundleProductListItem} from "../BundleProductListItem";

interface Props {
    items: ShopifyItem[];
    selectedItems?: ShopifyItem[];
    loading?: boolean;
    onShopifyItemClick(item: ShopifyItem): void;
}

const Container = styled(motion.div) <{
    $loading?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 700px;
    padding: 10px 52px;
    overflow: hidden auto;
    overflow: hidden overlay;

    &:empty {
        height: 0;
    }

    ${(props) => props.$loading && `
        pointer-events: none;
    `}
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: var(--grey-9);
`;

const TotalPrice = styled.span`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: var(--grey-9);
`;

export function BundleProductList({
    items,
    selectedItems,
    loading,
    onShopifyItemClick
}: Props) {
    const animation = {
        initial: loading ? 'loading' : 'hidden',
        animate: loading ? 'loading' : 'show',
        variants: {
            show: {
                transition: {
                    staggerChildren: 0.075
                }
            }
        }
    };

    const placeholder: ShopifyItem[] = Array(4).fill('').map(() => ({
        id: Helpers.generateId(),
        variantId: '',
        title: '',
        image: '',
        images: [],
        price: {
            minVariantPrice: { amount: 0 },
            maxVariantPrice: { amount: 0 }
        },
        type: '',
        totalInventory: 0
    }));

    const list = loading ? placeholder : items;

    const isItemSelected = (item: ShopifyItem) => {
        return !!selectedItems?.find(found => found?.id === item.id && found?.variant === item.variant);
    }

    const totalPrice = items.reduce((total, item) => {
        return total + item.price.minVariantPrice.amount;
    }, 0);

    return (
        <Container
            {...animation}
            $loading={loading}
        >
            <Title>
                You have included {items.length} products in your bundle
            </Title>

            {list.map((item, index) => (
                <BundleProductListItem
                    key={`${item.id}-${item.variant || index}`}
                    item={item}
                    selected={isItemSelected(item)}
                    loading={loading}
                    onClick={() => onShopifyItemClick(item)}
                />
            ))}
            
            <TotalPrice>
                Gift bundle value: ${totalPrice.toFixed(2)}
            </TotalPrice>

        </Container>
    );
}
