import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Badge } from 'app/components';
import { FilterSelect } from '../FilterSelect';
import { useMediaQuery } from 'usehooks-ts';


export interface TabItem {
    id: string;
    name: string;
    badgeId?: string;
}

interface Props {
    items: TabItem[];
    selected?: TabItem;
    className?: string;
    onChange(selected: TabItem): void;
    unreadByTab?: Dynamic;
    mobileStyle?: any; // any js style
}

export function Tab({
    items,
    selected,
    className,
    children,
    onChange,
    unreadByTab,
    mobileStyle
}: PropsWithChildren<Props>) {
    const handleMobileOnClick = (id: string, name: string) => {
        onChange({
            id,
            name
        });
    }

    const smallDevice = useMediaQuery('(max-width:900px)');

    return (
        <Container
            data-testid={'tab-component'}
            className={className}
        >
            <Header style={items.length <= 2 ? {display: 'flex'} : {}}>
                {items.map(({ id, name, badgeId }) => (
                    <Item
                        key={id}
                        $active={id === selected?.id}
                        onClick={() => onChange({
                            id,
                            name
                        })}
                    >
                        {name}

                        {(unreadByTab && badgeId) && unreadByTab[badgeId] > 0 ? (
                            <WaveCountBadge>{unreadByTab[badgeId]}</WaveCountBadge>
                        ) : null}

                    </Item>
                ))}
            </Header>

            <MobileHeader style={items.length <= 2 ? {display: 'none'} : {}}>

                <FilterSelect
                    style={smallDevice ? mobileStyle : {}}
                    onTab={true}
                    changeFilterItems={(val: IdName) => handleMobileOnClick(val.id as string, val.name)}
                    selected={selected || {id: '', name: ''}}
                    options={items}
                    whiteBkg={true}
                />


            </MobileHeader>
            <Body>
                {children}
            </Body>
        </Container>
    );
}


export const Item = styled.span<{
    $active?: boolean
}>`
    position: relative;
    font-size: 14px;
    line-height: 24px;
    padding: 7px 24px;
    border-bottom: 2px solid var(--grey-3);
    color: var(--grey-6);
    transition: border-color 300ms ease, color 300ms ease;
    cursor: pointer;
    z-index: 1;
    user-select: none;
    
    &:hover {
        color: var(--grey-9);
    }

    ${(props) => props.$active && `
        border-color: var(--grey-9);
        color: var(--grey-9);
    `}

`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }

`;

const MobileHeader = styled.div<{
    $active?: boolean
}>`
    display: none;

    @media (max-width:900px) and (min-width:0px) {
        display: flex;
        align-items: center;
        position: relative;
    }
`

const Body = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const WaveCountBadge = styled(Badge)`
    position: absolute;
    top: -6px;
    right: -6px;
    letter-spacing: 0px;
    border-radius: 8px;
    background-color: var(--error-active);
    color: var(--text-alt);
`;

const Container = styled(Body)``;
