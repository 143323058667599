import { AnalyticsProfile } from 'services/analyticsService';
import LogRocket from 'logrocket';
import errorReporter from "./errorReporter";
import {ClientSettings} from "./settingsService";

class LogRocketService {

  private profile: AnalyticsProfile|null = null;
  private settings: ClientSettings| null = null;

  private logRocketInit = false;

  startLogRocket(settings: ClientSettings) {
    if (this.logRocketInit) {
      return this;
    }
    this.settings = settings;
    if (!settings.logrocket.web) {
      return null;
    }
    const key = settings.logrocket.web;
    key && LogRocket.init(key);
    this.logRocketInit = true;
    return this;
  }

  identify(profile: AnalyticsProfile) {
    this.profile = profile;

    if (!this.logRocketInit) {
      return;
    }
    try {
      LogRocket.identify(profile.id, {
        email: profile.email as string,
        status: profile.status,
      });
    } catch (reason) {
      errorReporter.report('could not send event to log rocket', reason);
    }
  }

  track(eventName: string, props: Dynamic) {
    if (!this.logRocketInit) {
      return;
    }
    try {
      LogRocket.track(eventName, {
        ...props,
      });
    } catch (reason) {
      errorReporter.report('could not send event to log rocket', reason);
    }
  }

  unidentify() {
    if (this.logRocketInit) {
      LogRocket.startNewSession();
    }
  }
}

const logRocketService = new LogRocketService();
export default logRocketService;
