import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { API } from 'api';
import { AppLogo, Loader } from 'app/components';

const StyledAppLogo = styled(AppLogo)`
    svg {
        transform: scale(1.15);
    }
`;

const Caption = styled(motion.span)`
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -1px;
    color: var(--grey-6);
`;

const StyledLoader = styled.span`
    transform: scale(1.4);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
    width: 100vw;
    height: 100vh;
`;

export function EmailVerificationCallbackPage() {
    const animation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { delay: 0.5 }
    };

    const location = useLocation();

    const [title, setTitle] = useState<string>('Verifying your email address...');
    const [status, setStatus] = useState<string>('loading');

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const setError = (title: string) => {
        setTitle(title)
        setStatus('error');
    }

    useEffect(() => {
        if (!token) {
            setError('Missing token parameter');
            return;
        }

        API.Shared.verifyEmail(token)
            .then((data) => {
                setTitle('Email verified');
                setStatus('success');

                switch (data.type) {
                    case 'I':
                        redirectWithDelay('https://go.hashgifted.com/explore');
                        break;
                    case 'R':
                        redirectWithDelay('/');
                        break;
                    default:
                        throw new Error(`Could not redirect`)
                }
            })
            .catch(() => {
                setError('Could not verify your email address. Please try again.')
            });
    }, [token]);

    const redirectWithDelay = (url: string) => {
        setTimeout(() => {
            window.location.href = url
        }, 5000);
    }

    return (
        <Container data-testid={'email-verify-callback-page'}>
            <StyledAppLogo />

            <Caption {...animation}>
                {title}
            </Caption>

            <StyledLoader>
                <Loader status={status} />
            </StyledLoader>
        </Container>
    );
}
