import {ReactNode, useRef} from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';

import {Hooks} from 'utils';
import {Button} from '../Button';

interface Props {
    type?: 'completed'
    | 'delete'
    | 'drafted'
    | 'reviewing'
    | 'subscribed-reviewing'
    | 'not-subscribed-drafted'
    | 'subscription-required'
    | 'password-changed';
    show: boolean;
    onClose: () => void;
    primaryAction?: any;
    errors?: ApiErrors
}

const Image = styled.img`
    width: 220px;
    height: 220px;
`;

const Title = styled.span`
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    margin-top: -20px;
    margin-bottom: 20px;
    letter-spacing: -1.25px;
    color: var(--grey-9);
    text-align: center;
`;

const Desc = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: var(--grey-6);
    white-space: break-spaces;
    margin-bottom: 12px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }
`;

export function ErrorModal({
    type,
    show,
    onClose,
    primaryAction,
    errors
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);

    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    const renderContent = () => {
        const image = 'lightning';
        const title = 'Submission Error';

        const errorMsgArray: string[] = [];
        for (const value in errors) {
            if (value) {
                errors[value].forEach((errorMessage: any) => errorMsgArray.push(errorMessage as string));
            }
        }

        const typeName = type as string;
        return (
            <Body>
                <Image
                    src={require(`../../../images/${image}.png`)}
                    alt={`${typeName} gift`}
                />

                <Title>
                    {title}
                </Title>

                {errorMsgArray.map((err: string) => (
                    <Desc>
                        {err}
                    </Desc>
                ))}
            </Body>
        );
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'gift-modal-component'}
                >
                    <Content
                        {...contentAnimation}
                        ref={contentRef}
                    >
                        {renderContent()}

                        <Actions>
                            {primaryAction && (
                                <Button onClick={onClose}>
                                    Understood
                                </Button>
                            )}
                        </Actions>
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
}
