import styled from 'styled-components';

import { API } from 'api';
import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import { Color } from 'utils';
import {
    Badge,
    Breadcrumbs,
    Button,
    InfoCard,
    InfoCardHeader, Link
} from 'app/components';
import { PrivateLayout } from 'app/layouts';
import { ReactComponent as ShopifyIcon } from './shopify.svg';
import errorReporter from "../../../services/errorReporter";
import HyperLink from "../../components/HyperLink";
import { useEffect, useMemo } from 'react';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';

const ListItemImage = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 80px;
    border-radius: 8px;
    background-color: var(--grey-2);

    img {
        width: 100%;
        height: auto;
    }
`;

const ListItemStatus = styled(Badge) <{
    $active?: boolean;
}>`
    background-color: ${() => Color.hexVarToRGBA('--grey-5', 0.25)};
    color: var(--grey-7);
    width: min-content;

    ${(props) => props.$active && `
        background-color: ${Color.hexVarToRGBA('--aqua', 0.25)};
        color: var(--success-active);
    `}
`

const ListItemDesc = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    span:first-child {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: var(--grey-9);
    }
`;

const ListItemAction = styled(Button)`
    margin-left: auto;
`;

const ListItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 16px;
    border: 1px solid var(--grey-4);
    border-radius: 12px;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
`;

const Body = styled.div`
    width: 100%;
    padding: 0 8px;
    margin: 0 auto;
`;

const Container = styled.div`
    
    margin-top: 24px;
`;

export function IntegrationsPage() {
    const dispatcher = useAppDispatch();

    const {
        brand
    } = useAppSelector((state) => state.profile);

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const shopifyConnected = brand?.shopify &&
        brand.shopify.status === 'CONNECTED';

    // TODO: disconnect can be moved
    // inside the profileActions
    const handleShopfyConnectionToggle = () => {
        analyticsEvents.settingsShopifyConnect();
        if (brand && shopifyConnected) {
            API.Shopify.disconnect(brand.uid).then(() => {
                dispatcher(Actions.Profile.setShopifyConnection(false));
            }).catch((error) => {
                errorReporter.report('Could not disconnect shopify', error);
            })
        } else {
            const newTab = window.open('', '_blank');
            const storeUrl = settings?.shopify.storeUrl;
            if (newTab && storeUrl) {
                newTab.location.href = storeUrl;
            }
        }
    }


    useEffect(() => {
        analyticsEvents.settingsIntegrationsOpened();
    }, []);

    return (
        <PrivateLayout>
            <Container data-testid={'integrations-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'integrations', name: 'Integrations' }
                    ]}
                />

                <InfoCard>
                    <Body>
                        <InfoCardHeader>
                            Integrations
                        </InfoCardHeader>

                        <List>
                            <ListItem>
                                    <ListItemImage>
                                        <ShopifyIcon />
                                    </ListItemImage>

                                    <ListItemDesc>
                                        <span>Shopify</span>

                                        <ListItemStatus $active={shopifyConnected}>
                                            {shopifyConnected ? 'ACTIVE' : 'INACTIVE'}
                                        </ListItemStatus>

                                        <HyperLink href={'https://hashgifted.com/help/installing-the-shopify-app-and-creating-a-shopify-gift'}>Get help setting up Shopify</HyperLink>
                                    </ListItemDesc>

                                    <ListItemAction
                                        theme={'outline'}
                                        size={'small'}
                                        onClick={() => handleShopfyConnectionToggle()}
                                    >
                                        {shopifyConnected ? 'Disconnect' : 'Connect'}
                                    </ListItemAction>
                            </ListItem>
                        </List>
                    </Body>
                </InfoCard>
            </Container>
        </PrivateLayout>
    );
}
