import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { Loader } from '../Loader';

interface Props {
    show?: boolean;
    status: string;
    title?: string;
    message?: string;
}


const Message = styled.div`
    display: flex;
    flex-direction: column;
    
    span {
        &:first-child {
            font-size: 16px;
            line-height: 24px;
            color: var(--blue);
        }

        &:last-child {
            font-size: 14px;
            line-height: 24px;
            color: var(--grey-6);
        }
    }
    
    padding-left: 10px;
`;

const Body = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    right 32px;
    bottom: -50px;
    padding: 12px 20px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background: var(--background-alt);
    user-select: none;
    z-index: var(--loader-index);
`;

export function StatusLoader({
    show,
    status,
    title,
    message
}: Props) {
    const animation = {
        initial: { opacity: 0, y: '100%' },
        animate: { opacity: 1, y: -80 },
        exit: { opacity: 0, y: '100%' },
        layout: true,
        transition: { bounce: 0 }
    };

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence>
            {show && (
                <Container
                    {...animation}
                    data-testid={'status-loader-component'}
                >
                    <Body>
                        <Loader status={status} />

                        {(title || message) && (
                            <Message>
                                {title && (
                                    <span>{title}</span>
                                )}

                                {message && (
                                    <span>{message}</span>
                                )}
                            </Message>
                        )}
                    </Body>
                </Container>
            )}
        </AnimatePresence>
    );
}
