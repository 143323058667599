import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Routing } from '../routing';
import {Actions, Constants, useAppDispatch, useAppSelector} from '../store';
import { Splashscreen } from '../app/components';
import settingsService from "../services/settingsService";
import * as Sentry from "@sentry/react";
import 'intro.js/introjs.css';
import logRocketService from "../services/logRocketService";
import PaymentRequiredHandler from "./components/PaymentRequiredHandler";
import errorReporter from "../services/errorReporter";
import { ErrorPage } from './pages/ErrorPage';


const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

Sentry.init({  
    dsn: "https://4bd5da8882174daa9ec7d1f4e55ae24f@o1297785.ingest.sentry.io/6542490",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    environment: (process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : null)?.includes('//api.hashgifted.com') ? 'production' : 'staging'
});

function App() {
    const navigate = useNavigate();

    const dispatcher = useAppDispatch();

    const appState = useAppSelector((state) => state);

    const {
        auth: { signup },
        global: { booting, settings},
        profile: {
            representative,
            brand
        }
    } = appState;

    const [signedUp, setSignedUp] = useState(false)

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const affiliateCode = urlParams.get('atp');
        if (affiliateCode) {
            localStorage.setItem('affiliateCode', affiliateCode);
        }

        settingsService.loadSettings()
            .then((settings) => {
                dispatcher({
                    type: Constants.Global.Settings,
                    payload: settings
                });

                dispatcher({
                    type: Constants.Global.Tags,
                    payload: settings.tags
                });
            }).catch((reason) => {
                console.error('Encountered error loading settings', reason);
                errorReporter.report('could not load settings when app boots', reason);
                dispatcher(Actions.App.setBooting(false));
            })
    }, [])

    useEffect(() => {
        if (!settings || (representative && brand)) {
            return;
        }
        const storedCreds = localStorage.getItem(settingsService.credentialsStorageKey);
        if (!storedCreds) {
            setTimeout(() => dispatcher(Actions.App.setBooting(false)), 250);
        } else {
            const creds = JSON.parse(storedCreds) as Auth;
            if (creds?.status === 'ACTIVE' && !creds?.access_token) {
                localStorage.removeItem(settingsService.credentialsStorageKey)
            } else if (creds) {
                dispatcher(Actions.Auth.verify(creds)).catch((error: any) => {throw error})
            } else {
                setTimeout(() => dispatcher(Actions.App.setBooting(false)))
            }
        }
    }, [dispatcher, settings]);

    useEffect(() => {
        if (signup.user && !signedUp) {
            setSignedUp(true);
        }
    }, [navigate, signup]);

    useEffect(() => {
        settings && logRocketService.startLogRocket(settings);
    }, [settings]);

    if (booting) {
        return (
            <Sentry.ErrorBoundary fallback={<ErrorPage />}>
                <Container data-testid={'app'}>
                    <Splashscreen />
                </Container>
            </Sentry.ErrorBoundary>
        )
    }

    return (
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <Container data-testid={'app'}>
                <Routing />
                <PaymentRequiredHandler/>
            </Container>
        </Sentry.ErrorBoundary>
    );
}

export default App;
