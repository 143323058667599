import { useEffect } from 'react';
import styled from 'styled-components';

import { Actions, useAppDispatch, useAppSelector } from 'store';
import { LogInForm } from 'app/forms';
import { PublicLayout } from 'app/layouts';
import { LogInPageHeader } from './Header';
import { Alert } from 'app/components';

const Body = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: auto;

    p {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }
`;

export function LogInPage() {
    const dispatcher = useAppDispatch();

    const {
        auth: { loading },
        global: { alert }
    } = useAppSelector((state) => state);

    useEffect(() => {
        return () => {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [dispatcher]);

    const handleSubmit = (form: LoginForm) => {
        dispatcher(Actions.Auth.login(form)).catch((error: any) => {throw error});
    }

    return (
        <PublicLayout>
            <Container data-testid={'logn-page'}>
                <Body>
                    <LogInForm
                        errors={alert?.errors}
                        loading={loading}
                        onSubmit={handleSubmit}
                    >
                        <LogInPageHeader
                            title={'Start #gifting today'}
                            desc={'Create unique photos, videos, reviews, testimonials, and social buzz through the power of influencer gifting.'}
                        />

                        {alert && alert.errors && Object.keys(alert.errors).length === 0 && (
                            <Alert {...alert} />
                        )}
                    </LogInForm>
                </Body>
            </Container>
        </PublicLayout>
    );
}
