import AnalyticsService from './analyticsService';
import {ClientSettings} from "./settingsService";
import { Dict } from 'mixpanel-browser';

class AnalyticsEvents {

    private analyticsService: AnalyticsService;
    private lastTrackedTabName: string | null = null;

    constructor(settings: ClientSettings) {
        this.analyticsService = new AnalyticsService(settings);
    }

    login(profile: RepresentativeProfile, brand: BrandProfile) {
        this.analyticsService.identify(this.analyticsService.createProfileProps(profile));
        this.analyticsService.track('brand_app_login', AnalyticsEvents.propsFromBrand(brand));
        this.analyticsService.identify(this.analyticsService.createProfileProps(profile));
        this.analyticsService.track('brand_app_login',AnalyticsEvents.propsFromBrand(brand));
    }

    signup(email: string, id: string, status: string): void {
        console.log('signing up with ', email, id, status,)
        this.analyticsService.identify({
            id: id,
            email: email,
            status: status
        })

        this.analyticsService.track('brand_app_signed_up', { email, id, status, });
    }

    logout(brand: BrandProfile) {
        this.analyticsService.track('brand_app_logout', AnalyticsEvents.propsFromBrand(brand));
        this.analyticsService.unidentify();
    }

    giftWavesOpened(gift: GiftItem, brand: BrandProfile) {
        this.analyticsService.track('brand_gift_waves_open', AnalyticsEvents.propsFromBrandAndGift(brand, gift));
    }

    giftAudienceOpened(gift: GiftItem, brand: BrandProfile) {
        this.analyticsService.track('brand_gift_audience_open', AnalyticsEvents.propsFromBrandAndGift(brand, gift));
    }

    giftCreativeOpened(gift: GiftItem, brand: BrandProfile) {
        this.analyticsService.track('brand_gift_creative_open', AnalyticsEvents.propsFromBrandAndGift(brand, gift));
    }

    giftDeliverablesOpened(gift: GiftItem, brand: BrandProfile) {
        this.analyticsService.track('brand_gift_deliverables_open', AnalyticsEvents.propsFromBrandAndGift(brand, gift));
    }

    giftContentOpened(gift: GiftItem, brand: BrandProfile) {
        this.analyticsService.track('brand_gift_content_open', AnalyticsEvents.propsFromBrandAndGift(brand, gift));
    }

    giftVisibilityToggled(gift: GiftItem, brand: BrandProfile, visible: boolean) {
        const props = AnalyticsEvents.propsFromBrandAndGift(brand, gift, { visible: visible });
        this.analyticsService.track('brand_gift_visibility_toggled', props);
    }

    giftInfluencerAccepted(gift: GiftItem, brand: BrandProfile, influencer: Influencer) {
        this.analyticsService.track(
            'brand_gift_influencer_accepted',
            AnalyticsEvents.propsFromGiftAndBrandAndInfluencer(gift, brand, influencer)
        );
    }

    giftInfluencerRejected(gift: GiftItem, brand: BrandProfile, influencer: Influencer) {
        this.analyticsService.track(
            'brand_gift_influencer_rejected',
            AnalyticsEvents.propsFromGiftAndBrandAndInfluencer(gift, brand, influencer)
        );
    }

    giftInfluencerShortlisted(gift: GiftItem, brand: BrandProfile, influencer: Influencer) {
        this.analyticsService.track(
            'brand_gift_influencer_shortlisted',
            AnalyticsEvents.propsFromGiftAndBrandAndInfluencer(gift, brand, influencer)
        );
    }
    giftInfluencerCompleted(gift: GiftItem, brand: BrandProfile, influencer: Influencer) {
        this.analyticsService.track(
            'brand_gift_influencer_completed',
            AnalyticsEvents.propsFromGiftAndBrandAndInfluencer(gift, brand, influencer)
        );
    }

    giftInfluencerReviewed(gift: GiftItem, brand: BrandProfile, influencer: Influencer, form: ReviewForm) {
        const props: Dynamic = AnalyticsEvents.propsFromGiftAndBrandAndInfluencer(gift, brand, influencer);
        props.rating = form.rating;
        props.publicReview = form.review;
        props.feedback = form.feedback
        this.analyticsService.track('brand_influencer_reviewed', props);
    }

    giftInfluencerProfileOpened(gift: GiftItem, brand: BrandProfile, influencer: Influencer) {
        this.analyticsService.track(
            'brand_gift_influencer_profile_opened',
            AnalyticsEvents.propsFromGiftAndBrandAndInfluencer(gift, brand, influencer)
        );
    }

    giftCreateStart(brand: BrandProfile) {
        this.analyticsService.track('brand_gift_create_start', AnalyticsEvents.propsFromBrand(brand));
    }

    giftCreateCreative(brand: BrandProfile) {
        this.analyticsService.track('brand_gift_create_creative', AnalyticsEvents.propsFromBrand(brand));
    }

    giftCreateAudience(brand: BrandProfile) {
        this.analyticsService.track('brand_gift_create_audience', AnalyticsEvents.propsFromBrand(brand));
    }

    giftCreateDeliverables(brand: BrandProfile) {
        this.analyticsService.track('brand_gift_create_deliverables', AnalyticsEvents.propsFromBrand(brand));
    }

    giftCreateCompleted(gift: GiftItem, brand: BrandProfile) {
        this.analyticsService.track('brand_gift_create_completed', AnalyticsEvents.propsFromBrandAndGift(brand, gift));
    }

    giftInviteCreator(props: Dict) {
        this.analyticsService.track('brand_creator_invited', props);
    }

    firstWebAppOpen() {
        this.analyticsService.track('First Web App Open');
    }

    preOnboardingVisited() {
        this.analyticsService.track('pre_onboarding_screenview');
    }

    onboardingStarted() {
        this.analyticsService.track('brand_onboarding_started');
        this.analyticsService.track('brand_onboarding_profile_brand_opened');
    }

    onboardingSocials() {
        this.analyticsService.track('brand_onboarding_socials_opened');
    }

    onboardingUserProfile() {
        this.analyticsService.track('brand_onboarding_user_profile_opened');
    }

    onboardingShopifyProfile() {
        this.analyticsService.track('brand_onboarding_shopify_opened');
    }

    onboardingExited(props: Dict) {
        this.analyticsService.track('brand_onboarding_exited', props as Dynamic);
    }

    dashboardOpened() {
        this.analyticsService.track('brand_dashboard_opened');
    }

    creatorProfileOpened(props: Dict) {
        this.analyticsService.track('brand_creator_profile_opened', props as Dynamic);
    }

    dashboardTabOpened(id: string) {
        if (id.includes('active')) {
            this.analyticsService.track('brand_dashboard_active_gifts_opened');
        } else {
            this.analyticsService.track('brand_dashboard_past_gifts_opened');
        }
    }

    dashboardBlogLearnOpened() {
        this.analyticsService.track('brand_dashboard_learn_menu_opened');
    }

    dashboardCreateGiftOpened() {
        this.analyticsService.track('brand_dashboard_create_gifts_opened');
    }

    dashboardCreateGiftButtonClicked() {
        this.analyticsService.track('brand_dashboard_create_button_clicked');
    }

    dashboardSubscriptionButtonClicked() {
        this.analyticsService.track('brand_dashboard_subscription_clicked');
    }

    subscriptionPlansCheckoutSuccess(props: Dict) {
        this.analyticsService.track('brand_subscription_plan_checkout', props);
    }

    subscriptionPlansSubscribed(props: Dict) {
        this.analyticsService.track(
            'brand_subscription_plan_subscribed',
            props
        );
    }

    subscriptionPlansCanceled(props: Dict) {
        this.analyticsService.track('brand_subscription_plan_canceled', props);
    }

    subscriptionPlansPaused(props: Dict) {
        this.analyticsService.track('brand_subscription_plan_paused', props);
    }

    subscriptionPlansResumed(props: Dict) {
        this.analyticsService.track('brand_subscription_plan_resumed', props);
    }

    dashboardCaseStudyOpened() {
        this.analyticsService.track('brand_dashboard_casestudy_opened');
    }

    dashboardCaseStudyBlogNameOpened(blog: Blog) {
        if (blog.link.includes('/blog')) {
            this.analyticsService.track('brand_dashboard_blog_opened', {
                type: 'Blog',
                blog_name: blog.title,
                blog_id: blog.guid,
            });
        } else {
            this.analyticsService.track(
                'brand_dashboard_casestudy_name_opened',
                {
                    type: 'Case Studies',
                    case_name: blog.title,
                    case_id: blog.guid,
                }
            );
        }
    }

    giftManageOpened(props: Dict) {
        this.analyticsService.track('brand_gift_manage_opened', props);
    }

    giftCreateType(props: Dict) {
        this.analyticsService.track('brand_gift_create_type_opened', props);
    }

    giftCreateFlow(steps: string) {
        switch (steps) {
            case 'creative':
                this.analyticsService.track(
                    'brand_gift_create_flow_creative_opened'
                );
                break;
            case 'audience':
                this.analyticsService.track(
                    'brand_gift_create_flow_audience_opened'
                );
                break;
            case 'deliverables':
                this.analyticsService.track(
                    'brand_gift_create_flow_deliverables_opened'
                );
                break;
            case 'shopify_product':
                this.analyticsService.track(
                    'brand_gift_create_flow_shopify_product_opened'
                );
                break;
            case 'shopify_gift_card':
                this.analyticsService.track(
                    'brand_gift_create_flow_shopify_gift_card_opened'
                );
                break;
            case 'subscription_plans':
                this.analyticsService.track(
                    'brand_gift_create_flow_subscription_plans_opened'
                );
                break;
            case 'subscription_checkout':
                this.analyticsService.track(
                    'brand_gift_create_flow_subscription_checkout_opened'
                );
                break;
            default:
                this.analyticsService.track('brand_gift_create_flow_started');
                break;
        }
    }

    giftManageMatchesOpened(tabName: string) {
        switch (tabName) {
            case 'New':
                this.analyticsService.track(
                    'brand_gift_manage_matches_new_opened'
                );
                break;
            case 'Shortlisted':
                this.analyticsService.track(
                    'brand_gift_manage_matches_shortlisted_opened'
                );
                break;
            case 'Selected':
                this.analyticsService.track(
                    'brand_gift_manage_matches_selected_opened'
                );
                break;
            case 'Declined':
                this.analyticsService.track(
                    'brand_gift_manage_matches_declined_opened'
                );
                break;
            default:
                null;
        }
    }

    giftManageGiftTypeOpened(tabName: string) {
        switch (tabName) {
            case 'waves':
                this.analyticsService.track('brand_gift_manage_waves_opened');
                break;
            case 'audience':
                this.analyticsService.track(
                    'brand_gift_manage_audience_opened'
                );
                break;
            case 'creative':
                this.analyticsService.track(
                    'brand_gift_manage_creative_opened'
                );
                break;
            case 'deliverables':
                this.analyticsService.track(
                    'brand_gift_manage_deliverables_opened'
                );
                break;
            case 'completed-content':
                this.analyticsService.track(
                    'brand_gift_manage_completed_content_opened'
                );
                break;
            case 'reviews':
                this.analyticsService.track('brand_gift_manage_reviews_opened');
                break;
            default:
                break;
        }
    }

    giftFirstApplicant(props: Dynamic) {
        this.analyticsService.track(
            'brand_gift_first_applicant_retrieved',
            props
        );
    }

    giftFirstAcceptedApplicant(props: Dynamic) {
        this.analyticsService.track(
            'brand_gift_first_applicant_accepted',
            props
        );
    }

    settingsButtonOpened() {
        this.analyticsService.track('brand_settings_dropdown_button_opened');
    }

    settingsMyBrandOpened() {
        this.analyticsService.track('brand_settings_mybrand_opened');
    }

    settingsMyProfileOpened() {
        this.analyticsService.track('brand_settings_myprofile_opened');
    }

    settingsBrandReviewOpened() {
        this.analyticsService.track('brand_settings_brand_reviews_opened');
    }

    settingsBillingOpened() {
        this.analyticsService.track('brand_settings_billing_opened');
    }

    settingsIntegrationsOpened() {
        this.analyticsService.track('brand_settings_integrations_opened');
    }

    settingsShopifyConnect() {
        this.analyticsService.track('brand_settings_shopify_connect_opened');
    }

    settingsNotificationsOpened() {
        this.analyticsService.track('brand_settings_notifications_opened');
    }

    settingsHelpOpened() {
        this.analyticsService.track('brand_settings_help_opened');
    }

    waveConversationOpened(tabs: string, props: Dict) {
        this.analyticsService.track('wave_conversation_details_opened', {
            ...props,
            openedTabs: tabs,
        });
    }

    createATeamOpened() {
        this.analyticsService.track('brand_create_a_team_opened');
    }

    createATeamInviteSent() {
        this.analyticsService.track('brand_create_a_team_invite_sent');
    }

    private static propsFromGiftAndBrandAndInfluencer(gift: GiftItem, brand: BrandProfile, influencer: Influencer, props?: Dynamic) {
        return AnalyticsEvents.propsFromGift(
            gift,
            AnalyticsEvents.propsFromBrand(brand, AnalyticsEvents.propsFromInfluencer(influencer, props))
        );
    }

    private static propsFromBrandAndGift(brand: BrandProfile, gift: GiftItem, props?: Dynamic) {
        return AnalyticsEvents.propsFromBrand(brand, AnalyticsEvents.propsFromGift(gift, props));
    }

    private static propsFromGift(gift?: GiftItem, props?: Dynamic): Dynamic {
        if (!props) {
            props = {};
        }
        if (!gift) {
            return props;
        }
        props.gift_id = gift.uid;
        props.attributes = gift.attributes;
        props.gift_name = gift.name;
        props.gift_details = gift.analytic;
        let giftImageUrl = gift?.media?.[0]?.thumbnail_path;
        if (!giftImageUrl) {
            const media = gift.media ? gift.media : [];
            const found = media.find(() => true);
            if (found) {
                giftImageUrl = found.thumbnail_path;
            }
        }
        props.gift_image_url = giftImageUrl;
        props.gift_description = gift.description;
        return AnalyticsEvents.propsFromBrand(gift.brand, props);
    }

    private static propsFromBrand(brand: BrandProfile, props?: Dynamic): Dynamic {
        if (!props) {
            props = {};
        }
        props.brand_name = brand.name;
        props.brand_logo_url = brand.logo;
        props.brand_description = brand.description;
        props.brand_status = brand.status;
        props.brand_website = brand.website;
        return props;
    }

    private static propsFromInfluencer(influencer: Influencer, props?: Dynamic) {
        if (!props) {
            props = {};
        }
        props.influencer_name = influencer.name;
        props.influencer_headshot_image = influencer.headshot_image;
        props.influencer_bio = influencer.bio;
        props.influencer_last_seen_at = influencer.last_seen_at;
        return props;
    }
}
export default AnalyticsEvents;
