import {
    RefObject,
    useEffect,
    useLayoutEffect,
    useRef
} from 'react';

type ClickEvent = MouseEvent | TouchEvent;

const useEventListener = <
    KW extends keyof WindowEventMap,
    KH extends keyof HTMLElementEventMap,
    T extends HTMLElement | void = void,
    >(
        eventName: KW | KH,
        handler: (
            event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event,
        ) => void,
        element?: RefObject<T>,
) => {
    const savedHandler = useRef(handler);

    useIsomorphicLayoutEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const targetElement: T | Window = element?.current || window;

        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }

        const eventListener: typeof handler = event => savedHandler.current(event);

        targetElement.addEventListener(eventName, eventListener);

        return () => {
            targetElement.removeEventListener(eventName, eventListener);
        }
    }, [eventName, element]);
}

const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef<() => void | null>();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        const tick = () => {
            if (typeof savedCallback?.current !== 'undefined') {
                savedCallback?.current();
            }
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (event: ClickEvent) => void,
) => {
    useEffect(() => {
        const listener = (event: ClickEvent) => {
            const el = ref?.current;

            if (!el || el.contains(event.target as Node)) {
                return;
            }

            handler(event);
        }

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        }
    }, [ref, handler]);
}

export const Hooks = {
    useEventListener,
    useInterval,
    useOnClickOutside
};