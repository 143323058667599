import {
    ChangeEvent,
    MouseEvent,
    ReactNode,
    useEffect,
    useRef,
    useState
} from 'react';
import { motion } from 'framer-motion';
import styled, { CSSProperties } from 'styled-components';
import { Hooks } from 'utils';
import XIcon from '../../icons/close.svg';
import AngleDown from '../../icons/angle-down.svg'
import { FormLabel } from '../FormLabel';

export interface Option {
    id: string | number;
    name: string;
}

interface Props {
    name: string;
    options: Option[];
    selected?: Option;
    label: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    optionRenderer?(option: Option): ReactNode;
    selectedRenderer?(selected: Option): ReactNode;
    onChange(name: string, option: Option): void;
    noClearBtn?: boolean;
    showArrow?: boolean;
    pairValue?: any;
}

const SelectedItem = styled.div`
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    line-height: 24px;
    color: var(--grey-9);
    width: 100%;
    height: 100%;
    position: relative;
`;

const ClearInputBtn = styled.div`
    position: absolute;
    right: 12px;
`

const ExpandInputBtn = styled.div`

`

const OtionItem = styled.div<{
    $selected?: boolean;
}>`
    display: flex;
    column-gap: 8px;
    padding: 6px 10px;
    transition: background-color 300ms ease;
    cursor: pointer;
    width: 100%;

    span {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;    
    }

    &:hover {
        background-color: var(--grey-2);
    }

    ${(props) => props.$selected && `
        background-color: var(--grey-2);
    `}
`;

const Options = styled(motion.div)`
    display: flex;
    position: fixed;
    max-height: 264px;
    width: 200px !important;
    border: 1px solid var(--grey-2);
    border-radius: 12px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    overflow: hidden;

    > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 8px;
        overflow: hidden auto;
        overflow: hidden overlay;

        &:empty {
            padding: 20px;
    
            &:before {
                content: 'No available options';
            }
        }
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    min-height: 56px;
    padding: 8px 12px;
    border: 1.5px solid var(--grey-2);
    border-radius: 14px;
    background-color: var(--grey-1);
    color: var(--grey-9);
    transition: border-color 300ms ease;
    width: 100%;
    height: 100%;
`;

const Container = styled.div<{
    $focus?: boolean;
    $disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    user-select: none;
    cursor: pointer;

    ${(props) => props.$focus && `
        ${Content} {
            border-color: var(--blue);
        }
    `}

    ${(props) => props.$disabled && `
        pointer-events: none;

        ${Content} {
            background-color: var(--grey-3);
        }

        ${SelectedItem}:after {
            color: var(--grey-6);
        }
    `}
`;

const InputSearch = styled.input`
    background: none;
    border: none;
    width: 100%;
    height: 100%;
`

export function FormSelect({
    name,
    options,
    selected,
    label,
    placeholder,
    required,
    disabled,
    className,
    optionRenderer,
    selectedRenderer,
    onChange,
    noClearBtn,
    showArrow,
    pairValue
}: Props) {
    const id = `select-input-${name}`;

    const containerRef = useRef<HTMLDivElement>(null);

    const contentRef = useRef<HTMLDivElement>(null);

    const optionsRef = useRef<HTMLDivElement>(null);

    const [focus, setFocus] = useState<boolean>(false);

    const [hovered, setHovered] = useState<boolean>(false);

    const [style, setStyle] = useState<CSSProperties>({});

    const [inputValue, setInputValue] = useState<string>(selected?.name || '');

    const [oldPairValue, setOldPairValue] = useState<any>(pairValue || {name: '', id: 0});

    useEffect(() => {
        const defaultStyle = {
            top: '100vh',
            left: '100vw',
            width: 100,
            zIndex: 1050
        };

        setStyle(focus ? defaultStyle : {});
    }, [focus]);

    useEffect(() => {
        if (style.zIndex) {
            const contentEl = contentRef.current;
            const optionsEl = optionsRef.current;

            if (contentEl && optionsEl) {
                const contentRect = contentEl.getBoundingClientRect();
                const optionsRect = optionsEl.getBoundingClientRect();
                const commonStyle = {
                    left: contentRect.left,
                    width: contentRect.width
                };
                const totalHeight = (contentRect.top + contentRect.height + optionsRect.height);

                if (totalHeight > window.innerHeight) {
                    setStyle((prev) => ({
                        ...prev,
                        ...commonStyle,
                        top: (contentRect.top - 2) - optionsRect.height,
                    } as CSSProperties));
                } else {
                    setStyle((prev) => ({
                        ...prev,
                        ...commonStyle,
                        top: (contentRect.top + 2) + contentRect.height
                    }) as CSSProperties);
                }
            }
        }
    }, [style.zIndex]);

    useEffect(() => {
        if (selected && focus) {
            const index = options.findIndex((o) => o.id === selected.id);
            const firstChildEl = optionsRef.current?.firstElementChild;

            if (index >= 0 && firstChildEl) {
                const selectedTop = (firstChildEl.children[index] as HTMLDivElement)?.offsetTop;
                firstChildEl.scrollTo({ top: selectedTop });
            }
        }
    }, [options, selected, focus]);

    Hooks.useOnClickOutside(optionsRef, (event) => {
        if (!containerRef.current?.contains(event.target as HTMLElement)) {
            setFocus(false);
        }
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (focus && event.key === 'Escape') {
            setFocus(false);
        }
    }

    const handleChange = (option: Option, event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setInputValue(option.name)
        onChange(name, option);
        setFocus(false)
    }

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target?.value)
    }

    const renderSelected = () => {
        if (selected) {
            return selectedRenderer ?
                selectedRenderer(selected) :
                <InputSearch autoComplete={'new-off'} onChange={onInputChange} onFocus={() => setFocus(true)} placeholder={placeholder} onBlur={() => setFocus(false)} value={inputValue} />
        }

        return <InputSearch autoComplete={'new-off'} onChange={onInputChange} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} value={inputValue} placeholder={placeholder || ''} />;
    }

    const clearInput = () => {
        setInputValue('')
        onChange(name, {name: '', id: ''})
    }

    useEffect(() => {
        if (pairValue) {
            if (pairValue.id !== oldPairValue.id) {
                clearInput()
                setOldPairValue(pairValue)
            }
        }
    }, [pairValue])

    return (
        <Container
            data-testid={'form-select-component'}
            ref={containerRef}
            id={id}
            tabIndex={0}
            $focus={focus}
            $disabled={disabled}
            className={className}
            onKeyDown={handleKeyDown}
            onClick={(e) => {
                e.stopPropagation();
                setFocus(true)
            }}
            onMouseEnter={(() => setHovered(true))}
            onMouseLeave={() => setHovered(false)}
        >
            {label && (
                <FormLabel required={required}>
                    {label}
                </FormLabel>
            )}

            <Content ref={contentRef}>
                <SelectedItem>
                    {renderSelected()}
                    {hovered && !noClearBtn && (
                        <ClearInputBtn onClick={() => clearInput()}>
                            <img alt="closeBtn" height="13" src={XIcon} />
                        </ClearInputBtn>
                    )}
                    {showArrow && selected && (
                        <ExpandInputBtn>
                            <img alt="expand" height="13" src={AngleDown} />
                        </ExpandInputBtn>
                    )}
                </SelectedItem>

                {focus && (
                    <Options
                        ref={optionsRef}
                        tabIndex={0}
                        style={style}
                    >
                        <div>
                            {options.map((o) => (
                                o.name.toLowerCase().includes(inputValue.toLowerCase())) || noClearBtn ? (
                                    <OtionItem
                                        key={o.id}
                                        $selected={o.id === selected?.id}
                                        onMouseDown={(event) => handleChange(o, event)}
                                    >
                                        {optionRenderer ? optionRenderer(o) : o.name}
                                    </OtionItem>
                                ) : null
                            )}
                        </div>
                    </Options>
                )}
            </Content>
        </Container>
    );
}

export default FormSelect;
