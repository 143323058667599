import { Middleware } from 'redux';
import PusherJs, { Channel } from 'pusher-js';

import { Constants } from '../constants';
import settingsService from "../../services/settingsService";
import {PayloadAction} from "@reduxjs/toolkit";
import ErrorReporter from "../../services/errorReporter";
import {waveActions} from "../actions/waveActions";

let channel: Channel;

const _setSession = (payload: Auth) => {
    localStorage.setItem(
        settingsService.credentialsStorageKey,
        JSON.stringify(payload)
    );
}


const _startPusherEvents = (channel: Channel, dispatcher: AppDispatch) => {
    channel.bind('new.notification', (data: AppNotification) => {
        dispatcher({
            type: Constants.Profile.ReceivedNotification,
            payload: data
        });
    });

    channel.bind('producer.new.bubble', (data: WaveBubbleReceivedPayload) => {
        if (data?.bubble?.sender_type !== 'BRAND') {
            dispatcher({
                type: Constants.Wave.ReceivedBubble,
                payload: data
            });
            dispatcher(waveActions.setLastReceivedBubble(data.bubble));

            
        }

    })

    channel.bind('producer.read.bubble', (data: string) => {
        dispatcher({
            type: Constants.Wave.ReadBubble,
            payload: data
        });
    })

}

const _startPusherChannelListening = async (
    token: string,
    representativeId: string,
    brandId: string,
    dispatcher: AppDispatch
) => {
    console.log('Starting pusher');

    const settings = await settingsService.loadSettings(brandId);

    const authEndpoint = `${settingsService.baseApiUrl}producer/representative/${representativeId}/realtime/auth`;
    const pusher = new PusherJs(settings.pusher.key, {
        authEndpoint: authEndpoint,
        cluster: settings.pusher.cluster,
        forceTLS: true,
        auth: {
            headers: {
                authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        },
    });

    const channelName = `private-representative-${representativeId}`;

    channel = pusher.subscribe(channelName);

    _startPusherEvents(channel, dispatcher);
}

const _stoPusherChannelListening = () => {
    channel?.unsubscribe();
}


let tempBrandRepId : string | null = null;
let initializedPusher = false;

export const authMiddleware: Middleware<never, AppReducer, AppDispatch> = ({
    dispatch,
    getState
}) => (next) => (action : (dispatch: AppDispatch, getState: () => any) => any) => {
    if (typeof action === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return action(dispatch, getState);
    }

    const { type, payload } = action as PayloadAction<Auth>;

    const auth = payload;

    if (type === Constants.Auth.Login && payload.brand) {
        _setSession(auth);
        _startPusherChannelListening(
            auth.access_token,
            auth.brand_representative_id || '',
            auth.brand.brand_id,
            dispatch
        ).catch((error: any) => {throw error});
    }

    if (type === Constants.Auth.Logout) {
        _stoPusherChannelListening();
    }

    if (type === Constants.Auth.SignUp) {
        const signUp = action as PayloadAction<SignUpFlow>;
        signUp.payload.user && _setSession(signUp.payload.user);
    }

    if (auth?.brand_representative_id) {
        tempBrandRepId = auth.brand_representative_id
    }

    if ((payload?.brand_id && (payload?.brand_representative_id || tempBrandRepId)) && !initializedPusher) {
        _setSession(auth);
        const representativeId = (tempBrandRepId || payload.brand_representative_id) as string;
        _startPusherChannelListening(
            auth.access_token,
            representativeId,
            auth.brand_id as string,
            dispatch
        ).catch((error: any) => {
            ErrorReporter.report('Realtime chat did not initialize', error);
        });

        initializedPusher = true;
    }

    next(action);
}

