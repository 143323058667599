import {
    BrandProfilePage,
    DashboardPage,
    ForgotPasswordPage,
    GiftFlowPage,
    GiftViewPage,
    IntegrationsPage,
    LogInPage,
    LoginWithTokenPage,
    RepresentativeProfilePage,
    ShopifyAuthPage,
    SignUpPage,
    SubscriptionBillingPage,
    NotificationsPage,
    TeamsPage
} from '../app/pages';
import {EmailVerificationCallbackPage} from "../app/pages/EmailVerificationCallbackPage";
import { BrandReviewsPage } from '../app/pages/BrandReviewsPage';
import { TeamsInvitePage } from 'app/pages/TeamsInvitePage';

const privateRoutes: AppRoute[] = [
    {
        name: 'dashboard',
        path: '/',
        element: DashboardPage
    },
    {
        name: 'brandProfile',
        path: '/brand-profile',
        element: BrandProfilePage
    },
    {
        name: 'representativeProfile',
        path: '/representative-profile',
        element: RepresentativeProfilePage
    },
    {
        name: 'brandReviews',
        path: '/brand-reviews',
        element: BrandReviewsPage
    },
    {
        name: 'notifications',
        path: '/notifications',
        element: NotificationsPage
    },
    {
        name: 'subscriptionBilling',
        path: '/subscription-billing',
        element: SubscriptionBillingPage
    },
    {
        name: 'integrations',
        path: '/integrations',
        element: IntegrationsPage
    },
    {
        name: 'teams',
        path: '/teams',
        element: TeamsPage
    },
    {
        name: 'giftFlow',
        path: '/create/',
        element: GiftFlowPage
    },
    {
        name: 'giftFlow',
        path: '/create/:id',
        element: GiftFlowPage
    },
    {
        name: 'giftView',
        path: '/gift-view/:id',
        element: GiftViewPage
    },
    {
        name: 'giftViewWave',
        path: '/gift-view/:id/:waveId',
        element: GiftViewPage
    },
    {
        name: 'shopifyAuth',
        path: '/shopify/auth',
        element: ShopifyAuthPage
    },
    {
        name: 'emailVerify',
        path: '/email/verify',
        element: EmailVerificationCallbackPage
    }
];


const publicRoutes: AppRoute[] = [
    {
        name: 'signup',
        path: '/',
        element: SignUpPage
    },
    {
        name: 'signupVerify',
        path: 'signup/verify',
        element: SignUpPage
    },
    {
        name: 'login',
        path: '/login',
        element: LogInPage
    },
    {
        name: 'forgotPassword',
        path: '/forgot-password',
        element: ForgotPasswordPage
    },
    {
        name: 'invite',
        path: '/team/invite',
        element: TeamsInvitePage
    },
    {
        name: 'loginWithToken',
        path: '/admin/login',
        element: LoginWithTokenPage
    },
    {
        name: 'shopifyCallback',
        path: '/email/verify',
        element: EmailVerificationCallbackPage
    },
    {
        name: 'shopifyAuth',
        path: '/shopify/auth',
        element: ShopifyAuthPage
    },
];

export { privateRoutes, publicRoutes };
