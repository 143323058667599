import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { GiftReviewListItem } from '../GiftReviewListItem';

interface Props {
    items: any[];
    status: string;
    loading?: boolean;
    className?: string;
}

const Container = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
`;

export function GiftReviewList({
    items,
    status,
    loading,
    className
}: Props) {
    const animation = {
        initial: loading ? 'loading' : 'hidden',
        animate: loading ? 'loading' : 'show',
        variants: {
            show: {
                transition: {
                    staggerChildren: 0.075
                }
            }
        }
    };

    const placeholder = Array(4).fill('').map(() => ({
        uid: Helpers.generateId(),
        name: '',
        image: '',
        title: '',
        review: '',
        rating: '',
        media: [],
        status: status,
        reviewed_at: ''
    }));

    const list = loading ? placeholder : items;

    return (
        <Container
            {...animation}
            className={className}
        >
            {list.map((item) => (
                <GiftReviewListItem
                    key={item.uid}
                    name={item.name}
                    image={item.image}
                    rating={item.rating || 0}
                    title={item.title}
                    review={item.review}
                    media={item.media || []}
                    status={status}
                    reviewed_at={item.reviewed_at}
                    loading={loading}
                    is_anonymous={item.is_anonymous}
                />
            ))}
        </Container>
    );
}