import { combineReducers } from 'redux';

import { Constants } from '../constants';
import {PayloadAction} from "@reduxjs/toolkit";
import {ClientSettings} from "../../services/settingsService";

export const initialState: GlobalReducer = {
    alert: null,
    inputError: {},
    attributes: {},
    deliverables: [],
    giftStatus: [],
    locations: [],
    countries: [],
    cities: [],
    states: [],
    subscriptions: [],
    tags: [],
    booting: true,
    loading: false,
    showPaymentModal: {show: false, showAllPlans: false},
    showPaymentDialog: {show: false},
    settings: null
}

const alert = (
    state = initialState.alert,
    action: PayloadAction<ErrorResponse|null>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Alert:
            return payload;
        default: return state;
    }
}

const inputError = (
    state = initialState.inputError,
    action: PayloadAction<any>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.InputError:
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return payload;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        default: return state;
    }
}

const attributes = (
    state = initialState.attributes,
    action: PayloadAction<{
        [prop: string]: GiftAttribute[]
    }>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Attributes:
            return payload;
        default: return state;
    }
}

const deliverables = (
    state = initialState.deliverables,
    action: PayloadAction<Deliverable[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Deliverables:
            return payload;
        default: return state;
    }
}

const giftStatus = (
    state = initialState.giftStatus,
    action: PayloadAction<IdName[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.GiftStatus:
            return payload;
        default: return state;
    }
}

const locations = (
    state = initialState.locations,
    action: PayloadAction<IdName[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Locations:
            return payload;
        default: return state;
    }
}

const countries = (
    state = initialState.countries,
    action: PayloadAction<IdName[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Countries:
            return payload;
        default: return state;
    }
}

const cities = (
    state = initialState.cities,
    action: PayloadAction<IdName[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Cities:
            return payload;
        default: return state;
    }
}

const states = (
    state = initialState.states,
    action: PayloadAction<IdName[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.States:
            return payload;
        default: return state;
    }
}

const subscriptions = (
    state = initialState.subscriptions,
    action: PayloadAction<Subscription[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Subscriptions:
            return payload;
        default: return state;
    }
}

const tags = (
    state = initialState.tags,
    action: PayloadAction<Tag[]>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Tags:
            return payload;
        default: return state;
    }
}

const booting = (
    state = initialState.booting,
    action: PayloadAction<boolean>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Booting:
            return payload;
        default: return state;
    }
}

const loading = (
    state = initialState.loading,
    action: PayloadAction<boolean>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Loading:
            return payload;
        default: return state;
    }
}

const showPaymentModal = (
    state = initialState.showPaymentModal,
    action: PayloadAction<ShowPaymentModal>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.ShowPaymentModal:
            return payload;
        default: return state;
    }
}

const showPaymentDialog = (
    state = initialState.showPaymentDialog,
    action: PayloadAction<ShowPaymentDialog>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.ShowPaymentDialog:
            return {...payload};
        default: return state;
    }
};


const settings = (
    state = initialState.settings,
    action: PayloadAction<ClientSettings>
) => {
    const { type, payload } = action;

    switch (type) {
        case Constants.Global.Settings:
            return payload;
        default: return state;
    }
};


export default combineReducers<GlobalReducer>({
    alert,
    inputError,
    attributes,
    deliverables,
    giftStatus,
    locations,
    countries,
    cities,
    states,
    subscriptions,
    tags,
    booting,
    loading,
    showPaymentModal,
    showPaymentDialog,
    settings
});
