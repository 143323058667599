import { ReactNode } from 'react';
import styled from 'styled-components';

interface ColumnRendererProps<T> {
    key: string;
    row: T;
    column: ColumnProps<T>;
    rowIndex: number;
    columnIndex: number;
}

interface ColumnProps<T> {
    id: string;
    name: string;
    renderer?(data: string|Date, props: ColumnRendererProps<T>): ReactNode;
}

interface Props<T> {
    rows: T[];
    columns: ColumnProps<T>[];
    templateCols?: string;
    loading?: boolean;
    className?: string;
}

export const Column = styled.div`
    font-size: 14px;
    font-weight: 600;
    align-self: flex-start;
    line-height: 24px;
    color: var(--grey-9);
`;

const Row = styled.div`
    flex: 0 0 48px;
    display: grid;
    place-content: center;
    width: 100%;
    height: 48px;
    max-height: 48px;
    background: white;
    margin-bottom: 24px;
    padding-left: 24px;
    border-radius: 6px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${Column} {
        font-size: 11px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 2px;
        color: var(--grey-6);
    }
`;

const Body = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const Container = styled.div<{
    $templateCols?: string;
    $columnLength: number;
    $loading?: boolean;
}>`
    display: flex;
    flex-direction: column;

    ${Row} {
        grid-template-columns: repeat(${(props) => props.$columnLength}, 1fr);

        ${(props) => props.$templateCols && `
            grid-template-columns: ${props.$templateCols};
        `}
    }

    ${(props) => props.$loading && `
        ${Body} {
            ${Row} {
                border-radius: 4px;
                background: linear-gradient(
                    to right,
                    var(--grey-3),
                    var(--grey-2) 50%,
                    var(--grey-3) 80%
                ), var(--grey-3);
                background-position: 100% 0, 0 0;
                background-repeat: repeat-y;
                background-size: 200% 200%;
                animation: animate 1s infinite;

                &:before {
                    content: '###########';
                    visibility: hidden;
                }
            }
        }
    `}
`;

export function Table<T extends Dynamic>({
    rows,
    columns,
    templateCols,
    loading,
    className
}: Props<T>) {
    const columnRenderer = (
        row: T,
        column: ColumnProps<T>,
        rowIndex: number,
        columnIndex: number
    ) => {
        const { id, renderer } = column;
        const data = row[id] as string;

        if (renderer) {
            return renderer(data, {
                row,
                column,
                rowIndex,
                columnIndex,
                key: `${rowIndex}-${columnIndex}`
            });
        }

        return (
            <Column key={`${id}-${rowIndex}`} >
                {data}
            </Column>
        );
    }

    return (
        <Container
            data-testid={'table-component'}
            $templateCols={templateCols}
            $columnLength={columns.length}
            $loading={loading}
            className={className}
        >
            <Header>
                <Row style={{background: 'none'}}>
                    {columns.map(({ id, name }) => (
                        <Column key={id}>
                            {name}
                        </Column>
                    ))}
                </Row>
            </Header>

            <Body>
                {loading ?
                    (
                        <Row></Row>
                    ) : (
                        rows.map((item, rowIndex) => (
                            <Row key={rowIndex}>
                                {columns.map((column, columnIndex) =>
                                    columnRenderer(item, column, rowIndex, columnIndex))}
                            </Row>
                        ))
                    )
                }

                {!loading && rows.length === 0 && (
                    <Row>No available data</Row>
                )}
            </Body>
        </Container>
    );
}
