import styled from 'styled-components';

import { Actions, useAppDispatch, useAppSelector } from 'store';
import {
    Breadcrumbs,
    InfoCard,
    InfoCardHeader,
    StatusLoader
} from 'app/components';
import { PrivateLayout } from 'app/layouts';
import { NewPasswordForm, RepresentativeProfileForm } from 'app/forms';
import { useEffect, useMemo, useState } from 'react';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';

const Switcher = styled.span`
    font-size: 14px;
    font-weight: 600;
    align-self: center;
    line-height: 24px;
    color: var(--aqua);
    cursor: pointer;
`;

const StyledNewPasswordForm = styled(NewPasswordForm)`
    form {
        margin-top: 0;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 398px;
    margin: 0 auto;

    @media (max-width:900px) and (min-width:0px) {
        width: auto !important;
    }

`;

const Container = styled.div`
    
    margin-top: 24px;
`;

export function RepresentativeProfilePage() {
    const dispatcher = useAppDispatch();

    const {
        auth: {
            status: authStatus,
            loading: loadingAuth
        },
        global: { alert, settings },
        profile: {
            representative,
            status: profileStatus,
            loading: loadingProfile
        }
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const [step, setStep] = useState<number>(0);

    useEffect(() => {
        if (step === 0) {
            analyticsEvents.settingsMyProfileOpened();
        }
    }, [step]);

    useEffect(() => {
        return () => {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [dispatcher]);

    useEffect(() => {
        document
            .getElementsByTagName('main')[0]
            .scrollTo({
                top: 0,
                behavior: 'smooth'
            });
    }, [step]);

    const setRepresentativeProfile = (form: RepresentativeForm) => {
        dispatcher(Actions.Profile.setRepresentativeProfile(form, false)).catch((error: any) => {throw error});
    }

    const setRepresentativeProfileImage = (file: File) => {
        dispatcher(Actions.Profile.setRepresentativeProfileImage(file)).catch((error: any) => {throw error});
    }

    const changePassword = (data: NewPasswordForm) => {
        dispatcher(Actions.Auth.changePassword(data, () => {
            setStep(0);
        })).catch((error: any) => {throw error});
    }

    const getAuthMessage = () => {
        switch (authStatus) {
            case 'success':
                return 'Request sucessful';

            case 'error':
                return 'Request failed';

            case 'change-password':
                return 'Updating your password';

            default: return '';
        }
    }

    const getProfileMessage = () => {
        switch (profileStatus) {
            case 'success':
                return 'Request sucessful';

            case 'error':
                return 'Request failed';

            case 'set-representative-profile':
                return 'Updating your info';

            case 'set-representative-image':
                return 'Updating profile image';

            default: return '';
        }
    }

    const renderForm = () => {
        switch (step) {
            case 0:
                return (
                    <RepresentativeProfileForm
                        data={{
                            name: representative?.name || '',
                            mobile: representative?.mobile || '',
                            email: (representative?.email || representative?.auth.email) || ''
                        }}
                        headshotImage={representative?.headshot_image}
                        errors={alert?.errors}
                        buttonLabel={'Save changes'}
                        showEmail={true}
                        loading={loadingProfile}
                        onSubmit={setRepresentativeProfile}
                        onChangeImage={setRepresentativeProfileImage}
                        signUpFlow={false}
                    >
                        <InfoCardHeader>
                            My Profile
                        </InfoCardHeader>
                    </RepresentativeProfileForm>
                );
            case 1:
                return (
                    <StyledNewPasswordForm
                        buttonLabel={'Save changes'}
                        loading={loadingAuth}
                        onSubmit={changePassword}
                    >
                        <InfoCardHeader>
                            Change Password
                        </InfoCardHeader>
                    </StyledNewPasswordForm>
                );

            default: return null;
        }
    }

    useEffect(() => {
        analyticsEvents.settingsMyBrandOpened();
    }, []);

    return (
        <PrivateLayout>
            <Container data-testid={'representative-profile-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'representative-profile', name: 'My Profile' }
                    ]}
                />

                <InfoCard>
                    <Body>
                        {representative && (
                            renderForm()
                        )}

                        <Switcher onClick={() => setStep((prev) => prev ^= 1)}>
                            {step === 0 ? 'Change Password' : 'Update My Profile'}
                        </Switcher>
                    </Body>
                </InfoCard>
            </Container>

            <StatusLoader
                show={authStatus !== 'idle'}
                status={authStatus}
                title={'My Profile'}
                message={getAuthMessage()}
            />

            <StatusLoader
                show={profileStatus !== 'idle'}
                status={profileStatus}
                title={'My Profile'}
                message={getProfileMessage()}
            />
        </PrivateLayout>
    );
}
