import axios from './axios';

const getInstallToken = async () => {
    const response = await axios.post('/partner/shopify/token');
    return response.data as { token: string }
}

const validateInstall = async (shop: string) => {
    return axios.post('/partner/shopify/validate?shop=' + shop);
}

const verify = async (brandId: string, search: string) => {
    const url = `/producer/brands/${brandId}/shopify/verify` +
        `${search}`;
    await axios.get(url);
}

const getShopifyStatus = async (brandId: string) => {
    const url = `/producer/brands/${brandId}/shopify`;
    const result = await axios.get(url)
    return result.data as BrandShopifyStatus;
}

const disconnect = async (brandId: string) => {
    const url = `/producer/brands/${brandId}/shopify/disconnect`;
    try {
        await axios.delete(url);
    } catch (err: any) {
        alert('There was an error disconnecting your Shopify store.')
        throw err;
    }
}

const getProducts = (
    brandId: string,
    giftId: string,
    options?: { search: string, filter?: string }
) => {
    const { search = '', filter = '', } = options || {};
    const searchParams = new URLSearchParams({
        filter,
        search
    });
    const url = `/producer/brands/${brandId}/gifts/${giftId}/shopify/products?${searchParams.toString()}`;

    return new Promise<{ listing: ShopifyItem[] }>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as { listing: ShopifyItem[] });
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

export const shopifyAPI = {
    getInstallToken,
    validateInstall,
    verify,
    disconnect,
    getProducts,
    getShopifyStatus
};
