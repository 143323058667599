import {
    ReactNode,
    useCallback,
    useRef,
    useState
} from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';

import { Helpers, Hooks, ImageHelper } from 'utils';
import { CropIcon } from 'app/icons';
import { Button } from '../Button';
import { FormInput } from '../FormInput';
import { FormSlider } from '../FormSlider';
import errorReporter from 'services/errorReporter';

interface ModalAction {
    name: string;
    action(): void;
}

interface Props {
    image: string;
    show?: boolean;
    onClose(): void;
    primaryAction?: Omit<ModalAction, 'action'> & {
        action(file: File): void;
    };
    secondaryAction?: ModalAction;
}

const Header = styled.span`
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    padding: 16px 24px;
    text-align: center;
    letter-spacing: -1.25px;
    color: var(--grey-9);
`;

const CroppingArea = styled.div`
    position: relative;
    height: 210px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
`;

const StyledFormSlider = styled(FormSlider)`
    width: 210px;
`;

const StyledFormInput = styled(FormInput)`
    width: 108px;
    text-align: center;

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: var(--blue);
        }
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding: 24px;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 40px;
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: 1020;

    button {
        margin-top: auto;
    }
`;

export function ProfileUploadModal({
    image,
    show,
    onClose,
    primaryAction,
    secondaryAction
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

    const [zoom, setZoom] = useState<number>(1);

    const [areaPixels, setAreaPixels] = useState<Area | null>(null)

    const onCropComplete = useCallback((_: any, areaPixels: Area) => {
        setAreaPixels(areaPixels);
    }, []);

    const handlePrimaryAction = useCallback(() => {
        if (areaPixels) {
            ImageHelper.getCroppedProfile(
                image,
                areaPixels
            ).then((blob) => {
                primaryAction?.action(new File(
                    [blob as BlobPart],
                    `${Helpers.generateId()}.png`
                ));
            }).catch((error) => {
                errorReporter.report('Cropping error on profile upload modal', error);
            })
        }
    }, [image, primaryAction, areaPixels]);

    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'profile-upload-modal-component'}
                >
                    <Content
                        {...contentAnimation}
                        ref={contentRef}
                    >
                        <Header>
                            Adjust Photo
                        </Header>

                        <Body>
                            <CroppingArea>
                                <Cropper
                                    image={image}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    maxZoom={2}
                                    showGrid={false}
                                    zoomWithScroll={false}
                                    objectFit={'horizontal-cover'}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </CroppingArea>

                            <StyledFormSlider
                                name={'zoom'}
                                label={''}
                                min={1}
                                max={2}
                                step={0.001}
                                value={zoom}
                                onChange={(_, value) => setZoom(value)}
                            />

                            <StyledFormInput
                                type={'text'}
                                name={'zoom-value'}
                                label={''}
                                value={`${((zoom - 1) / 1 * 100).toFixed(2)}%`}
                                onChange={() => { }}
                            >
                                <CropIcon />
                            </StyledFormInput>
                        </Body>

                        <Actions>
                            {primaryAction && (
                                <Button onClick={() => handlePrimaryAction()}>
                                    {primaryAction.name}
                                </Button>
                            )}

                            {secondaryAction && (
                                <Button
                                    theme={'outline'}
                                    onClick={secondaryAction.action}
                                >
                                    {secondaryAction.name}
                                </Button>
                            )}
                        </Actions>
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
}
