import React, { useMemo } from 'react';
import {WaveStatus} from "../../../../api/wave";
import styled from "styled-components";
import {CheckCircleFilled, ExclamationCircleFilled, InfoCircleFilled} from "../../../icons";

export enum WaveStatusSimplified {
    ACCEPTED,
    COMPLETED,
    CHATTING,
    DECLINED,
    WITHDRAWN,
}

const waveStatusToSimplified = (wave: WaveItem): WaveStatusSimplified => {
    let status;
    switch (wave.status) {
        case WaveStatus.ACCEPTED:
            status = WaveStatusSimplified.ACCEPTED;
            break;
        case WaveStatus.COMPLETED:
            status = WaveStatusSimplified.COMPLETED;
            break;
        case WaveStatus.REJECTED:
            status = WaveStatusSimplified.DECLINED;
            break;
        default:
            status = WaveStatusSimplified.CHATTING;
            break;
    }
    if (wave.influencer_withdrawn_at) {
        status = WaveStatusSimplified.WITHDRAWN;
    }
    return status;
}

interface Props {
    wave: WaveItem;
}

export function WaveStatusMessage({ wave }: Props) {
    const status = useMemo<{
        message: string;
        icon?: JSX.Element;
    } | null>(() => {
        const simplified = waveStatusToSimplified(wave);
        switch (simplified) {
            case WaveStatusSimplified.ACCEPTED:
                return {
                    message: 'You have accepted this creator for gifting',
                    icon: <CheckIcon />,
                };
            case WaveStatusSimplified.COMPLETED:
                return {
                    message: 'This collaboration is complete',
                    icon: <InfoIcon />,
                };
            case WaveStatusSimplified.CHATTING:
                return {
                    message: 'You have not yet accepted this creator',
                    icon: <WarnIcon />,
                };
            case WaveStatusSimplified.DECLINED:
                return {
                    message: 'You have declined this creator',
                    icon: <WarnIcon />
                };
            case WaveStatusSimplified.WITHDRAWN:
                return {
                    message: 'The creator withdrew from your gift',
                    icon: <WarnIcon />
                };
            default:
                return null;
        }
    }, [wave]);

    if (!status) {
        return null;
    }

    return (
            <Container>
                {status.icon}
                {status.message}
            </Container>
    );
}

export default WaveStatusMessage;


const Container = styled.div`
  font-size: 14px;
  font-weight: 600;
  -webkit-box-align: center;
  text-align: center;
  line-height: 24px;
  padding-right: 10px;
  letter-spacing: -0.25px;
  word-break: break-word;
  color: var(--grey-9);
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width:900px) and (min-width:0px) {
    display: none;
  }
`;

const InfoIcon = styled(InfoCircleFilled)`
    path {
      fill: var(--info-active);
    }
  margin-right: 5px;
`

const CheckIcon = styled(CheckCircleFilled)`
  path {
    fill: var(--success-active);
  }
  margin-right: 5px;
`;

const WarnIcon = styled(ExclamationCircleFilled)`
  path {
    fill: var(--warning-active);
  }
  margin-right: 5px;
`;

