import styled, { CSSProperties } from 'styled-components';

import { Mapping } from 'utils';
import {PropsWithChildren} from "react";

interface Props {
    type: AlertTypes;
    title: string;
    message?: string;
    ctaLabel?: string;
    ctaAction?: () => void;
    className?: string;
    style?: CSSProperties;
    actionUrl?: string;
}

const Icon = styled.span`
    align-self: flex-start;
    display: flex;
    border-radius: 50%;
`;

const Desc = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    
    .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: var(--grey-9);
    }
    
    .additional-items {
        padding-top: 12px;
    }

    span {
        font-size: 14px;
        line-height: 24px;
        color: var(--grey-9);
    }
    
    .cta, .message {
        color: var(--grey-9);
        padding-top: 8px;
        font-size: 14px;
    }
    
    .cta {
        color: var(--grey-9);
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
        text-underline-offset: 1px;
        padding-top: 12px;
        font-weight: 600;
    }
    
`;

const Container = styled.div<{
    $type: AlertTypes;
}>`
    z-index: 99;
    flex-shrink: 0;
    display: flex;
    column-gap: 12px;
    position: relative;
    width: 100%;
    padding: 16px;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: var(--shadow-2);
    overflow: hidden;
    border: 1px solid var(--grey-2);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;

        ${(props) => props.$type !== 'hidden' && `
            background-color: var(--${props.$type}-active);
        `}
    }

    ${(props) => props.$type !== 'hidden' && `
        ${Icon} svg path {
            fill: var(--${props.$type}-active);
        }
    `}
`;

export function Alert({
    type,
    title,
    message,
    ctaLabel,
    ctaAction,
    className,
    style,
    children,
    actionUrl
}: PropsWithChildren<Props>) {

    const onClick = () => {
        if (ctaAction) {
            ctaAction();
        } else if (actionUrl) {
            window.open(actionUrl, '_blank');
        }
    }

    return (
        <Container
            data-testid={'alert-component'}
            $type={type}
            className={className}
            style={{ ...{ backgroundColor: 'white' }, ...style }}
        >
            <Icon className="left-alert">
                {Mapping.AlertType[type]}
            </Icon>

            <Desc>
                <span className="title">{title}</span>

                {!!message && (<span className='message'>{message}</span>)}

                {children && (
                    <div className={'additional-items'}>
                        {children}
                    </div>
                )}

                {((!!ctaLabel && !!ctaAction) || actionUrl) && (
                    <span className="cta" onClick={() => onClick()}>{ctaLabel}</span>
                )}
            </Desc>
        </Container>
    );
}
