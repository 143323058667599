import {combineReducers} from 'redux';

import {Constants} from '../constants';
import {PayloadAction} from "@reduxjs/toolkit";

export const initialState: WaveReducer = {
    list: [],
    view: null,
    paginations: {},
    status: 'idle',
    loading: true,
    loadingView: false,
    loadingReviews: false,
    loadingViewMore: false,
    showPIIModal: false,
    lastReceivedBubble: null,
}

const list = (
    state = initialState.list,
    action: PayloadAction<Wave[]>
): Wave[] => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.List:
            return payload;
        case Constants.Wave.Clear:
            return initialState.list;
        default: return state;
    }
}

const view = (
    state = initialState.view,
    action: PayloadAction<WaveItem|WaveBubbleReceivedPayload>
) => {
    const { payload, type } = action;
    switch (type) {
        case Constants.Wave.View:
            return payload as WaveItem;
        case Constants.Wave.ReceivedBubble:
            if (state) {
                const recieved = payload as WaveBubbleReceivedPayload;
                if (recieved.bubble?.wave?.uid === state?.uid) {
                    return {
                        ...state,
                        bubbles: state.bubbles?.concat([recieved.bubble])
                    };
                }
            }
            return state;
        case Constants.Wave.ReadBubble:
            if (state) {
                state.bubbles[state.bubbles.length - 1].seen_at = `${new Date()}`
                return {
                    ...state,
                    bubbles: state.bubbles
                };
            }
            return state;
        case Constants.Wave.Clear:
            return initialState.view;
        default: return state;
    }
}

const paginations = (
    state = initialState.paginations,
    action: PayloadAction<Generic<AppPagination>>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.Paginations:
            return payload;
        case Constants.Wave.Clear:
            return initialState.paginations;
        default: return state;
    }
}

const status = (
    state = initialState.status,
    action: PayloadAction<string>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.Status:
            return payload;
        case Constants.Wave.Clear:
            return initialState.status;
        default: return state;
    }
}

const loading = (
    state = initialState.loading,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.Loading:
            return payload;
        case Constants.Wave.Clear:
            return initialState.loading;
        default: return state;
    }
}

const loadingView = (
    state = initialState.loadingView,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.LoadingView:
            return payload;
        case Constants.Wave.Clear:
            return initialState.loadingView;
        default: return state;
    }
}

const loadingReviews = (
    state = initialState.loadingReviews,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.LoadingReviews:
            return payload;
        case Constants.Wave.Clear:
            return initialState.loadingReviews;
        default: return state;
    }
}

const loadingViewMore = (
    state = initialState.loadingViewMore,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.LoadingViewMore:
            return payload;
        case Constants.Wave.Clear:
            return initialState.loadingViewMore;
        default: return state;
    }
}

const showPIIModal = (state: boolean = initialState.showPIIModal, action: PayloadAction<boolean>) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Wave.ShowPIIModal:
            return payload as boolean;
        default: return state;
    }
}

const lastReceivedBubble = (state: WaveBubbleWithWave|null = initialState.lastReceivedBubble, action: PayloadAction<WaveBubbleWithWave>) => {
    switch (action.type) {
        case Constants.Wave.LastReceivedBubble:
            return action.payload;
        default:
            return state;
    }
}

export default combineReducers<WaveReducer>({
    list,
    view,
    paginations,
    status,
    loading,
    loadingView,
    loadingReviews,
    loadingViewMore,
    showPIIModal,
    lastReceivedBubble
});
