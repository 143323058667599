import { AnalyticsProfile } from './analyticsService';
import Mixpanel, { Dict } from 'mixpanel-browser';

class MixpanelService {
  private profile: AnalyticsProfile | null = null;
  private envName: string | undefined;
  private readonly defaultToken = '9a994600035e7e292d67ae7413078cf2';
  private readonly productionToken = 'f08f5bb69f90f3fad9237e4760e227f1';

  constructor() {
    try {
      this.envName = (process.env.REACT_APP_API_ENDPOINT
        ? process.env.REACT_APP_API_ENDPOINT
        : null
      )?.includes('//api.hashgifted.com')
        ? 'production'
        : 'staging';
      const token = this.envToken(this.envName);
      Mixpanel.init(token);
      console.log('Mixpanel initialized with token:', token);
    } catch (error) {
      console.error('Error initializing Mixpanel:', error);
    }
  }

  private envToken(environment: string | null): string {
    switch (environment) {
      case 'staging':
        return this.defaultToken;
      case 'production':
        return this.productionToken;
      default:
        return this.defaultToken;
    }
  }

  identify(profile: AnalyticsProfile) {
    this.profile = profile;
    try {
      Mixpanel.identify(this.profile.id);
      Mixpanel.people.set({
        $name: this.profile.name,
        $first_name: this.profile.name,
        $email: this.profile.email,
        $avatar: this.profile.headshot_image,
      });
    } catch (error: any) {
      console.warn('Could not identify user in Mixpanel.', error);
    }
  }

  unidentify() {
    this.profile = null;
    Mixpanel.reset();
  }

  track(eventName: string, props: Dict | undefined) {
    try {
      Mixpanel.track(eventName, props);
    } catch (error: any) {
      console.warn('Could not track event in Mixpanel.', error);
    }
  }
}

const mixpanelService = new MixpanelService();
export default mixpanelService;
