import {
    FormEvent,
    PropsWithChildren,
    useMemo,
    useState
} from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { Button, FormInput } from 'app/components';
import { LockIcon } from 'app/icons';

interface Props {
    errors?: ApiErrors;
    buttonLabel?: string;
    loading?: boolean;
    className?: string;
    onSubmit(data: NewPasswordForm): void;
}

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`;

export function NewPasswordForm({
    errors,
    buttonLabel,
    loading,
    className,
    children,
    onSubmit
}: PropsWithChildren<Props>) {
    const [form, setForm] = useState<NewPasswordForm>({
        password: '',
        password_confirmation: ''
    });

    const disabledSubmit = useMemo(() => {
        return Helpers.isEmptyObject(form) ||
            form.password !== form.password_confirmation;
    }, [form]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(form);
    }

    const handleChange = (
        name: string,
        value: string
    ) => {
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    return (
        <Container className={className}>
            {children}

            <Form onSubmit={handleSubmit}>
                <FormInput
                    type={'password'}
                    name={'password'}
                    label={'New password'}
                    placeholder={'Enter new password'}
                    value={form.password}
                    error={errors?.password}
                    required={true}
                    onChange={handleChange}
                >
                    <LockIcon />
                </FormInput>

                <FormInput
                    type={'password'}
                    name={'password_confirmation'}
                    label={'Confirm new password*'}
                    placeholder={'Re-enter new password'}
                    value={form.password_confirmation}
                    error={errors?.password_confirmation}
                    required={true}
                    onChange={handleChange}
                >
                    <LockIcon />
                </FormInput>

                <StyledButton
                    loading={loading}
                    disabled={disabledSubmit}
                >
                    {buttonLabel || 'Next'}
                </StyledButton>
            </Form>
        </Container>
    );
}
