import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {ReactNode, useRef, useState} from "react";
import {Hooks} from "../../../utils";
import {createPortal} from "react-dom";
import {Button, ButtonTheme} from "../Button";
import { NotAllowedIcon } from "app/icons";
import { Checkbox } from "../Checkbox";

export interface ModalAction {
    name: string;
    action(): void;
    theme?: ButtonTheme;
}

interface Props {
    title: string,
    description: string;
    image?: string;
    icon?: any;
    show?: boolean;
    primaryAction: ModalAction;
    secondaryAction: ModalAction;
    size?: 'small' | 'medium';
    newFeature?: boolean;
    dontShowAgainToggle?: boolean;
}

const Modal = ({ title, description, image, icon, show, primaryAction, secondaryAction, newFeature = false, size = 'small', dontShowAgainToggle = false } :Props) => {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);
    Hooks.useOnClickOutside(contentRef, () => show && secondaryAction.action());

    const [dontShowAgain, setDontShowAgain] = useState(false)

    const handleDontShowAgain = () => {
        const newValue = !dontShowAgain;
        const modalName = 'hidden-' + title.toLowerCase().replace(/ /g, '-').split(' ').join('') + '-modal';

        if (newValue) {
            localStorage.setItem(`${modalName}`, 'TRUE')
        } else {
            localStorage.removeItem(`${modalName}`)
        }

        setDontShowAgain(newValue)
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'gift-modal-component'}
                >
                    <Content
                        $size={size}
                        {...contentAnimation}
                        ref={contentRef}
                    >
                        <Body>
                            {image ? (
                                <Image
                                    src={require(`../../../images/${image}.png`)}
                                    alt={title}
                                />
                            ) : null}

                            {icon ? (
                                <IconContainer>
                                    {
                                        icon === 'not-allowed' && (
                                            <NotAllowedIcon />
                                        )
                                    }
                                </IconContainer>
                            ) : null}

                            <Title>
                                {newFeature ? (<strong>New:</strong>) : null}
                                {title}
                            </Title>

                            <Desc>
                                {description}
                            </Desc>
                        </Body>

                        {dontShowAgainToggle && (
                            <DontShowAgainToggle onClick={() => handleDontShowAgain()}>
                                <Checkbox checked={dontShowAgain} onChange={() => handleDontShowAgain()} />
                                Don't show me this message again
                            </DontShowAgainToggle>
                        )}

                        <Actions>
                            {primaryAction && (
                                <Button
                                    theme={primaryAction.theme}
                                    onClick={primaryAction.action}>
                                    {primaryAction.name}
                                </Button>
                            )}

                            {secondaryAction && (
                                <Button
                                    theme={secondaryAction.theme || 'outline'}
                                    onClick={secondaryAction.action}
                                >
                                    {secondaryAction.name}
                                </Button>
                            )}
                        </Actions>
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
};

export default Modal;

const DontShowAgainToggle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(60px);
`

const Image = styled.img`
    width: 220px;
    height: 220px;

    @media (max-height:711px) and (min-height:0px) {
        display: none;
    }
`;

const IconContainer = styled.div`
    width: 220px;
    height: 170px;
    display: flex;
    justify-content: center;

    @media (max-height:711px) and (min-height:0px) {
        display: none;
    }
`;


const Title = styled.span`
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    margin-top: -10px;
    margin-bottom: 20px;
    letter-spacing: -1.25px;
    color: var(--grey-9);
    text-align: center;

    @media (max-height:711px) and (min-height:0px) {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
    }

`;

const Desc = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: var(--grey-6);
    white-space: break-spaces;

    @media (max-height:711px) and (min-height:0px) {
        font-size: 14px;
        line-height: 20px;
    }

`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-height:711px) and (min-height:0px) {
        padding-top: 24px;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;

    @media (max-height:711px) and (min-height:0px) {
        margin-top: 24px;
    }

`;

const Content = styled.div<{
    $size: string;
}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${(props) => props.$size == 'small' && `
        width: 439px;   
    `}

    ${(props) => props.$size == 'medium' && `
        width: 539px;   
    `}
    
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }
`;
