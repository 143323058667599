import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useState, useEffect, useMemo } from 'react';
import { ArrowLeftIcon } from 'app/icons';
import TimeAgo from 'javascript-time-ago';
import { useMediaQuery } from 'usehooks-ts';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';
import { useAppSelector } from '../../../store';

interface Props {
    list: Blog[]
}

const ListContainer = styled.div`
    position: relative;
    height: 214px;
    display: flex;

    // overflow-x: scroll;
    // overflow-y: visible;
    width: 100%;
    transition: transform 450ms ease-in-out;
`

const Container = styled(motion.div) <{
    $loading?: boolean
}>`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 327px;
    // height: 244px;
    border-radius: 20px;
    background-color: var(--background-alt);
    cursor: pointer;
    margin-right: 14px;
    margin-bottom: 14px;
    overflow: hidden;
    transition: all 200ms ease;
    will-change: transform;

    &:hover {
        // transform: translateY(-1px);
    }

    h1 {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        margin: 20px;
    }

    h2 {
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        text-align: left;
        margin: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    h3 {
        font-size: 14px;
        line-height: 14px;
        margin: 20px;
    }


`;

const LoadingContainer = styled.div`
    width: 327px;
    height: 200px;
    border-radius: 20px;
    background-color: var(--background-alt);
    margin-right: 14px;
    margin-bottom: 14px;
    background: linear-gradient(
        to right,
        var(--grey-3),
        var(--grey-2) 50%,
        var(--grey-3) 80%
    ), var(--grey-3);
    background-position: 100% 0, 0 0;
    background-repeat: repeat-y;
    background-size: 200% 200%;
    animation: animate 1s infinite;        
`;


const ArrowButton = styled.button`
    position: absolute;
    top: 0;
    bottom: 14px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    transition: all 200ms ease;
    box-shadow: 0 0 5px 0px rgb(0 0 0 / 50%);

    svg {
        transform: translateY(3px);
        path {
            fill: white;
        }
    }

    &.left {
        left: 12px;
    }

    &.right {
        right: 12px;
        transform: rotate(180deg);
    }

    &:hover {
        transform: scale(1.1);

        &.right {
            transform: scale(1.1) rotate(180deg);
        }
    }

    &:active {
        transform: scale(1.2);

        &.right {
            transform: scale(1.2) rotate(180deg);
        }
    }


`

const KenBurnsCover = styled.div`
    position: relative;
    width: 100%;
    height: 200px;

    img {
        width: 130%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 400ms ease-in-out;
        filter: brightness(1);
    }

    h1 {
        position: absolute;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        bottom: 10px;
        color: white;
        text-shadow: 1px 2px 9px rgba(0,0,0,1);
    }

    p {
        position: absolute;
        font-size: 9px;
        text-align: left;
        bottom: 9px;
        color: white;
        text-shadow: 1px 2px 9px rgba(0,0,0,1);
        margin-left: 20px;
    }

    &:hover {

        img {
            transform: scale(1.05);
            filter: brightness(1);
        }
    }

`

const CarouselContainer = styled.div`
    position: relative;
`

export function CarouselList({list}: Props) {

    const { global: { settings }} = useAppSelector((state) => state);
    const smallDevice = useMediaQuery('(max-width: 900px)');
    const smallestValue = smallDevice ? -40 : 0;

    const [moveList, setMoveList] = useState(smallestValue);
    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(true)

    const width = 327;
    const increment = width * 2;

    const amountPresentInPort = 3


    const arrowClick = (direction: string) => {
        if (direction === 'left') {
            setShowRight(true)
            if (moveList < 0) {
                setMoveList(moveList + increment)
            } else {
                setShowLeft(false)
            }

        } else {
            setShowLeft(true);
            if (list.length * width > (Math.abs(moveList) + (width * amountPresentInPort))) {
                const temp = moveList - increment;

                setMoveList(temp)

                setShowRight(true)
            } else {
                setShowLeft(true)
                setShowRight(false)
            }
        }
    }

    useEffect(() => {
        if (moveList === smallestValue) {
            setShowLeft(false);
        }

        if (list.length * width < (Math.abs(moveList) + (width * amountPresentInPort))) {
            setShowRight(false);
        }

    }, [moveList])

    useEffect(() => {
        setMoveList(smallestValue);
        setShowRight(true);
    }, [list])

    const translate = `translateX(${moveList}px)`
    const timeAgo = new TimeAgo('en-US')
    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );

    return (
        <CarouselContainer>

            <ListContainer style={{transform: translate}}>
                {[1,2,3,4].map((value) => (
                    <LoadingContainer key={value}></LoadingContainer>
                ))}
                {list?.map((blog) => (
                    <Container key={blog.guid} onClick={() => {
                        analyticsEvents.dashboardCaseStudyBlogNameOpened(blog);
                        window.open(blog.link, '_blank')
                        }}>

                        <KenBurnsCover>
                            <img alt={blog.title} src={blog.thumbnail} />
                            <h1>{blog.title}</h1>
                            <p>{timeAgo.format(new Date(blog.date))}</p>
                        </KenBurnsCover>
                    </Container>
                ))}
            </ListContainer>

            {showLeft && (
                <ArrowButton className="left" onClick={() => arrowClick('left')}>
                    <ArrowLeftIcon />
                </ArrowButton>
            )}

            {showRight && (
                <ArrowButton className="right" onClick={() => arrowClick('right')}>
                    <ArrowLeftIcon />
                </ArrowButton>
            )}


        </CarouselContainer>
    );
}
