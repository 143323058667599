import { appActions } from './appActions';
import { authActions } from './authActions';
import { completedActions } from './completedActions';
import { giftActions } from './giftActions';
import { profileActions } from './profileActions';
import { waveActions } from './waveActions';

export const Actions = {
    App: appActions,
    Auth: authActions,
    Completed: completedActions,
    Gift: giftActions,
    Profile: profileActions,
    Wave: waveActions
};