import styled from 'styled-components';

interface Props {
    title: string;
    center?: boolean;
}

export const Container = styled.div<{
    $center?: boolean;
}>`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 8px 0;
    letter-spacing: -0.75px;
    color: var(--grey-9);

    ${(props) => props.$center && `
        text-align: center;
    `}
`;

export function SubscriptionPageHeader({
    title,
    center
}: Props) {
    return (
        <Container $center={center}>
            {title}
        </Container>
    );
}