import {PropsWithChildren, useMemo, useState} from 'react';
import styled from 'styled-components';
import subscriptionService, {Plan, PlanType} from "../../../services/subscriptionService";

interface Props {
    brandSubscription: BrandSubscription|undefined;
    changeFilterItems: (planType: PlanType) => void;
    currentlySelected: PlanType;
    showAllPlans: boolean;
}

export function PaymentFilterButtons({
    brandSubscription,
    changeFilterItems,
    currentlySelected,
    showAllPlans
}: PropsWithChildren<Props>) {

    const [selected, setSelected] = useState(currentlySelected);
    const [selectedIndex, setSelectedIndex] = useState<number|undefined>(undefined);

    const buttonData = useMemo(() => {
        if (brandSubscription && !showAllPlans) {
            const type = subscriptionService.findPlanTypeForSubscription(brandSubscription)
            return subscriptionService.planDefinitions().filter((value) => value.type !== type);
        } else {
            return subscriptionService.planDefinitions();
        }
    }, [brandSubscription]);

    const handleClick = (plan: Plan, index: number) => {
        setSelected(plan.type);
        setSelectedIndex(index);
        changeFilterItems(buttonData[index].type);
    }

    const leftValue = useMemo(() => {

        let selectedPlanIndex = 0;
        buttonData.forEach((plan, index) => {
            if (selected === plan.type) {
                selectedPlanIndex = index;
            }
        })

        const buttonCount = buttonData.length;
        const increment = 100 / buttonCount;
        const left = increment * (selectedIndex || selectedPlanIndex);
        return selectedIndex === 0 ? left + 2 : left;
    }, [selectedIndex, buttonData]);

    return (
        <Container>
            <div className="filter-btn-bkg" style={{left: `${leftValue}%`}}></div>

            {buttonData.map((plan, index) => (
                <button key={`filter-btn ${index}`} className={selected === plan.type ? 'active' : ''}  onClick={() => handleClick(plan, index)}>{plan.shortName}</button>
            ))}

        </Container>
    );
}


const Container = styled.div`
    position: relative;
    margin-right: 12px;
    background: var(--grey-1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px;
    height: 44px;
    color: var(--grey-9);
    font-size: 14px;

    button {
        width: 145px;
        background: none;
        border: none;
        z-index: 1;
        cursor: pointer;
        transition: all 250ms ease;
        will-change: color;
        color: var(--blue);
        margin-right: 8px;

        &.active {
            color: white;
            font-weight: bold;
        }
    }

    .filter-btn-bkg {
        border-radius: 20px;
        height: 75%;
        width: 145px;
        position: absolute;
        left: 0;
        background: #74d7c2;
        z-index: 0;
        transition: all 250ms ease;
        will-change: transform;
    }
`;
