import {
    ChangeEvent,
    useRef,
    useState,
    useEffect
} from 'react';
import styled from 'styled-components';

import {Button, FormTextarea} from 'app/components';
import { AttachmentIcon, CheckIcon, PencilIcon, SendIcon } from 'app/icons';
import { ThreadItem } from './ThreadItem';
import {useAppSelector} from "../../../../store";
import { useMediaQuery } from 'usehooks-ts';
import SettingsService from "../../../../services/settingsService";
import settingsService from "../../../../services/settingsService";


interface Props {
    data: WaveItem | null;
    brandName?: string;
    loadingView?: boolean;
    onSubmitReview?: any;
    onSendAttachment(waveId: string, file: File, wave: WaveItem): void;
    onSendMessage(waveId: string, message: string, wave: WaveItem): void;
    onResendMessage(waveId: string, bubbleId?: string): void;
    onViewProfile(influencer: Influencer): void;
    influencer: Influencer;
    onStatusUpdate(
        waveId: string,
        status: string,
        influencer: Partial<Influencer>
    ): void;
}

export function InfluencerChat({
    data,
    brandName,
    loadingView,
    onSendAttachment,
    onSubmitReview,
    onSendMessage,
    onResendMessage,
    onViewProfile,
    influencer,
    onStatusUpdate
}: Props) {
    const {
        wave: {
            showPIIModal
        },
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const smallDevice = useMediaQuery('(max-width: 900px)')
    const chatRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [prevMessage, setPrevMessage] = useState('');
    const [message, setMessage] = useState<string>('');
    const [chatError, setChatError] = useState('');
    const bubbles = data?.bubbles || [];

    const scrollToBottom = (element: HTMLDivElement | null, behavior?: ScrollBehavior) => {
        setTimeout(() => {
            element?.scrollTo({
                top: element.scrollHeight,
                behavior: behavior
            });
        }, 0);
    }

    useEffect(() => {
        if (!smallDevice && data?.bubbles.length) {
            scrollToBottom(chatRef.current);
        }
    }, [chatRef, data])

    useEffect(()=>{
        setMessage(prevMessage);
    }, [showPIIModal])

    /**
     * Clears messages when switching between waves
     */
    useEffect(() => {
        setMessage('');
    }, [influencer?.uid]);

    useEffect(() => {
        if (chatError.length) {
            setChatError('');
        }
    }, [loadingView]);

    const handleResendMessage = (id?: string) => {
        if (data?.uid) {
            onResendMessage(data.uid, id);
        }
    }

    const handleButtonClick = () => {
        if (data?.uid) {
            if (message) {
                onSendMessage(data.uid, message, data);
                setPrevMessage(message);
                setMessage('');
                scrollToBottom(chatRef.current, 'smooth');

                if (saveQuickEdit.active) {
                    // set session storage to message
                    localStorage.setItem(`quickEditMessage${saveQuickEdit.index}`, message);
                    setSaveQuickEdit({index: -1, active: false})
                }

            } else {
                if (inputRef.current) {
                    inputRef.current.value = '';
                    inputRef.current.click();
                }
            }
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target: { files } } = event;
        const file = Array.from(files || [])[0];

        if (data?.uid) {
            if (file) {
                if(file.size > 32 * 1024 * 1024){
                    setChatError('Please upload a file 32Mb or smaller');
                    scrollToBottom(chatRef.current, 'smooth');
                    return;
                } else {
                    setChatError('');
                }
                onSendAttachment(data.uid, file, data);
                scrollToBottom(chatRef.current, 'smooth');
            } else {
                if (chatError.length) {
                    setChatError('');
                }
            }
        }
    }

    const noMessagesBubble = (bubble?: WaveBubble) => {
        return {
            bubble_type: 'user-message',
            created_at: bubble?.created_at || `${new Date().toString()}`,
            meta: {message: `${influencer?.name} has applied for your gift.`},
            seen_at: '',
            sender: {
                brand_logo: "logo-192.png",
                name: influencer?.name,
                uid: influencer?.uid, fname: "",
                headshot_image: "",
            },
            sender_type: 'INFLUENCER',
            isSystem: true    
        } as WaveBubble;
    } 

    const shopifyDisconnectedBubble = (bubble: WaveBubble) => {
        return {
            bubble_type: 'shopify-selection-fallback',
            created_at: bubble.created_at || `${new Date().toString()}`,
            meta: {message: bubble.meta.message},
            seen_at: '',
            sender: {
                brand_logo: "logo-192.png",
                name: influencer?.name,
                uid: influencer?.uid,
                headshot_image: ""
            },
            sender_type: 'INFLUENCER',
            isSystem: true
        } as WaveBubble;
    }

    const withdrawnBubble = (bubble: WaveBubble) => {
        return {
            bubble_type: 'creator-withdrawn',
            created_at: bubble.created_at || `${new Date().toString()}`,
            meta: { message: bubble.meta.message ? bubble.meta.message : `${influencer?.name} has withdrawn from gifting` },
            seen_at: '',
            sender: {
                brand_logo: "logo-192.png",
                name: influencer?.name,
                uid: influencer?.uid,
                headshot_image: ""
            },
            sender_type: 'INFLUENCER',
            isSystem: true
        } as WaveBubble;
    }

    const personalInformationDetectedBubble = (bubble: WaveBubble) => {
        return {
            bubble_type: 'personal-information-detected',
            created_at: bubble.created_at || `${new Date().toString()}`,
            meta: {message: `${influencer?.name} has tried to send their address, email, phone number, or social handles in the chat. If you are asking for this information, #gifted will automatically request this information once they have been accepted for gifting.`},
            seen_at: '',
            sender: {
                brand_logo: "logo-192.png",
                name: influencer?.name,
                uid: influencer?.uid, fname: "",
                headshot_image: "",
            },
            sender_type: 'INFLUENCER',
            isSystem: true    
        } as WaveBubble;
    }

    const renderMessage = (bubble: WaveBubble, index: number) => {

        if (index !== 0 && bubble.bubble_type === 'silent-wave') {
            return;
        }

        const nextBubble = bubbles[index + 1];
        const lastSentMessage = nextBubble?.sender_type !== bubble.sender_type;
        switch (bubble.bubble_type) {
            case 'silent-wave':
                bubble = noMessagesBubble(bubble);
                break;
            case 'creator-withdrawn':
                bubble = withdrawnBubble(bubble);
                break;
            case 'shopify-selection-fallback':
                bubble = shopifyDisconnectedBubble(bubble);
                break;
            case 'personal-information-detected':
                bubble = personalInformationDetectedBubble(bubble);
                break;
        }

        const completedMsgText = 'Time to get creative! When the product arrives, dive into the collaboration, and aim to finish it within two weeks. Thanks for being on board!'

        // compare that bubbles are not the same
        if (bubble.meta.message === completedMsgText && nextBubble?.meta.message === completedMsgText) {
            return null;
        }

        return (
            !loadingView && (
                <ThreadItem
                    onSubmitReview={onSubmitReview}
                    key={index}
                    index={index}
                    bubble={bubble}
                    brandName={brandName}
                    influencerImage={data?.influencer.headshot_image}
                    lastSentMessage={lastSentMessage}
                    onViewProfile={onViewProfile}
                    onResend={handleResendMessage}
                    nextBubble={nextBubble}
                    influencer={influencer}
                    data={data}
                    onStatusUpdate={(status) => data && onStatusUpdate(data?.uid as string, status, influencer)}
                />
            )
        );
    }

    const handleChangeTextArea = (value: string) => {
        setMessage(value)
        if(chatError.length){
            setChatError('');
        }
    }

    const handleTextAreaKeyPress = (e: any) => {
        if (e.charCode === 13 && !e.shiftKey) {
            // e.preventDefault()
            // handleButtonClick()
            // setMessage(message + `\n`)

        }
        if (e.key === "Enter" && e.shiftKey) {
            // setMessage(message + `\n`)
            e.preventDefault()
            handleButtonClick()
            setMessage('')
        }


    }

    const quickReplyText = [
        localStorage.getItem('quickEditMessage0') || 'Great to connect. What could you do in exchange for this gift?',
        localStorage.getItem('quickEditMessage1') || 'Love your profile. Can you let us know how we could collab with you?',
        localStorage.getItem('quickEditMessage2') || 'Lovely to match. What would you be willing to do in this collaboration? Stories, in feed post, reels, tiktok etc?'
    ]

    const handleQuickReplyClick = (msg: string) => {
        if (data?.uid) {
            if (msg) {
                onSendMessage(data.uid, msg, data);
                setPrevMessage(msg);
                setMessage('');
                scrollToBottom(chatRef.current, 'smooth');
            } else {
                if (inputRef.current) {
                    inputRef.current.value = '';
                    inputRef.current.click();
                }
            }
        }
    }

    const [saveQuickEdit,setSaveQuickEdit] = useState({index: -1, active: false});
    const handleQuickReplyEditClick = (msg: string, index: number) => {
        setMessage(msg);
        setSaveQuickEdit({index: index, active: true});
    }

    return (
        <>
            <Container $loading={loadingView}>
                <Content ref={chatRef}>
                    <ChatThreads>
                        {!loadingView &&
                            bubbles.map(renderMessage)
                        }

                        {!bubbles.length && influencer && (
                            renderMessage(noMessagesBubble(), 0)
                        )}

                        {chatError.length ? <p style={{textAlign: "right", fontSize: "14px", color: "red"}}>{chatError}</p> : null}

                        {bubbles.length === 1 && !loadingView ? (
                                <QuickReplyBody>
                                    <QuickReplyMsg>Start a conversation with <strong>{influencer.name}</strong> with a quick reply:</QuickReplyMsg>

                                    <FloatRight>
                                        <QuickReplyMsgContainer>
                                            {quickReplyText[0]}

                                            <QuickReplyActions>
                                                <QuickReplyBtn className="edit" onClick={() => handleQuickReplyEditClick(quickReplyText[0],0)}>
                                                    <StyledPencilIcon />
                                                </QuickReplyBtn>
                                                <QuickReplyBtn className="send" onClick={() => handleQuickReplyClick(quickReplyText[0])}>
                                                    <StyledSendIcon />
                                                </QuickReplyBtn>
                                            </QuickReplyActions>

                                        </QuickReplyMsgContainer>

                                        <QuickReplyMsgContainer>
                                            {quickReplyText[1]}

                                            <QuickReplyActions>
                                                <QuickReplyBtn className="edit" onClick={() => handleQuickReplyEditClick(quickReplyText[1],1)}>
                                                    <StyledPencilIcon />
                                                </QuickReplyBtn>
                                                <QuickReplyBtn className="send" onClick={() => handleQuickReplyClick(quickReplyText[1])}>
                                                    <StyledSendIcon />
                                                </QuickReplyBtn>
                                            </QuickReplyActions>

                                        </QuickReplyMsgContainer>

                                        <QuickReplyMsgContainer>
                                            {quickReplyText[2]}

                                            <QuickReplyActions>
                                                <QuickReplyBtn className="edit" onClick={() => handleQuickReplyEditClick(quickReplyText[2],2)}>
                                                    <StyledPencilIcon />
                                                </QuickReplyBtn>
                                                <QuickReplyBtn className="send" onClick={() => handleQuickReplyClick(quickReplyText[2])}>
                                                    <StyledSendIcon />
                                                </QuickReplyBtn>
                                            </QuickReplyActions>

                                        </QuickReplyMsgContainer>
                                    </FloatRight>
                                
                                </QuickReplyBody>
                            ) : null}
                    </ChatThreads>
                </Content>
                <InputBox>
                    <StyledTextarea
                        name={'message'}
                        label={''}
                        placeholder={loadingView ? '' : 'Type here...'}
                        value={!loadingView ? message : ''}
                        autoHeight={true}
                        onKeyPress={handleTextAreaKeyPress}
                        onChange={(_, value) => handleChangeTextArea(value)}
                    />
                    <StyledButton
                        theme={'aqua'}
                        size={'small'}
                        iconOnly={true}
                        onClick={handleButtonClick}
                    >
                        {message ? <SendIcon /> : <AttachmentIcon />}
                    </StyledButton>

                    {!!settings && (
                        <input
                            ref={inputRef}
                            type={'file'}
                            hidden={true}
                            accept={Object.values(settings.allowedMimeTypes).flat().join(', ')}
                            onChange={handleInputChange}
                        />
                    )}
                </InputBox>

                {/* {message.length > 10 && ( */}
                    <TipText>'Tip: Press Shift + Enter to send messages'</TipText>
                {/* )} */}

            </Container>

        </>
    );
}

const QuickReplyBody = styled.div`
    display: flex;
    flex-direction: column;
    border-left: solid 1px var(--grey-4);
    border-top: solid 1px var(--grey-4);
    border-top-left-radius: 8px;
    padding: 8px;
    padding-right: 0px;
    padding-bottom: 0px;
`

const QuickReplyBtn = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;

    &.edit {
        margin-right: 8px;
        margin-left: 4px;
        background-color: var(--aqua);

        svg {
            path {
                fill: var(--blue);
            }
        }    
    }

    &.send {
        background-color: var(--blue);

        svg {
            path {
                fill: white;
            }
        }
    
    }

    &:hover {
        opacity: 0.8;
    }

`

const QuickReplyActions = styled.div`
    display: flex;
`

const StyledPencilIcon = styled(PencilIcon)`
    width: 13px;
    height: 13px;
`

const StyledSendIcon = styled(SendIcon)`
    width: 13px;
    height: 13px;
`


const FloatRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
`

const QuickReplyMsgContainer = styled.div`
    padding: 8px 12px;
    border-radius: 12px;
    word-break: break-word;
    align-self: flex-end;
    background-color: var(--grey-2);
    font-size: 12px;
    line-height: 24px;
    white-space: pre-line;
    color: var(--grey-8);
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    border: solid 1px var(--grey-5);

    &:last-child {
        margin-bottom: 0px;
    }
`

const QuickReplyMsg = styled.div`
    padding: 8px 12px;
    border-radius: 12px;
    word-break: break-word;
    align-self: flex-start;
    // background-color: var(--grey-2);
    font-size: 14px;
    line-height: 24px;
    white-space: pre-line;
    color: var(--grey-8);
    // border: solid 1px var(--grey-5);
    // margin-bottom: 12px;
`


const StyledButton = styled(Button)`
    position: absolute;
    top: 18px;
    right: 30px;
    width: 44px;
    height: 44px;

    svg path {
        fill: var(--blue);
    }
`;

const StyledTextarea = styled(FormTextarea)`
    > div {
        padding: 18px 8px;
    }

    textarea {
        max-height: 150px;
        padding-right: 42px;
    }
`;

const InputBox = styled.div`
    position: relative;
    padding: 12px 24px 24px;
    background: white;

    @media (max-width:900px) and (min-width:0px) {
        bottom: 50px;
    }
`;

const ChatThreads = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border-radius: 12px;

    > div {
        max-width: 95%;
    }

`;

const Content = styled.div`
    height: 100%; 
    padding: 24px 24px 12px;
    overflow: hidden auto;
    overflow: hidden overlay;
    background: white;
`;

const Container = styled.div<{
    $loading?: boolean;
}>`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    overflow: hidden overlay;
    border-bottom-right-radius: 28px;

    ${(props) => props.$loading && `
        pointer-events: none;

        ${ChatThreads},
        ${StyledTextarea} div,
        ${StyledButton} {
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '##';
                visibility: hidden;
            }
        }

        ${ChatThreads} {
            height: 100%;
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }
`;

const TipText = styled.p`
    position: absolute;
    bottom: -3px;
    font-size: 11px;
    padding: 0px 30px;
    color: var(--grey-7);

    @media (max-width:900px) and (min-width:0px) {
        bottom: 40px;
    }
`