import {AnalyticsProfile} from "./analyticsService";

class VeroService {
    private profile: AnalyticsProfile | null = null;

    identify(profile: AnalyticsProfile) {
        this.profile = profile;
        const data = Object.assign({
            id: this.profile?.id,
            email: this.profile.email,
            data: profile
        }, profile);
        _veroq.push(['user', data]);
    }

    unidentify() {
        this.profile = null;
    }

    track(eventName: string, props: any) {
        _veroq.push(['track', eventName, props]);
    }
}

const veroService = new VeroService();
export default veroService;
