import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Spinner = styled(motion.span)`
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 3px solid var(--grey-3);
    border-top: 3px solid var(--blue);
    animation: spin 800ms linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;