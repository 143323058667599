import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import {GiftTypeDefinitions} from "../../../../utils/mapping";
import {Badge} from "../../../components";

interface Props {
    name: string;
    selected?: string | number;
    loading?: boolean;
    onClick(name: string, value: string | number): void;
}

export function GiftTypeList({
    name,
    selected,
    loading,
    onClick
}: PropsWithChildren<Props>) {
    return (
        <Container $loading={loading}>
            {GiftTypeDefinitions.map((definition) => (
                <GiftTypeItem
                    key={definition.type}
                    $selected={definition.type === selected}
                    $loading={loading}
                    onClick={() => onClick(name, definition.type)}
                >
                    {definition.type === 'EVENT' && (
                        <FreeBadge>
                            Free
                        </FreeBadge>
                    )}

                    <span>{definition.icon}</span>

                    <span>{definition.label}</span>

                    <span>{definition.description}</span>
                </GiftTypeItem>
            ))}
        </Container>
    );
}


const GiftTypeItem = styled.span<{
    $selected?: boolean;
    $loading?: boolean;
}>`
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
    height: 112px;
    line-height: 24px;
    padding-bottom: 8px;
    border: 1px solid var(--grey-4);
    border-radius: 16px;
    color: var(--grey-6);
    transition: all 300ms ease;
    cursor: pointer;
    position: relative;

    &:hover {
        border-color: var(--blue);
    }

    &:active {
        transform: scale(0.95);
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 12px;
        width: 80%;
        text-align: center;

        &:first-child {
            width: 40px;
            height: 40px;
            margin-bottom: -4px;
        }

        &:last-child {
            font-size: 12px;
            text-align: center;
            font-weight: 400;
        }
        
        svg path {
            transition: fill 300ms ease;
        }
    }

    ${(props) => props.$selected && `
        border-color: var(--blue);
        background-color: var(--grey-2);
        color: var(--blue);

        svg path {
            fill: var(--blue);
        }
    `}

    ${(props) => props.$loading && `
        pointer-events: none;

        span {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '##################';
                visibility: hidden;
            }
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }
`;

const Container = styled.div<{
    $loading?: boolean;
}>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
    user-select: none;

    ${(props) => props.$loading && `
        pointer-events: none;
    `}

    @media (max-width:900px) and (min-width:0px) {
        display: flex;
        flex-direction: column;
    }
`;

const FreeBadge = styled(Badge)`
  position: absolute;
  right: 10px;
  top: 10px;
  background: #FFB800;
  color: white;
`;
