import {
    ReactNode,
    SyntheticEvent,
    useCallback,
    useRef,
    useState,
    useEffect
} from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import ReactCrop, {Crop, PixelCrop, convertToPixelCrop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'

import { Hooks, ImageHelper } from 'utils';
import { MultidirectionalIcon } from 'app/icons';
import { Button } from '../Button';
import settingsService from "../../../services/settingsService";
import useDebouncy from "use-debouncy/lib/effect";
import errorReporter from "../../../services/errorReporter";
import {debounce} from "lodash";
import {useDebouncyEffect} from "use-debouncy";
import {fromCanvas, fromImage} from "imtool";

interface ModalAction {
    name: string;
    action(): void;
}

interface Props {
    image: string;
    file: File;
    show?: boolean;
    initialCrop?: Crop;
    onClose(): void;
    primaryAction?: Omit<ModalAction, 'action'> & {
        action(file: Blob | undefined, thumbnail: Blob, crop?: Crop): void;
    };
    secondaryAction?: ModalAction;
}

export function GiftImageUploadModal({
    image,
    file,
    show,
    onClose,
    initialCrop,
    primaryAction,
    secondaryAction
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const [crop, setCrop] = useState<Crop>(initialCrop || { height: 100, width: 100, unit: 'px', x: 0, y: 0 });
    const [cropChanged, setCropChanged] = useState(false)
    const thumbnailCanvasRef = useRef<HTMLCanvasElement>(null);
    const ogImage = useRef<HTMLCanvasElement>(null);

    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [blob, setBlob] = useState<Blob>();
    const [ogBlob, setOgBlob] = useState<Blob>();

    const [isCropping, setIsCropping] = useState(false);

    useEffect(() => {
        initialCrop && setCrop(initialCrop)
    }, [initialCrop, show]);

    useDebouncyEffect(
        () => {
            if (
                imgRef.current &&
                ogImage.current &&
                thumbnailCanvasRef.current
            ) {
                setIsCropping(true);
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    thumbnailCanvasRef.current,
                    completedCrop || convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height),
                )

                canvasPreview(imgRef.current, ogImage.current, null, 1, 0, true)

                fromCanvas(thumbnailCanvasRef.current)
                    .then(value => {
                        return value
                            .type('image/webp')
                            .thumbnail(4096)
                            .toBlob()
                    })
                    .then((blob) => {
                        setBlob(blob);
                    })
                    .catch((reason) => {
                        errorReporter.report('Failed to create blob for image', reason);
                    })
                    .finally(() => {
                        setIsCropping(false);
                    })

                    fromCanvas(ogImage.current)
                    .then(value => {
                        return value
                            .type('image/webp')
                            .thumbnail(4096)
                            .toBlob()
                    })
                    .then((blob) => {
                        setOgBlob(blob);
                    })
                    .catch((reason) => {
                        errorReporter.report('Failed to create blob for image', reason);
                    })
                    .finally(() => {
                        setIsCropping(false);
                    })

            }
        },
        200,
        [completedCrop, imgRef.current, thumbnailCanvasRef.current],
    );

    const handlePrimaryAction = () => {
        if (!blob) {
            throw new Error('missing thumbnail blob');
        }
        try {
            primaryAction?.action(ogBlob, blob, crop);
        } catch (error) {
            console.error('GiftImageUploadModal', error);
        }
    };

    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    const handleImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = event.currentTarget;
        const crop = ImageHelper.getCenterCropped(
            width,
            height,
            settingsService.imageUploadAspectRatio
        );
        setCrop(crop);
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    const changeCrop = (percentCrop: Crop) => {
        setCrop(percentCrop)
        setCropChanged(true)
    }

    return renderToPortal(
        <>
            <AnimatePresence>
                {show && (
                    <Container
                        {...containerAnimation}
                        data-testid={'gift-image-upload-modal-component'}
                    >
                        <Content
                            {...contentAnimation}
                            ref={contentRef}
                        >
                            <Header>
                                <MultidirectionalIcon />

                                <span>Drag to adjust your thumbnail</span>
                            </Header>

                            <Body>
                                <ReactCrop
                                    crop={cropChanged ? crop : (initialCrop || crop) }
                                    aspect={settingsService.imageUploadAspectRatio}
                                    onChange={(pixelCrop, percentCrop) => {
                                        changeCrop(percentCrop);
                                        setCompletedCrop(pixelCrop);
                                    }}
                                    onComplete={setCompletedCrop}
                                >
                                    <img
                                        crossOrigin={'anonymous'}
                                        ref={imgRef}
                                        src={image}
                                        alt={''}
                                        onLoad={handleImageLoad}
                                    />
                                </ReactCrop>
                            </Body>

                            <Actions>
                                {primaryAction && (
                                    <Button
                                        theme={'aqua'}
                                        size={'medium'}
                                        loading={isCropping}
                                        disabled={isCropping}
                                        onClick={() => handlePrimaryAction()}
                                    >
                                        {isCropping ? '' : 'Upload'}
                                    </Button>
                                )}

                                {secondaryAction && (
                                    <Button
                                        theme={'outline'}
                                        size={'medium'}
                                        onClick={secondaryAction.action}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </Actions>
                        </Content>
                    </Container>
                )}
            </AnimatePresence>
            {/* {!!completedCrop && ( */}
                <canvas ref={thumbnailCanvasRef} style={{
                    width: completedCrop?.width,
                    height: completedCrop?.height,
                    position: 'absolute',
                    left: -10000,
                }}/>

                <canvas ref={ogImage} style={{
                    width: imgRef?.current?.width,
                    height: imgRef?.current?.height,
                    position: 'absolute',
                    left: -10000,
                }}/>

            {/* )} */}
        </>
    );
}

const TO_RADIANS = Math.PI / 180

function canvasPreview(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop | null,
    scale = 1,
    rotate = 0,
    dontCrop = false
) {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = crop ? Math.floor(crop.width * scaleX * pixelRatio) : image.naturalWidth
    canvas.height = crop ? Math.floor(crop.height * scaleY * pixelRatio) : image.naturalHeight

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'


    const cropX = crop ? crop.x * scaleX : 0
    const cropY = crop ? crop.y * scaleY : 0

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    if (!dontCrop) {
        ctx.translate(-cropX, -cropY)
    }
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}

const Header = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;

    svg path {
        fill: var(--grey-9);
    }

    span {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: var(--grey-9);
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    row-gap: 10px;
    padding: 24px;

    .ReactCrop__child-wrapper {
        max-height: 480px;

        img {
            border-radius: 24px;
        }
    }

    .ReactCrop__crop-selection {
        border-radius: 24px;
        box-shadow: 0 0 0 9999em rgb(255 255 255 / 75%);
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    column-gap: 12px;

    button {
        width: 130px;
    }
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 800px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);

    @media (max-width:900px) and (min-width:0px) {
        height: 100%;
    }
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: 1020;

    button {
        margin-top: auto;
    }
`;
