import styled from 'styled-components';

import { FormRadio } from 'app/components';

interface Props {
    code: string;
    name: string;
    description: string;
    frequency: string;
    amount: number;
    selected?: boolean;
    onClick(name: string, value: string): void;
}

const Contact = styled.div`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
`;

const Value = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        &:first-child {
            font-size: 24px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: -0.75px;
            color: var(--grey-9);
        }

        &:last-child {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            margin-top: -1px;
            color: var(--grey-6);
        }
    }
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: var(--grey-9);
    height: 100px;
`;

const Header = styled.div`
    padding: 24px 0 16px;
`;

const Divider = styled.div`
    width: 90%;
    height: 1px;
    margin: 0 auto;
    background-color: var(--grey-3);
`;

const GroupTitle = styled.div`
    font-size: 11px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    color: var(--grey-6);
`;

const Offers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }
`;

const StyledFormRadio = styled(FormRadio)`
    margin: auto auto 24px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    padding: 16px 0;
`;

const Container = styled.div<{
    $selected?: boolean;
}>`
    flex: 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 168px;
    text-align: center;
    padding: 0 20px;
    border: 1px solid var(--grey-3);
    border-radius: 16px;
    transform: scale(1);
    transition: all 300ms ease;
    cursor: pointer;

    &:hover {
        border: 1px solid var(--blue);
    }

    &:active {
        transform: scale(0.95);
    }

    ${(props) => props.$selected && `
        border-color: var(--blue);
        background-color: var(--grey-2);
    `}
`;

export function SubscriptionItem({
    code,
    name,
    description,
    frequency,
    amount,
    selected,
    onClick
}: Props) {
    return (
        <Container
            key={code}
            $selected={selected}
            onClick={() => onClick(name, code)}
        >
            <Header>
                <Title>
                    {name}
                </Title>

                {amount > 0 ?
                    (
                        <Value>
                            <span>$ {amount}</span>

                            <span>/ {frequency}</span>
                        </Value>
                    ) : (
                        <Contact>Contact Us</Contact>
                    )
                }
            </Header>

            <Divider />

            <Body>
                <GroupTitle>
                    This plan includes
                </GroupTitle>
                <Offers>
                    {description || 'No description set in stripe admin'}
                </Offers>
            </Body>

            <StyledFormRadio checked={selected} />
        </Container>
    );
}
