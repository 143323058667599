export const option = {
    style: {
        base: {
            color: '#262161',
            fontWeight: '400',
            fontFamily: 'Mont, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#9299BE',
            },
            '::placeholder': {
                color: '#9299BE',
            },
        },
        invalid: {
            iconColor: '#FF647C',
            color: '#FF647C',
        }
    }
};