import {
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useState
} from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { CameraAddIcon, GiftIcon, UserIcon } from 'app/icons';

interface Props {
    id?: string;
    image?: string | null;
    width?: number;
    height?: number;
    isUpload?: boolean;
    isGift?: boolean;
    loading?: boolean;
    className?: string;
    onClick?(event: MouseEvent<HTMLDivElement>): void;
}

const UploadPlaceholder = styled.div`
    font-size: 8px;
    font-weight: 300;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 12px;
    text-align: center;
    color: var(--grey-6);
`;

const UserPlaceholder = styled(UploadPlaceholder)`
    svg path {
        fill: var(--text-alt);
    }
`;

const Container = styled.div<{
    $width: number;
    $height: number;
    $hasImage?: boolean;
}>`
    flex-shrink: 0;
    position: relative;
    width: ${(props) => props.$width}px;
    height: ${(props) => props.$height}px;
    transition: transform 300ms ease;
    user-select: none;

    ${UserPlaceholder} {
        svg {
            width: ${(props) => props.$width * 0.6}px;
            height: ${(props) => props.$height * 0.6}px;
        }
    }
   
    ${(props) => props.$hasImage && `
        ${UploadPlaceholder} {
            z-index: -1;
        }
   `}
`;

export function ProfileFrame({
    id,
    image,
    width = 52,
    height = 52,
    isUpload,
    isGift,
    loading,
    className,
    children,
    onClick
}: PropsWithChildren<Props>) {
    const [hasImage, setHasImage] = useState<boolean>(!!image);

    useEffect(() => {
        setHasImage(!!image);
    }, [image]);

    const getFill = () => {
        if (loading) {
            return 'var(--grey-3)';
        }

        if (hasImage) {
            return `url(#${id})`;
        }

        return isUpload ? 'var(--grey-1)' : 'var(--blue)';
    }

    return (
        <Container
            data-testid={'profile-frame-component'}
            key={id}
            $width={width}
            $height={height}
            $hasImage={hasImage}
            className={className}
            onClick={onClick}
        >
            {!loading && (
                isUpload ? (
                    <UploadPlaceholder>
                        <CameraAddIcon />

                        <span>
                            JPG or PNG, <br />at least 300x300
                        </span>
                    </UploadPlaceholder>
                ) : (
                    <UserPlaceholder>
                        {isGift ?
                            (
                                <GiftIcon />
                            ) : (
                                <UserIcon />
                            )
                        }
                    </UserPlaceholder>
                )
            )}

            <svg
                xmlns={'http://www.w3.org/2000/svg'}
                xmlnsXlink={'http://www.w3.org/1999/xlink'}
                viewBox={`-1 -1 ${width + 2} ${height + 2}`}
                width={width}
                height={height}
            >
                {hasImage && (
                    <defs>
                        <pattern
                            id={id}
                            width={width}
                            height={height}
                            patternUnits={'userSpaceOnUse'}
                        >
                            <image
                                href={image || ''}
                                width={width}
                                height={height}
                                preserveAspectRatio={'xMidYMid slice'}
                                onError={() => setHasImage(false)}
                            />
                        </pattern>
                    </defs>
                )}

                <path
                    d={Helpers.generateSquircle(width, height)}
                    fill={getFill()}
                    stroke={(!hasImage && isUpload) ? 'var(--grey-6)' : undefined}
                    strokeDasharray={(!hasImage && isUpload) ? '6' : undefined}
                    strokeWidth={1}
                />
            </svg>

            {children}
        </Container>
    );
}