import { ClientSettings } from "./settingsService";

class CompletedContentService {

    public platformToName(platform: string) {
        switch (platform) {
            case 'INSTAGRAM':
                return 'Instagram';
            case 'TIKTOK':
                return 'TikTok';
            case 'YOUTUBE':
                return 'YouTube';
        }
    }

    getMediaUrl(settings: ClientSettings, mediaItem: SocialMediaItem) {
        let url = CompletedContentService.updateWithMediaEndpoint(settings, mediaItem.mediaUrl);
        if (!url) {
            url = this.getThumbnail(settings, mediaItem)
        }
        return url;
    }

    getMediaUrls(settings: ClientSettings, mediaItems: Array<SocialMediaItem>) {
        const urls: string[] = [];
        let url = '';
        mediaItems.forEach((mediaItem)=>{
            url = CompletedContentService.updateWithMediaEndpoint(settings, mediaItem.mediaUrl);
            if (!url) {
                url = this.getThumbnail(settings, mediaItem)
            }
            urls.push();
        })

        return urls;
    }

    getThumbnail(settings: ClientSettings, mediaItem: SocialMediaItem) {
        const url = mediaItem.mediaThumbnailUrl || mediaItem.mediaUrl;
        return CompletedContentService.updateWithMediaEndpoint(settings, url);
    }

    private static updateWithMediaEndpoint(settings: ClientSettings, url: string|undefined) {
        if (!url) {
            return 'https://static.hashgifted.com/missing.png';
        }
        if (settings && url && (!url.startsWith('https://') && !url.startsWith('http://'))) {
            url = settings.media.endpoint + url;
        }
        return url;
    }
}

const completedContentService = new CompletedContentService();
export default completedContentService;
