import {
    PropsWithChildren,
    useEffect,
    useState
} from 'react';
import styled from 'styled-components';
import { API } from 'api';

import {Constants, useAppDispatch, useAppSelector} from 'store';
import {
    FormSelect,
    OptionProps,
} from 'app/components';
import errorReporter from "../../../services/errorReporter";
import {useMediaQuery} from "usehooks-ts";

interface Props {
    index: number;
    removeLocation: (index: number) => void;
    disabledInputs?: boolean;
    updateLocationField: (index: number, name: string, selectedId: number | string, value: any) => void;
    initialValue: any;
    data?: AudienceForm;
    currentPlan?: any;
}
const LocationRow = styled.div`
    display: flex;
    align-items: center;
    height: fit-content;

  @media (max-width:900px) and (min-width:0px) {
    flex-direction: column;
    border-bottom: solid 1px var(--grey-4);
  }
`

const ActionBtn = styled.div`
    height: 56px;
    width: 56px;
    background: none;
    border: solid 1.5px #ff647c;
    color: #ff647c;
    font-weight: bold;
    margin-left: 4px;
    margin-right: 4px;
    transform: translateY(14px);
    border-radius: 14px;
    cursor: pointer;
    transition: all 300ms ease;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(255,100,124,0.15);
    }

    path {
        fill: #ff647c;
    }

    &.disable {
        cursor: not-allowed;
    }

  @media (max-width:900px) and (min-width:0px) {
    transform: none;
    margin-top: 6px;
    margin-bottom: 12px;
    width: 100%;
    font-size: 16px;
    height: 48px;
    font-weight: 600;

    svg {
        display: none;
    }
  }
`

const StyledFormSelect = styled(FormSelect)`
    margin-right: 8px;
    width: 205px;

  @media (max-width:900px) and (min-width:0px) {
    width: 100%;
    margin-bottom: 6px;
  }
`

export const followersRange = [0, 100000];

export const ageRange = [16, 100];

export function LocationGroupForm({
    index,
    removeLocation,
    updateLocationField,
    initialValue,
    data,
    currentPlan,
    disabledInputs
}: PropsWithChildren<Props>) {

    console.log('current plan', currentPlan)

    const {
        global: {
            countries,
        },
    } = useAppSelector((state) => state);

    const dispatcher = useAppDispatch();

    const [form, setForm] = useState<GiftLocation>(data && data.locations ? data.locations[index] : {});
    const [stateList, setStateList] = useState<any>([]);

    const handleChangeLocation = (
        name: string,
        value: OptionProps
    ) => {
        setForm((prev: any) => {
            let nextState = {
                ...prev,
                [name]: value
            } as GiftLocation;

            updateLocationField(index, name, value.id, value)

            // reset the location's state and city
            // whenever the value of the country changes
            if (name === 'country') {
                nextState = {
                    country: nextState.country
                } as GiftLocation
            }

            return nextState;
        });
    }

    useEffect(() => {
        if (countries.length > 0) {
            return;
        }
        API.Shared.getCountries().then((countries) => {
            const aussieIndex = countries.findIndex((obj) => obj.name === 'Australia')
            const countTemp = countries[aussieIndex]
            countries[aussieIndex] = countries[0]
            countries[0] = countTemp
            dispatcher({
                type: Constants.Global.Countries,
                payload: countries
            });
        }).catch((reason) => {
            errorReporter.report('failed to load countries', reason);
        })
    });

    useEffect(() => {
        if (form?.country && form?.country?.id) {
            const fetchStates = async () => {

                const result = await API.Shared.getStates(form.country?.id as number);
                setStateList(result)
                //@ts-ignore
            }

            fetchStates().catch(console.error);
        }


    }, [form?.country]);

    const smallDevice = useMediaQuery('(max-width: 900px)')

    return (
        <LocationRow>
            <StyledFormSelect
                name={'country'}
                label={'Country'}
                required={true}
                disabled={disabledInputs}
                placeholder={'Select country'}
                options={countries}
                selected={initialValue?.country}
                onChange={handleChangeLocation}
            />
            <div style={{position: 'relative'}}>
                <StyledFormSelect
                    name={'state'}
                    label={'State'}
                    disabled={currentPlan === 'Starter' || disabledInputs}
                    placeholder={'Select state'}
                    options={stateList}
                    selected={initialValue?.state}
                    onChange={handleChangeLocation}
                    pairValue={form?.country ? form.country : null}
                />

                {currentPlan === 'Starter' ? (
                    <label style={{position: 'absolute', fontSize: '12px', color: '#ff647c', 
                        left: '53px',
                        top: '0px',
                        width: '194px',
                        lineHeight: '11px'
                    }}>
                        State targeting not available on #gifted Basic
                    </label>
                ) : null}

            </div>

            {!disabledInputs && (
                <ActionBtn className={`${disabledInputs ? 'disable' : ''}`} onClick={() => !disabledInputs && removeLocation(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                    {smallDevice && (<span>Remove location</span>)}
                </ActionBtn>

            )}

        </LocationRow>
    );
}
