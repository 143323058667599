import * as Sentry from '@sentry/react';

export interface ErrorTags {
  [prop: string]: any;
}

class ErrorReporter {
  report(description: string, error?: any, tags?: ErrorTags) {
    if (error) {
      Sentry.addBreadcrumb({
        message: description,
      });
      Sentry.captureException(error, {
        tags: tags
      });
    } else {
      Sentry.captureMessage(description, {
        tags: tags
      });
    }
  }
}

const errorReporter = new ErrorReporter();
export default errorReporter;
