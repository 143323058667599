import axios from 'axios';
import {
    differenceWith,
    every,
    find,
    isEqual,
    isNil,
    omit,
    pick,
    some
} from 'lodash';
import { uid } from 'uid';
import {
    format as fnsFormat,
    formatRelative,
    isValid as fnsIsValid,
} from 'date-fns';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import VideoSnapshot from 'video-snapshot';

import { ClientSettings } from "../services/settingsService";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

/**
 * Generates a random string of the given length.
 *
 * @param length [length=8] the length of the id
 * @returns a random id with the given length
 */
const generateId = (length = 8) => {
    return uid(length);
}

/**
 * Generates a random number between the
 * given min and max value (inclusive).
 *
 * @param min the minimum value
 * @param max the maximum value
 * @returns a random number between min and max value (inclusive)
 */
const generateInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Generates a squircle path from the given dimensions,
 * with a fixed curvature of 0.85.
 *
 * @param width the width of the squircle shape
 * @param height the height of the squircle shape
 * @returns the squircle path
 */
const generateSquircle = (width: number, height: number) => {
    const curvature = 0.85;
    const curveWidth = (width / 2) * (1 - curvature);
    const curveHeight = (height / 2) * (1 - curvature);
    return `
        M 0, ${height / 2}
        C 0, ${curveWidth} ${curveHeight}, 0 ${width / 2}, 0
        S ${width}, ${curveHeight} ${width}, ${height / 2}
            ${width - curveWidth}, ${height - 0} ${width / 2}, ${height}
            0, ${width - curveHeight} 0, ${height / 2}
    `;
}

/**
 * Gets the appropriate match percentage value
 * given the current match percentage value.
 *
 * matchPercentage >= 75 = 100
 * matchPercentage >= 50 = 75
 * matchPercentage >= 25 = 50
 * matchPercentage >= 0 = 25
 *
 * @param match the match percentage value
 * @returns the match percentage value
 */
const getMatchValue = (match: number) => {
    return match >= 75 ? 100 :
        match >= 50 ? 75 :
            match >= 25 ? 50 : 25;
}

/**
 * Parses and formats the error response
 * from the api request.
 *
 * @param title
 * @param error the error to format
 * @returns the formatted error
 */
const createRequestError = (title: string, error: any): ErrorResponse => {
    console.warn('errory', error)
    if (axios.isAxiosError(error)) {
        const data = error?.response?.data
        return {
            message: data.message as string,
            type: 'error',
            title: title,
            errors: data.errors
        };
    } else if (error instanceof Error) {
        return {
            message: error.message,
            type: 'error',
            title: title,
            errors: undefined
        };
    } else {
        return {
            message: error as string,
            type: 'error',
            title: title,
            errors: undefined
        };
    }
}

/**
 * Gets the relative tive time from the given date.
 *
 * @param date the date to check
 * @returns new date
 */
const getTimeAgo = (date: string | Date) => {
    if (!date) return '';

    try {

        if (!`${date.toString()}`.includes('UTC') && !`${date.toString()}`.includes('+') && !`${date.toString()}`.endsWith('Z')) {
            date+= ' UTC'
        }

        return timeAgo.format(new Date(date));
    } catch (error) {
        console.warn(`could not parse date ${date?.toString()}`, error);
        return '';
    }
}

const replaceNumberWithUnlimited = (str: string) => {
    const numToReplace = "9223372036854776000";
    const regex = new RegExp(`\\b${numToReplace}\\b`, "g");

    if (str.match(regex)) {
      return str.replace(regex, "Unlimited");
    } else {
      return str;
    }
  }

/**
 * Gets a snapshot of a video file in base64 string.
 *
 * @param file the file to upload
 * @returns base64 string
 */
const getVideoSnapshot = async (file: File) => {
    try {
        const snapshoter = new VideoSnapshot(file);
        return await snapshoter.takeSnapshot();
    } catch (err) {
        console.error('getVideoSnapshot', err);
    }
}

/**
 *
 * @param date the date to format
 * @param format the desired format (see: https://date-fns.org/v2.28.0/docs/format)
 * @returns formatted date
 */
const formatDate = (
    date: string | Date | undefined,
    format = 'Pp'
) => {
    const now = Date.now();
    if (!date) {
        return '';
    }
    if (isEmpty(date)) {
        return fnsFormat(now, format);
    } else if (typeof date === 'string') {
        const nextDate = new Date(date);
        const validDate = fnsIsValid(nextDate);

        if (format === 'chat-thread' && validDate) {

            const options: Intl.DateTimeFormatOptions = { // options object for toLocaleDateString and toLocaleTimeString methods
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
            };

            const formattedDate = nextDate.toLocaleDateString(undefined, options); // format the date component of the date string based on the browser's locale

            return formattedDate
        }

        if (format === 'relative') {
            formatRelative(nextDate, now)
        }

        return fnsFormat(validDate ? nextDate : now, format);
    } else {
        return fnsFormat(date, format);
    }
}

/**
 * Converts the given media url or object from
 * shopify to a Media object format.
 *
 * @param media the media to upload
 * @returns the formatted media
 */
const formatMedia = (media: string | ShopifyMedia) :Media => {
    return {
        uid: Helpers.generateId(),
        name: '',
        mime: typeof media === 'string' ?
            'image.png' : media?.videoSrc ?
                media.videoSrc.mimeType : 'image.png',
        gallery_path: typeof media === 'string' ?
            media : media?.videoSrc?.url || media?.image,
        display_order: 1,
        is_cover: 1,
        primary_color: null,
        secondary_color: null,
        thumbnail_path: typeof media === 'string' ?
            media : media?.image,
        type: 'SHOPIFY'
    }
}

/**
 * Formats the plain-text message/attachment message.
 * This is added to immediately show the message
 * on the Wave/Influencer chat ui.
 *
 * @param message the message to format
 * @param sendingStatus the sending status of the message
 * @returns the formatted message
 */
const formatMessage = (message: string | File, sendingStatus: string) => {
    const now = new Date().toUTCString();

    if (typeof message === 'string') {
        return {
            id: generateId(),
            bubble_type: 'user-message',
            sender_type: 'BRAND',
            meta: {
                message: message
            },
            created_at: now,
            sendingStatus
        }
    } else {
        return {
            id: generateId(),
            bubble_type: 'attachment',
            sender_type: 'BRAND',
            meta: {
                attributes: {
                    attachment: URL.createObjectURL(message),
                    mime_type: message.type
                },
                file: message
            },
            created_at: now,
            sendingStatus
        }
    }
}

const isEmpty = (data: object|string|number|undefined|boolean|null): boolean => {
    return isNullOrUndefined(data) ||
        (typeof data === 'object' && Object.keys(data).length === 0) ||
        (Array.isArray(data) && data.length === 0) ||
        (typeof data === 'string' && data.trim().length === 0);
}

const isEmptyObject = (data: Dynamic, strict = false) => {
    if (strict) {
        return some(data, isEmpty);
    } else {
        return every(data, isEmpty);
    }
}

const isNullOrUndefined = (data: any): data is null | undefined => {
    return isNil(data);
}

const isVideo = (typeOrExtension: string, settings: ClientSettings) => {
    return settings.allowedMimeTypes.videos.some((mime) => mime.includes(typeOrExtension));
}

//#region Array related functions
const arrayEquals = (arr1: any[], arr2: any[]) => {
    return isEqual(arr1, arr2);
}

const inArray = (arr: object[], item: object) => {
    return find(arr, item);
}

const diffArray = (arr1: object[], arr2: object[]) => {
    return differenceWith(arr1, arr2, isEqual);
}

/**
 * Gets the symmetrical difference between the two arrays.
 *
 * e.g.
 *
 * array1 = [2, 4, 5, 7]
 *
 * array2 = [5, 8, 11]
 *
 * symmetrical result = [2, 4, 7, 8, 11]
 *
 * not symmetrical result = [2, 4, 7]
 *
 * @param arr1 the first array
 * @param arr2 the second array
 * @returns the symmetric difference between the two arrays
 */
const diffSymmArray = (arr1: object[], arr2: object[]) => {
    return diffArray(arr1, arr2)
        .concat(diffArray(arr2, arr1));
}
//#endregion

//#region String related functions
const containsUrl = (text: string) => {
    const regex = /(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    return text.match(regex)?.[0];
}

const padLeft = (data: string | number, pad: string, length = 2) => {
    return data.toString().padStart(length, pad);
}

const padRight = (data: string | number, pad: string, length = 2) => {
    return data.toString().padStart(length, pad);
}
//#endregion

//#region Object related functions
const pickObjectKeys = (obj: Dynamic, keys: string[]) => {
    return pick(obj, keys);
}

const removeObjectKeys = (obj: Dynamic, keys: string[]) => {
    return omit(obj, keys) as object;
}
//#endregion


export const Helpers = {
    generateId,
    generateInt,
    generateSquircle,
    getMatchValue,
    createRequestError,
    getTimeAgo,
    getVideoSnapshot,
    formatDate,
    formatMedia,
    formatMessage,
    isEmpty,
    isEmptyObject,
    isEqual,
    isNullOrUndefined,
    isVideo,

    arrayEquals,
    inArray,
    diffArray,
    diffSymmArray,

    replaceNumberWithUnlimited,

    containsUrl,
    padLeft,
    padRight,

    pickObjectKeys,
    removeObjectKeys
};
