type DataLayerType = {
    push: (event: any) => void;
};
declare let dataLayer: DataLayerType;

class GoogleTagManagerService {
    track(eventName: string, props: any) {
        const gtmEvent = Object.assign({ eventName: eventName }, props);
        dataLayer.push(gtmEvent);
    }
}

const googleTagManagerService = new GoogleTagManagerService();
export default googleTagManagerService;
