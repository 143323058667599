import { ChangeEvent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { FormLabel } from '../FormLabel';
import { useAppSelector } from 'store';

interface Props {
    name: string;
    label: string;
    placeholder?: string;
    value: string;
    error?: string|string[];
    rows?: number;
    limit?: number;
    autoHeight?: boolean;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    onKeyPress?: any;
    onChange(name: string, value: string): void;
}

const Textarea = styled.textarea`
    font-size: 14px;
    width: 100%;
    min-height: 88px;
    max-height: 350px;
    border: none;
    background-color: transparent;
    color: var(--grey-9);
    resize: vertical;
    outline: none;

    &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: var(--grey-6);
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: var(--grey-6);
    }
    
    ::-ms-input-placeholder {
        color: var(--grey-6);
    }
`;

const Content = styled.div`
    display: flex;
    column-gap: 8px;
    position: relative;
    padding: 12px 12px 6px;
    border: 1.5px solid var(--grey-2);
    border-radius: 14px;
    background-color: var(--grey-1);
    color: var(--grey-9);
    transition: border-color 300ms ease;
    overflow: hidden;

    svg {
        flex-shrink: 0;
        margin-left: -1.5px;
    }
`;

const Limit = styled.span`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 4px;
    text-align: center;
    color: var(--grey-6);
`;

const Error = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: var(--error-active);
`;

const Container = styled.div<{
    $autoHeight?: boolean;
    $focus?: boolean;
    $disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    ${(props) => props.$autoHeight && `
        ${Textarea} {
            height: auto;
            min-height: 0px;
            resize: none;
        }
    `}

    ${(props) => props.$focus && `
        ${Content} {
            border-color: var(--blue);
        }
    `}

    ${(props) => props.$disabled && `
        pointer-events: none;

        ${Content} {
            background-color: var(--grey-3);

            ${Textarea} {
                color: var(--grey-6);
            }
        }
    `}
`;

export function FormTextarea({
    name,
    label,
    placeholder,
    value,
    error,
    rows,
    limit,
    autoHeight,
    required,
    disabled,
    className,
    children,
    onChange,
    onKeyPress
}: PropsWithChildren<Props>) {
    const {
        global: {
            inputError
        },
    } = useAppSelector((state) => state);

    const id = `textarea-${name}`;

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [focus, setFocus] = useState<boolean>(false);

    useEffect(() => {
        if (autoHeight) {
            const textareaEl = textareaRef.current;

            if (textareaEl) {
                textareaEl.style.height = '0px';

                const scrollHeight = textareaEl.scrollHeight;

                textareaEl.style.height = `${scrollHeight + 1}px`;
            }
        }
    }, [value, autoHeight]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        onChange(name, value);
    }

    const handleBlur = () => {
        setFocus(false);
    }

    const handleClick = () => {
        setFocus(true);
    }

    return (
        <Container
            data-testid={'form-textarea-input'}
            $autoHeight={autoHeight}
            $focus={focus}
            $disabled={disabled}
            className={className}
        >
            {label && (
                <FormLabel
                    labelFor={id}
                    required={required}
                >
                    {label}
                </FormLabel>
            )}

            <Content>
                {children}

                <Textarea
                    ref={textareaRef}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    rows={rows}
                    maxLength={limit}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    onChange={handleChange}
                    onKeyPress={onKeyPress ? (e) => {onKeyPress(e)} : () => {}}
                    onBlur={handleBlur}
                    onClick={handleClick}
                />
            </Content>

            {(limit && limit > 0) && (
                <Limit>{value.length}/{limit} characters</Limit>
            )}

            {error && (
                <Error>{error}</Error>
            )}

            {inputError[name] && (
                <Error>{inputError[name][0]}</Error>
            )}

        </Container>
    );
}
