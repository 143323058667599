import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-areas:
        'header right-content'
        'left-content right-content';
    grid-template-rows: max-content 1fr;
    grid-template-columns: minmax(0, 1fr) 428px;
    width: 1240px;
    height: 70vh;
    min-height: 750px;
    margin: auto;
    margin-top: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background: var(--background-alt);
    // overflow: hidden;

    // eventually we will want to split these into columns?
    // or it just need to render a different component that's optimized for mobile...
    // @media (max-width:900px) and (min-width:0px) {
    //     display: flex;
    //     flex-direction: column;
    //     height: fit-content;
    // }

    @media (max-width:900px) and (min-width:0px) {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 98vw;
    }

`;

export function GiftViewCard({
    children
}: PropsWithChildren<any>) {
    return (
        <Container data-testid={'gift-view-card-component'}>
            {children}
        </Container>
    );
}
