import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import AngleDown from '../../icons/angle-down.svg'
import { useMediaQuery } from 'usehooks-ts';

export interface Option {
    id: string | number;
    name: string;
}

interface Props {
    options: Option[],
    selected: Option,
    changeFilterItems: (option: Option) => void;
    onTab?: boolean
    whiteBkg?: boolean;
    style?: any;
}

const Container = styled.div`
    position: relative;
    background: var(--grey-1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px 16px;
    height: 44px;
    cursor: pointer;

    span {
        color: var(--blue);
    }
    font-size: 12px;

    img {
        transform: translateY(2px);
    }


    @media (max-width:900px) and (min-width:0px) {
        width: 79vw;
        margin: auto;
        padding: 4px 12px;

        &.white {
            background: white;
            box-shadow: 0 0 2px 0 rgb(65 62 62 / 63%);
        }
    }
`;

const SelectName =  styled.div`
    @media (max-width:900px) and (min-width:0px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 12px;
    }
`

const OptionList = styled.div`
    position: absolute;
    top: 115%;
    right: 0;
    background: white;
    padding: 8px 8px;
    border-radius: 16px;
    z-index: 10;
    min-width: 200px;
    align-items:center;
    justify-content:center;
    box-shadow: var(--shadow-1);
    flex-direction: column;
    row-gap: 12px;
    width: -webkit-fill-available;

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
    }
`

const OptionComponent = styled.div`
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 16px;
    transition: all 100ms ease;
    will-change: background;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--grey-9);
    font-size: 14px;

    &:hover {
        background: var(--grey-3);
    }

    &.active {
        background: #74d7c2;
        color: white;

        span {
            color: white;
        }
    }
`

export function FilterSelect({
    options,
    selected,
    changeFilterItems,
    onTab,
    whiteBkg,
    style
}: PropsWithChildren<Props>) {

    const [showMenu, setShowMenu] = useState(false)

    const [selectedValue, setSelectedValue] = useState(selected)

    const handleChange = (opt: Option) => {
        setShowMenu(false)
        setSelectedValue(opt)
        changeFilterItems(opt)
    }

    const smallDevice = useMediaQuery('(max-width:900px)');

    return (
        <Container style={style} onClick={() => setShowMenu(!showMenu)} className={whiteBkg ? 'white' : ''}>
            <SelectName>
                {!onTab && (!smallDevice ? 'Sorting by ' : 'By ')}
                {selectedValue.name} <img alt="expand" height="13" src={AngleDown} />
            </SelectName>

            {showMenu && (
                <OptionList>
                    {options.map((opt) => (
                        <OptionComponent className={selectedValue.id === opt.id ? 'active' : ''} onClick={() => handleChange(opt)}>{!smallDevice ? 'Sort by' : 
                        'By'} {opt.name}</OptionComponent>
                    ))}
                </OptionList>
            )}
        </Container>
    );
}
