import {
    BlogIcon,
    CardAEIcon,
    CardMasterCardIcon,
    CardVisaIcon,
    CartIcon,
    CheckCircleFilled,
    CloseCircleFilled, ConfettiIcon,
    ExclamationCircleFilled,
    ExperienceIcon,
    InfoCircleFilled,
    InstaPostIcon,
    QuestionCircleFilled, StarFilledIcon,
    StarIcon,
    TiktokIcon,
    VoucherIcon,
    YoutubeIcon,
} from '../app/icons';

const alertTypeMapping: Dynamic = {
    alert: <QuestionCircleFilled />,
    error: <CloseCircleFilled />,
    info: <InfoCircleFilled />,
    success: <CheckCircleFilled />,
    warning: <ExclamationCircleFilled />
};

const analyticsMapping: Dynamic = {
    comments: 'Comments',
    engagementRate: 'Engagement',
    followers: 'Followers',
    likes: 'Likes',
    rating: 'Rating',
};

const attributesMap: Dynamic = {
    gender: {
        name: 'Gender',
        placeholder: 'Select gender'
    },
    sexuality: {
        name: 'Sexuality',
        placeholder: 'Select sexuality'
    },
    marital_status: {
        name: 'Relationship status',
        placeholder: 'Select relationship status'
    },
    ethnicity: {
        name: 'Ethnicity',
        placeholder: 'Select ethnicity'
    },
    eye_color: {
        name: 'Eye color',
        placeholder: 'Select eye color'
    },
    hair_type: {
        name: 'Hair type',
        placeholder: 'Select your hair type'
    },
    hair_color: {
        name: 'Hair color',
        placeholder: 'Select your hair color'
    },
    hair_length: {
        name: 'Hair length',
        placeholder: 'Select your hair length'
    },
    facial_hair: {
        name: 'Facial hair',
        placeholder: 'Select facial hair'
    },
    skin_tone: {
        name: 'Skin tone',
        placeholder: 'Select your skin tone'
    },
    build: {
        name: 'Build',
        placeholder: 'Select your body build'
    },
    shirt_size: {
        name: 'Size',
        placeholder: 'Select your shirt size'
    },
    skin_type: {
        name: 'Skin type',
        placeholder: 'Select your skin type',
    },
    children: {
        name: 'Children',
        placeholder: 'Select your children'
    },
    diet: {
        name: 'Diet',
        placeholder: 'Select your diet'
    },
    pets: {
        name: 'Pets',
        placeholder: 'Select your pets'
    }
};

const cardsMapping: Dynamic = {
    amex: <CardAEIcon />,
    mastercard: <CardMasterCardIcon />,
    visa: <CardVisaIcon />,
};

export const deliverableToIcon = (id: number): JSX.Element => {
    switch (id) {
        case 1:
        case 2:
        case 3:
            return <InstaPostIcon />;
        case 4:
            return <TiktokIcon />;
        case 5:
            return <YoutubeIcon />;
        case 6:
            return <BlogIcon />;
        case 7:
            return <StarIcon />;
        case 8:
            return <ConfettiIcon />
        default:
            return <StarFilledIcon />
    }
}

const giftButtonTextMapping: Dynamic = {
    'DRAFT': 'Submit',
    'SUBMITTED': 'Edit',
    'REJECTED': 'Edit',
    'ACTIVE': 'Close',
    'CLOSED': 'Close'
};

const giftNextStatusMapping: Dynamic = {
    'close': 'CLOSED',
    'open': 'ACTIVE',
    'submit': 'SUBMITTED',
    'draft': 'DRAFT',
    'complete': 'COMPLETED',
    'cancel': 'CANCELED'
};

const giftTransitionStatusMapping: Dynamic = {
    'DRAFT': 'submit',
    'SUBMITTED': 'draft',
    'REJECTED': 'draft',
    'ACTIVE': 'complete',
    'CLOSED': 'complete',
    'DELETE': 'cancel',
    // hiding and unhiding gift
    'HIDE': 'close',
    'UNHIDE': 'open'
};

const giftTypeMap: Dynamic = {
    PRODUCT: 'Products',
    GIFT_VOUCHER: 'Gift Voucher',
    EXPERIENCE: 'Experience',
    SHOPIFY: 'Product from Shopify',
};

const matchPercentageMap: Dynamic = {
    100: 'var(--success-active)',
    75: 'var(--alert-active)',
    50: 'var(--warning-active)',
    25: 'var(--error-active)'
};
const socialMapping = (platform: SocialPlatformType) => {
    switch (platform) {
        case 'INSTAGRAM':
            return <InstaPostIcon />;
        case 'TIKTOK':
            return <TiktokIcon />;
        case 'YOUTUBE':
            return <YoutubeIcon />;
        default:
            return null;
    }
}

const socialPlaceholderMapping = (platform: SocialPlatformType) => {
    switch (platform) {
        case 'INSTAGRAM':
            return 'https://instagram.com/yourbrand';
        case 'TIKTOK':
            return 'https://tiktok.com/@yourbrand';
        case 'YOUTUBE':
            return 'https://www.youtube.com/c/yourbrand';
        default:
            return '';
    }
}

const supportedSocialPlatforms: SocialPlatform[] = [
    { id: 'INSTAGRAM', name: 'Instagram' },
    { id: 'TIKTOK', name: 'TikTok' },
    { id: 'YOUTUBE', name: 'YouTube' },
];

export const Mapping = {
    AlertType: alertTypeMapping,
    Analytics: analyticsMapping,
    Attributes: attributesMap,
    Card: cardsMapping,
    GiftButtonStatusMappig: giftButtonTextMapping,
    GiftNextStatusMapping: giftNextStatusMapping,
    GiftTransitionStatusMapping: giftTransitionStatusMapping,
    GiftTypes: giftTypeMap,
    MatchPercentage: matchPercentageMap,
    Social: socialMapping,
    SocialPlaceholder: socialPlaceholderMapping,
    SupportedSocialPlatforms: supportedSocialPlatforms,
};

export const GiftTypeDefinitions: GiftTypeDefinition[] = [
    {
        type: 'PRODUCT',
        label: 'Products & Services',
        description: 'Product or service that you sell',
        icon: <CartIcon />,
    },
    {
        type: 'GIFT_VOUCHER',
        label: 'Gift Card',
        description: 'Redeemable through your website or in person',
        icon: <VoucherIcon />,
    },
    {
        type: 'EXPERIENCE',
        label: 'Experience',
        description: 'Hotel Stays, Restaurants, hairdressing & more',
        icon: <ExperienceIcon />,
    },
    {
        type: 'EVENT',
        label: 'Events',
        description: 'In person events, parties & more',
        icon: <ExperienceIcon />,
    }
];
