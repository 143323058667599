import { HTMLMotionProps, motion } from 'framer-motion';
import styled from 'styled-components';

import { Logo } from 'app/icons';

type Props = HTMLMotionProps<'div'>;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    transform: scale(1);
    z-index: 1010;
`;

export function AppLogo(props: Props) {
    const { className, onClick } = props;

    const animation = {
        initial: false,
        layoutId: 'logo-motion',
        transition: { duration: 0.65 }
    };

    return (
        <Container
            {...animation}
            {...props}
            data-testid={'app-logo'}
            className={className}
            onClick={onClick}
        >
            <Logo />
        </Container>
    );
}