import {useMemo} from "react";
import subscriptionService, {PlanType} from "../../../services/subscriptionService";
import {Button, Props as ButtonProps} from "../Button";
import {Actions, useAppDispatch, useAppSelector} from "../../../store";
import {GiftIcon} from "../../icons";
import styled from "styled-components";
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';

interface Props extends ButtonProps {
    brand: BrandProfile;
}

const UpgradeButton = ({ brand, theme = 'alert', size = 'small' }: Props) => {

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );
    const dispatcher = useAppDispatch();

    const upgradeCta = useMemo(() => {
        if (!brand) {
            return null;
        }
        if (!brand.subscription || !subscriptionService.isSubscribed(brand.subscription)) {
            const proPlan = subscriptionService.findPlanDefinition(PlanType.PRO);
            return 'Upgrade to ' + proPlan.shortName;
        } else if (brand.subscription && (subscriptionService.isSubscribed(brand.subscription))) {
            const type = subscriptionService.findPlanTypeForSubscription(brand.subscription);
            const upgradePlan = subscriptionService.findUpgradePlan(type);
            if (upgradePlan) {
                const plan = subscriptionService.findPlanDefinition(upgradePlan);
                return 'Upgrade to ' + plan.shortName;
            }

            if (brand.subscription.canceled_at) {
                return 'Resubscribe';
            }
        }
        return null;
    }, [brand?.subscription]);

    if (!settings?.features.subscriptions || !upgradeCta) {
        return null;
    }

    return (
        <StyledButton size={size} theme={theme} className="upgrade-btn" onClick={() => {
            analyticsEvents.dashboardSubscriptionButtonClicked();
            dispatcher(Actions.App.setShowPaymentModal(true, (!!brand?.subscription?.canceled_at || !subscriptionService.isSubscribed(brand.subscription))));
        }}>
            <GiftIcon />
            {upgradeCta}
        </StyledButton>
    );
};

export default UpgradeButton;

const StyledButton = styled(Button)`
    svg path {
      fill: white;
    }

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`
