import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Helpers, Mapping } from 'utils';
import { Button, NoResult } from 'app/components';
import { AngleLeftIcon } from 'app/icons';
import { useAppSelector, Actions, useAppDispatch } from 'store';
import { useMemo } from 'react';
import subscriptionService, { PlanType } from 'services/subscriptionService';

interface Props {
    posts?: SocialMedia[];
    influencer: Influencer;
    selectedPost?: SocialMedia;
    loading?: boolean;
    onPostSelected(post: SocialMedia): void;
    onSubmitReview(): void;
    onGoBack(): void;
}

const Title = styled.span`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.75px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


const Image = styled.div<{
    $image?: string;
}>`
    position: relative;
    height: 296px;
    background-color: var(--blue);
   
    ${(props) => props.$image && `
        background-image: url(${props.$image});
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    `}

    &.blurred {
        filter: blur(2px);
    }

`;

const SocialIcon = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--grey-3);
    border-radius: 12px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);

    svg {
        width: 20px;
        height: 20px;
    }
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    user-select: none;

    span {
        align-self: flex-start;

        &:first-child {
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.5px;
            color: var(--grey-9);
        }

        &:last-child {
            font-size: 12px;
            line-height: 16px;
            color: var(--grey-6);
        }
    }
`;

const Info = styled.div`
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 8px 12px 12px;
`;

const Item = styled(motion.div) <{
    $selected?: boolean;
}>`
    flex-shrink: 0;
    width: 244px;
    border-radius: 20px;
    background-color: var(--grey-2);
    transform: scale(1);
    transform-origin: center center;
    transition: box-shadow 300ms ease,
        background-color 300ms ease;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &:active:not(:focus-within) {
        transition: transform 300ms ease;
        transform: scale(0.95) !important;
    }

    &:hover {
        box-shadow: 0 0 0 2px var(--aqua);
        background-color: var(--background-alt);
    }
    
    ${(props) => props.$selected && `
        box-shadow: 0 0 0 2px var(--aqua);
        background-color: var(--background-alt);
    `}

`;

const List = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
`;

const Body = styled.div``;

const Container = styled.div<{
    $loading?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    width: 100%;

    ${(props) => props.$loading && `
        pointer-events: none;

        ${SocialIcon},
        ${Image},
        ${Desc} span {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '##############';
                visibility: hidden;
            }
        }

        ${SocialIcon} {
            border-radius: 12px;
        }

        ${Desc} span:last-child {
            margin-top: 1px;
        }
    `}
`;

const StyledButton = styled(Button)`

    &.upgrade-paywall {
        position: absolute;
        margin: auto;
        inset: 0;
        width: 170px;

        &:hover {
            background: #262161;
            color: white;
        }

        &:focus {
            transform: none;
        }
    }
`

const NeverMiss = styled.div`
    position: absolute;
    margin: auto;
    inset: 0;
    top: 100px;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 2px rgba(0,0,0,.7);
`

const Badge = styled.div`
    position: absolute;
    bottom: 69px;
    right: 8px;
    height: 20px;
    background: var(--blue);
    color: white;
    font-weight: 900;
    font-size: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
`

export function InfluencerPosts({
    influencer,
    posts,
    selectedPost,
    loading,
    onPostSelected,
    onGoBack
}: Props) {

    const {
        profile: {
            brand
        }
    } = useAppSelector((state) => state);

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const dispatcher = useAppDispatch();

    const isStarter = useMemo(() => brand?.subscription?.subscription && subscriptionService.isSubscriptionType(brand?.subscription?.subscription, PlanType.STARTER), [brand]);
    const isGrow = useMemo(() => brand?.subscription?.subscription && subscriptionService.isSubscriptionType(brand?.subscription?.subscription, PlanType.GROW), [brand]);


    const postListAnimation = {
        initial: loading ? 'loading' : 'hidden',
        animate: loading ? 'loading' : 'show',
        variants: {
            show: {
                transition: {
                    staggerChildren: 0.1
                }
            }
        }
    };

    const postItemAnimation = {
        transition: {
            type: 'spring',
            duration: 0.8
        },
        variants: {
            loading: { opacity: 1, scale: 1, y: 0 },
            hidden: { opacity: 0, scale: 0.8, y: 80 },
            show: { opacity: 1, scale: 1, y: 0 },
        }
    };

    //@ts-ignore
    const placeholder: SocialMedia[] = Array(3).fill('').map((value, index) => ({
        external_id: `fake-post-${index}`,
        influencer: "", insights: {
            comments: 0, dislikes: 0, engagement_rate: 0, favourites: 0, likes: 0, shares: 0, views: 0
        },
        items: [{
            description: "",
            embedHtml: "",
            externalUrl: "",
            mediaThumbnailUrl: "",
            mediaUrl: "",
            title: "",
            stored: true,
            type: 'VIDEO'
        }],
        platform: "",
        posted_at: "",
        type: ""
    }));

    const postList = loading ? placeholder : posts;

    const handleItemClick = (post: SocialMedia) => {

        if (((settings?.features.subscriptions) && (!brand?.subscription?.subscription || isStarter ||isGrow)) && (post.platform === 'INSTAGRAM' && post.type === 'STORY')) {
            dispatcher(Actions.App.setShowPaymentModal(true, !subscriptionService.isSubscribed(brand?.subscription)));
            return
        } else {
            onPostSelected(post);
        }
    }

    return (
        <Container $loading={loading}>
            <Header>
                <Button
                    theme={'aqua'}
                    size={'small'}
                    iconOnly={true}
                    onClick={onGoBack}
                >
                    <AngleLeftIcon />
                </Button>

                <Title>
                    Content by {influencer.name}
                </Title>

                <Title>

                </Title>
            </Header>

            <Body>
                <List>
                    {postList?.map((post) => {
                        const mediaItem = post.items[0];
                        let url = mediaItem.mediaThumbnailUrl || mediaItem.mediaUrl;
                        if (settings && (!url.startsWith('https://') && !url.startsWith('http://'))) {
                            url = settings.media.endpoint + url;
                        }
                        return (
                            <Item
                                {...postListAnimation}
                                {...postItemAnimation}
                                key={`${post.platform}-${post.external_id}`}
                                $selected={selectedPost?.external_id === post.external_id}
                                onClick={() => {
                                    handleItemClick(post);
                                }}
                            >
                                {!loading && (<Badge>{post.platform} {post.type}</Badge>)}
                                <div style={{overflow: 'hidden'}}>
                                    <Image
                                        className={
                                            ((settings?.features.subscriptions) && (!brand?.subscription?.subscription || isStarter || isGrow)) && (post.platform === 'INSTAGRAM' && post.type === 'STORY') ? 'blurred' : ''
                                        }
                                        $image={url}
                                    />
                                </div>

                                {
                                    (settings?.features.subscriptions && (!brand?.subscription?.subscription || isStarter || isGrow) && (post.platform === 'INSTAGRAM' && post.type === 'STORY')) &&
                                        (
                                            <>
                                                <NeverMiss>Never miss an Instagram Story with #gifted Pro</NeverMiss>
                                                <StyledButton size="small" className="upgrade-paywall">Upgrade to Pro</StyledButton>
                                            </>
                                        )
                                }

                                <Info>
                                    <SocialIcon>
                                        {Mapping.Social(post.platform)}
                                    </SocialIcon>

                                    <Desc>
                                        <span>
                                            {!loading && `Posted ${Helpers.formatDate(post.posted_at, 'dd MMM, Y').toString()}`}
                                        </span>
                                    </Desc>
                                </Info>
                            </Item>
                        );
                    })}
                </List>

                {(!loading && postList?.length === 0) && (
                    <NoResult
                        image={'search'}
                        title={`Sorry, we didn't find any\ninfluencer's posts.`}
                    />
                )}
            </Body>
        </Container>
    );
}
