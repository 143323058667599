import {Navigate, Route, Routes, useLocation, useSearchParams} from 'react-router-dom';

import {
    privateRoutes,
    publicRoutes
} from './routes';
import settingsService from 'services/settingsService';
import { useMediaQuery } from 'usehooks-ts';
import { useEffect } from 'react';

export function Routing() {
    const storedCreds = localStorage.getItem(settingsService.credentialsStorageKey)
    const creds = storedCreds && JSON.parse(storedCreds);
    const invited = localStorage.getItem('teamInviteFinish');

    const loggedIn = (creds && ((creds.status !== 'INCOMPLETE' && creds.brand_id) || invited))
    const routes = loggedIn ? privateRoutes : publicRoutes;

    const history = useLocation();
    const [searchParams,] = useSearchParams();
    const smallDevice = useMediaQuery('(max-width: 900px)');


    const possibleParams = `${searchParams.toString()}`.split('&')


    let path = '/';
    if (history.pathname !== '/' && !searchParams.has('redirect')) {
        path += `${loggedIn ? '' : 'login/'}?redirect=${history.pathname}`

        possibleParams.forEach((param, index) => {
            if (param.length) {
                path += `${index === 0 ? '?' : '&'}${param}`
            }
        })
    }

    let redirect = searchParams.get('redirect');
    if (redirect && possibleParams.length > 1) {
        possibleParams.forEach((param, index) => {
            if (param.length) {
                redirect += `${index === 0 ? '?' : '&'}${param}`
            }
        })
    }

    useEffect(() => {
        if (smallDevice) {
            window.scrollTo(0, 0);
        }
    }, [history.pathname])

    return (
        <Routes>
            {routes.map(({ name, path, element: Element }) => (
                <Route
                    key={name}
                    path={path}
                    element={<Element />}
                />
            ))}

            <Route
                path={'*'}
                element={
                    <Navigate
                        to={redirect ? redirect : path}
                        replace={true}
                    />
                }
            />
        </Routes>
    );
}
