import styled from 'styled-components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CarouselList } from '../CarouselList';
import settingsService from 'services/settingsService';

const H2 = styled.h2`
    color: #262262;
    margin-top: 0px;
    margin-bottom: 25px;
    color: #262262;
    font-size: 32px;
    line-height: 1.3;
    font-weight: 700;    
    width: 100%;
    max-width: 780px;    

    &.sub {
        font-size: 23px;
        transform: translateY(15px);
    }
`;

const Desc = styled.span`
    color: #262262;
    font-size: 22px;
    line-height: 34px;  
    width: 100%;
    max-width: 780px;    
    font-weight: 400;
    margin-bottom: 50px;  
`;

const Container = styled.div`
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export function PendingBrand() {

    const [blogData, setBlogData] = useState<Blog[]>([])

    useEffect(() => {

        const getArticles = () => {
            axios.get(`${settingsService.baseApiUrl}shared/articles/brands`).then((res) => {
                setBlogData(res.data as Blog[])
            }).catch((e) => {
                throw e
            })    
        }

        getArticles();

    }, [])

    return (
        <Container data-testid={'noresult-component'}>

            <H2>
                Thanks for signing up to #gifted
            </H2>

            <Desc>
                We are so happy for you to join us in the #gifted beta program. We will approve your application shortly. When approved you can post your first gift and start gifting.
            </Desc>

            <H2 className="sub">
                Check out these tips, tricks, and stories from the team at #Gifted
            </H2>


            {blogData.length ? (
                <CarouselList list={blogData} />
            ) : null}

        </Container>
    );
}
