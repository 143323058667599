
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';

import { Actions, useAppDispatch } from 'store';
import { AppLogo, Loader } from 'app/components';
import { Timer } from '../../components/Timer';

export function LoginWithTokenPage() {
    const animation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { delay: 0.5 }
    };

    const navigate = useNavigate();

    const dispatcher = useAppDispatch();

    const { search } = useLocation();

    const [status, setStatus] = useState<string>('loading');
    const [statusMessage, setStatusMessage] = useState<string>('Admin login in progress...');

    useEffect(() => {
        const params = new URLSearchParams(search);
        const token = params.get('token');
        if (token) {
            dispatcher(Actions.Auth.logout())
                .finally(() => {
                    dispatcher(Actions.Auth.loginWithToken(token ))
                        .then(() => setStatus('success'))
                        .then(() => navigate('/')).catch((error: any) => {throw error});
                });
        } else {
            setStatus('failure');
            setStatusMessage('Admin login failed')
        }
    }, [dispatcher, navigate, search]);

    return (
        <Container data-testid={'login-token-callback-page'}>
            <StyledAppLogo />

            <Caption {...animation}>
                {statusMessage}
            </Caption>

            {status !== 'failure' && (
                <StyledLoader>
                    <Loader status={status} />
                </StyledLoader>
            )}

            {status === 'success' && (
                <StyledTimer seconds={5} />
            )}
        </Container>
    );
}


const StyledAppLogo = styled(AppLogo)`
    svg {
        transform: scale(1.15);
    }
`;

const Caption = styled(motion.span)`
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -1px;
    color: var(--grey-6);
`;

const StyledTimer = styled(Timer)`
    position: absolute;
    bottom: 40px;
    margin: auto;
`

const StyledLoader = styled.span`
    transform: scale(1.4);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 50px;
    width: 100vw;
    height: 100vh;
`;
