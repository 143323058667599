import {
    ReactNode,
} from 'react';
import styled from 'styled-components';
import {
    Link,
    Button
} from 'app/components';
import { useSearchParams } from "react-router-dom";
import HorizontalSub from '../../../images/horizontal-sub.png'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`;

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: white;
    width: 250px;
    margin-right: 24px;

    button {
        width: 250px;
    }
`

const LoginLink = styled(Link)`
    text-decoration: none;
    display: block;
    width: 250px;

    button {
        width: 250px;
    }
`

interface DisabledSignUpFormProps {
    children: ReactNode;
}  

export function DisabledSignUpForm({
    children,
  }: DisabledSignUpFormProps) {
  
    const [searchParams,] = useSearchParams();

    let loginPath = '/login';
    if (searchParams.get('redirect')) {

        const possibleParams = `${searchParams}`.split('&')

        loginPath += `?redirect=${searchParams.get('redirect')}`
        possibleParams.forEach((param, index) => {
            if (index !== 0) {
                loginPath += `&${param}`
            }
        })

    }

    return (
        <Container>
            {children}

            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <StyledLink href="https://forms.gle/1DGNXvGxqacu4SxX7" target="_blank">
                    <StyledButton
                        theme="aqua"
                    >
                        Signup for early access
                    </StyledButton>
                </StyledLink>

                <LoginLink to={loginPath}>
                    <StyledButton>
                        Login as brand
                    </StyledButton>                    
                </LoginLink>

            </div>


            <img src={HorizontalSub} />

            {/* <p>
                <span>Already have an account? </span>

                <Link to={loginPath}>Log in</Link>
            </p> */}
            
        </Container>
    );
}
