import { Fragment } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { AngleRightIcon } from 'app/icons';
import { useMediaQuery } from 'usehooks-ts';

export interface Breadcrumb {
    id: string;
    name: string;
    destination?: GiftFlowStep;
    route?: string;
}

interface Props {
    items: Breadcrumb[];
    onClick?(step: GiftFlowStep): void;
}

const Item = styled.span<{
    $active?: boolean;
}>`
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    padding: 4px 12px;
    border-radius: 8px;
    border: 1px solid var(--grey-5);
    text-transform: capitalize;
    background-color: var(--background-alt);
    color: var(--grey-9);
    transition: background-color 300ms ease;
    cursor: pointer;

    &:hover {
        background-color: var(--aqua);
    }

    ${(props) => props.$active && `
        background-color: var(--aqua);
        cursor: default;
    `}
`;

const Container = styled.div`
    position: absolute;
    top: -12px;
    display: flex;
    align-items: center;
    column-gap: 2px;
    margin: 24px 100px;
    user-select: none;
    z-index: 1000;

    svg path {
        fill: var(--grey-5);
    }

    @media (max-width:900px) and (min-width:0px) {
        position: fixed;
        left: -36px;
    }
`;

export function Breadcrumbs({
    items,
    onClick
}: Props) {
    const navigate = useNavigate();

    const smallDevice = useMediaQuery('(max-width:900px) and (min-width:0px)');

    const handleNavigate = (item: Breadcrumb) => {
        if (item.destination) {
            onClick && onClick(item.destination);
        } if (item.route) {
            navigate(item.route);
        }
    }

    const renderBreadcrumb = (
        item: Breadcrumb,
        index: number,
    ) => {
        const last = (index === items.length - 1 || smallDevice);

        return (
            <Fragment key={item.id}>
                <Item
                    $active={last && !smallDevice}
                    onClick={() => { handleNavigate(item) }}
                >
                    {item.name}
                </Item>

                {!last && (
                    <AngleRightIcon />
                )}
            </Fragment>
        );
    }

    return (
        <Container data-testid={'breadcrumbs-component'}>
            {smallDevice ? (
                renderBreadcrumb(items[0], 0)
            ) : (
                items.map(renderBreadcrumb)
            )}
        </Container>
    );
}
