import styled from 'styled-components';

import { CheckIcon } from 'app/icons';
import { FormLabel } from '../FormLabel';

interface Props {
    name: string;
    label: string;
    checked?: boolean;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    onChange(name: string, value: boolean): void;
}

const Switch = styled.div<{
    $checked?: boolean;
}>`
    order: -1;
    position: relative;
    width: 42px;
    height: 24px;
    border-radius: 8px;
    background-color: var(--grey-5);
    cursor: pointer;

    svg,
    span {
        position: absolute;
    }

    svg {
        top: 6px;
        left: 5px;
        width: 12px;
        height: 12px;

        path {
            fill: var(--blue);
        }
    }

    span {
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        border-radius: 6px;
        background-color:var(--background-alt);
        transform: translateX(0);
        transition: all 250ms ease;
    }

    ${(props) => props.$checked && `
        background-color: var(--aqua);
        
        span {
            transform: translateX(18px);
        }
    `}
`;

const Container = styled.div<{
    $disabled?: boolean;
}>`
    display: flex;
    column-gap: 8px;

    label {
        font-size: 14px;
        font-weight: 500;
    }

    ${(props) => props.$disabled && `
        pointer-events: none;

        ${Switch} {
            background-color: var(--grey-6);

            svg path {
                fill: var(--grey-3);
            }

            span {
                background-color: var(--grey-3);
            }
        }
    `}
`;

export function FormSwitch({
    name,
    label,
    checked,
    required,
    disabled,
    className,
    onChange,
}: Props) {
    const id = `switch-input-${name}`;

    const handleClick = () => {
        if (!disabled) {
            onChange(name, !checked);
        }
    }

    return (
        <Container
            data-testid={'form-switch-component'}
            id={id}
            $disabled={disabled}
            className={className}
        >
            {label && (
                <FormLabel required={required}>
                    {label}
                </FormLabel>
            )}

            <Switch
                $checked={checked}
                onClick={handleClick}
            >
                <CheckIcon />

                <span />
            </Switch>
        </Container>
    );
}