import styled from 'styled-components';

interface Props {
    title: string;
    desc: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    text-align: center;

    span {
        &:first-child {
            font-size: 40px;
            font-weight: 300;
            line-height: 48px;
            letter-spacing: -1.25px;
            color: var(--grey-9);
        }

        &:last-child {
            font-size: 20px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: -0.5px;
            color: var(--grey-6);
        }
    }
`;

export function ForgotPasswordHeader({
    title,
    desc
}: Props) {
    return (
        <Container>
            <span>{title}</span>

            <span>{desc}</span>
        </Container>
    );
}