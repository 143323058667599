import { combineReducers } from 'redux';

import { Constants } from '../constants';
import {PayloadAction} from "@reduxjs/toolkit";

export const initialState: GiftReducer = {
    list: [],
    flow: { step: 'start' },
    item: null,
    shopifyItems: [],
    paginations: {},
    status: 'idle',
    loading: false,
    loadingShopifyItems: false,
    shopifyIsDisconnected: false,
    loadingViewMore: false
}

const list = (
    state = initialState.list,
    action: PayloadAction<Gift[]>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.List:
            return payload;
        case Constants.Gift.Clear:
            return initialState.list;
        default: return state;
    }
}

const flow = (
    state = initialState.flow,
    action: PayloadAction<GiftFlow>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.Flow:
            return payload;
        case Constants.Gift.Clear:
            return initialState.flow;
        default: return state;
    }
}

const item = (
    state = initialState.item,
    action: PayloadAction<GiftItem>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.Item:
            return payload;
        case Constants.Gift.Clear:
            return initialState.item;
        default: return state;
    }
}

const shopifyItems = (
    state = initialState.shopifyItems,
    action: PayloadAction<ShopifyItem[]>
): ShopifyItem[] => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.ShopifyItems:
            return payload;
        case Constants.Gift.Clear:
            return initialState.shopifyItems;
        default: return state;
    }
}

const paginations = (
    state = initialState.paginations,
    action: PayloadAction<Generic<AppPagination>>
): Generic<AppPagination>  => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.Paginations:
            return payload;
        case Constants.Gift.Clear:
            return initialState.paginations;
        default: return state;
    }
}

const status = (
    state = initialState.status,
    action: PayloadAction<string>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.Status:
            return payload;
        case Constants.Gift.Clear:
            return initialState.status;
        default: return state;
    }
}

const loading = (
    state = initialState.loading,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.Loading:
            return payload;
        case Constants.Gift.Clear:
            return initialState.loading;
        default: return state;
    }
}

const loadingShopifyItems = (
    state = initialState.loadingShopifyItems,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.LoadingShopifyItems:
            return payload;
        case Constants.Gift.Clear:
            return initialState.loadingShopifyItems;
        default: return state;
    }
}

const shopifyIsDisconnected = (
    state = initialState.shopifyIsDisconnected,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.ShopifyIsDisconnected:
            return payload;
        case Constants.Gift.Clear:
            return initialState.shopifyIsDisconnected;
        default: return state;
    }

}

const loadingViewMore = (
    state = initialState.loadingViewMore,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Gift.LoadingViewMore:
            return payload;
        case Constants.Gift.Clear:
            return initialState.loadingViewMore;
        default: return state;
    }
}

export default combineReducers<GiftReducer>({
    list,
    flow,
    item,
    shopifyItems,
    paginations,
    status,
    loading,
    loadingShopifyItems,
    shopifyIsDisconnected,
    loadingViewMore
});
