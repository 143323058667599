import {
    FormEvent,
    KeyboardEvent,
    PropsWithChildren,
    useMemo,
    useRef,
    useState
} from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { Button, FormInput, FormLabel } from 'app/components';
import { API } from 'api';

interface Props {
    data?: CodeVerificationForm;
    hasError?: boolean;
    loading?: boolean;
    onSubmit(data: CodeVerificationForm): void;
    digits?: number;
    email?: string;
    repId?: string;
}

const StyledFormLabel = styled(FormLabel)`
    font-size: 11px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 2px;
`;

const StyledFormInput = styled(FormInput)`
    input {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.75px;
        text-align: center;
    }
`;

const FormInline = styled.div`
    display: flex;
    column-gap: 16px;
`;

const Error = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--error-active);
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`;

export function CodeVerificationForm({
    data,
    hasError,
    loading,
    children,
    digits = 4,
    onSubmit,
    email,
    repId
}: PropsWithChildren<Props>) {

    const formRef = useRef<HTMLFormElement>(null);

    const [form, setForm] = useState<CodeVerificationForm>(data || {
        code: []
    });

    const disabledSubmit = useMemo(() => {
        return Helpers.isEmpty(form);
    }, [form]);

    const [hasErrorChild, setHasErrorChild] = useState(false);

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setHasErrorChild(false)

        if (email && repId) {
            console.log('form.code',form.code)
            API.Profile.testVerificationCode(form.code.join(''), email, repId).then(() => {
                onSubmit(form);
            }).catch(() => {
                setHasErrorChild(true)
            })
        } else {
            onSubmit(form);
        }

    }

    const handleFormKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Backspace') {
            const id = document.activeElement?.id;
            const index = id?.split('-').pop();

            if (!Helpers.isNullOrUndefined(index)) {
                const nextIndex = Number(index);

                handleInputChange(nextIndex, '', true);

                event.preventDefault();
            }
        }
    }

    const handleInputChange = (
        index: number,
        value: string | number,
        goBack = false
    ) => {

        if (hasErrorChild) {
            setHasErrorChild(false)
        }

        value = `${value}`
        if (value.length > 1) {
            const values = value.split('');
            setForm((prev) => {
                const newCode = [...prev.code || ''];
                values.forEach((val, i) => {
                    if (index + i < digits) {
                        //@ts-ignore
                        newCode[index + i] = val;
                    }
                });
                return { code: newCode };
            });

            (formRef.current?.elements[index + values.length - 1] as HTMLInputElement).focus();
        } else {
            setForm((prev) => ({
                code: Object.assign(
                    [],
                    prev.code,
                    { [index]: value.toString().slice(-1) }
                )
            }));

            const focusIndex = goBack
                ? Math.max(0, index - 1)
                : Math.min(digits - 1, index + 1);

            (formRef.current?.elements[focusIndex] as HTMLInputElement).focus();
        }
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pasteData = event.clipboardData.getData('text');

        const id = document.activeElement?.id;
        const index = id ? parseInt(id.split('-').pop() || '0', 10) : 0;

        handleInputChange(index, pasteData);
        event.preventDefault();
    };

    return (
        <Container>
            {children}

            <Form
                ref={formRef}
                onKeyDown={handleFormKeyDown}
                onSubmit={handleFormSubmit}
            >
                <FormGroup>
                    <StyledFormLabel>VERIFICATION CODE</StyledFormLabel>

                    <FormInline>
                        {Array(digits).fill('').map((_, i) => (
                            <StyledFormInput
                                key={i}
                                type={'number'}
                                name={`code-${i}`}
                                label={''}
                                value={form.code ? form.code[i] || '' : ''}
                                error={hasError || hasErrorChild ? '' : undefined}
                                required={true}
                                autoFocus={i === 0}
                                onPaste={handlePaste}
                                onChange={(_, value) =>
                                    handleInputChange(i, value)}
                            />
                        ))}
                    </FormInline>

                    {hasError || hasErrorChild && (
                        <Error>Wrong verification code</Error>
                    )}
                </FormGroup>

                <StyledButton
                    loading={loading}
                    disabled={disabledSubmit}
                >
                    Next
                </StyledButton>
            </Form>
        </Container>
    );
}