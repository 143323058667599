import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { Button, PictureFrame } from 'app/components';
import {
    ArrowUpRightIcon,
    ChatBubbleIcon,
    CheckIcon,
    StarFilledIcon,
    StarIcon
} from 'app/icons';

interface Props {
    name: string;
    image?: string;
    title?: string;
    review?: string;
    rating: number;
    media: string[];
    status?: string;
    reviewed_at?: string;
    loading?: boolean;
    onOpen?(): void;
    onApprove?(): void;
    onLetsChat?(): void;
    is_anonymous?: boolean;
}

const Date = styled.span`
    font-size: 12px;
    align-self: flex-start;
    line-height: 16px;
    margin-top: 8px;
    margin-left: auto;
    color: var(--grey-6);
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
`;

const Name = styled.span`
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: var(--grey-9);
`;

const Rating = styled.div`
    svg {
        width: 20px;
        height: 20px;

        path {
            fill: var(--yellow);
        }
    }
`;

const Header = styled.div`
    display: flex;
    column-gap: 16px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;

    span {
        &:first-child {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: var(--grey-9);
        }

        &:last-child {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            color: var(--grey-6);
        }
    }
`;

const StyledCheckIcon = styled(CheckIcon)`
    width: 20px;
    height: 20px;

    path {
        fill: var(--grey-9);
    }
`;

const StyledArrowUpRightIcon = styled(ArrowUpRightIcon)`
    width: 20px;
    height: 20px;

    path {
        fill: var(--grey-9);
    }
`;
const StyledChatBubbleIcon = styled(ChatBubbleIcon)`
    width: 20px;
    height: 20px;

    path {
        fill: var(--grey-9);
    }
`;

const Actions = styled.div`
    display: flex;
    column-gap: 12px;
    margin-top: 4px;

    button {
        width: 100%;
    }
`;

const Media = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
`;

const Container = styled(motion.div) <{
    $loading?: boolean;
}>`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 348px;
    padding: 12px;
    border-radius: 14px;
    background-color: var(--grey-2);

    ${(props) => props.$loading && `
        pointer-events: none;

        ${Name},
        ${Rating},
        ${Date},
        ${Body} span,
        ${Media} > div,
        ${Actions} button {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '###########';
                visibility: hidden;
            }
        }

        ${Rating} {
            width: 100px;
            height: 25px;
            margin-top: 1px;
        }

        ${Date}:before {
            content: '#########';
        }

        ${Media} > div {
            border-radius: 16px;
        }

        ${Actions} button {
            border: none;
            border-radius: 16px;
            color: transparent;
            transition: none;

            svg path {
                fill: transparent;
            }

            &:before {
                content: '##';
            }
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }
`;

export function GiftReviewListItem({
    name,
    image,
    title,
    review,
    rating,
    media,
    reviewed_at,
    status,
    loading,
    onApprove,
    onLetsChat,
    onOpen,
    is_anonymous
}: Props) {
    const animation = {
        transition: {
            type: 'spring',
            duration: 0.8
        },
        variants: {
            loading: { opacity: 1, scale: 1, y: 0 },
            hidden: { opacity: 0, scale: 0.9, y: 60 },
            show: { opacity: 1, scale: 1, y: 0 }
        }
    };

    const renderActions = () => {
        switch (status) {
            case 'approved':
                return (
                    <Button
                        theme={'outline'}
                        size={'small'}
                        onClick={onOpen}
                    >
                        <StyledArrowUpRightIcon />

                        <span>Open Review</span>
                    </Button>
                );

            case 'pending':
                return (
                    <>
                        <Button
                            theme={'aqua'}
                            size={'small'}
                            onClick={onApprove}
                        >
                            <StyledCheckIcon />

                            <span>Approve</span>
                        </Button>

                        <Button
                            theme={'outline'}
                            size={'small'}
                            onClick={onLetsChat}
                        >
                            <StyledChatBubbleIcon />

                            <span>Let's Chat</span>
                        </Button>
                    </>
                );
        }
    }

    console.log('is_anonymous', is_anonymous)

    return (
        <Container
            {...animation}
            $loading={loading}
        >
            <Header>
                <PictureFrame
                    image={image}
                    width={64}
                    height={64}
                    loading={loading}
                />

                <Desc>
                    <Name>{is_anonymous ? 'Anonymous' : name}</Name>

                    <Rating>
                        {!loading && Array(5).fill('').map((_, i) => (
                            i <= rating ? (
                                <StarFilledIcon key={i} />
                            ) : (
                                <StarIcon key={i} />
                            )
                        ))}
                    </Rating>
                </Desc>

                <Date>
                    {!loading &&
                        Helpers.formatDate(reviewed_at, 'dd MMMM, Y')}
                </Date>
            </Header>

            <Body>
                <span>{title}</span>

                <span>{review}</span>
            </Body>

            <Media>
                {media.map((media) => (
                    <PictureFrame
                        image={media}
                        width={56}
                        height={56}
                        isGift={true}
                        loading={loading}
                    />
                ))}
            </Media>

            <Actions>
                {renderActions()}
            </Actions>
        </Container>
    );
}