import styled from 'styled-components';

import { API } from 'api';
import { useState, useEffect } from 'react';

interface Props {
    url: string,
    incoming: boolean;
    ctaMode?: boolean;
    linkText?: string;
}

const LinkPreview = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    height: 104px;
    padding: 12px;
    margin-top: 10px;
    background-color: var(--text-alt);
    cursor: pointer;
    transition: background 250ms ease;

    &:hover {
        background-color: var(--grey-4) !important;
    }

    .Image {
        width: 60px;
        height: 100%;
        border-radius: 8px;
        background-color: var(--grey-2);

        &.no-img {
            display: none;
        }
    }

    .LowerContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 2px;
        padding: 0;

        .Title {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            padding: 0;
            margin: 0;
            height: 180px;
            overflow: hidden;        
        }

        .Description.Secondary {
            font-size: 14px;
            line-height: 24px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .Secondary.SiteDetails {
            display: none;
        }

    }

    &.sender {

        .LowerContainer {
            
            .Title, .Description {
                color: var(--grey-8);
            }    
        }
    }

    &.cta-mode {
        height: auto;
        background: var(--blue);
        color: white;
        text-align: center;
        justify-content: center;
        border-radius: 16px;
    }

    &.no-img {
        height: 40px !important;
    }

`

export function MessageLink({
    url,
    incoming,
    ctaMode,
    linkText
}: Props) {

    const [linkData, setLinkData] = useState<any>({})
    const [noImage, setNoImage] = useState(false);

    useEffect(() => {
        const getPreview = () => {
            API.Shared.getLinkPreview(url).then((res: any) => {
              setLinkData(res);
              if (noImage) {
                setNoImage(false);
              }
              console.log('RES for img preview', res)
            }).catch((e) => {
                console.log('could not find image')
                setNoImage(true);
            });  
        }
        
        getPreview();

    }, [])

    return (
        <LinkPreview className={`${incoming ? '' : 'sender'} ${ctaMode ? 'cta-mode' : ''} ${noImage ? 'no-img' : ''}`} onClick={() => {window.open(url, '_blank')}}>
            {linkData?.title ? (
                <>
                    <img className={`Image ${noImage ? 'no-img' : null}`} src={linkData.image} />
                    <div className="LowerContainer">
                        <div className="Title">{linkData.title}</div>
                        <div className="Description Secondary">{linkData.description}</div>
                    </div>
                </>
            ) : (
                <div className={`LowerContainer`}>
                    <div className="Title">{linkText ? linkText : `Visit ${url}`}</div>
                </div>
            )}
        </LinkPreview>
    );
}
