import {
    FormEvent,
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import styled from 'styled-components';
import { ImageHelper } from 'utils';

import { Helpers } from 'utils';
import {
    Button,
    FormInput,
    FormLabel,
    FormMaskInput,
    FormMedia,
    FormTextarea,
    Loader,
    MobilePreview,
    ProfileFrame
} from 'app/components';
import { CloseIcon, CropIcon } from 'app/icons';
import {Crop} from "react-image-crop";
import settingsService, { ClientSettings } from "../../../services/settingsService";
import { GiftImageUploadModal } from 'app/components/GiftImageUploadModal';
import {useAppSelector} from "../../../store";
import {th} from "date-fns/locale";

interface Props {
    data?: CreativeForm;
    media?: Media[];
    status?: string;
    type?: GiftType,
    disabledActions?: boolean;
    disabledInputs?: boolean;
    loading?: boolean;
    dragAction?: any;
    cropAction?: (index: number, crop: Crop | undefined) => void;
    primaryAction: GiftFormAction;
    secondaryAction: GiftFormAction;
    onUploadMedia(file: Blob, thumbnail: Blob, crop?: Crop): void;
    onDeleteMedia(id: string): void;
    propagateFormToGiftView?: any;
    noBottomButtons?: boolean;
    forceUpdate?: number;
    setForceUpdate?: (value: number) => void;
}

const StyledButton = styled(Button)`
    width: 186px;
`;

const MediaLimit = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-left: auto;
    color: var(--grey-6);
`;

const ProfileFrameAction = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: -4px;
    background: #FF647C;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #e91e63;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
        
        path {
            fill: white;
        }
    }
`;

const ProfileFrameCrop = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: -4px;
    background: var(--blue);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px #08083a;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
        
        path {
            fill: white;
        }
    }
`

const StyldeProfileFrame = styled(ProfileFrame)`
    position: relative;
    cursor: pointer;
    transition: all 200ms ease;
    margin-right: 8px;
    margin-bottom: 8px;

    :hover {
        transform: translateY(-2px);
    }

    :not(:last-child) {
        margin-right: 5px;
        margin-bottom: 5px;
    }

    > svg path {
        stroke: var(--blue);
        stroke-width: 2px;
    }

    .frame-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 84px;
        height: 84px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(1.4);
    }

    &.loading-styled-frame {
        svg {
            filter: brightness(0.7);
        }
    }
`;

const StyledFormMedia = styled(FormMedia)`
    transition: transform 200ms ease;
    cursor: pointer;

    :hover {
        transform: translateY(-2px);
    }


    &:active {
        transform: scale(0.95);
    }
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    margin-top: auto;

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column;
        width: 100%;

        button {
            width: 100%;
            margin-top: 12px;
        }
    }
`;

const FormInline = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;

    div[role=presentation] {
        flex-wrap: wrap;
    }
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;

    p {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        margin: 0;
        color: var(--grey-6);

        span {
            text-decoration: underline;
            text-underline-offset: 1px;
            text-underline-position: under;
            color: var(--info-active);
            cursor: pointer;
        }
    }
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;
    padding: 24px 52px 40px;
    overflow-y: scroll;
  @media (max-width:900px) and (min-width:0px) {
    padding: 24px 24px 40px;
  }
`;

const RightContent = styled.div`
    grid-area: right-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--grey-4);

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const RelativElem = styled.div`
    position: relative;
`

const UpdateGiftDescription = styled.div`
    position: absolute;
    bottom: -27px;
    right: 0;
    background: #75D6C2;
    color: var(--blue);
    font-weight: 600;
    padding: 12px 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
        background: rgba(117,214,194,0.25);
        color: var(--success-active);
    }
`

const LinkDescription = styled.div`
    font-size: 14px;
    margin-top: 4px;

  align-self: left;
  line-height: 24px;
  color: var(--grey-9);
    a {
      text-decoration: underline;
      text-underline-position: under;
      text-underline-offset: 1px;
      color: var(--grey-6);
      margin-left: 3px;
    }
`

interface CreativeErrors {
    name?: string;
    description?: string;
    value?: string;
    media?: string;
}

const initialData: CreativeForm = {
    name: '',
    description: '',
    value: null,
};

export function CreativeForm({
    data,
    media,
    type,
    status,
    disabledActions,
    disabledInputs,
    loading,
    children,
    primaryAction,
    dragAction,
    cropAction,
    secondaryAction,
    onUploadMedia,
    onDeleteMedia,
    propagateFormToGiftView,
    noBottomButtons,
}: PropsWithChildren<Props>) {

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const rightContent = useRef<HTMLDivElement>(null);

    const [form, setForm] = useState<CreativeForm>(data || {
        ...initialData,
    });

    useEffect(() => {
        if (propagateFormToGiftView) {
            propagateFormToGiftView(form)
        }
    }, [form])

    const [initialDescription,] = useState(form.description)
    const [triedToSubmit, setTriedToSubmit] = useState(form.description || false);

    const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0);

    const [formErrors, setFormErrors] = useState<CreativeErrors>({});
    const [currentLocalImg, setCurrentLocalImg] = useState<string>('');

    const checkErrors = (mediaCount: number, form: CreativeForm, settings?: ClientSettings): CreativeErrors => {
        const errors: CreativeErrors = {};

        if (mediaCount <= 0) {
          errors.media = 'Please upload at least one image';
        }

        if (Helpers.isEmpty(form.name)) {
          errors.name = 'Please enter a name';
        }

        if (Helpers.isEmpty(form.description)) {
          errors.description = 'Please enter a description';
        }

        if (!Helpers.isEmpty(form.value)) {
          if (!settings && !form) {
            errors.value = `Please enter a value`;
          } else {
            if (!form.value) {
                errors.value = `Please enter a value`;
            }
            if (form.value && settings?.gifts.min_gift_value) {
                if (form.value < settings?.gifts.min_gift_value) {
                    errors.value = `Minimum value is $${settings?.gifts.min_gift_value}`;
                }
            }
          }
        } else {
          errors.value = 'Please enter a value';
        }

        return errors;
    }

    const disabledSubmit = useMemo(() => {
        const mediaCount = media?.length || 0;

        let isValid;
        let errors: CreativeErrors;

        if (!settings) {
            return true;
        }

        switch (type) {
            case 'EVENT':
            errors = checkErrors(mediaCount, form);
            isValid = mediaCount > 0 && !Helpers.isEmpty(form.name) && !Helpers.isEmpty(form.description);
            break;
            default:
            errors = checkErrors(mediaCount, form, settings);
            isValid = mediaCount > 0 && !Helpers.isEmpty(form.name) && !Helpers.isEmpty(form.description) && !Helpers.isEmpty(form.value);
            break;
        }

        setFormErrors(errors);

        if (isValid || !triedToSubmit) {
            setFormErrors({});
        }

        return !isValid || loading;
    }, [media?.length, type, loading, form.name, form.description, form.value, triedToSubmit]);

    const disabledUpload = useMemo(() => {
        if (!settings) {
            return;
        }
        return disabledInputs || (
            media &&
            media.length >= settings.gifts.max_gift_media
        );
    }, [disabledInputs, settings, media]);

    useEffect(() => {
        const rightContentEl = rightContent.current;
        const parentEl = rightContentEl?.parentElement;

        if (rightContentEl && parentEl && rightContentEl) {
            const rightContentRect = rightContentEl.getBoundingClientRect();
            const parentRect = parentEl.getBoundingClientRect();

            if (rightContentRect.height > parentRect.height) {
                parentEl.style.height = 'auto';
            }
        }

        return () => {
            if (parentEl) {
                parentEl.style.removeProperty('height');
            }
        }
    }, [media?.length]);

    const handleDeleteMedia = (
        event: MouseEvent<HTMLOrSVGElement>,
        id: string
    ) => {
        event.stopPropagation();
        if (media?.length === 1) {
            setFormErrors((prev) => {
                return {
                    ...prev,
                    media: 'You must have at least one image'
                }
            })
            return
        }
        onDeleteMedia(id)
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        primaryAction.action(form);
    }

    const handlePrimaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (form.description.length > 500) {
            form.description = form.description.substring(0, 500);
        }

        if (primaryAction.type === 'reset') {
            setForm(data || initialData);
        }
        primaryAction.action(form);
    }

    const handleSecondaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (secondaryAction.type === 'reset') {
            setForm(data || initialData);
        }

        secondaryAction.action(form);
    }

    const handleChange = (
        name: string,
        value: string | number
    ) => {
        setForm((prev) => ({
            ...prev,
            [name]: name === 'value' ?
                Number(value) : value
        }));
    }

    const onDragEnd = (result: any) => {

        // dropped outside the list
        if (!result.destination) {
            return;
          }

        dragAction(result.source.index, result.destination.index)
    }

    const sendDescriptionUpdate = () => {
        primaryAction.action(form)
    }

    const [showGiftImageModal, setShowGiftImageModal] = useState(false)
    const [giftModalImage, setGiftModalImage] = useState('')
    const [giftModalCrop, setGiftModalCrop] = useState<Crop>()

    const [giftModalFile, setGiftModalFile] = useState<File>()

    const getMeta = async (url: string) => {
        const img = new Image();
        img.src = url;
        await img.decode();
        return img
    };

    const setImageCropEdit = (event: any, media: Media) => {
        setGiftModalImage(media.original_path || media.gallery_path) // gift flow page does not have a original path
        const myFile = new File([media.original_path || media.gallery_path], media.name || media.uid, {
            type: media.mime,
        });

        if (media.metadata) {
            setGiftModalCrop(media.metadata.crop)
            setGiftModalFile(myFile)
            setShowGiftImageModal(true)
        } else {
            getMeta(media.original_path || media.thumbnail_path).then((img) => {
                const crop = ImageHelper.getCenterCropped(
                    img.naturalWidth,
                    img.naturalHeight,
                    settingsService.imageUploadAspectRatio
                ) as Crop;

                media.metadata = {
                    crop: crop
                }
                setGiftModalCrop(crop)
                setGiftModalFile(myFile)
                setShowGiftImageModal(true)
            }).catch((error: any) => {
                throw error;
            })
        }
    }

    const updateMediaCrop = (file: Blob | undefined, thumbnail: Blob, crop: Crop | undefined) => {
        if (!file) {
            console.log('no file?', file, thumbnail, crop)
            return
        }
        console.log('test.', media, file)
        let cropped = false
        if (media) {
            media.forEach((img, index) => {
                if (((img.name === file.name) || (img.uid === file.name)) || selectedMediaIndex === index) {

                    if (!img.metadata) {
                        img.metadata = {}
                    }

                    img.metadata.crop = crop
                    setShowGiftImageModal(false)
                    cropAction && cropAction(index, crop)
                    cropped = true
                }
            })
        }

        if (!cropped) {
            setShowGiftImageModal(false)
        }
    }

    const onNext = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setTriedToSubmit(true);
        if (disabledSubmit) {
            return
        }

        handlePrimaryActionClick(e)
    }

    const handleFormMedia = (file: Blob, thumbnail?: Blob, crop?: Crop) => {
        setCurrentLocalImg(URL.createObjectURL(file))
        if (!thumbnail) {
            console.log('thumby thumb thumb')
            throw new Error('no thumbnail blob provided');
        }
        onUploadMedia(file, thumbnail, crop)
    }

    const generateGiftGuidanceText = () => {
        if (!form.value) {return 'Your gift must be valued $150 RRP or more to be a fair exchange.'}
        if (form.value < 150) {
            return "Min gift value $150, this is to make sure it's a fair exchange with creators. You can gift them a bundle. Remember, creators usually get paid + product :)";
        } else if (form.value <= 200) {
            return `For this gift value of $${form.value}, industry best practice would be: 1 x in feed post for someone 10k or under on IG, 30k or under on Tiktok. For creators with over this following, you should expect stories.`;
        } else if (form.value <= 300) {
            return `For this gift value of $${form.value}, industry best practice would be: 1 x in feed post for someone 20k or under on IG, 50k or under on Tiktok. For creators with over this following, you should expect stories.`;
        } else if (form.value <= 500) {
            return `For this gift value of $${form.value}, industry best practice would be: 1 x in feed post for someone 25k or under on IG, 60k or under on Tiktok. For creators with over this following, you should expect stories.`;
        } else {
            return `For this gift value of $${form.value}, industry best practice would be: 1 x in feed post for someone 40k or under on IG, 80k or under on Tiktok. For creators with over this following, you should expect stories.`;
        }
    }

    return (
        <>
            <LeftContent>
                {children}

                <Form onSubmit={handleSubmit}>
                    <FormInput
                        type={'text'}
                        name={'name'}
                        label={'Gift name'}
                        placeholder={'Type your gift name'}
                        value={form.name}
                        required={true}
                        disabled={disabledInputs}
                        error={formErrors.name}
                        onChange={handleChange}
                    />

                    <RelativElem>
                        <FormTextarea
                            name={'description'}
                            label={'Describe exactly what the creator will receive'}
                            placeholder={'Describe exactly what the creator will receive. Do not include specific post requirements.'}
                            value={form.description}
                            required={true}
                            error={formErrors.description}
                            disabled={disabledInputs}
                            onChange={handleChange}
                            limit={500}
                        />

                        {(disabledInputs && form.description !== initialDescription) && (
                            <UpdateGiftDescription onClick={() => sendDescriptionUpdate()}>Update Gift Description</UpdateGiftDescription>
                        )}
                    </RelativElem>


                    {!!settings && type !== 'EVENT' && (
                        <FormMaskInput
                            name={'value'}
                            label={`Retail value (Minimum is $${settings.gifts.min_gift_value})`}
                            placeholder={`$${settings.gifts.min_gift_value}`}
                            value={form.value || ''}
                            error={formErrors.value}
                            thousandSeparator={true}
                            required={true}
                            disabled={disabledInputs}
                            onChange={handleChange}
                        >
                            <LinkDescription>
                                {generateGiftGuidanceText()}
                                <a href="https://www.hashgifted.com/how-it-works/what-is-a-fair-gifting-exchange-as-a-brand" target="_blank" rel="noreferrer">
                                    My gift is under $150
                                </a>
                            </LinkDescription>

                        </FormMaskInput>
                    )}

                    <FormGroup>
                        {!!settings && (
                            <FormLabel required={true}>
                                Images

                                <MediaLimit>
                                    {media?.length || 0}/{settings.gifts.max_gift_media} media
                                </MediaLimit>
                            </FormLabel>
                        )}

                        <FormInline>

                            <DragDropContext onDragEnd={(result: any) => onDragEnd(result)}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                {(provided: any) => (
                                    <div
                                        style={{display: 'flex', flexWrap: 'wrap'}}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {media?.map((media, index) => (
                                            <Draggable key={media.thumbnail_path} draggableId={media.thumbnail_path} index={index}>
                                                {(provided: any) => (
                                                    <div
                                                        style={{position: 'relative', marginRight: '8px', marginBottom: '8px'}}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={media.thumbnail_path}
                                                    >
                                                        <StyldeProfileFrame
                                                            id={`creative-images-${index}`}
                                                            image={media.thumbnail_path}
                                                            width={84}
                                                            height={84}
                                                            isGift={true}
                                                            onClick={() => setSelectedMediaIndex(index)}
                                                        >

                                                            <ProfileFrameCrop>
                                                                <CropIcon onClick={(event) => setImageCropEdit(event, media )} />
                                                            </ProfileFrameCrop>

                                                            <ProfileFrameAction>
                                                                <CloseIcon onClick={(event) => handleDeleteMedia(event, media.uid)} />
                                                            </ProfileFrameAction>
                                                        </StyldeProfileFrame>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}

                                    {status === 'set-gift-creative-media' && currentLocalImg &&(
                                        <StyldeProfileFrame
                                            className='loading-styled-frame'
                                            id={'temp-loading-img'}
                                            image={currentLocalImg}
                                            width={84}
                                            height={84}
                                            isGift={true}
                                        >
                                            <div className='frame-loader'>
                                                <Loader status="idle" thick />
                                            </div>
                                        </StyldeProfileFrame>
                                    )}

                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>

                            {!disabledUpload && (
                                <StyledFormMedia
                                    image={''}
                                    name={'images'}
                                    isGift={true}
                                    allowVideo={true}
                                    disabled={disabledUpload || loading}
                                    onChange={(_, file, thumbnail, crop) => {
                                        handleFormMedia(file, thumbnail, crop)
                                    }}
                                />
                            )}
                        </FormInline>
                    </FormGroup>

                    {formErrors.media && (
                        <p style={{color: '#ff647c', fontWeight: 400, fontSize: 14}}>{formErrors.media}</p>
                    )}

                    {(!disabledActions && !disabledInputs) && (!noBottomButtons) && (
                        <FormActions>
                            <StyledButton
                                size={'medium'}
                                loading={loading &&
                                    status === 'set-gift-creative'}
                                onClick={(e) => onNext(e)}
                            >
                                {primaryAction.name}
                            </StyledButton>

                            <StyledButton
                                theme={'outline'}
                                size={'medium'}
                                loading={loading &&
                                    status === 'saving-draft'}
                                onClick={handleSecondaryActionClick}
                            >
                                {secondaryAction.name}
                            </StyledButton>
                        </FormActions>
                    )}
                </Form>
            </LeftContent>

            <RightContent ref={rightContent}>
                <MobilePreview
                    name={form.name}
                    price={form.value}
                    type={type}
                    media={media?.[selectedMediaIndex]?.thumbnail_path}
                    image={media?.[selectedMediaIndex]?.thumbnail_path}
                />
            </RightContent>

            {showGiftImageModal && giftModalCrop && giftModalFile && (
                <GiftImageUploadModal
                    show={showGiftImageModal}
                    image={giftModalImage}
                    initialCrop={giftModalCrop}
                    file={giftModalFile}
                    onClose={() => setShowGiftImageModal(false)}
                    primaryAction={{
                        name: 'Save',
                        action: (media, thumbnail, crop) => updateMediaCrop(media, thumbnail, crop)
                    }}
                    secondaryAction={{
                        name: 'Cancel',
                        action: () => setShowGiftImageModal(false )
                    }}
                />
            )}

        </>
    );
}
