import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { StarFilledIcon, StarIcon } from 'app/icons';
import { NoResult } from '../../NoResult';
import { PictureFrame } from '../../PictureFrame';

interface Props {
    items: Review[];
    show?: boolean;
    brandReviews?: boolean;
}

const StyledPictureFrame = styled(PictureFrame)`
    box-shadow: var(--shadow-1);
`;

const Date = styled.span`
    font-size: 12px;
    align-self: flex-start;
    line-height: 16px;
    margin-top: 8px;
    margin-left: auto;
    color: var(--grey-6);
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
`;

const Name = styled.span`
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: var(--grey-9);
`;

const Rating = styled.div`
    svg {
        width: 20px;
        height: 20px;

        path {
            fill: var(--yellow);
        }
    }
`;

const Header = styled.div`
    display: flex;
    column-gap: 16px;
`;

const Body = styled.div`
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--grey-8);
    margin-left: 2px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 24px;
    border-radius: 14px;
    background-color: var(--grey-2);

    &.brands {
        background-color: white;
    }
`;

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 56px 24px 24px;
    border-radius: 32px;
    background-color: var(--background-alt);
    overflow: hidden auto;
    overflow: hidden overlay;

    &.brands {
        position: static;
        background-color: transparent;
        max-width: 600px;
        padding: 0;
    }
`;

export function ReviewList({
    items,
    show,
    brandReviews
}: Props) {
    const animation = {
        initial: { opacity: 0, x: '100%' },
        animate: { opacity: 1, x: 0 },
        exit: { x: '100%' },
        transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.45
        }
    };

    return (
        <AnimatePresence>
            {show && (
                <Container className={brandReviews ? 'brands' : ''} {...animation}>
                    {items.map((item, i) => (
                        item.influencer && (
                            <Item className={brandReviews ? 'brands' : ''} key={i}>
                                <Header>
                                    {brandReviews && (item.influencer && item.influencer.headshot_image) && (
                                        <StyledPictureFrame
                                            image={brandReviews ? item?.influencer?.headshot_image : item?.brand.image}
                                            width={64}
                                            height={64}
                                        />
                                    )}

                                    <Desc>
                                        <Name>{brandReviews ? item.influencer.name : item?.brand.name}</Name>

                                        <Rating>
                                            {Array(5).fill('').map((_, i) => (
                                                i <= item?.rating ? (
                                                    <StarFilledIcon key={i} />
                                                ) : (
                                                    <StarIcon key={i} />
                                                )
                                            ))}
                                        </Rating>
                                    </Desc>

                                    <Date>
                                        {Helpers.formatDate(item?.reviewed_at, 'dd MMMM, Y')}
                                    </Date>
                                </Header>

                                <Body>
                                    {item?.review}
                                </Body>
                            </Item>
                        )
                    ))}

                    {(show && items.length === 0) && (
                        <NoResult
                            image={'search'}
                            title={`Sorry, we didn't find any\ninfluencer reviews.`}
                        />
                    )}
                </Container>
            )}
        </AnimatePresence>
    );
}
