import {
    CSSProperties,
    MouseEvent,
    useEffect,
    useRef,
    useState
} from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers, Hooks } from 'utils';
import { Badge } from '../Badge';
import { FormLabel } from '../FormLabel';
import { Option } from '../FormSelect';
import ReactTooltip from "react-tooltip";

interface Props {
    name: string;
    label: string;
    placeholder?: string;
    options: Option[];
    selected?: Option[];
    limit?: number;
    required?: boolean;
    disabled?: boolean;
    onChange(name: string, options: Option[]): void;
    error?: string|string[];
    tooltip?: string;
}

const Limit = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-left: auto;
    color: var(--grey-6);
`;

const SelectedBadge = styled(Badge)`
    font-size: 11px;
    padding: 10px;
    border-radius: 100px;
    white-space: normal;
`;

const Placeholder = styled.span`
    color: var(--grey-6);
`;

const SelectedItem = styled.div`
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    line-height: normal;
    padding-right: 20px;
    color: var(--grey-6);

    i {
        margin-top: 2px;
        margin-left: auto;
    }

    &:after {
        content: '▾';
        position: absolute;
        top: 50%;
        right: 18px;
        color: var(--grey-9);
        transform: rotate(0deg) translateY(-50%);
        transform-origin: top center;
        transition: transform 300ms ease;
    }
`;

const Options = styled(motion.div)`
    display: flex;
    position: fixed;
    min-width: 380px;
    max-height: 264px;
    border: 1px solid var(--grey-2);
    border-radius: 12px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    overflow: hidden;

    > div {
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 8px;
        padding: 16px 30px;
        overflow: hidden auto;
        overflow: hidden overlay;

        &:empty {
            padding: 20px;
    
            &:before {
                content: 'No available options';
            }
        }
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    min-height: 56px;
    padding: 8px 12px;
    border: 1.5px solid var(--grey-2);
    border-radius: 14px;
    background-color: var(--grey-1);
    color: var(--grey-9);
    transition: border-color 300ms ease;
`;

const Container = styled.div<{
    $focus?: boolean;
    $disabled?: boolean;
    $tooltip?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    user-select: none;

    ${(props) => props.$focus && `
        ${Content} {
            border-color: var(--blue);
        }

        ${SelectedItem}:after {
            transform: rotate(180deg) translateY(-50%);
        }
    `}

    ${(props) => props.$disabled && `
        
        cursor: not-allowed;

        ${Content} {
            background-color: var(--grey-3);
        }

        ${SelectedItem}:after {
            color: var(--grey-6);
        }
    `}
`;

const Error = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: var(--error-active);
`;

export function FormMultiSelect({
    name,
    options,
    selected,
    label,
    placeholder,
    limit,
    required,
    disabled,
    onChange,
    error,
    tooltip
}: Props) {
    const id = `multiselect-input-${name}`;

    const optionsAnimation = {
        initial: { opacity: 0, y: -10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 }
    };

    const containerRef = useRef<HTMLDivElement>(null);

    const contentRef = useRef<HTMLDivElement>(null);

    const optionsRef = useRef<HTMLDivElement>(null);

    const [focus, setFocus] = useState<boolean>(false);

    const [style, setStyle] = useState<CSSProperties>({});

    const [wheelListener, setWheelListener] = useState<number>(1);

    useEffect(() => {
        const defaultStyle = {
            top: '100vh',
            left: '100vw',
            width: 100,
            zIndex: 1050
        };

        setStyle(focus ? defaultStyle : {});
    }, [focus]);

    useEffect(() => {
        if (style.zIndex) {
            const contentEl = contentRef.current;
            const optionsEl = optionsRef.current;

            if (contentEl && optionsEl) {
                const contentRect = contentEl.getBoundingClientRect();
                const optionsRect = optionsEl.getBoundingClientRect();
                const commonStyle = {
                    left: contentRect.left,
                    width: contentRect.width
                };
                const totalHeight = (contentRect.top + contentRect.height + optionsRect.height);

                if (totalHeight > window.innerHeight) {
                    setStyle((prev) => ({
                        ...prev,
                        ...commonStyle,
                        top: (contentRect.top - 2) - optionsRect.height,
                    }));
                } else {
                    setStyle((prev) => ({
                        ...prev,
                        ...commonStyle,
                        top: (contentRect.top + 2) + contentRect.height
                    }));
                }
            }
        }
    }, [style.zIndex, wheelListener]);

    Hooks.useEventListener('wheel', () => {
        setWheelListener((prev) => prev + 1);
    });

    Hooks.useOnClickOutside(optionsRef, (event) => {
        if (!containerRef.current?.contains(event.target as HTMLElement)) {
            setFocus(false);
        }
    });

    const isSelected = (option: Option) => {
        if (
            Array.isArray(selected) &&
            selected &&
            selected.find((s) => s.id === option.id)
        ) {
            return true;
        }

        return false;
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (focus && event.key === 'Escape') {
            setFocus(false);
        }
    }

    const handleClick = (focus: boolean) => !disabled && setFocus(!focus);

    const handleChange = (
        event: MouseEvent<HTMLSpanElement>,
        option: Option
    ) => {
        event.stopPropagation();

        if (selected) {
            const exists = selected.find((o) => o.id === option.id);

            const nextOptions = exists ?
                selected.filter((o) => o.id !== option.id) :
                [...selected, option];

            if (limit && limit < nextOptions.length) return;

            onChange(name, nextOptions);
        } else {
            onChange(name, [option]);
        }
    }

    const renderSelected = () => {
        if (Array.isArray(selected) && selected.length > 0) {
            return selected.map((item) => (
                <SelectedBadge
                    key={item.id}
                    theme={'reverse'}
                >
                    {item.name}
                </SelectedBadge>
            ));
        }

        return <Placeholder>{placeholder || 'Please select'}</Placeholder>;
    }

    return (
        <Container
            data-testid={'form-multiselect-component'}
            data-tip={tooltip}
            ref={containerRef}
            id={id}
            tabIndex={0}
            $focus={focus}
            $disabled={disabled}
            $tooltip={!!tooltip}
            onKeyDown={handleKeyDown}
            onClick={() => handleClick(focus)}
        >
            {!!tooltip && (
                <ReactTooltip />
            )}

            {label && (
                <FormLabel
                    required={required}
                >
                    {label}

                    {limit && (
                        <Limit>
                            {selected && Helpers.padLeft(selected.length, '0')}
                            /
                            {Helpers.padLeft(limit, '0')}
                            &nbsp;selected
                        </Limit>
                    )}
                </FormLabel>
            )}

            <Content ref={contentRef}>
                <SelectedItem>
                    {renderSelected()}
                </SelectedItem>

                {focus && (
                    <Options
                        {...optionsAnimation}
                        ref={optionsRef}
                        tabIndex={0}
                        style={style}
                        onClick={(event) => event.stopPropagation()}
                    >
                        <div>
                            {options.map((o) => (
                                <SelectedBadge
                                    key={o.id}
                                    theme={isSelected(o) && 'reverse'}
                                    onClick={(event) => handleChange(event, o)}
                                >
                                    {o.name}
                                </SelectedBadge>
                            ))}
                        </div>
                    </Options>
                )}
            </Content>
            {error && <Error>{error}</Error>}
        </Container>
    );
}

export default FormMultiSelect;
