import styled from 'styled-components';

import { GiftIcon, UserIcon } from 'app/icons';

interface Props {
    image?: string | null;
    width?: number;
    height?: number;
    isGift?: boolean;
    loading?: boolean;
    className?: string;
}

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: auto;
    transform: translate(-50%, -50%);

    svg {
        width: 40px;
        height: auto;
        margin: auto;

        path {
            fill: var(--text-alt);
        }
    }
`;

const Container = styled.div<{
    $image?: string | null;
    $width?: number;
    $height?: number;
    $isGift?: boolean;
    $loading?: boolean;
}>`
    flex-shrink: 0;
    position: relative;
    width: ${(props) => `${props.$width || 52}px`};
    height: ${(props) => `${props.$height || 52}px`};
    padding: 12px;
    border-radius: 16px;
    background-color: var(--blue);

    ${(props) => props.$image && `
        background-image: url(${props.$image});
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        ${StyledIcon} {
            display: none;
        }
    `}

    ${(props) => props.$loading && `
        background: linear-gradient(
            to right,
            var(--grey-3),
            var(--grey-2),
            var(--grey-3) 80%
        ), var(--grey-3);
        background-position: 100% 0, 0 0;
        background-repeat: repeat-y;
        background-size: 200% 200%;
        animation: animate 1s infinite;

        &:before {
            content: '##';
            visibility: hidden;
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }
`;

export function PictureFrame({
    image,
    width,
    height,
    isGift,
    loading,
    className
}: Props) {
    return (
        <Container
            data-testid={'picture-frame-component'}
            $image={image}
            $width={width}
            $height={height}
            $isGift={isGift}
            $loading={loading}
            className={className}
        >
            {!loading && (
                <StyledIcon>
                    {isGift ? <GiftIcon /> : <UserIcon />}
                </StyledIcon>
            )}
        </Container>
    );
}