import {PropsWithChildren} from 'react';
import styled from 'styled-components';

interface Props {
    image: 'search' | 'sandwatch' | 'camera' | 'pig' | 'picture'
    title: string;
    message?: string;
}

const Image = styled.img`
    width: 140px;
    height: 140px;
`;

const H2 = styled.h2`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--grey-6);
    white-space: break-spaces;
`;

const Desc = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--grey-6);
    white-space: break-spaces;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    padding: 5%
`;

export function NoResult({
    image,
    title,
    message,
    children
}: PropsWithChildren<Props>) {
    return (
        <Container data-testid={'noresult-component'}>
            <Image
                src={require(`../../../images/${image}.png`)}
                alt={`not found`}
            />

            <H2>
                {title}
            </H2>

            {message && (
                <Desc>
                    {message}
                </Desc>
            )}

            {children}
        </Container>
    );
}
