import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from 'api';
import { useAppSelector } from 'store';
import { AppLogo, Loader } from 'app/components';
import { Timer } from '../../components/Timer';
import settingsService from "../../../services/settingsService";
import errorReporter from "../../../services/errorReporter";

export function ShopifyAuthPage() {
    const animation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { delay: 0.5 }
    };

    const location = useLocation();

    const { profile: { brand } } = useAppSelector((state) => state);
    const navigate = useNavigate();

    const [title, setTitle] = useState<string>('Connecting your shopify store');
    const [status, setStatus] = useState<string>('loading');

    const setError = (title: string) => {
        setTitle(title)
        setStatus('error');
    }

    const extractUTMParamsAsString = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams: string[] = [];
      
        urlParams.forEach((value, key) => {
            if (key.toLowerCase() !== 'redirect') {
                utmParams.push(`${key}=${value}`);
            }
        });
      
        return utmParams.join('&');
    }
            
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const shop = searchParams.get('shop');

        if (!shop) {
            setError('Missing shop parameter');
            errorReporter.report('Missing shop parameter', undefined, searchParams.entries());

            setTimeout(() => {
                navigate('/');
            },3000)
            return;
        }

        if (!brand) {
            setError('We could not load your brand settings, please login and try again');
            errorReporter.report(`We could not load your brand settings for store ${shop}`, undefined, searchParams.entries());
            setTimeout(() => {
                navigate('/login')
            },3000)
            return;
        }

        API.Shopify.getInstallToken()
            .then((value) => {
                const installUrl = settingsService.baseApiUrl + `partner/shopify/connect${window.location.search}&token=${value.token}`;
                window.open(installUrl, '_self');
            })
            .catch((error) => {
                setError('Connection failure');
                errorReporter.report('Could not fetch shopify install token', error, searchParams.entries());
            });
    }, [brand]);

    return (
        <Container data-testid={'shopify-callback-page'}>
            <StyledAppLogo />

            <Caption {...animation}>
                {title}
            </Caption>

            <StyledLoader>
                <Loader status={status} />
            </StyledLoader>

            {status === 'success' && (
                <StyledTimer seconds={5} />
            )}
        </Container>
    );
}


const StyledAppLogo = styled(AppLogo)`
  svg {
    transform: scale(1.15);
  }
`;

const Caption = styled(motion.span)`
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--grey-6);
`;

const StyledTimer = styled(Timer)`
  position: absolute;
  bottom: 40px;
  margin: auto;
`

const StyledLoader = styled.span`
  transform: scale(1.4);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 50px;
  width: 100vw;
  height: 100vh;
`;
