import { AppLogo, Button } from 'app/components';
import {Actions, useAppDispatch} from 'store';
import styled from 'styled-components';

export function ErrorPage() {
    const dispatcher = useAppDispatch();

    const handleLogOut = () => {
        dispatcher(Actions.Auth.logout()).then(() => {
            window.location.href = '/';
        })
            .catch(() => {
                throw new Error('Error logging out');
            })
            .catch((error) => {throw error})
    }

    const handleRefresh = () => {
        window.location.reload();
    }

    return (
        <ErrorCenter>
            <AppLogo />
            <h1>Oops, something went wrong</h1>
            <p>You can try refreshing or logging out then back in again. If the problem persists please contact us at <a href="mailto:hello@hashgifted.com">hello@hashgifted.com</a></p>
            <ButtonRow>
                <Button onClick={handleLogOut} theme="outline">Log Out</Button>
                <Button onClick={handleRefresh} theme="main">Refresh</Button>
            </ButtonRow>
        </ErrorCenter>
    );
}

const ErrorCenter = styled.div`
    height: 100vh;
    width: 100vw;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('/assets/gifted-shapes.png');
    background-size: 110vh;

    h1 {
        color: var(--blue);
        margin-bottom: 0;
    }

    p {
        color: var(--grey-9);
        text-shadow: 3px 3px 10px rgba(255,255,255,1);
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`