import {
    FormEvent,
    PropsWithChildren,
    useMemo,
    useState
} from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { Button, Link, FormInput } from 'app/components';
import { EmailIcon, LockIcon } from 'app/icons';

interface Props {
    errors?: Dynamic;
    loading?: boolean;
    onSubmit(data: LoginForm): void;
}

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @media (max-width: 900px) and (min-width: 0px) {
        row-gap: 16px;
    }
`;

export function LogInForm({
    errors,
    loading,
    children,
    onSubmit
}: PropsWithChildren<Props>) {
    const [form, setForm] = useState<LoginForm>({
        email: '',
        password: ''
    });

    const disabledSubmit = useMemo(() => {
        return Helpers.isEmpty(form);
    }, [form]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmit(form);
    }

    const handleChange = (name: string, value: string) => {
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    return (
        <Container>
            {children}

            <Form onSubmit={handleSubmit}>
                <FormInput
                    type={'text'}
                    name={'email'}
                    label={'Email'}
                    placeholder={'Enter your email'}
                    value={form.email}
                    error={errors?.email}
                    required={true}
                    onChange={handleChange}
                >
                    <EmailIcon />
                </FormInput>

                <FormInput
                    type={'password'}
                    name={'password'}
                    label={'Password'}
                    placeholder={'Enter your password'}
                    value={form.password}
                    error={errors?.password}
                    required={true}
                    onChange={handleChange}
                >
                    <LockIcon />
                </FormInput>

                <Link to={'/forgot-password'}>
                    Forgot Password?
                </Link>

                <StyledButton
                    loading={loading}
                    disabled={disabledSubmit}
                >
                    <span>Log In</span>
                </StyledButton>
            </Form>

            <p>
                <span>Don't have an account yet? </span>

                <Link to={'/'}>Sign Up</Link>
            </p>
        </Container>
    );
}