import { ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { Hooks } from 'utils';

interface Props {
    id?: string | number;
    media?: string;
    onClose(): void;
}

const Content = styled(motion.div)`
    img {
        width: 100%;
        height: auto;
        max-height: 600px;
        border-radius: 32px;
        object-fit: contain;
        user-drag: none;
    }
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);
`;

export function MediaViewer({
    id,
    media,
    onClose
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        layoutId: 'media-motion'
    };

    const contentRef = useRef<HTMLDivElement>(null);

    Hooks.useOnClickOutside(contentRef, () => media && onClose());

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence>
            {media && (
                <Container {...containerAnimation}>
                    <Content
                        {...contentAnimation}
                        ref={contentRef}
                        layoutId={`media-viewer-${id}`}
                        id={`media-viewer-${id}`}
                    >
                        <img src={media} alt={`bubble media ${id}`} />
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
}