import {
    FormEvent,
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import styled from 'styled-components';

import { useAppSelector } from 'store';
import { Helpers } from 'utils';
import {
    Alert,
    Button,
    EmptyPlaceholder,
    FormSwitch
} from 'app/components';
import {deliverableToIcon} from "../../../utils/mapping";
import listify from 'listify';
import { useMediaQuery } from 'usehooks-ts';

interface Props {
    data?: DeliverablesForm;
    status?: string;
    disabledActions?: boolean;
    disabledInputs?: boolean;
    loading?: boolean;
    primaryAction: GiftFormAction;
    secondaryAction: GiftFormAction;
    propagateFormToGiftView?: any;
    noBottomButtons?: boolean;
}

const DeliverableHeader = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
`;

const DeliverableDesc = styled.div`
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: var(--grey-7);
`;

const DeliverableInfo = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    span {
        display: flex;

        &:last-child {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: var(--grey-9);
        }
    }
`;

const StyledSwitch = styled(FormSwitch)`
    margin-left: auto;
`;

const Delieverable = styled.div`
    display: flex;
    padding: 8px 16px;
    border-radius: 12px;
    background: var(--grey-2);
`;

const StyledButton = styled(Button)`
    width: 186px;
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    margin-top: auto;

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column;

        button {
            width: 100%;
            margin-top: 12px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 100%;
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;
    padding: 24px 52px 40px;
  @media (max-width:900px) and (min-width:0px) {
    padding: 24px 24px 40px;
  }
`;

const RightContent = styled.div`
    grid-area: right-content;
    border-left: 1px solid var(--grey-4);

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const initialData = {
    deliverables: []
};

interface DeliverablesErrors {
    deliverables?: string;
}

export function DeliverablesForm({
    data,
    status,
    disabledActions,
    disabledInputs,
    loading,
    children,
    primaryAction,
    secondaryAction,
    propagateFormToGiftView,
    noBottomButtons
}: PropsWithChildren<Props>) {
    const rightContent = useRef<HTMLDivElement>(null);

    const { deliverables } = useAppSelector((state) => state.global);

    const [form, setForm] = useState<DeliverablesForm>(data || initialData);
    const [triedToSubmit, setTriedToSubmit] = useState(data?.deliverables.length || false);
    const [formErrors, setFormErrors] = useState<DeliverablesErrors>({});


    const smallDevice = useMediaQuery('(max-width: 900px)')

    useEffect(() => {
        if (propagateFormToGiftView) {
            propagateFormToGiftView(form)
        }
    }, [form])

    const disabledSubmit = useMemo(() => {

        if (form.deliverables.length === 0) {
            setFormErrors((prev) => ({
                ...prev,
                deliverables: 'Please select at least one platform'
            }));
        } else {
            setFormErrors((prev) => ({
                ...prev,
                deliverables: undefined
            }));
        }

        if (!triedToSubmit) {
            if (form.deliverables.length === 0) {
                setFormErrors({});
            }
        }

        if (Helpers.isEmptyObject(form, true)) {
            return true;
        }
        return false
    }, [data, disabledActions, loading, form]);

    const exists = (deliverables: Deliverable[], deliverable: IdName) => {
        const exists = deliverables.find(({ id }) => id === deliverable.id);
        return !Helpers.isNullOrUndefined(exists);
    }

    useEffect(() => {
        const rightContentEl = rightContent.current;
        const parentEl = rightContentEl?.parentElement;

        if (rightContentEl && parentEl && rightContentEl) {
            const rightContentRect = rightContentEl.getBoundingClientRect();
            const parentRect = parentEl.getBoundingClientRect();

            if (rightContentRect.height > parentRect.height) {
                parentEl.style.height = 'auto';
            }
        }

        return () => {
            if (parentEl) {
                parentEl.style.removeProperty('height');
            }
        }
    }, []);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        primaryAction.action(form);
    }

    const handlePrimaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (primaryAction.type === 'reset') {
            setForm(data || initialData);
        }

        primaryAction.action(form);
    }

    const handleSecondaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (secondaryAction.type === 'reset') {
            setForm(data || initialData);
        }

        secondaryAction.action(form);
    }

    const handleChange = (deliverable: Deliverable) => {
        setForm((prev) => ({
            ...prev,
            deliverables: exists(prev.deliverables, deliverable) ?
                prev.deliverables.filter(({ id }) => id !== deliverable.id) :
                prev.deliverables.concat([deliverable])
        }));
    }

    const socialPlatformToHumanReadableName = (social_platform: SocialPlatformType) => {
        switch (social_platform) {
            case 'INSTAGRAM':
                return 'Instagram';
            case 'YOUTUBE':
                return 'YouTube';
            case 'TIKTOK':
                return 'TikTok';
            default:
                throw new Error(`platform type missing mapping ${social_platform}`)
        }
    };

    const allTypes = useMemo<SocialPlatformType[]>(() => {
        return [
            'INSTAGRAM',
            'TIKTOK',
            'YOUTUBE'
        ]
    }, []);

    const deliverablesMessage = useMemo(() => {
        if (form.deliverables.length === 0) {
            return 'Please select at least one platform';
        }

        const platforms: SocialPlatformType[] = [];
        for (const deliverable of form.deliverables) {
            if (deliverable.social_platform && platforms.indexOf(deliverable.social_platform) === -1) {
                platforms.push(deliverable.social_platform);
            } else {
                for (const type of allTypes) {
                    if (platforms.indexOf(type) === -1) {
                        platforms.push(type);
                    }
                }
            }
        }

        if (platforms.length === 0) {
            return `Your gift will be shown to ${socialPlatformToHumanReadableName('INSTAGRAM')}, ${socialPlatformToHumanReadableName('TIKTOK')} and ${socialPlatformToHumanReadableName('YOUTUBE')} creators`
        }
        const list = listify(platforms.map((value) => socialPlatformToHumanReadableName(value)), { finalWord: 'and' })
        return `Your gift will be shown to ${list} creators`
    }, [allTypes, form.deliverables]);

    const onNext = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setTriedToSubmit(true);
        if (disabledSubmit) {
            return
        }

        handlePrimaryActionClick(e)
    }

    return (
        <>
            <LeftContent>
                {children}

                {!smallDevice ? (
                    <Alert type={form.deliverables.length === 0 ? 'info' : 'success'}
                        title={deliverablesMessage}
                        message={'The creators will then let you know what they can do in exchange for your gift. You\'ll be able to chat with the creators you match with directly in the platform to agree final gift & deliverables'}
                        ctaLabel={'What is a fair exchange?'}
                        actionUrl={'https://www.hashgifted.com/how-it-works/what-is-a-fair-gifting-exchange-as-a-brand'}
                    />
                ) : null}

                <Form onSubmit={handleSubmit}>
                    {deliverables.map((deliverable) => (
                        <Delieverable key={deliverable.id}>
                            <DeliverableInfo>
                                <DeliverableHeader>
                                    <span>{deliverableToIcon(deliverable.id)}</span>

                                    <span>{deliverable.name}</span>
                                </DeliverableHeader>

                                <DeliverableDesc>
                                    {deliverable.additional_info}
                                </DeliverableDesc>
                            </DeliverableInfo>

                            <StyledSwitch
                                name={deliverable.name}
                                label={''}
                                checked={exists(form.deliverables, deliverable)}
                                disabled={disabledInputs}
                                onChange={() => handleChange(deliverable)}
                            />
                        </Delieverable>
                    ))}
                    {formErrors.deliverables && (
                        <p style={{color: '#ff647c', fontWeight: 400, fontSize: 14}}>{formErrors.deliverables}</p>
                    )}

                    {(!disabledActions && !disabledInputs) && (!noBottomButtons) && (
                        <FormActions>
                            <StyledButton
                                size={'medium'}
                                loading={loading ||
                                    status === 'set-gift-deliverables'}
                                onClick={(e) => onNext(e)}
                            >
                                {primaryAction.name}
                            </StyledButton>

                            <StyledButton
                                theme={'outline'}
                                size={'medium'}
                                loading={loading &&
                                    status === 'saving-draft'}
                                onClick={handleSecondaryActionClick}
                            >
                                {secondaryAction.name}
                            </StyledButton>
                        </FormActions>
                    )}
                </Form>

                {smallDevice ? (
                    <Alert type={form.deliverables.length === 0 ? 'info' : 'success'}
                        title={deliverablesMessage}
                        message={'The creators will then let you know what they can do in exchange for your gift. You\'ll be able to chat with the creators you match with directly in the platform to agree final gift & deliverables'}
                        ctaLabel={'What is a fair exchange?'}
                        actionUrl={'https://www.hashgifted.com/how-it-works/what-is-a-fair-gifting-exchange-as-a-brand'}
                    />
                ) : null}

            </LeftContent>

            <RightContent ref={rightContent}>
                <EmptyPlaceholder image={'girl-2'} />
            </RightContent>
        </>
    );
}
