import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Actions, useAppDispatch, useAppSelector } from 'store';
import { LogInForm } from 'app/forms';
import { PublicLayout } from 'app/layouts';
import { InvitePageHeader } from './Header';
import { Alert, Button, FormInput } from 'app/components';
import { API } from 'api';
import { useNavigate } from 'react-router-dom';


export function TeamsInvitePage() {
    const dispatcher = useAppDispatch();
    const navigate = useNavigate();

    const {
        auth: { loading },
        global: { alert }
    } = useAppSelector((state) => state);

    const [brandId, setBrandId] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);

    const [brandName, setBrandName] = useState<string>('');
    const [brandLogo, setBrandLogo] = useState<string>('');

    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

    const [noInvite, setNoInvite] = useState<boolean>(false);
    const [inviteAccepted, setInviteAccepted] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const brandId = params.get('brand');
        const email = params.get('email');

        if (brandId && email) {
            setBrandId(decodeURIComponent(brandId))
            const decodedEmail = decodeURIComponent(email);
            const formattedEmail = decodedEmail.replace(/ /g, '+');
            setEmail(formattedEmail);
            API.Profile.getBrandTeamInvite(formattedEmail, brandId).then((response) => {
                setBrandName(response.brand_name as string);
                setBrandLogo(response.logo as string);

                if (response.status) {
                    setInviteAccepted(true);
                }
            }).catch((error) => {
                setNoInvite(true);
                console.log(error);
            });
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [dispatcher]);

    const handleSubmit = (form: LoginForm) => {
        dispatcher(Actions.Auth.login(form)).catch((error: any) => {throw error});
    }

    const signUp = () => {
        if (email && password && passwordConfirm) {

            if (password !== passwordConfirm) {
                setPasswordError('Passwords do not match');
                return;
            }

            if (password.length < 6) {
                setPasswordError('Password must be at least 6 characters');
                return;
            }

            setPasswordError('');

            void dispatcher(Actions.Auth.createAccount({email: email, password: password, passwordConfirm: passwordConfirm, agreeToTerms: true}, () => {}, true)).then(() => {
                API.Profile.acceptBrandInvite(email as string).then((response) => {
                    console.log(response);
                    localStorage.setItem('teamInvite', 'true');
                    dispatcher(Actions.Auth.login({email: email, password: password})).then(() => {
                        navigate('/');
                    }).catch((error: any) => {
                        console.log(error);
                    });
                }).catch((error) => {
                    throw error;
                });
            });
        }
    }

    const generateTitle = useMemo(() => {
        if (noInvite) {
            return 'No invite found!';
        }

        if (inviteAccepted) {
            return 'You\'ve already joined this team!';
        }
        return 'You\'re invited to join a team!';
    }, [noInvite, inviteAccepted]);

    const generateDescription = useMemo(() => {
        if (noInvite) {
            return 'The invite link you tried to use is invalid or has expired. Please ask the brand to check their invite link and try again.';
        }

        if (inviteAccepted) {
            return 'You\'ve already joined this team!';
        }

        return `${brandName} has invited you to join their team. Create unique photos, videos, reviews, testimonials, and social buzz through the power of influencer gifting.`;
    }, [noInvite, inviteAccepted, brandName]);

    return (
        <Container data-testid={'logn-page'}>
            <Body>
                {brandLogo && (
                    <BrandCardHeader>
                        <BrandCardLogo src={brandLogo} />
                    </BrandCardHeader>
                )}

                <InvitePageHeader
                    title={generateTitle}
                    desc={generateDescription}
                />

                {noInvite || inviteAccepted ? (
                    <div style={{marginTop: '24px'}}>
                        <Button onClick={() => window.location.href = '/login'}>Go back to login</Button>
                    </div>
                ) : (
                    <BrandCard>
                        <BrandCardBody>
                            <BrandCardTitle>{brandName} has invited you to join their team</BrandCardTitle>
                            <FormInput label="password" value={password} onChange={(e, v) => setPassword(v)} name="password" type="password" placeholder="Password" />
                            <FormInput label="confirm password" value={passwordConfirm} onChange={(e, v) => setPasswordConfirm(v)} name="passwordConfirm" type="password" placeholder="Password confirmation" />
                            {passwordError && <label style={{color: 'red'}}>{passwordError}</label>}
                            <Button loading={loading} onClick={() => signUp()}>Sign up and join team</Button>

                        </BrandCardBody>
                    </BrandCard>
                )}

                {alert && alert.errors && Object.keys(alert.errors).length === 0 && (
                    <Alert {...alert} />
                )}
            </Body>
        </Container>
    );
}

const BrandCardTitle = styled.h2`
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    color: var(--blue);
`;

const BrandCardBody = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-left: 24px;

    p {
        font-size: 24px !important;
    }

`;

const BrandCardLogo = styled.img`
    width: 140px;
    height: 140px;
    object-fit: cover;
    margin-bottom: 24px;
    border-radius: 9px;
`;

const BrandCard = styled.div`
    display: flex;
    align-items: flex-start;
    row-gap: 24px;
    margin-top: 24px;
`;

const BrandCardHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;



const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: auto;

    p {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }
`;
