import { batch } from 'react-redux';

import { API } from 'api';
import { Helpers } from 'utils';
import { Constants } from '../constants';
import settingsService from "../../services/settingsService";
import { WaveStatus } from "../../api/wave";
import { appActions } from './appActions';

let timeoutHandler: NodeJS.Timeout;

const _setStatus = (status: string) => ({
    type: Constants.Wave.Status,
    payload: status
});

const _setLoading = (loading: boolean) => ({
    type: Constants.Wave.Loading,
    payload: loading
});

const _setLoadingView = (loading: boolean) => ({
    type: Constants.Wave.LoadingView,
    payload: loading
});

const _setLoadingReviews = (loading: boolean) => ({
    type: Constants.Wave.LoadingReviews,
    payload: loading
});

const _setLoadingViewMore = (loading: boolean) => ({
    type: Constants.Wave.LoadingViewMore,
    payload: loading
});

const clearState = () => {
    return (dispatch: AppDispatch) => {
        dispatch({ type: Constants.Wave.Clear, payload: null });
    }
}

const getByStatuses = (
    giftId: string,
    statuses: Array<WaveStatus>,
    options: PaginatedActionOptions,
    sort: string,
    filter: string,
    search: string,
    showLoading = true,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        if (options.page === 1) {
            showLoading && dispatch(_setLoading(true));
        } else {
            showLoading && dispatch(_setLoadingViewMore(true));
        }

        try {
            const {
                wave: {
                    list,
                    paginations
                }
            } = getState();

            const {
                data,
                current_page,
                last_page,
                total
            } = await API.Wave.getByStatuses(giftId, statuses, options.page, sort, filter, search);

            batch(() => {

                dispatch({
                    type: Constants.Wave.Paginations,
                    payload: {
                        ...paginations,
                        list: {
                            current_page,
                            last_page,
                            total
                        }
                    }
                });

                dispatch({
                    type: Constants.Wave.List,
                    payload: options.page === 1 ?
                        data : list.concat(data)
                });

                showLoading && dispatch(_setLoading(false));
                showLoading && dispatch(_setLoadingViewMore(false));
            });
        } catch(e: any) {
            if (e.code !== "ERR_CANCELED") {
                batch(() => {
                    showLoading && dispatch(_setStatus('error'));
                    showLoading && dispatch(_setLoading(false));
                    showLoading && dispatch(_setLoadingViewMore(false));
                });

                timeoutHandler = setTimeout(() => {
                    dispatch(_setStatus('idle'));
                }, settingsService.alertTimeout);
            }
        }
    }
}

const getWaveItem = (
    giftId: string,
    waveId: string,
    markAsSeen: boolean,
    showLoading: boolean,
    callback?: (wave: WaveItem) => void
) => {
    return async (dispatch: AppDispatch) => {
        if (showLoading) {
            dispatch(_setLoadingView(true));
        }

        try {
            const wave = await API.Wave.getItem(giftId, waveId, markAsSeen);

            batch(() => {
                dispatch({
                    type: Constants.Wave.View,
                    payload: wave
                });

                showLoading && dispatch(_setLoadingView(false));
            });

            if (callback) {
                callback(wave);
            }
        } catch(e: any) {
            batch(() => {
                if (e.code !== "ERR_CANCELED") {
                    dispatch(_setStatus('error'));
                    if (showLoading) {
                        dispatch(_setLoadingView(false));
                    }
                }
            });

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

const getInfluencerReviews = (
    influencerId: string,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const { wave: { view } } = getState();

        if (view) {
            dispatch(_setLoadingReviews(true));

            try {
                const reviews = await API.Influencer.getInfluencerReviews(influencerId);

                batch(() => {
                    dispatch({
                        type: Constants.Wave.View,
                        payload: {
                            ...view,
                            influencer: {
                                ...view.influencer,
                                reviews: reviews
                            }
                        }
                    });

                    dispatch(_setLoadingReviews(false));
                });

                if (callback) {
                    callback();
                }
            } catch {
                batch(() => {
                    dispatch(_setStatus('error'));
                    dispatch(_setLoadingReviews(false));
                });
            }

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

const resendMessage = (
    giftId: string,
    waveId: string,
    bubbleId?: string
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        // TODO: implement a better way to hadle
        // updating the status of the bubble
        const { wave: { view } } = getState();
        const currentBubble = view?.bubbles.find((bubble: WaveBubble) =>
            bubble.id === bubbleId);

        if (view && currentBubble) {
            try {
                dispatch({
                    type: Constants.Wave.View,
                    payload: {
                        ...view,
                        bubbles: view.bubbles.map((bubble) =>
                            currentBubble.id === bubble.id ?
                                { ...bubble, sendingStatus: 'sending' } :
                                bubble
                        )
                    }
                });

                if (currentBubble.bubble_type === 'user-message') {
                    await API.Wave.sendMessage(giftId, waveId, currentBubble.meta.message as string);
                } else {
                    await API.Wave.sendAttachment(giftId, waveId, currentBubble.meta.file as File);
                }

                dispatch({
                    type: Constants.Wave.View,
                    payload: {
                        ...view,
                        bubbles: view.bubbles.map((bubble) =>
                            currentBubble.id === bubble.id ?
                                { ...bubble, sendingStatus: 'sent' } :
                                bubble
                        )
                    }
                });
            } catch {
                batch(() => {
                    dispatch({
                        type: Constants.Wave.View,
                        payload: {
                            ...view,
                            bubbles: view.bubbles.map((bubble) =>
                                currentBubble.id === bubble.id ?
                                    { ...bubble, sendingStatus: 'error' } :
                                    bubble
                            )
                        }
                    });

                    dispatch(_setStatus('error-sending-message'));
                });
            }
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const sendMessage = (
    giftId: string,
    waveId: string,
    message: string | File
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        const { wave: { view }, profile, gift } = getState();

        if (!profile.brand?.subscription && (gift?.item?.status === 'COMPLETED' && view?.status !== 'ACCEPTED' && gift?.item?.gift_type !== 'EVENT')) {
            appActions.setShowPaymentDialog(true, 'subscription_required')(dispatch)
            return;
        }

        if (view) {
            const formattedMessage = Helpers.formatMessage(message, 'sending');
            const wave = {
                ...view,
                bubbles: [
                    ...view.bubbles,
                    formattedMessage // this seems to double send messages client side.
                ]
            };

            try {
                // TODO: implement a better way to hadle
                // adding and updating the status of
                // the bubble

                // add the message to the wave bubbles
                // before sending to the api
                dispatch({
                    type: Constants.Wave.View,
                    payload: wave
                });

                if (typeof message === 'string') {
                    await API.Wave.sendMessage(giftId, waveId, message);
                } else {
                    await API.Wave.sendAttachment(giftId, waveId, message);
                }

                // update the status of the message
                // note:
                //   this is still buggy since the
                //   value of the bubbles is the value
                //   before we add the bubble from the
                //   previous dispatch event
                dispatch({
                    type: Constants.Wave.View,
                    payload: {
                        ...wave,
                        bubbles: wave.bubbles.map((bubble) =>
                            bubble.id === formattedMessage.id ?
                                { ...bubble, sendingStatus: 'sent' } :
                                bubble
                        )
                    }
                });
            } catch (error:any) {
                if(error?.response?.status===422){
                    dispatch(setShowPIIModal(true))
                }

                if(error?.response?.status===402){
                    appActions.setShowPaymentDialog(true, 'subscription_required')(dispatch)
                }


                // same with the above
                batch(() => {
                    dispatch({
                        type: Constants.Wave.View,
                        payload: {
                            ...wave,
                            bubbles: wave.bubbles.map((bubble) =>
                                bubble.id === formattedMessage.id ?
                                    { ...bubble, sendingStatus: 'error' } :
                                    bubble
                            )
                        }
                    });

                    dispatch(_setStatus('error-sending-message'));
                });
            }
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setStatus = (
    giftId: string,
    waveId: string,
    status: string,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        dispatch(_setStatus(`set-${status}`));

        const {
            wave: { list },
            profile,
            gift
        } = getState();

        if (!profile.brand?.subscription && gift?.item?.status === 'COMPLETED' && gift?.item?.gift_type !== 'EVENT') {
            appActions.setShowPaymentDialog(true, 'subscription_required')(dispatch)
            dispatch(_setStatus('idle'));
            return;
        }

        try {

            const updatedItem = await API.Wave.updateStatus(giftId, waveId, status);

            batch(() => {
                // update the current list of waves
                // to remove the wave that has been
                // accepted/rejected
                if (status === 'complete') {
                    const updated = [];
                    for (const item of list) {
                        if (item.uid === updatedItem.uid) {
                            updated.push(updatedItem);
                        } else {
                            updated.push(item);
                        }
                    }
                    dispatch({
                        type: Constants.Wave.List,
                        payload: updated
                    });
                } else {
                    dispatch({
                        type: Constants.Wave.List,
                        payload: list.filter((item) => item.uid !== waveId)
                    });
                }

                dispatch(_setStatus('success'));
            });

            if (callback) {
                callback();
            }
        } catch (err: any) {
            if (err.response.status == 402) {
                const response = err.response.data as RequiresPaymentResponse;
                appActions.setShowPaymentDialog(true, response.code)(dispatch)
            }
            dispatch(_setStatus('error'));
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const submitReview = (
    giftId: string,
    influencerId: string,
    form: ReviewForm,
    callback: () => void
) => {
    return async (dispatch: AppDispatch) => {
        clearTimeout(timeoutHandler);

        dispatch(_setStatus('set-review'));

        try {
            await API.Wave.submitReview(giftId, influencerId, form);

            dispatch(_setStatus('success'));

            callback();
        } catch {
            dispatch(_setStatus('error'));
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setShowPIIModal = (show: boolean) => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Wave.ShowPIIModal,
            payload: show
        });
    }
}

const setLastReceivedBubble = (bubble: WaveBubbleWithWave) => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Wave.LastReceivedBubble,
            payload: bubble
        });
    }
}

const updateWaveInList = (wave: WaveItem) => {
    return (
        dispatch: AppDispatch,
        getState: () => AppReducer
    )=> {
        const {
            wave: {
                list,
            }
        } = getState();

        const updatedList = [...list];

        const index = updatedList.findIndex((candidate) => candidate.uid === wave.uid);
        if (index > -1) {
            updatedList[index] = wave;
            dispatch({
                type: Constants.Wave.List,
                payload: updatedList
            });
        }
    }
}

export const waveActions = {
    clearState,
    getByStatuses,
    getWaveItem,
    getInfluencerReviews,
    resendMessage,
    sendMessage,
    setStatus,
    submitReview,
    setShowPIIModal,
    setLastReceivedBubble,
    updateWaveInList
};
