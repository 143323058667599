export const Constants = {
    Auth: {
        ForgotPassword: 'AUTH:FORGOT_PASSWORD',
        Login: 'AUTH:LOGIN',
        Logout: 'AUTH:LOGOUT',
        SignUp: 'AUTH:SIGNUP',
        Verify: 'AUTH:VERIFY',
        Status: 'AUTH:STATUS',
        Loading: 'AUTH:LOADING'
    },
    Completed: {
        List: 'COMPLETED:LIST',
        View: 'COMPLETED:VIEW',
        Status: 'COMPLETED:STATUS',
        Loading: 'COMPLETED:LOADING',
        LoadingView: 'COMPLETED:LOADING_VIEW',
        LoadingReviews: 'COMPLETED:LOADING_REVIEWS',
        Clear: 'COMPLETED:CLEAR'
    },
    Gift: {
        List: 'GIFT:LIST',
        Flow: 'GIFT:FLOW',
        Item: 'GIFT:ITEM',
        Recommendations: 'GIFT:RECOMMENDATIONS',
        ShopifyItems: 'GIFT:SHOPIFY_ITEMS',
        Paginations: 'GIFT:PAGINATIONS',
        Status: 'GIFT:STATUS',
        Loading: 'GIFT:LOADING',
        LoadingRecommendations: 'GIFT:LOADING_RECOMMENDATIONS',
        LoadingShopifyItems: 'GIFT:LOADING_SHOPIFY_ITEMS',
        ShopifyIsDisconnected: 'GIFT:SHOPIFY_IS_DISCONNECTED',
        LoadingViewMore: 'GIFT:LOADING_VIEW_MORE',
        Clear: 'GIFT:CLEAR',
        ClearRecommendations: 'GIFT:CLEAR_RECOMMENDATIONS'
    },
    Global: {
        Alert: 'APP:ALERT',
        Attributes: 'APP:ATTRIBUTES',
        Deliverables: 'APP:DELIVERABLES',
        GiftTypes: 'APP:GIFT_TYPES',
        GiftStatus: 'APP:GIFT_STATUS',
        Locations: 'APP:LOCATIONS',
        Countries: 'APP:COUNTRIES',
        Cities: 'APP:CITIES',
        States: 'APP:STATES',
        Subscriptions: 'APP:SUBSCRIPTIONS',
        Tags: 'APP:TAGS',
        Booting: 'APP:BOOTING',
        Loading: 'APP:LOADING',
        InputError: 'APP:INPUT_ERROR',
        ShowPaymentModal: 'APP:SHOW_PAYMENT_MODAL',
        ShowPaymentDialog: 'APP:SHOW_PAYMENT_DIALOG',
        Settings: 'APP_SETTINGS',
    },
    Profile: {
        Brand: 'PROFILE:BRAND',
        Representative: 'PROFILE:REPRESENTATIVE',
        Subscription: 'PROFILE:SUBSCRIPTION',
        Billing: 'PROFILE:BILLING',
        Invoices: 'PROFILE:INVOICES',
        Shopify: 'PROFILE:SHOPIFY',
        Notifications: 'PROFILE:NOTIFICATIONS',
        ReceivedNotification: 'PROFILE:RECIEVED_NOTIFICATION',
        Status: 'PROFILE:STATUS',
        Clear: 'PROFILE:CLEAR',
        Loading: 'PROFILE:LOADING',
        LoadingBilling: 'PROFILE:LOADING_BILLING',
        LoadingInvoices: 'PROFILE:LOADING_INVOICES',
        LoadingSubscription: 'PROFILE:LOADING_SUBSCRIPTION',
        LoadingNotifications: 'PROFILE:LOADING_NOTIFICATIONS',
        Team: 'PROFILE:TEAM'
    },
    Wave: {
        List: 'WAVE:LIST',
        View: 'WAVE:VIEW',
        ReceivedBubble: 'WAVE:RECEIVED_BUBBLE',
        ReadBubble: 'WAVE:READ_BUBBLE',
        Paginations: 'WAVE:PAGINATIONS',
        Status: 'WAVE:STATUS',
        Clear: 'WAVE:CLEAR',
        Loading: 'WAVE:LOADING',
        LoadingView: 'WAVE:LOADING_VIEW',
        LoadingReviews: 'WAVE:LOADING_REVIEWS',
        LoadingViewMore: 'WAVE:LOADING_VIEW_MORE',
        ShowPIIModal: 'WAVE:SHOW_PII_MODAL',
        LastReceivedBubble: 'WAVE:LAST_RECEIVED_BUBBLE',
    }
};
