import { Helpers } from 'utils';
import axios from './axios';

const changePassword = (data: NewPasswordForm) => {
    const url = '/producer/password';

    return new Promise<number>((resolve, reject) => {
        axios.put(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const createAccount = (data: SignUpForm) => {
    const url = '/producer/register';

    return new Promise<Auth>((resolve, reject) => {
        axios.post(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Auth);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const forgotPassword = (data: ForgotPasswordForm,) => {
    const url = '/producer/reset-password';

    return new Promise<ForgotPassword>((resolve, reject) => {
        axios.post(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as ForgotPassword);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const forgotPasswordUpdate = (
    form: NewPasswordForm,
    resetToken: string
) => {
    const url = '/producer/reset-password';
    const data = Helpers.removeObjectKeys(form, ['reset_token']);
    const headers = { 'Reset-Token': resetToken };

    return new Promise<number>((resolve, reject) => {
        axios.put(url, JSON.stringify(data), { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const forgotPasswordVerify = (
    data: Omit<CodeVerificationForm, 'code'> & {
        code: string;
    },
    verifyToken: string
) => {
    const url = '/producer/reset-password/verify';
    const headers = { 'Verify-Token': verifyToken };

    return new Promise<{ reset_token: string }>((resolve, reject) => {
        axios.post(url, JSON.stringify(data), { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as { reset_token: string });
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const login = (data: LoginForm) => {
    const url = '/producer/login';

    return new Promise<Auth>((resolve, reject) => {
        axios.post(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Auth);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const loginWithToken = (token: string) => {
    const url = '/producer/loginWithToken';

    return new Promise<Auth>((resolve, reject) => {
        axios.post(url, JSON.stringify({ 'token': token }))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Auth);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const logout = (token: string) => {
    const url = `/producer/logout`;
    const headers = { 'Token': token };

    return new Promise<number>((resolve, reject) => {
        axios.post(url, {}, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

export const authAPI = {
    changePassword,
    createAccount,
    forgotPassword,
    forgotPasswordUpdate,
    forgotPasswordVerify,
    login,
    loginWithToken,
    logout
};
