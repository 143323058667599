import { FormEvent, PropsWithChildren, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { Button, FormInput } from 'app/components';
import { EmailIcon } from 'app/icons';

interface Props {
    data?: ForgotPasswordForm;
    errors?: Dynamic;
    loading?: boolean;
    onSubmit(email: ForgotPasswordForm): void;
}

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`;

export function ForgotPasswordForm({
    data,
    errors,
    loading,
    children,
    onSubmit
}: PropsWithChildren<Props>) {
    const [form, setForm] = useState<ForgotPasswordForm>(data || {
        email: ''
    });

    const disabledSubmit = useMemo(() => {
        return Helpers.isEmpty(form);
    }, [form]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        onSubmit(form);
        event.preventDefault();
    }

    return (
        <Container>
            {children}

            <Form onSubmit={handleSubmit}>
                <FormInput
                    type={'text'}
                    name={'email'}
                    label={'Email'}
                    placeholder={'Enter your email'}
                    value={form.email}
                    error={errors?.email}
                    required={true}
                    onChange={(_, value) => setForm({ email: value })}
                >
                    <EmailIcon />
                </FormInput>

                <StyledButton
                    loading={loading}
                    disabled={disabledSubmit}
                >
                    Next
                </StyledButton>
            </Form>
        </Container>
    );
}