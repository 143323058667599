import {motion} from 'framer-motion';
import styled from 'styled-components';

import {Helpers} from 'utils';

interface Props {
    image: 'girl-1' | 'girl-2' | 'subscription-placeholder';
    cancelModel?: boolean;
}

const GiftImage = styled(motion.img) <{
    index: number;
}>`
    position: absolute;
    width: ${(props) => props.index === 0 ? '150px' : '160px'};
    height:  ${(props) => props.index === 0 ? '150px' : '160px'};

    ${(props) => !Helpers.isNullOrUndefined(props.index) && `
        top: ${props.index % 2 === 0 ? 20 : 5}%;
        left: ${props.index * 32 + 2}%;
    `}
`;

const MainImage = styled(motion.img)`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 302px;
    margin: auto;
`;

const Body = styled.div`
    position: relative;
    height: 768px;
    margin-top: auto;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--grey-1);
`;

const giftImages = [
    require('../../../images/camera.png'),
    require('../../../images/gift.png'),
    require('../../../images/phone.png'),
];

export function EmptyPlaceholder({
    image,
    cancelModel = false
}: Props) {
    const mainImageAnimation = {
        initial: { opacity: 0, translateY: '100%' },
        animate: { opacity: 1, translateY: '0px' },
        transition: {
            type: 'spring',
            duration: 1
        }
    };

    const giftImagesAnimation = {
        animate: 'visible',
        initial: { opacity: 0, scale: 0 },
        variants: {
            visible: (custom: number) => ({
                opacity: 1,
                scale: 1,
                transition: {
                    type: 'spring',
                    delay: custom * 0.1 + 0.2
                }
            })
        }
    };

    return (
        <Container style={cancelModel ? {background: 'white',  height: 'auto'} : {}} data-testid={'empty-placeholder-component'}>
            <Body style={cancelModel ? {margin: '0px'} : {}}>
                {giftImages.map((image, index) => (
                    <GiftImage
                        {...giftImagesAnimation}
                        key={index}
                        index={index}
                        custom={index}
                        src={image}
                        alt={`gift ${index}`}
                    />
                ))}

                <MainImage
                    {...mainImageAnimation}
                    src={require(`../../../images/placeholder/${image}.png`)}
                />
            </Body>
        </Container>
    );
}
