import axios from 'axios';

import { Constants, Store } from 'store';
import settingsService from "../services/settingsService";
import axiosRetry from "axios-retry";

const axiosServices = axios.create({
    baseURL: settingsService.baseApiUrl,
    timeout: 60000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    },
});

axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 200;
    },
    retryCondition: (error) => {
        return error.response?.status === 503 || error.response?.status === 502 || axiosRetry.isNetworkError(error);
    },
});

axiosServices.interceptors.request.use((config) => {
    const storedCreds = localStorage.getItem(settingsService.credentialsStorageKey);
    const creds : { access_token: string } = storedCreds && JSON.parse(storedCreds);

    if (creds && config.headers) {
        config.headers.Authorization = `Bearer ${creds.access_token}`;
    }

    return config;
});

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            Store.dispatch({ type: Constants.Auth.Logout, payload: null });
        }

        return Promise.reject(error);
    }
);

export default axiosServices;
