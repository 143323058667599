import {useEffect, useState, useRef, useMemo} from 'react';
import styled from 'styled-components';
import GiftedBkg from '../../../../images/gifted-bkg.png';
import { Button } from 'app/components/Button';
import { API } from 'api';
import {useLocation, useNavigate} from 'react-router-dom';

import errorReporter from 'services/errorReporter';
import SocialProfileButtons from 'app/components/SocialProfileButtons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {StarFilledIcon, PeopleIcon, ConfettiIcon,AngleLeftIcon} from 'app/icons';
import {Hooks} from 'utils';
import { CarouselList } from 'app/components/CarouselList';
import {useAppSelector} from "../../../../store";
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../../services/settingsService';

const tags = [
    { name: 'all' },
    { name: 'fashion' },
    { name: 'beauty & makeup' },
    { name: 'travel' },
    { name: 'lifestyle' },
    { name: 'parenting/mummy' },
    { name: 'health/fitness' },
    { name: 'business' },
]

interface Props {
    firstTime: boolean,
    profile: RepresentativeProfile
}

export const Showcase = ({ firstTime, profile }: Props) => {

    const {
        global: {
            settings
        },
        profile: {
            brand
        }
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const location = useLocation();
    const navigate = useNavigate();

    const [selectedTag, setSelectedTag] = useState('all');
    const [showcaseItems, setShowcaseItems] = useState<ShowcaseResponse['data']>([]);
    const ref = useRef<HTMLDivElement>(null);
    const reviewRef = useRef<HTMLDivElement>(null);

    const [showFullGallery, setShowFullGallery] = useState<boolean>(false);
    const [showcaseIndex, setShowcaseIndex] = useState(0);
    const [galleryIndex, setGalleryIndex] = useState(0);

    const [reviewInfluencer, setReviewInfluencer] = useState<Influencer | null>(null)
    const [influencerReviews, setInfluencerReviews] = useState<Review[]>([])
    const [showInfluencerReviews, setShowInfluencerReviews] = useState(false);
    const [loadingShowcase, setLoadingShowcase] = useState(false);
    const [caseStudies, setCaseStudies] = useState([]);

    useEffect(() => {
        const fetchCaseStudies = async () => {
          try {
            const res: [] = await API.Shared.getCaseStudies();
            setCaseStudies(res);
          } catch (err) {
            throw new Error('An error occurred.');
          }
        };

        void fetchCaseStudies(); // Mark the promise as ignored with void
      }, []);


    Hooks.useOnClickOutside(ref, () => showFullGallery &&
        setShowFullGallery(false));

    Hooks.useOnClickOutside(reviewRef, () => {
        if (influencerReviews) {
            setShowInfluencerReviews(false);
            setReviewInfluencer(null);
            setInfluencerReviews([]);
        }
    })

    const handleCreateGift = () => {
        const path = '/create';

        if (location.pathname !== path) {
            navigate(path);
        }
    }

    useEffect(() => {
        fetchShowcaseItems();
    }, [selectedTag])

    const fetchShowcaseItems = () => {
        setLoadingShowcase(true);
        setShowcaseItems([]);
        API.Shared.getShowcase([selectedTag === 'all' ? '' : selectedTag]).then((data: ShowcaseResponse) => {
            setShowcaseItems(data.data);
            setLoadingShowcase(false);
        }).catch((error: any) => {
            errorReporter.report('Error fetching showcase', error);
        })
    }

    const displayTopEngagements = (data: SocialProfile[]) => {
        let profileToDisplay = data[0];

        if (!profileToDisplay) {
            return
        }

        data.forEach((profile) => {
            if (profile.followers > profileToDisplay.followers) {
                profileToDisplay = profile
            }
        })

        const followers = Intl
        .NumberFormat('en-US', {
            notation: 'compact'
        })
        .format(Number(profileToDisplay.followers));

        const engagement = `${(Number.parseFloat(`${profileToDisplay.engagement_rate}` as string) * 100.0).toFixed(2)}%`;

        return (
            <Metric><PeopleIcon className="people-icon" />{followers} followers {engagement !== 'NaN%' ? <><span className="metric-divider">||</span> <ConfettiIcon className="confetti-icon" /> {engagement} engagement</> : null}</Metric>
        )
    }

    const displayCarouselModal = (index: number, showcaseIndex: number) => {
        setShowFullGallery(true);
        setShowcaseIndex(showcaseIndex);
        setGalleryIndex(index);
    }

    const handleRatingClick = (influencer: Influencer) => {
        setReviewInfluencer(influencer);
        setShowInfluencerReviews(true);
    }

    useEffect(() => {
        if (reviewInfluencer) {
            API.Influencer.getInfluencerReviews(reviewInfluencer.uid).then((reviews) => {
                setInfluencerReviews(reviews)
            }).catch((error: any) => {
                errorReporter.report('Unable to fetch reviews', error);
            })
        }
    }, [reviewInfluencer])

    const baseUrl = useMemo(() => {
        return settings?.media.endpoint;
    }, [settings]);

    const pageTitle = useMemo(() => firstTime ? 'Welcome to #gifted' : `Welcome back, ${profile.first_name || profile.name}!`, [firstTime, profile]);
    const pageSubTitle = useMemo(() => firstTime ? 'Create your first gift to get started.' : 'Create a new gift to get started.', [firstTime]);
    const buttonCtaTitle = useMemo(() => firstTime ? 'Create your first gift' : 'Create a new gift', [firstTime]);

    return (
        <>
            <MainBkg></MainBkg>
            <Container>
                <HeaderBkg></HeaderBkg>
                <MainContainer style={!firstTime ? {paddingTop: '0px'} : {}}>
                    <Hero>
                        <h1>{pageTitle}</h1>
                        <h2>{pageSubTitle}</h2>
                        <Button theme="aqua" onClick={handleCreateGift}>{buttonCtaTitle}</Button>
                    </Hero>

                    <h2 className="extra-margin">Top case studies</h2>
                    <CaseStudies>
                        <CarouselList list={caseStudies} />
                    </CaseStudies>

                    <h2>Top creators on #gifted</h2>
                    <BrandTags>
                        {tags.map((tag) => (
                            <Button onClick={() => setSelectedTag(selectedTag !== tag.name ? tag.name : '')} key={tag.name} theme={selectedTag === tag.name ? 'main' : 'outline'} size="small">
                                {tag.name}
                            </Button>
                        ))}
                    </BrandTags>

                    <ShowcaseContainer>
                        {(loadingShowcase || !settings) && (
                            Array(4).fill('').map(() => (
                                <LoadingShowcaseBox></LoadingShowcaseBox>
                            ))
                        )}
                        {settings && showcaseItems.map((showcase, showcaseIndex: number) => {
                            const headshot_image = showcase.influencer.headshot_image && showcase.influencer.headshot_image.startsWith('http') ? showcase.influencer.headshot_image : `${settings.media.endpoint}${showcase.influencer.headshot_image}`;
                            return (
                                showcase?.showcase_media && showcase?.showcase_media?.length > 0 ? (
                                    <ShowcaseBox>
                                        <Left>
                                            {showcase.influencer.headshot_image && (
                                                <img className="profile" src={headshot_image}
                                                     alt={`${showcase.influencer.name} profile picture`}/>
                                            )}
                                            <h3>{showcase.influencer.name}</h3>
                                            {displayTopEngagements(showcase.profiles as SocialProfile[])}
                                            <Bio
                                                onClick={() => {
                                                    analyticsEvents.creatorProfileOpened(
                                                        {
                                                            profile_id: showcase.influencer.uid,
                                                            profile_name: showcase.influencer.name,
                                                        }
                                                    );
                                                    displayCarouselModal(0, showcaseIndex)}}>{showcase.influencer.bio}</Bio>
                                            <SocialProfileButtons influencer={{
                                                ...showcase.influencer,
                                                social_profiles: showcase.profiles
                                            }} loading={false} subscription={brand?.subscriptionv2}/>

                                            <Rating
                                                onClick={() => handleRatingClick(showcase.influencer as Influencer)}><StarFilledIcon
                                                className="rating-icon"/>{showcase.influencer.review_average} stars</Rating>
                                        </Left>
                                        <Right>
                                            <Carousel key={showcase.influencer.name}
                                                      onClickItem={(index: number) => displayCarouselModal(index, showcaseIndex)}>
                                                {showcase?.showcase_media?.map((img) => (
                                                    <div key={img.relative_path}>
                                                        <img className='img-gallery-showcase' src={`${baseUrl}/${img.relative_path}`}/>
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </Right>
                                    </ShowcaseBox>
                                ) : null
                            );
                        })}
                    </ShowcaseContainer>

                </MainContainer>
            </Container>

            {showFullGallery && (
                <GalleryModalBackdrop onClick={(e) => e.preventDefault()}>
                    <Gallery ref={ref} style={{width: '600px', height: 'auto'}}>
                        <Carousel selectedItem={galleryIndex}>
                            {showcaseItems[showcaseIndex]?.showcase_media?.map((img) => (
                                <div className="has-img" key={img.relative_path} style={{background: `url('${baseUrl}/${img.relative_path}')`}}>
                                    <img src={`${baseUrl}/${img.relative_path}`} />
                                </div>
                            ))}
                        </Carousel>
                        <GalleryUser>
                            <div>
                                {settings && showcaseItems[showcaseIndex]?.influencer?.headshot_image && (
                                    <img className="profile" src={
                                        showcaseItems[showcaseIndex]?.influencer?.headshot_image && showcaseItems[showcaseIndex]?.influencer?.headshot_image.startsWith('http') ? showcaseItems[showcaseIndex]?.influencer?.headshot_image : `${settings.media.endpoint}${showcaseItems[showcaseIndex]?.influencer?.headshot_image}` as string} />
                                )}
                            </div>
                            <div>
                                <p className="user-name">{showcaseItems[showcaseIndex]?.influencer.name}</p>
                                <p className="user-bio">{showcaseItems[showcaseIndex]?.influencer.bio}</p>
                            </div>
                        </GalleryUser>
                    </Gallery>
                </GalleryModalBackdrop>
            )}

            {showInfluencerReviews && (
                <GalleryModalBackdrop>
                    <ReviewContainer ref={reviewRef}>
                        <img className="review-pfp" src={reviewInfluencer?.headshot_image as string} />
                        <h2>{reviewInfluencer?.name}</h2>
                        {influencerReviews.length ? influencerReviews?.map((review) => (
                            <ReviewBox>
                                <div className="info-box">
                                    <div className="name">{new Date(review.reviewed_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</div>
                                </div>
                                <div>
                                    {[...Array(review.rating)].map(() => (
                                        <StyledStarFilledIcon />
                                    ))}
                                </div>

                                <p>{review.review}</p>
                            </ReviewBox>
                        )) : <p style={{color: 'var(--blue)'}}>No reviews listed.</p>}

                        <Button size="small" className="back-btn" theme="aqua" iconOnly={true} onClick={() => setShowInfluencerReviews(false)}>
                            <AngleLeftIcon />
                        </Button>

                    </ReviewContainer>
                </GalleryModalBackdrop>
            )}
        </>
    );
};

const GalleryUser = styled.div`
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: flex-start;

    img {
        height: 40px;
        width: 40px;
        border-radius: 8px;
        margin-right: 8px;
        margin-bottom: 24px;
    }

    p {
        padding: 0;
        margin: 0;
    }

    .user-name {
        font-weight: 600;
        color: var(--blue);
    }

    .user-bio {
        color: var(--grey-7);
        padding-bottom: 24px;
    }
`

const StyledStarFilledIcon = styled(StarFilledIcon)`
    path {
        fill: var(--yellow);
    }
`;

const ReviewBox = styled.div`
    margin-top: 6px;
    margin-bottom: 12px;
    background: var(--grey-2);
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;

    .info-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 4px;

        .name {
            color: var(--blue);
            font-weight: bold;
        }

        .date {
            color: var(--grey-7);
            font-size: 12px;
            font-weight: medium;
        }
    
    }

    p {
        color: var(--grey-7);
        margin: 4px 0px;
        font-size: 16px;
    }
`

const ReviewContainer = styled.div`
    height: 800px;
    width: 500px;
    background: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    overflow-y: scroll;
    position: relative;

    .back-btn {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    h2 {
        color: var(--blue);
        font-weight: 600;
    }

    .review-pfp {
        height: 100px;
        width: 100px;
        border-radius: 18px;
    }
`

const GalleryModalBackdrop = styled.div`
    position: absolute;
    top: 0; left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000000;
    backdrop-filter: blur(2px);
    background: rgba(0,0,0,.4);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`

const Gallery = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 12px;
    padding-top: 12px;

    .thumbs-wrapper {
        display: none;
    }

    .slide {
        display: flex;
        justify-content: center !important;
    }

    .has-img {
        height: 100%;
        position: relative;
        backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
        display: flex;
        align-items: center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            backdrop-filter: blur(10px); /* Adjust the blur radius as needed */
            z-index: -1;
        }

    }

    .slide img {
        max-width: 600px;
    }

    .control-arrow {
        background: rgba(0,0,0,.3) !important;

        &:hover, &:focus, &:active {
            background: rgba(0,0,0,.2) !important;
        }
    }

    .carousel-status {
        display: none !important;
    }

    @media (max-width:900px) and (min-width:0px) {
        max-height: 90vh;
        max-width: 100vw;
        overflow-y: scroll;
    }
`

const Metric = styled.div`
    font-size: 12px;
    color: var(--grey-6);
    line-height: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .confetti-icon {
        fill: #262161;
        margin-right: 4px;
        height: 14px;
        width: 14px;

        path {
            fill: #262161;
        }

        @media (max-width:900px) and (min-width:0px) {
            display: none;
        }
    }

    .people-icon {
        fill: #262161;
        margin-right: 4px;
        height: 14px;
        width: 14px;

        @media (max-width:900px) and (min-width:0px) {
            display: none;
        }
    }

    .metric-divider {
        margin: 0px 4px;
    }

    @media (max-width:900px) and (min-width:0px) {
        font-size: 10px;
        line-height: 12px;
    }
`

const Rating = styled.div`
    position: absolute;
    top: 13px;
    right: 18px;
    text-decoration: underline;
    color: var(--grey-6);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;

    .rating-icon {
        height: 18px;
        width: 18px;
        margin-right: 4px;
    }

    .rating-icon, path {
        fill: #FFB800;
    }

    @media (max-width:900px) and (min-width:0px) {

        .rating-icon {
            display: none;
        }
    }
`

const Left = styled.div`
    padding: 13px 14px;
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h3, p {
        margin: 0;
        padding: 0;
    }

    h3 {
        margin-bottom: 4px;
        font-size: 16px;
        color: var(--blue);
        font-weight: 600;
    }

    p {
        margin-top: 6px;
        margin-bottom: 8px;
        height: 33px;
        font-size: 12px;
        color: var(--grey-7);
        overflow-y: hidden;

        @media (max-width:900px) and (min-width:0px) {
            height: 28px;
        }
    }

    img {

        &.profile {
            border-radius: 20px;
            height: 75px;
            width: 75px;   
            margin-bottom: 5px; 

            @media (max-width:900px) and (min-width:0px) {
                height: 60px;
                width: 60px;
            }
        }
    }
`

const Bio = styled.p`
    cursor: pointer;
    border-radius: 6px;
    transition: background 200ms ease;
    padding: 4px 8px !important;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* or 3 for 3 lines */
    -webkit-box-orient: vertical;

    &:hover {
        background: var(--grey-2);
    }

`

const Right = styled.div`
    width: 250px;

    .carousel .carousel-slider {
        height: 250px;
    }

    .slide {

        @media (max-width:900px) and (min-width:0px) {
            width: 265px !important;
        }
    }

    .img-gallery-showcase {

        @media (max-width:900px) and (min-width:0px) {

            width: auto !important;
            height: 250px !important;    
        }
    }

    .carousel-slider {
        height: 250px !important;
    }

    .carousel-status {
        display: none !important;
    }

    .dot:only-child {
        display: none !important;
    }

    @media (max-width:900px) and (min-width:0px) {
        overflow: hidden;
    }

`

const ShowcaseContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1185px;
    margin-top: 27px;

    @media (max-width:900px) and (min-width:0px) {
        width: 100vw;
        justify-content: center;
    }
`

const ShowcaseBox = styled.div`
    width: 580px;
    height: 250px;
    margin-bottom: 20px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 6px 32px 11px rgba(41, 43, 50, 0.12);
    border-radius: 20px;
    overflow: hidden;

    @media (max-width:900px) and (min-width:0px) {
        width: 95vw;
        max-width: 580px;
    }
`

const LoadingShowcaseBox = styled.div`
    width: 580px;
    height: 250px;
    margin-bottom: 20px;
    display: flex;
    background: #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
    border-radius: 12px;
    background: linear-gradient(
        to right,
        var(--grey-2),
        var(--grey-1) 50%,
        var(--grey-2) 80%
    ), var(--grey-2);
    background-position: 100% 0, 0 0;
    background-repeat: repeat-y;
    background-size: 200% 200%;
    animation: animate 1s infinite;        

`

const BrandTags = styled.div`
    display: flex;

    button {
        margin-right: 7px;
        text-transform: capitalize;
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100vw;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 8px;

        button {
            margin-bottom: 8px;
        }
    }
`

const CaseStudies = styled.div`
    min-height: 214px;
    width: calc(95vw - 330px);
    overflow-x: hidden;

    h1 {
        font-size: 18px !important;
        line-height: 24px;
        text-align: left;
        margin: 20px;
        color: white !important;
    }

    h2 {
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        text-align: left;
        margin: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    h3 {
        font-size: 14px;
        line-height: 14px;
        margin: 20px;
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100vw;
    }
`

const MainContainer = styled.div`
    padding: 72px 128px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        color: var(--blue);
        font-weight: 600;
        font-size: 28px;

        &.extra-margin {
            margin-top: 72px;
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        padding: 0px !important;

        h2 {

            &.extra-margin {
                margin-top: 24px;
            }
        }
    }
`

const Hero = styled.div`

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 48px;
  
  h1 {
    color: var(--blue);
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 22px;
  }

  h2 {
    color: var(--blue);
    font-weight: 600;
    font-size: 28px;

    &.extra-margin {
      margin-top: 72px;
    }
  }

  @media (max-width:900px) and (min-width:0px) {
    width: 100%;

    h1 {
      text-align: center;
      font-size: 28px;
      padding: 12px 2px;
      margin: 0;
      margin-bottom: 22px;
    }

    h2 {
      text-align: center;
      font-size: 22px;
      margin: 0;
      margin-bottom: 22px;
    }
  }
`;

const MainBkg = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: white;
`

const Container = styled.div`
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
`

const HeaderBkg = styled.div`
    height: 700px;
    width: 1100px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 1439px;
    height: 1259px;
    // background-image: url(${GiftedBkg});
    background: linear-gradient(179.98deg, #FFFFFF 57.35%, rgba(255, 255, 255, 0) 82.66%, rgba(255, 255, 255, 0.541667) 99.99%), url(${GiftedBkg});
    background-position: bottom;
    background-size: contain;
    transform: rotate(179.92deg);
    z-index: 0;


    @media (max-width:900px) and (min-width:0px) {
        width: 100vw;
    }
`
