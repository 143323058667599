import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { Badge } from 'app/components';
import {
    GiftIcon,
    PencilEditIcon,
    WaveIcon
} from 'app/icons';
import {useMemo, useState} from "react";
import { Actions } from 'store';
import { API } from 'api';


interface Props {
    id: string;
    name: string | null;
    tags: IdName[];
    media: Media[];
    waveCount: number;
    status: GiftStatus;
    loading?: boolean;
    onClick?(id: string, status: GiftStatus): void;
    brand: BrandProfile;
    refresh(): Promise<void>;
}

export function GiftListItem({
    id,
    name,
    tags,
    media,
    waveCount,
    status,
    loading,
    brand,
    refresh,
    onClick
}: Props) {
    const animation = {
        transition: {
            type: 'spring',
            duration: 0.8
        },
        variants: {
            loading: { opacity: 1, scale: 1, y: 0 },
            hidden: { opacity: 0, scale: 0.85, y: 80 },
            show: { opacity: 1, scale: 1, y: 0 }
        }
    };

    const featuredMedia = media.find((item) => item.is_cover);

    const featuredImage = useMemo(() => (featuredMedia || media[0])?.thumbnail_path || (featuredMedia || media[0])?.gallery_path, [featuredMedia, media]);

    const placeholder = Array(Helpers.generateInt(1, 3)).fill('').map((_, i) => ({
        id: i,
        name: ''
    }));
    const [deleting, setDeleting] = useState(false);

    const tagList = loading ? placeholder : tags;

    const handleClick = () => {
        if (onClick) {
            onClick(id, status);
        }
    }

    const renderImageIcon = () => {
        switch (status) {
            case 'ACTIVE':
            case 'COMPLETED':
            case 'CLOSED':
            case 'SCHEDULED':
                return (
                    <ImageIcon>
                        <WaveIcon />

                        {waveCount > 0 && (
                            <WaveCountBadge>
                                {waveCount}
                            </WaveCountBadge>
                        )}
                    </ImageIcon>
                );

            case 'DRAFT':
                return (
                    <ImageIcon>
                        <PencilEditIcon />
                    </ImageIcon>
                );

            default: return null;
        }
    }

    const renderImageBadge = () => {
        let text: string|null = null;

        switch (status) {
            case 'ACTIVE':
                text = 'ACTIVE';
                break;

            case 'CLOSED':
                text = 'APPLICATIONS PAUSED';
                break;

            case 'SUBMITTED':
                text = 'REVIEWING';
                break;

            case 'REJECTED':
                text = 'DECLINED';
                break;

            case 'DRAFT':
                text = 'DRAFT';
                break;

            case 'SCHEDULED':
                text = 'SCHEDULED FOR RELEASE';
                break;
            
            case 'COMPLETED':
                text = 'COMPLETED';
                break;
        }

        return (
            <>
                {text && (
                    <ImageBadge $rejected={status === 'REJECTED'} $draft={status === 'DRAFT'}>
                        {text}
                    </ImageBadge>
                )}
            </>
        );
    }
    
    const handleDeleteGift = (e: any, id: string) => {
        e.stopPropagation();
        console.log('hello', brand)

        setDeleting(true);
        API.Gift.deleteGift(brand.uid, id).then(async () => {
            setDeleting(false);
            await refresh().catch((e) => {
                setDeleting(false);
                throw e;
            });
        }).catch((error: any) => {
            setDeleting(false);
            throw error;
        })

        // Actions.Gift.deleteGift(id, () => {console.log('nice.')});
    }

    return (
        <Container
            {...animation}
            $loading={loading || deleting}
            onClick={handleClick}
        >
            <Image $image={featuredImage}>
                {!loading && !deleting && (
                    <>
                        {renderImageIcon()}
                        {renderImageBadge()}
                    </>
                )}

                {!loading || !deleting && (
                    <StyledIcon />
                )}
            </Image>

            <Desc>
                <Name>{deleting ? '' : name}</Name>
                

                {/* {status !== 'DRAFT' ? ( */}
                    <BadgeList>
                        {status === 'DRAFT' && !deleting ? (
                            <DeleteButton onClick={(e) => handleDeleteGift(e,id)}>Delete</DeleteButton>
                        ) : null}

                        {tagList.slice(0, 3).map(({ id, name }) => (
                            <HashBadge key={id}>
                                {name}
                            </HashBadge>
                        ))}
                    </BadgeList>
                {/* ) : null} */}

            </Desc>
        </Container>
    );
}


const WaveCountBadge = styled(Badge)`
    position: absolute;
    top: -6px;
    right: -6px;
    letter-spacing: 0px;
    border-radius: 8px;
    background-color: var(--error-active);
    color: var(--text-alt);
`;

const ImageIcon = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 12px 16px;
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const DeleteButton = styled.button`
    // position: absolute;
    // top: 120px;
    // left: 12px;
    background-color: var(--error-active);
    color: var(--text-alt);
    padding: 0px 6px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 300ms ease;

    &:hover {
        background-color: var(--warning-active);
    }
`;

const ImageBadge = styled(Badge) <{
    $rejected?: boolean;
    $draft?: boolean;
}>`
    font-size: 11px;
    position: absolute;
    top: 12px;
    left: 12px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
    color: var(--grey-9);

    ${(props) => props.$rejected && `
        background-color: var(--error-muted);
        color: var(--error-active);
    `}

    ${(props) => props.$draft && `
        background-color: var(--grey-3);
        color: var(--grey-7);
    `}
`;

const StyledIcon = styled(GiftIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: auto;
    transform: translate(-50%, -50%);

    path {
        fill: var(--text-alt);
    }
`;

const Image = styled.div<{
    $image?: string;
}>`
    position: relative;
    height: 164px;
    background-color: var(--blue);
   
    ${(props) => props.$image && `
        background-image: url(${props.$image});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        ${StyledIcon} {
            display: none;
        }
    `}
`;

const Name = styled.div`
    font-size: 20px;
    font-weight: 600;
    align-self: flex-start;
    width: 100%;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: var(--blue);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const HashBadge = styled(Badge)`
    &:before {
        content: '# ';
        font-weight: 600;
    }
`;

const BadgeList = styled.div`
    display: flex;
    flex-wrap: nowrap;
    column-gap: 8px;
    row-gap: 8px;
    overflow: hidden;
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;
    padding: 8px 12px;
    user-select: none;
`;

const Container = styled(motion.div) <{
    $loading?: boolean
}>`
    flex-shrink: 0;
    position: relative;
    width: 327px;
    height: 244px;
    border-radius: 20px;
    background-color: var(--background-alt);
    overflow: hidden;
    cursor: pointer;

    &:active:not(:focus-within) {
        transition: transform 300ms ease;
        transform: scale(0.95) !important;
    }

    ${(props) => !props.$loading && `
        ${Name}:empty:before {
            content: '#';
            visibility: hidden;
        }
    `}

    ${(props) => props.$loading && `
        ${Image},
        ${Name},
        ${Badge} {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '###################';
                visibility: hidden;
            }
        }

        ${Name} {
            width: auto;
        }

        ${Badge} {
            &:before {
                content: '#####'
            }
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 90%;
    }
`;
