import { MouseEvent, PropsWithChildren } from 'react';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import { Color } from '../../../utils';
import { Spinner } from '../Spinner';
import ReactTooltip from "react-tooltip";

export type ButtonTheme = 'aqua' | 'danger' | 'outline' | 'alert' | 'info' | 'main';

export interface Props {
    theme?: ButtonTheme;
    size?: 'medium' | 'small';
    iconOnly?: boolean;
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?(event: MouseEvent<HTMLButtonElement>): void;
    tooltip?: string
}

const StyledSpinner = styled(Spinner)`
    border: 1px solid var(--background-alt);
    border-top: 1px solid var(--blue);
`;

const Container = styled.button<{
    $theme?: string;
    $size?: string;
    $iconOnly?: boolean;
    $loading?: boolean;
    $disabled?: boolean;
}>`
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    line-height: 24px;
    border: none;
    border-radius: 16px;
    background-color: var(--blue);
    color: var(--text-alt);
    transform: scale(1);
    transition: all 300ms ease;
    cursor: pointer;

    svg path {
        transition: fill 300ms ease;
    }

    &:disabled {
        background-color: var(--grey-3);
        border-color: var(--grey-3);
        color: var(--grey-6);
    }

    &:active:enabled {
        transform: scale(0.95);
    }

    &:hover:enabled {
        background-color: ${() => Color.hexVarToRGBA('--blue', 0.35)};
        color: var(--blue);

        svg path {
            fill: var(--blue);
        }
    }

    .__react_component_tooltip {
        position: absolute;
        width: max-content;
        max-width: 300px;
        z-index: 200000;
        transform: translazeZ('20000px');
    }


    ${(props) => props.$theme === 'aqua' && `
        background-color: var(--aqua);
        color: var(--blue);

        &:hover:enabled {
            background-color: ${Color.hexVarToRGBA('--aqua', 0.25)};
            color: var(--success-active);
    
            svg path {
                fill: var(--success-active);
            }
        }
    `}

    ${(props) => props.$theme === 'danger' && `
        border: 1.5px solid var(--error-active);
        background-color: var(--background-alt);
        color: var(--error-active);

        &:hover:enabled {
            background-color: ${Color.hexVarToRGBA('--error-active', 0.15)};
            color: var(--error-active);

            svg path {
                fill: var(--error-active);
            }
        }
    `}

    ${(props) => props.$theme === 'alert' && `
        border: 1.5px solid var(--alert-active);
        background-color: var(--alert-active);
        color: 'white';

        &:hover:enabled {
            background-color: ${Color.hexVarToRGBA('--warning-active', 0.15)};
            color: var(--alert-active);

            svg path {
                fill: var(--alert-active);
            }
        }
    `}

    ${(props) => props.$theme === 'info' && `
        border: 1.5px solid var(--info-active);
        background-color: var(--info-active);
        color: 'white';

        &:hover:enabled {
            background-color: ${Color.hexVarToRGBA('--warning-active', 0.15)};
            color: var(--info-active);

            svg path {
                fill: var(--info-active);
            }
        }
    `}

    ${(props) => props.$theme === 'outline' && `
        border: 1.5px solid var(--blue);
        background-color: var(--background-alt);
        color: var(--blue);

        &:disabled {
            border-color: var(--grey-3);
        }

        &:hover:enabled {
            background-color: ${Color.hexVarToRGBA('--grey-7', 0.15)};
            color: var(--grey-7);

            svg path {
                fill: var(--grey-7);
            }
        }
    `}

    ${(props) => props.$theme === 'main' && `
        background-color: var(--blue);
        color: white;

        &:hover:enabled {
            background-color: ${Color.hexVarToRGBA('--blue', 0.25)};
            color: var(white);

            svg path {
                fill: var(--success-active);
            }
        }
    `}


    ${(props) => !props.$size && `
        height: 56px;
        padding: 16px 24px;
    `}

    ${(props) => props.$size === 'medium' && `
        height: 48px;
        padding: 12px 16px;
    `}

    ${(props) => props.$size === 'small' && `
        height: 40px;
        padding: 8px 12px;
        font-size: 14px;
    `}

    ${(props) => !props.$size && props.$iconOnly && `
        height: 52px;
    `}

    ${(props) => props.$size === 'medium' && props.$iconOnly && `
        height: 44px;
    `}
    
    ${(props) => props.$size === 'small' && props.$iconOnly && `
        height: 36px;
    `}

    ${(props) => props.$loading && `
        background-color: var(--grey-3);
        color: var(--grey-6);
    `}

`;

export function Button({
    theme,
    size,
    iconOnly,
    loading,
    disabled,
    className,
    children,
    onClick,
    tooltip
}: PropsWithChildren<Props>) {
    const spinnerAnimation = {
        initial: { opacity: 0, width: 0, height: 0, marginLeft: 0 },
        animate: { opacity: 1, width: 20, height: 20, marginLeft: 0 },
        exit: { opacity: 0, width: 0, height: 0, marginLeft: -10 }
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (!loading && !disabled && onClick) {
            onClick(event);
        }
    }

    // @ts-ignore
    return <Container
        data-testid={'button-component'}
        $theme={theme}
        $size={size}
        $iconOnly={iconOnly}
        $loading={loading}
        disabled={disabled || loading}
        className={className}
        onClick={handleClick}
        data-tip={tooltip}
    >
        {!!tooltip && (
            <ReactTooltip place='left' effect="float" />
        )}
        <AnimatePresence>
            {loading && <StyledSpinner {...spinnerAnimation} />}
        </AnimatePresence>

        {children}
    </Container>;
}
