import {
    FormEvent,
    PropsWithChildren, useEffect,
    useState
} from 'react';
import styled from 'styled-components';

import { Mapping } from 'utils';
import { Alert, Button, FormInput, FormLabel} from 'app/components';

interface Props {
    data?: BrandForm;
    errors: ApiErrors | undefined;
    buttonLabel?: string;
    loading?: boolean;

    onSubmit(data: Dynamic): void;
}

export function SocialAccountsForm({
    data,
    errors,
    buttonLabel,
    loading,
    children,
    onSubmit
}: PropsWithChildren<Props>) {
    const [form, setForm] = useState<BrandForm>(data || {
        description: '', logo: undefined, name: '', social_profiles: [], tags: [], website: '', brand_type: ''
    });
    const [preventSubmition, setPreventSubmition] = useState(!!(form.social_profiles && form.social_profiles.length <= 1));
    const [formHasError, setformHasError] = useState('')

    const handleSubmit = (event: any, skipped = false) => {
        event.preventDefault();
        if (!preventSubmition || skipped) {
            onSubmit(form);
        }
    }

    const handleChange = (
        name: string,
        value: string | boolean
    ) => {
        checkFields();
        setForm((prev) => {

            const platform = name as SocialPlatformType;
            const profile_url = value as string;

            const newSocialProfiles = (prev.social_profiles?.filter((value: BrandSocialProfile) => value.platform !== platform)) || [];
            if (profile_url && profile_url.trim().length > 0) {
                newSocialProfiles.push({platform: platform, profile_url: profile_url});
            }

            return {
                ...prev,
                social_profiles: newSocialProfiles.length > 0 ? [...newSocialProfiles] : null
            } as BrandForm;
        });
    }

    const checkFields = () => {

        if(!form.social_profiles || (form.social_profiles && form.social_profiles.length === 0)){
            setPreventSubmition(true)
            return;
        }

        if (preventSubmition) {
            setformHasError('')
            setPreventSubmition(false)
        }
    }

    const findProfileUrl = (platformType: SocialPlatformType) => {
        return (form.social_profiles && form.social_profiles.find(value => value.platform === platformType)?.profile_url) || '';
    };

    const errorMessage = errors?.social_profiles?.join('.\r\n');

    useEffect(()=>{
        checkFields()
    }, [form.social_profiles]);

    return (
        <Container>
            {children}

            {errorMessage && (
                <Alert type={"error"} title={errorMessage} />
            )}

            <Form onSubmit={handleSubmit}>
                {Mapping.SupportedSocialPlatforms.map(({ id, name }) => (
                    <FormGroup key={id}>
                        <FormLabel>
                            {name}
                        </FormLabel>

                        <FormInline>
                            {Mapping.Social(id)}

                            <StyledFormInput
                                type={'text'}
                                name={id}
                                label={''}
                                placeholder={Mapping.SocialPlaceholder(id)}
                                value={findProfileUrl(id)}
                                onChange={handleChange}
                            />
                        </FormInline>
                    </FormGroup>
                ))}

                <StyledButton
                    onClick={() => checkFields()}
                    loading={loading}
                    disabled={preventSubmition}
                >
                    {buttonLabel || 'Next'}
                </StyledButton>

                <Button theme={'outline'} loading={loading} onClick={(e) => handleSubmit(e, true)}>Skip</Button>

                {formHasError && <Error>{formHasError}</Error>}

            </Form>
        </Container>
    );
}


const StyledFormInput = styled(FormInput)`
    flex: 1;
`;

const FormInline = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg {
        width: 32px;
        height: 32px;
    }
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;
`;

const Error = styled.p`
    color: #ff647c;
`

const Container = styled.div``;
