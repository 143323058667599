import { EyeClose, EyeOpen } from 'app/icons';
import { ChangeEvent, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { FormLabel } from '../FormLabel';
import { useAppSelector } from 'store';

type InputType = 'text' | 'email' | 'password' | 'number' | 'search' | 'hidden';

interface Props {
    type: InputType;
    name: string;
    label: string;
    placeholder?: string;
    value: string | number;
    error?: string|string[];
    maxLength?: number;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    className?: string;
    onPaste?: any
    onChange(platform: string, value: string): void;
}

const PasswordToggler = styled.span`
    display: flex;
    position: absolute;
    right: 18px;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: var(--grey-6);
        }
    }
`;

const Input = styled.input`
    font-size: 14px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    color: var(--grey-9);
    outline: none;

    &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: var(--grey-6);
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: var(--grey-6);
    }
    
    ::-ms-input-placeholder {
        color: var(--grey-6);
    }

    ::-ms-reveal {
        display: none;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }

    [type=number] {
        -moz-appearance: textfield;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    height: 56px;
    padding: 0 12px;
    border: 1.5px solid var(--grey-2);
    border-radius: 14px;
    background-color: var(--grey-1);
    color: var(--grey-9);
    transition: border-color 300ms ease;
    overflow: hidden;

    svg {
        flex-shrink: 0;
        margin-left: -1.5px;
    }
`;

const Error = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: var(--error-active);
`;

const Container = styled.div<{
    $error?: boolean;
    $focus?: boolean;
    $disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    ${(props) => props.$error && `
        ${Content} {
            border-color: var(--error-active);

            svg path {
                fill: var(--error-active);
            }
        }
    `}

    ${(props) => props.$focus && `
        ${Content} {
            border-color: var(--blue);
        }
    `}

    ${(props) => props.$disabled && `
        pointer-events: none;

        ${Content} {
            background-color: var(--grey-3);

            ${Input} {
                color: var(--grey-6);
            }
        }
    `}

    &.hidden {
        display: none !important;
    }
`;

export function FormInput({
    type,
    name,
    label,
    placeholder,
    value,
    error,
    maxLength,
    required,
    autoFocus,
    disabled,
    className,
    children,
    onChange,
    onPaste
}: PropsWithChildren<Props>) {
    const {
        global: {
            inputError
        },
    } = useAppSelector((state) => state);

    const id = `input-${name}`;

    const [inputType, setInputType] = useState<InputType>(type);

    const [focus, setFocus] = useState<boolean>(false);

    const handleToggleShowPassword = (show: boolean) => {
        setInputType(show ? 'text' : 'password');
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (
            !Helpers.isNullOrUndefined(maxLength) &&
            maxLength < value.length
        ) {
            return;
        }

        onChange(name, value);
    }

    const handleBlur = () => {
        setFocus(false);
    }

    const handleFocus = () => {
        setFocus(true);
    }

    return (
        <Container
            data-testid={'form-input-component'}
            $error={!Helpers.isNullOrUndefined(error)}
            $focus={focus}
            $disabled={disabled}
            className={`${className} ${type === 'hidden' ? 'hidden' : null}`}
        >
            {label && (
                <FormLabel
                    labelFor={id}
                    required={required}
                >
                    {label}
                </FormLabel>
            )}

            <Content>
                {children}

                {type === 'password' && (
                    inputType === 'password' ? (
                        <PasswordToggler onClick={() =>
                            handleToggleShowPassword(true)}>
                            <EyeClose />
                        </PasswordToggler>
                    ) : (
                        <PasswordToggler onClick={() =>
                            handleToggleShowPassword(false)}>
                            <EyeOpen />
                        </PasswordToggler>)
                )}

                <Input
                    type={inputType}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    maxLength={maxLength}
                    autoFocus={autoFocus}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onPaste={onPaste}
                />
            </Content>

            {error && (
                <Error>{error}</Error>
            )}

            {inputError[name] && (
                <Error>{inputError[name][0]}</Error>
            )}
        </Container>
    );
}
