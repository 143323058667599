import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { ProductListItem } from '../ProductListItem';
import { ShopifyMode } from '..';
import { useMemo } from 'react';

interface Props {
    items: ShopifyItem[];
    selectedItems?: ShopifyItem[];
    mode: ShopifyMode;
    loading?: boolean;
    onShopifyItemClick(item: ShopifyItem): void;
}

const Container = styled(motion.div) <{
    $loading?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 438px;
    padding: 10px 52px;
    margin: -10px -52px;
    overflow: hidden auto;
    overflow: hidden overlay;

    &:empty {
        height: 0;
    }

    ${(props) => props.$loading && `
        pointer-events: none;
    `}
`;

const OutOfStock = styled.div`
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    font-weight: bold;
    color: var(--error-active);
`;

export function ProductList({
    items,
    selectedItems,
    mode,
    loading,
    onShopifyItemClick
}: Props) {
    const animation = {
        initial: loading ? 'loading' : 'hidden',
        animate: loading ? 'loading' : 'show',
        variants: {
            show: {
                transition: {
                    staggerChildren: 0.075
                }
            }
        }
    };

    const placeholder: ShopifyItem[] = Array(4).fill('').map(() => ({
        id: Helpers.generateId(),
        variantId: '',
        title: '',
        image: '',
        images: [],
        price: {
            minVariantPrice: { amount: 0 },
            maxVariantPrice: { amount: 0 }
        },
        type: '',
        totalInventory: 0
    }));

    const list = useMemo(() => {
        
        if (loading) {
            return placeholder
        }

        if (mode === 'PRODUCT') {
            return items.filter(item => item.type === 'PRODUCT')
        }

        return items
    }, [loading, items, placeholder, mode])

    const isItemSelected = (item: ShopifyItem) => {
        return !!selectedItems?.find(found => found?.id === item.id && found?.variant === item.variant);
    }

    const hasOutOfStockItems = useMemo(() => {
        if (!selectedItems) {return false}
        return selectedItems.some(item => item.totalInventory <= 0 || item.totalInventory === null);
    }, [selectedItems]);

    console.log(hasOutOfStockItems)

    return (
        <>
            {hasOutOfStockItems && mode !== 'GIFT_CARD' ? (
                <OutOfStock>
                    One or more selected items are out of stock, this could lead to creators not being able to place shopify orders automatically. 
                </OutOfStock>
            ) : null}

        <Container
            {...animation}
            $loading={loading}
        >

            {list.map((item, index) => (
                <ProductListItem
                    key={`${item.id}-${item.variant || index}`}
                    item={item}
                    mode={mode}
                    selected={isItemSelected(item)}
                    loading={loading}
                    onClick={() => onShopifyItemClick(item)}
                />
            ))}

        </Container>
        </>
    );
}
