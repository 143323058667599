import {
    FormEvent,
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import styled from 'styled-components';

import { useAppSelector } from 'store';
import { Helpers, Mapping } from 'utils';
import {
    Button, EmptyPlaceholder,
    FormLabel,
    FormMultiSelect,
    FormMultiSlider,
    OptionProps,
    RangeValueProps
} from 'app/components';
import { LocationGroupForm } from '../LocationGroupForm';
import { FormSelectBasic } from 'app/components/FormSelectBasic';
import subscriptionService from "../../../services/subscriptionService";
import ReactTooltip from "react-tooltip";

interface Props {
    data: AudienceForm;
    status?: string;
    disabledActions?: boolean;
    disabledInputs?: boolean;
    loading?: boolean;
    primaryAction: GiftFormAction;
    secondaryAction: GiftFormAction;
    setGiftAudienceLocations: (locations: GiftLocation[]) => void;
    propagateFormToGiftView?: any;
    propagateAudienceLocations?: any;
    noBottomButtons?: boolean;
}

interface LocationPrototype {
    country?: Country,
    state?: State,
    uniqueKey?: number
}

const TagsLabel = styled.span`
    font-size: 14px;
    align-self: left;
    line-height: 24px;
    color: var(--grey-9);
`;

const AgeRangeLabel = styled.span`
    font-size: 12px;
    font-weight: 600;
    align-self: center;
    line-height: 16px;
    color: var(--warning-active);
    width: 240px;
`;

const FormGroupLabel = styled(FormLabel)`
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    line-height: 32px;
    letter-spacing: -0.5px;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column !important;
    }

`;

const MoreAttributes = styled.div<{
    $show?: boolean;
}>`
    display: none;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    row-gap: 8px;
    column-gap: 16px;
    margin-top: 16px;

    ${(props) => props.$show && `
        display: grid;

        @media (max-width:900px) and (min-width:0px) {
            display: flex !important;
            flex-direction: column !important;
            width: 100%;
        }
    
    `}
`;

const ShowMore = styled(Button)`
`;

const StyledButton = styled(Button)`
    width: 186px;
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    margin-top: auto;

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column !important;

        button {
            width: 100%;
            margin-top: 12px;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;
`;

const Spacer = styled.div`
  height: 36px;
  width: 24px;
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;
    padding: 24px 52px 40px;
    overflow-y: scroll;
    margin-bottom: 50px;

  @media (max-width:900px) and (min-width:0px) {
    padding: 24px 24px 40px;
  }
`;

const RightContent = styled.div`
    grid-area: right-content;
    border-left: 1px solid var(--grey-4);
    overflow: hidden;

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const LocationRow = styled.div`
    display: flex;
    align-items: center;
    height: fit-content;
    padding-bottom: 24px;

    @media (max-width:900px) and (min-width:0px) {
        padding-bottom: 6px;
    }
`

const ActionBtn = styled.div`
    color: var(--blue);
    height: 48px;
    padding: 12px 16px;
    background: none;
    border: 1.5px solid var(--blue);
    margin-left: 4px;
    margin-right: 4px;
    transform: translateY(14px);
    border-radius: 16px;
    cursor: pointer;
    transition: all 300ms ease;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;


    &:hover {
        background-color: rgba(84,90,119,0.15);
        color: var(--grey-7);
    }

    &.disable {
        cursor: not-allowed;
        border-color: var(--grey-3);
        background-color: var(--grey-3);
        color: var(--grey-6);
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
        justify-content: center;
        transform: none;
        margin: 0;
        margin-top: 3px;
    }
`

const LinkDescription = styled.div`
    font-size: 14px;
    padding-bottom: 0px;
    font-weight: 600;
    a {
      text-decoration: underline;
      text-underline-position: under;
      text-underline-offset: 1px;
      color: var(--grey-6);
    }
`

const FullOnMobile = styled.div`
    display: flex;
    flex-direction: column;

`

const FollowerAndGenderGroup = styled.div`
  display: flex;
  columnWidth: 300;

  @media (max-width:900px) and (min-width:0px) {
    flex-direction: column;
  }
`

const GenderFormGroup = styled(FormGroup)`
  margin-left: 48px;
  @media (max-width:900px) and (min-width:0px) {
    margin-left: 0;
    margin-top: 24px;
  }
`;

const Error = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: var(--error-active);
    margin: 0px !important;
    padding: 0px !important;
` 

export const followersRange = [0, 100000];

export const ageRange = [13, 100];

const initialData = {};

interface AudienceErrors {
    creatorTypes?: string;
    followers?: string;
    gender?: string;
}

export function AudienceForm({
    data,
    status,
    disabledActions,
    disabledInputs,
    loading,
    children,
    primaryAction,
    secondaryAction,
    setGiftAudienceLocations,
    propagateFormToGiftView,
    propagateAudienceLocations,
    noBottomButtons
}: PropsWithChildren<Props>) {
    const {
        global: {
            attributes,
            tags,
            settings,
        },
        profile: {
            brand
        },
        gift: {
            item
        },
    } = useAppSelector((state) => state);

    const rightContent = useRef<HTMLDivElement>(null);

    const [form, setForm] = useState<AudienceForm>(data);
    const [showMore, setShowMore] = useState<boolean>(false);

    const [triedToSubmit, setTriedToSubmit] = useState(!!data?.gender?.length || false); // gender is only set if the user has already submitted the form
    const [formErrors, setFormErrors] = useState<AudienceErrors>({});

    const currentPlan = useMemo(() => {
        if (!settings) {
            return true;
        }
        if (!settings.features.subscriptions) {
            return true
        }
        return brand?.subscription ? subscriptionService.findPlanTypeForSubscription(brand?.subscription) : null
    },[brand, settings])

    const isEvent = useMemo(() => {
        return item?.gift_type === 'EVENT';
    }, [item])

    const locationsToPrototype = (giftLocations: GiftLocation[]): LocationPrototype[] => {
        if (giftLocations) {
            return giftLocations.map((value) => {
                return {
                    country: value.country,
                    state: value.state
                };
            })
        }

        return []
    }

    const [locationList, setLocationList] = useState<LocationPrototype[]>(locationsToPrototype(data.locations))
    const disabledSubmit = useMemo(() => {

        if (form.tags.length < 1) {
            setFormErrors((prev) => {
                return {
                    ...prev,
                    creatorTypes: 'Please select at least one creator type'
                }
            })
        } else {
            setFormErrors((prev) => {
                return {
                    ...prev,
                    creatorTypes: undefined
                }
            })
        }

        if (!form.followers) {
            setFormErrors((prev) => {
                return {
                    ...prev,
                    followers: 'Please select a follower range'
                }
            })
        } else {
            setFormErrors((prev) => {
                return {
                    ...prev,
                    followers: undefined
                }
            })
        }

        // check for both because it will start off as undefined, but when selected and unselected it will become an empty array
        if (!form.gender || !form.gender.length) {
            setFormErrors((prev) => {
                return {
                    ...prev,
                    gender: 'Please select a gender'
                }
            })
        } else {
            setFormErrors((prev) => {
                return {
                    ...prev,
                    gender: undefined
                }
            })
        }

        if (!triedToSubmit) {
            setFormErrors({})
        }

        // tags and followers and gender are the only
        // required fields
        // note:
        //   maybe the followers can be removed
        //   since it has a default value
        if (Helpers.isEmptyObject(
            Helpers.pickObjectKeys(
                form,
                ['tags', 'followers', 'gender']
            ), true
        )) {
            return true;
        }

        if (locationList.length > 0) {
            return false;
        }

        // TODO: do the deep compare on the form

        const disabled = !Helpers.isNullOrUndefined(disabledActions);
        return disabled || loading;
    }, [data, disabledActions, loading, form.gender, form.followers, form.tags, triedToSubmit]);

    useEffect(() => {
        if (propagateFormToGiftView) {
            propagateFormToGiftView(form)
        }

    }, [form]);

    useEffect(() => {
        const parentEl = rightContent.current?.parentElement;

        if (parentEl) {
            if (showMore) {
                parentEl.style.height = 'auto';
            } else {
                parentEl.style.removeProperty('height');
            }
        }

        return () => {
            if (parentEl) {
                parentEl.style.removeProperty('height');
            }
        }
    }, [showMore]);

    useEffect(() => {
        if (form?.locations) {
            const nameMap = {
                country: 'country_id',
                state: 'state_id',
                city: 'city_id',
            }

            for (const locationKey in nameMap) {
                if (locationKey) {
                    form.locations.forEach((location: any) => {
                        // @ts-ignore
                        if (!location[nameMap[locationKey]] && location[locationKey] && location[locationKey].id) {
                            // @ts-ignore
                            location[nameMap[locationKey]] = location[locationKey].id
                        }
                    })

                }
            }

            form.locations.forEach((location: any) => {
                if (!location.uniqueKey) {
                    location.uniqueKey = Math.random() * 10000
                }
            })

            if (propagateAudienceLocations) {
                propagateAudienceLocations(form.locations)
            }
        }
    }, [form?.locations])

    function locationPrototypesToGiftLocation() {
        return locationList.filter(value => value.country)
            .map((value) => {
                return {
                    country: value.country,
                    country_id: value.country?.id,
                    state_id: value.state?.id,
                    state: value.state
                } as GiftLocation
            });
    }

    useEffect(() => {
        if (propagateAudienceLocations) {
            if (!locationList[0] && locationList[1]) {
                locationList.shift()
            }

            form.locations = locationPrototypesToGiftLocation();

            propagateAudienceLocations(form.locations)
        }
    }, [locationList])

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        primaryAction.action(form);
    }

    const handlePrimaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (primaryAction.type === 'reset') {
            setForm(data || initialData);
        }

        if (!locationList[0] && locationList[1]) {
            locationList.shift()
        }

        form.locations = locationPrototypesToGiftLocation();
        setGiftAudienceLocations(form.locations) // TODO: should we be dispatching here so that redux makes the call?

        primaryAction.action(form);
    }

    const handleSecondaryActionClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (secondaryAction.type === 'reset') {
            setForm(data || initialData);
        }

        secondaryAction.action(form);
    }

    const handleChange = (
        name: string,
        value: OptionProps[] | RangeValueProps
    ) => {
        setForm((prev) => {
            const nextState : Dynamic = { ...prev, [name]: value };

            for (const attribute in attributes) {
                if (attribute) {
                    if (!nextState[attribute]) {
                        nextState[attribute] = null;
                    }
                }
            }
            return nextState as AudienceForm;
        });
    }

    const handleShowMore = () => {
        setShowMore((prev) => !prev);
    }

    const addLocation  = () => {
        const locations = [...locationList];
        locations.push({country: undefined, state: undefined, uniqueKey: Math.random() * 100000});
        setLocationList(locations);
    }

    const removeLocation = (index:number) => {
        const locations = [...locationList];
        locations.splice(index, 1);
        setLocationList(locations)
    }

    const updateLocationField = (index: number, name: string, selectedId: number | string, value: any) => {
        const locations = [...locationList];

        const nameMap = {
            country: 'country_id',
            state: 'state_id',
            city: 'city_id',
        }

        switch (name) {
            case 'country':
                locations[index].country = value;
                break;
            case 'state':
                locations[index].state = value;
        }

        // @ts-ignore
        locations[index][nameMap[name]] = selectedId;

        // double check each location has the correct value set for the api


        for (const locationKey in nameMap) {
            if (locationKey) {
                locations.forEach((location) => {
                    // @ts-ignore
                    if (!location[nameMap[locationKey]] && location[locationKey] && location[locationKey].id) {
                        // @ts-ignore
                        location[nameMap[locationKey]] = location[locationKey].id
                    }
                })
            }
        }

        setLocationList([...locations])
    }

    const renderLocationsList = () => {
        return (
            locationList.map((location: any, index: number) => {
                return <LocationGroupForm disabledInputs={disabledInputs}
                            key={locationList[index].uniqueKey || Math.random() * 10000}
                            initialValue={form.locations ? form.locations[index] : locationList[index] ? locationList[index] : null}
                            index={index}
                            data={form}
                            removeLocation={removeLocation}
                            currentPlan={currentPlan}
                            updateLocationField={updateLocationField}
                        />
            })
        )
    }

    const followerOptions = [
        {name: 'Any followers', id: '0'},
        {name: '1K+ followers', id: '1000'},
        {name: '2K+ followers', id: '2000'},
        {name: '3K+ followers', id: '3000'},
        {name: '4K+ followers', id: '4000'},
        {name: '5K+ followers', id: '5000'},
        {name: '6K+ followers', id: '6000'},
        {name: '7K+ followers', id: '7000'},
        {name: '8K+ followers', id: '8000'},
        {name: '9K+ followers', id: '9000'},
        {name: '10K+ followers', id: '10000'},
        {name: '15K+ followers', id: '15000'},
        {name: '20K+ followers', id: '20000'},
    ];

    const handleFollowersChange = (name: string) => {
        const option = followerOptions.find(value => value.name == name);

        handleChange('followers', [parseInt(option?.id || '1000'), 2147483647])
    }

    if (form.followers) {
        if (form.followers[1] === 2147483647) {
            form.followers[1] = 100000
        }    
    }
    if (form.age[1] === 2147483647) {
        form.age[1] = 100
    }

    const attributesToOption = (attributes: GiftAttribute[]): OptionProps[]|undefined => {
        if (!attributes) {
            return undefined;
        }

        return attributes.map((value: GiftAttribute) => {
            return {
                id: value.id,
                name:  value.producer_label
            }
        })
    };

    const createAttributeField = (key: string, value: GiftAttribute[]) => {
        return (
            <FormMultiSelect
                key={key}
                name={key}
                label={Mapping.Attributes[key].name}
                placeholder={Mapping.Attributes[key].placeholder}
                options={attributesToOption(value) || []}
                selected={form[key] || []}
                disabled={disabledInputs || (!currentPlan && !isEvent)}
                tooltip={(!currentPlan && !isEvent) ? 'Upgrade to Grow or Pro plans to use audience targeting' : undefined}
                onChange={handleChange}
            />
        );
    }

    const selectedAttributes = useMemo(() => {
        return Object
            .entries(attributes).filter(([key]) => (key !== 'gender'))
            .filter(([key]) => {
                return form[key] && form[key].length > 0;
            });
    }, [attributes]);


    const unselectedAttributes = useMemo(() => {
        return Object
            .entries(attributes).filter(([key]) => (key !== 'gender'))
            .filter(([key]) => !form[key])
    }, [attributes]);

    const onNext = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setTriedToSubmit(true);
        if (disabledSubmit) {
            return;
        }

        handlePrimaryActionClick(e);
    }

    return (
        <>
            <LeftContent ref={rightContent}>
                {children}

                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <FormGroupLabel required={true}>
                            Which hashtags are most relevant to your gift?
                        </FormGroupLabel>

                        <FormMultiSelect
                            name={'tags'}
                            label={''}
                            options={tags}
                            selected={form.tags}
                            disabled={disabledInputs}
                            onChange={handleChange}
                            error={formErrors.creatorTypes}
                            limit={(item && item.max_tags) || 3}
                        />

                        {(item && item.min_tags && item.max_tags) && (
                            <TagsLabel>
                                Choose up to {item.max_tags} hashtags
                            </TagsLabel>
                        )}
                    </FormGroup>

                    <FollowerAndGenderGroup>
                        <FormGroup>
                            <FormGroupLabel required={true}>
                                Followers
                            </FormGroupLabel>

                            <FormSelectBasic
                                options={followerOptions}
                                placeholder={'Select followers'}
                                disabled={disabledInputs}
                                selected={
                                    form.followers && form.followers.length ? {
                                        // @ts-ignore
                                        name: (followerOptions.find(value => value.id == form.followers[0].toString())?.name|| '1000'),
                                        id: (`${form.followers[0]}` || '1000')
                                    }
                                    : {name: '', id: ''}
                                }
                                onChange={(name: string) => handleFollowersChange(name)}
                            />

                            {formErrors.followers && (
                                <Error>{formErrors.followers}</Error>
                            )}

                            <LinkDescription>
                                <a href="https://www.hashgifted.com/how-it-works/what-is-a-fair-gifting-exchange-as-a-brand" target="_blank" rel="noreferrer">
                                    What is a fair exchange?
                                </a>
                            </LinkDescription>

                        </FormGroup>

                        <GenderFormGroup>
                            <FormGroupLabel required={true}>
                                Gender
                            </FormGroupLabel>
                            <FullOnMobile>
                                <FormMultiSelect
                                    required={true}
                                    name={'gender'}
                                    label={''}
                                    error={formErrors.gender}
                                    placeholder={'Select gender'}
                                    options={attributesToOption(attributes.gender) || []}
                                    selected={form.gender || []}
                                    onChange={handleChange}
                                />
                            </FullOnMobile>
                        </GenderFormGroup>
                    </FollowerAndGenderGroup>

                    <FormGroup>
                        <FormGroupLabel>
                            Open to creators from{locationList.length ? '' : ' all locations'}
                        </FormGroupLabel>

                        {renderLocationsList()}

                        <LocationRow data-tip={(!currentPlan && !isEvent) ? 'Upgrade to Grow or Pro plans to use audience targeting' : undefined}>
                            {(!currentPlan && !isEvent) && (
                                <ReactTooltip />
                            )}

                            <ActionBtn
                                className={`${disabledInputs || (!currentPlan && !isEvent) ? 'disable' : ''}`}
                                onClick={() => (disabledInputs || (!currentPlan && !isEvent)) ? null : addLocation()}
                            >
                                Add new location
                            </ActionBtn>
                        </LocationRow>

                        <FormGroupLabel>
                            Other attributes
                        </FormGroupLabel>

                            <FormGroup style={{flexDirection: 'row'}}>
                                <div style={{width: '240px'}}>

                                    <FormMultiSlider
                                        name={'age'}
                                        label={'Age range'}
                                        min={ageRange[0]}
                                        max={ageRange[1]}
                                        value={form.age || ageRange}
                                        disabled={disabledInputs || (!currentPlan && !isEvent)}
                                        tooltip={(!currentPlan && !isEvent) ? 'Upgrade to Grow or Pro plans to use audience targeting' : undefined}
                                        onChange={handleChange}
                                    >
                                        <AgeRangeLabel>
                                            Between&nbsp;
                                            {(form.age || ageRange)[0]}yr and&nbsp;
                                            {((form.age || ageRange)[1]) === (ageRange[1] || 2147483647) ? 'unlimited' : form.age[1]} years old
                                        </AgeRangeLabel>
                                    </FormMultiSlider>
                                </div>

                                <Spacer></Spacer>

                            </FormGroup>


                        <MoreAttributes $show={true}>
                            {selectedAttributes
                                .map(([key, value]) => createAttributeField(key, value))
                            }
                        </MoreAttributes>

                        <MoreAttributes $show={showMore}>
                            {unselectedAttributes
                                .map(([key, value]) => createAttributeField(key, value))
                            }
                        </MoreAttributes>
                    </FormGroup>

                    {!showMore && (
                        <ShowMore onClick={handleShowMore} theme={'outline'} size={'small'}>
                            Show more attributes
                        </ShowMore>
                    )}

                    {(!noBottomButtons) && (
                        <FormActions>
                            <StyledButton
                                size={'medium'}
                                loading={loading &&
                                    status === 'set-gift-audience'}
                                onClick={(e) => onNext(e)}
                            >
                                {primaryAction.name}
                            </StyledButton>

                            <StyledButton
                                theme={'outline'}
                                size={'medium'}
                                loading={loading &&
                                    status === 'saving-draft'}
                                onClick={handleSecondaryActionClick}
                            >
                                {secondaryAction.name}
                            </StyledButton>
                        </FormActions>
                    )}
                </Form>
            </LeftContent>

            <RightContent>
                <EmptyPlaceholder image={'girl-2'} />
            </RightContent>
        </>
    );
}
