import {
    FormEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useState
} from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import {
    Button,
    Link,
    FormInput,
    Checkbox
} from 'app/components';
import { EmailIcon, LockIcon } from 'app/icons';
import { isEmail } from 'commons-validator-es';
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from 'store';
import AnalyticsEvents from "services/analyticsEvents";
import {ClientSettings} from "../../../services/settingsService";
import { API } from 'api';
import errorReporter from 'services/errorReporter';


interface Props {
    errors?: Dynamic;
    loading?: boolean;
    onSubmit(data: SignUpForm): void;
}


export function SignUpForm({
    errors,
    loading,
    children,
    onSubmit
}: PropsWithChildren<Props>) {

    const [searchParams,] = useSearchParams();

    const [form, setForm] = useState<SignUpForm>({
        email: '',
        password: '',
        passwordConfirm: '',
        agreeToTerms: true
    });

    const [formError, setFormError] = useState('')

    const [showSignupFields, setShowSignupFields] = useState(false);

    const [showCreatorButtons, setShowCreatorButtons] = useState(false);

    const {
        global: { settings }
    } = useAppSelector((state) => state);

    const disabledSubmit = useMemo(() => {
        if (!form.agreeToTerms) {
            return true;
        }

        return Helpers.isEmptyObject(
            Helpers.removeObjectKeys(form, ['agreeToTerms']),
            true
        );
    }, [form]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formError) {
            setFormError('')
        }

        if (form.password !== form.passwordConfirm) {
            setFormError('Passwords do not match')
            return
        }

        if (isEmail(form.email)) {


            // lets do our email validation check here.

            API.Profile.checkEmailValidity(form.email).then((data) => {
                console.log(data)
                if (!data) {
                    setFormError('Email is invalid')
                    return            
                }
                onSubmit(form);
            }).catch((e) => {
                setFormError('Email is invalid')
                return        
            })
            


        } else {
            setFormError('Email is invalid')
            return
        }
    }

    const handleChange = (
        name: string,
        value: string | boolean
    ) => {

        if (name === 'email') {
            //@ts-ignore
            value = value.toLowerCase()
        }

        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    let loginPath = '/login';
    if (searchParams.get('redirect')) {

        const possibleParams = `${searchParams}`.split('&')

        loginPath += `?redirect=${searchParams.get('redirect')}`
        possibleParams.forEach((param, index) => {
            if (index !== 0) {
                loginPath += `&${param}`
            }
        })

    }

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);

    useEffect(() => {
        const isFirstAppOpen = localStorage.getItem('firstAppOpen');

        if (!isFirstAppOpen) {
            analyticsEvents.firstWebAppOpen();
            localStorage.setItem('firstAppOpen', 'true');
          }

        analyticsEvents.preOnboardingVisited();
    }, [])

    return (
        <Container>
            {children}

            {!showSignupFields && !showCreatorButtons ? (
                <ButtonRow>
                    <StyledButton onClick={() => {
                        setShowSignupFields(true)
                        setShowCreatorButtons(false)
                    }}>I am a brand/agency</StyledButton>
                    <StyledButton onClick={() => setShowCreatorButtons(true)} theme="outline">I am a creator/influencer</StyledButton>

                </ButtonRow>
            ) : null}

            {!showSignupFields && showCreatorButtons ? (
                <>
                    <h1>Download #gifted on Apple iOS or Android to continue</h1>
                    <ButtonRow>
                        <StyledButton onClick={() => window.location.href = 'https://apps.apple.com/us/app/gifted/id1589733314'}>Download for Apple iOS</StyledButton>
                        <StyledButton onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.hashgifted.gifted'}>Download for Android</StyledButton>
                    </ButtonRow>
                </>
            ) : null}

            {showSignupFields ? (
                <Form onSubmit={handleSubmit}>
                    <FormInput
                        type={'text'}
                        name={'email'}
                        label={'Email'}
                        placeholder={'Enter your email'}
                        value={form.email}
                        error={errors?.email}
                        required={true}
                        onChange={handleChange}
                    >
                        <EmailIcon />
                    </FormInput>

                    {formError ? <Error>{formError}</Error> : null}

                    <FormInput
                        type={'password'}
                        name={'password'}
                        label={'Password'}
                        placeholder={'Enter strong password'}
                        value={form.password}
                        error={errors?.password}
                        required={true}
                        onChange={handleChange}
                    >
                        <LockIcon />
                    </FormInput>

                    <FormInput
                        type={'password'}
                        name={'passwordConfirm'}
                        label={'Confirm Password'}
                        placeholder={'Enter strong password'}
                        value={form.passwordConfirm}
                        error={errors?.password}
                        required={true}
                        onChange={handleChange}
                    >
                        <LockIcon />
                    </FormInput>

                    <p>
                        <Checkbox
                            checked={form.agreeToTerms}
                            onChange={(checked) =>
                                handleChange('agreeToTerms', !!checked)}
                        >
                            I agree to
                        </Checkbox>
                        &nbsp;
                        <ExternalLink onClick={() =>
                            window.open('https://www.hashgifted.com/terms')}>
                            Terms &amp; Conditions
                        </ExternalLink>
                    </p>


                    <StyledButton
                        loading={loading}
                        disabled={disabledSubmit}
                    >
                        Sign up
                    </StyledButton>
                </Form>
            ) : null}

            <p>
                <span>Already have an account? </span>

                <Link to={loginPath}>Log in</Link>
            </p>
        </Container>
    );
}

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const ExternalLink = styled.span`
    text-decoration: underline;
    text-underline-offset: 2px;
    text-underline-position: under;
    color: var(--aqua);
    cursor: pointer;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    h1 {
        text-align: center;
        color: var(--blue);
    }
`;

const Error = styled.p`
    color: #ff647c;
    text-align: left !important;
    display: block !important;
    margin-top: none !important;
`

const ButtonRow = styled.div`
    display: flex;
    column-gap: 16px;
    width: 100%;
    justify-content: center;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`;
