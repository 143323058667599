import { batch } from 'react-redux';

import { API } from 'api';
import { Converter, Helpers, Mapping } from 'utils';
import { Constants } from '../constants';
import { appActions } from './appActions';
import {Crop} from "react-image-crop";
import settingsService, {ClientSettings} from "../../services/settingsService";
import errorReporter from "../../services/errorReporter";
import {Axios} from "axios";

let timeoutHandler: NodeJS.Timeout;

const _setStatus = (status: string) => ({
    type: Constants.Gift.Status,
    payload: status
});

const _setLoading = (loading: boolean) => ({
    type: Constants.Gift.Loading,
    payload: loading
});

const _setLoadingShopifyItems = (loading: boolean) => ({
    type: Constants.Gift.LoadingShopifyItems,
    payload: loading
});

const _setShopifyIsDisconnected = (disconnected: boolean) => ({
    type: Constants.Gift.ShopifyIsDisconnected,
    payload: disconnected
});

const clearState = () => {
    return (dispatch: AppDispatch) => {
        dispatch({ type: Constants.Gift.Clear, payload: null });
    }
}

const createGiftType = (
    form: GiftTypeForm,
    options?: GiftFlowActionOptions
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);
        batch(() => {
            dispatch(_setLoading(true));

            if (options?.draft) {
                dispatch(_setStatus('saving-draft'));
            } else {
                dispatch(_setStatus('create-gift-type'));
            }
        });

        const {
            auth: { user },
        } = getState();

        try {

            if (user && user.brand_id) {
                const result = await API.Gift.createGiftType(
                    user.brand_id,
                    form
                );

                batch(() => {
                    dispatch({
                        type: Constants.Gift.Item,
                        payload: result
                    });

                    dispatch(_setLoading(false));
                });

                if (options?.callback) {
                    options.callback();
                }
            }
        } catch (err: any) {

            batch(() => {
                if (err.response.status == 402) {
                    const response = err.response.data as RequiresPaymentResponse;
                    appActions.setShowPaymentDialog(true, response.code)(dispatch)
                    dispatch(_setStatus('idle'));
                    dispatch(_setLoading(false));
                } else {
                    appActions.setAlert(Helpers.createRequestError('Create gift', err))(dispatch);

                    dispatch(_setStatus('error'));
                    dispatch(_setLoading(false));
                }

            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const deleteGiftCreativeMedia = (
    giftId: string,
    mediaId: string,
    callback?: (media: Media[]) => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('delete-gift-creative-media'));
        });

        try {
            const {
                auth: { user },
                gift: { item }
            } = getState();

            if (user && user.brand_id) {
                const media = item?.media.find(({ uid }) => uid === mediaId);

                if (media && media.type !== 'SHOPIFY') {
                    await API.Gift.deleteGiftCreativeMedia(
                        user.brand_id,
                        giftId,
                        mediaId
                    );
                }

                batch(() => {
                    const payload = {
                        ...item,
                        media: item?.media.filter((media) =>
                            media.uid !== mediaId)
                    }

                    dispatch({
                        type: Constants.Gift.Item,
                        payload: payload
                    });

                    if (callback && payload.media) {
                        callback(payload.media);
                    }

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });
            } else {
                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const duplicateGift = (
    id: string,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        dispatch(_setStatus('duplicate-gift'));

        try {
            const { auth: { user } } = getState();

            if (user && user.brand_id) {
                const result = await API.Gift.duplicateGift(user.brand_id, id);

                setTimeout(() => {
                    batch(() => {
                        dispatch({
                            type: Constants.Gift.Flow,
                            payload: { step: 'creative' }
                        });

                        dispatch({
                            type: Constants.Gift.Item,
                            payload: ({
                                ...result,
                                attributes: Converter.toAppGiftAttributes(result.attributes)
                            })
                        });

                        dispatch(_setStatus('idle'));
                    });
                }, 100);

                if (callback) {
                    callback();
                }
            }
        } catch (err) {
            dispatch(_setStatus('error'));
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const getByType = (type: string, noHardReload = false) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        if (!noHardReload) {
            dispatch(_setLoading(true));
        }

        try {
            const { auth: { user } } = getState();

            if (user && user.brand_id) {
                const result = await API.Gift.getByType(user.brand_id, type);

                batch(() => {
                    dispatch({
                        type: Constants.Gift.List,
                        payload: result
                    });

                    if (!noHardReload) {
                        dispatch(_setLoading(false));
                    }
                });
            } else {
                throw new Error('No user set.');
            }
        } catch(err: any) {
            batch(() => {
                if (err.message !== 'No user set.') {
                    dispatch(_setStatus('error'));
                }
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const getGift = (id: string, withLoadingState = true) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        withLoadingState && dispatch(_setLoading(true));

        try {
            const { auth: { user } } = getState();

            if (user && user.brand_id) {
                const result = await API.Gift.getGift(user.brand_id, id);

                batch(() => {
                    dispatch({
                        type: Constants.Gift.Item,
                        payload: ({
                            ...result,
                            attributes: Converter.toAppGiftAttributes(result.attributes),
                        })
                    });

                    withLoadingState && dispatch(_setLoading(false));
                });
            } else {
                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setLoading(false));
                dispatch(_setStatus('error'));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const getShopifyProducts = (
    giftId: string,
    options?: ShopifyOptions
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        dispatch(_setLoadingShopifyItems(true));

        try {
            const { auth: { user } } = getState();

            if (user && user.brand_id) {
                const { listing } = await API.Shopify.getProducts(
                    user.brand_id,
                    giftId,
                    options
                );

                batch(() => {
                    dispatch({
                        type: Constants.Gift.ShopifyItems,
                        payload: listing
                    });

                    dispatch(_setLoadingShopifyItems(false));
                });
            } else {
                throw new Error('An error occurred.');
            }
        } catch (err: any) {
            batch(() => {
                if (err.response.status === 424) {
                    dispatch({
                        type: Constants.Gift.ShopifyItems,
                        payload: []
                    });
                    dispatch(_setShopifyIsDisconnected(true));
                } else {
                    dispatch({
                        type: Constants.Gift.ShopifyItems,
                        payload: []
                    });
                }
                dispatch(_setLoadingShopifyItems(false));

            });
        }
    }
}

const setFlowStep = (step: GiftFlowStep) => {
    return (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {

        const { gift: { flow } } = getState();

        dispatch({
            type: Constants.Gift.Flow,
            payload: { ...flow, step }
        });

    }
}

const setGiftAudience = (
    id: string,
    form: AudienceForm,
    options?: GiftFlowActionOptions
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));

            if (options?.draft) {
                dispatch(_setStatus('saving-draft'));
            } else {
                dispatch(_setStatus('set-gift-audience'));
            }
        });

        try {
            const {
                auth: { user },
                gift: { item }
            } = getState();

            if (user && user.brand_id) {
                const filteredData = Helpers.removeObjectKeys(
                    form,
                    ['country', 'state', 'city', 'followers', 'age']
                );
                const data = {
                    ...Converter.toApiGiftAttributes(filteredData),
                    locations: form.locations,
                    min_followers: form.followers && form.followers[0],
                    max_followers: form.followers && form.followers[1],
                    min_age: form.age[0],
                    max_age: form.age[1],
                } as any as AudienceForm;

                await API.Gift.setGiftAudience(
                    user.brand_id,
                    id,
                    data
                );

                batch(() => {
                    dispatch({
                        type: Constants.Gift.Item,
                        payload: {
                            ...item,
                            attributes: Helpers.removeObjectKeys(filteredData, ['tags']),
                            locations: (form.locations && form.locations?.length) ? form.locations : [],
                            min_followers: form.followers && form.followers[0],
                            max_followers: form.followers && form.followers[1],
                            min_age: form.age[0],
                            max_age: form.age[1],
                            tags: form.tags
                        }
                    });

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });

                if (options?.callback) {
                    options.callback();
                }
            } else {

                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

///
const setGiftCreative = (
    id: string,
    form: CreativeForm,
    options?: GiftFlowActionOptions
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));

            if (options?.draft) {
                dispatch(_setStatus('saving-draft'));
            } else {
                dispatch(_setStatus('set-gift-creative'));
            }
        });

        try {
            const {
                auth: { user },
                gift: { item }
            } = getState();

            if (user && user.brand_id) {
                if (item?.gift_type === 'SHOPIFY') {
                    await API.Gift.setGiftShopify(user.brand_id, id, {
                        name: form.name,
                        description: form.description,
                        value: form.value || 0,
                    });
                }

                await API.Gift.setGiftCreative(
                    user.brand_id,
                    id,
                    form
                ).then((res: any) => {


                    // On success returns a gift
                    if (res?.uid) {
                        if (item && res.slug) {
                            //@ts-ignore
                            if (item && !item.slug) {
                                item.slug = res.slug;
                            }                        
                        }

                        batch(() => {
                            dispatch({
                                type: Constants.Global.InputError,
                                payload: {}
                            })
                            dispatch({
                                type: Constants.Gift.Item,
                                payload: { ...item, ...form }
                            });
                            dispatch(_setStatus('success'));
                            dispatch(_setLoading(false));
                        });

                        if (options?.callback) {
                            options.callback();
                        }
                    } else if (res.data.errors) {
                        dispatch({
                            type: Constants.Global.InputError,
                            payload: res.data.errors
                        })
                        throw new Error('An error occured.')
                    }
                })

            } else {
                throw new Error('An error occurred.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setGiftCreativeMedia = (
    id: string,
    file: Blob,
    crop: Crop,
    settings: ClientSettings,
    callback?: any,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-gift-creative-media'));
        });

        try {
            const { auth: { user }, gift: { item } } = getState();
            if (user && user.brand_id && item) {
                const position = (item.media?.length || 0) + 1;

                const result = await API.Gift.setGiftCreativeMedia(
                    user.brand_id,
                    id,
                    position,
                    file,
                    crop,
                    settingsService.baseApiUrl
                ) as UploadMedia;
                // const galleryPath = Helpers.isVideo(file.type, settings) ?
                //     await Helpers.getVideoSnapshot(file) : result.media_thumbnail;

                const galleryPath = result.media_thumbnail;

                batch(() => {

                    result.crop.x = result.crop.x / 100
                    result.crop.y = result.crop.y / 100
                    result.crop.height = result.crop.height / 100
                    result.crop.width = result.crop.width / 100

                    const payload = {
                        ...item,
                        media: [
                            ...(item?.media || []),
                            {
                                ...result,
                                metadata: {crop: result.crop},
                                gallery_path: result.media,
                                thumbnail_path: result.media_thumbnail || galleryPath
                            }
                        ]
                    }

                    dispatch({
                        type: Constants.Gift.Item,
                        payload: payload
                    });
                    if (callback) {
                        callback(payload.media)
                    }
                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });
            } else {
                throw new Error('An error occured uploading media.');
            }
        } catch (err) {

            errorReporter.report('Failed to upload media ' + file.name + ' of type ' + file.type, err);

            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));

                appActions.setAlert(Helpers.createRequestError('Uploading gift media', err))(dispatch);
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}


const setGiftCreativeMediaAndThumbnail = (
    id: string,
    file: Blob,
    thumbnail: Blob,
    crop: Crop,
    settings: ClientSettings,
    callback?: any,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-gift-creative-media'));
        });

        try {
            const { auth: { user }, gift: { item } } = getState();
            if (user && user.brand_id && item) {
                const position = (item.media?.length || 0) + 1;
                const response = await API.Gift.startGiftMediaUpload(user.brand_id, item.uid, file.type, 'image/webp');
                await fetch(
                    response.original_upload as URL | RequestInfo, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': file.type
                        }
                    }
                )
                await fetch(
                    response.thumb_upload as URL | RequestInfo, {
                        method: 'PUT',
                        body: thumbnail,
                        headers: {
                            'Content-Type': 'image/webp'
                        }
                    }
                )

                const media = await API.Gift.completeGiftMediaUpload(
                    user.brand_id,
                    item.uid,
                    response.original_path,
                    response.thumb_path,
                    crop,
                    position
                );

                batch(() => {
                    const payload = {
                        ...item,
                        media: [
                            ...(item?.media || []),
                            media,
                        ]
                    }

                    dispatch({
                        type: Constants.Gift.Item,
                        payload: payload
                    });
                    if (callback) {
                        callback(payload.media)
                    }
                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });
            } else {
                throw new Error('An error occurred uploading media.');
            }
        } catch (err) {

            errorReporter.report('Failed to upload media ' + file.name + ' of type ' + file.type, err);

            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));

                appActions.setAlert(Helpers.createRequestError('Uploading gift media', err))(dispatch);
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setGiftCreativeMediaOrder = (
    brandId: string,
    giftId: string,
    mediaIds: Array<string>
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);
        const data = new FormData();
        data.append('mediaIds', mediaIds.join(','));

        try {
            const { auth: { user } } = getState();
            if (user && user.brand_id) {

                await API.Gift.setGiftCreativeMediaOrder(
                    brandId,
                    giftId,
                    mediaIds,
                );

                // dispatch(_setStatus('success'));
                // dispatch(_setLoading(false));
            } else {
                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        // timeoutHandler = setTimeout(() => {
        //     dispatch(_setStatus('idle'));
        // }, settingsService.alertTimeout);
    }
}




const setGiftCreativeMediaCrop = (
    brandId: string,
    giftId: string,
    mediaId: string,
    crop: Crop | undefined
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        dispatch(_setLoading(true));

        try {
            const { auth: { user }, gift: { item } } = getState();

            if (user && user.brand_id && item) {

                const result = await API.Gift.setGiftCreativeMediaCrop(
                    brandId,
                    giftId,
                    mediaId,
                    crop
                );

                const tempItem = {...item}
                tempItem?.media.forEach((mediaItem, i) => {
                    if (mediaItem.uid === mediaId) {
                        //@ts-ignore
                        mediaItem = result
                        tempItem.media.splice(i, 1)
                        tempItem.media.splice(i, 0, mediaItem)
                    }
                })

                const payload = {
                    ...tempItem,
                    media: [
                        ...tempItem.media,
                    ]
                }

                dispatch({
                    type: Constants.Gift.Item,
                    payload: payload
                });


                dispatch(_setStatus('success'));
                dispatch(_setLoading(false));
            } else {
                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}






const setGiftCreativeShopify = (
    giftId: string,
    selections: ShopifySelection[]
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-gift-creative-shopify'));
        });

        try {
            const {
                auth: { user },
                gift: { flow, item }
            } = getState();

            if (user && user.brand_id) {
                const result = await API.Gift.getShopifySelectionDetails(
                    user.brand_id,
                    giftId,
                    selections,
                );

                let title;
                let description;
                let price = 0.0;
                if (result.length > 1) {
                    title = "";
                    description = 'This gift includes:\n';
                    for (const item of result) {
                        description += '- ' + item.title + '\n';
                        price += item.price.amount;
                    }
                } else {
                    const item = result[0];
                    title = item.title;
                    description = item.description;
                    price = item.price.amount;
                }

                const form = {
                    name: title,
                    description: description,
                    value: Math.round(price * 100) / 100,
                };

                batch(() => {
                    dispatch({
                        type: Constants.Gift.Item,
                        payload: { ...item, ...form }
                    });

                    dispatch({
                        type: Constants.Gift.Flow,
                        payload: { ...flow, step: 'creative' }
                    });

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });
            } else {
                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setGiftDeliverables = (
    id: string,
    form: DeliverablesForm,
    options?: GiftFlowActionOptions
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));

            if (options?.draft) {
                dispatch(_setStatus('saving-draft'));
            } else {
                dispatch(_setStatus('set-gift-deliverables'));
            }
        });

        try {
            const {
                auth: { user },
                gift: { item }
            } = getState();

            if (user && user.brand_id) {
                await API.Gift.setGiftDeliverables(
                    user.brand_id,
                    id,
                    {
                        deliverables: form
                            .deliverables
                            .map(({ id }) => id)
                    }
                );

                batch(() => {
                    dispatch({
                        type: Constants.Gift.Item,
                        payload: { ...item, ...form }
                    });

                    if (!options?.callback) {
                        dispatch(_setStatus('success'));
                        dispatch(_setLoading(false));
                    }
                });

                if (options?.callback) {
                    options.callback();
                }
            } else {
                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setGiftStatus = (
    id: string,
    status: string,
    callback?: (status: string) => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);
        dispatch(_setLoading(true));

        dispatch(_setStatus(`set-gift-status-${status.toLowerCase()}`));

        const {
            auth: { user },
            gift: { item },
        } = getState();

        try {
            if (user && user.brand_id && item) {

                // the status that the gift will transition to
                const transitionStatus = Mapping.GiftTransitionStatusMapping[status] as string;
                // the status that will be need to redirect the user
                // to the appropriate page
                const nextStatus = status === 'ACTIVE' ? 'ACTIVE' : Mapping.GiftNextStatusMapping[transitionStatus] as string;

                console.log('nextStatus', nextStatus, transitionStatus)
                await API.Gift.setGiftStatus(user.brand_id, id, transitionStatus);

                batch(() => {
                    dispatch({
                        type: Constants.Gift.Item,
                        payload: { ...item, status: nextStatus }
                    });

                    appActions.setAlert(null)(dispatch);
                    dispatch(_setStatus('success'));
                });

                if (callback) {
                    callback(nextStatus);
                    clearTimeout(timeoutHandler);
                    dispatch(_setLoading(false));
                }

                dispatch(_setStatus('success'));
                if (!callback) {
                    dispatch(_setLoading(false));
                }

                timeoutHandler = setTimeout(() => {
                    dispatch(_setStatus('idle'));
                    dispatch(_setLoading(false));
                }, settingsService.alertTimeout);
                appActions.setAlert(null)(dispatch);
                return
            } else {
                throw new Error('An error occured.');
            }
        } catch (err: any) {
            if (err.response.status == 402) {
                const response = err.response.data as RequiresPaymentResponse;
                appActions.setShowPaymentDialog(true, response.code)(dispatch)
                setGiftStatus(id,"DRAFT");
                dispatch(_setStatus('idle'));
                dispatch(_setLoading(false));
            }

            appActions.setAlert(Helpers.createRequestError('Setting gift status', err))(dispatch);

            dispatch(_setLoading(false));
            dispatch(_setStatus('error'));
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
            dispatch(_setLoading(false));
        }, settingsService.alertTimeout);
    }
}

const deleteGift = (
    id: string,
    callback?: () => void
) => {
    console.log('deleting???')
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);
        dispatch(_setLoading(true));
        dispatch(_setStatus('delete-gift'));
        console.log('whyyy')

        const {
            auth: { user },
            gift: { item },
        } = getState();

        try {
            if (user && user.brand_id && item) {

                await API.Gift.deleteGift(user.brand_id, id);

                batch(() => {
                    appActions.setAlert(null)(dispatch);
                    dispatch(_setStatus('success'));
                });

                if (callback) {
                    callback();
                    clearTimeout(timeoutHandler);
                    dispatch(_setLoading(false));
                }

                dispatch(_setStatus('success'));
                if (!callback) {
                    dispatch(_setLoading(false));
                }

                timeoutHandler = setTimeout(() => {
                    dispatch(_setStatus('idle'));
                }, settingsService.alertTimeout);
                appActions.setAlert(null)(dispatch);
                return
            } else {
                throw new Error('An error occured.');
            }
        } catch (err: any) {
            appActions.setAlert(Helpers.createRequestError('Deleting gift', err))(dispatch);
            dispatch(_setStatus('error'));
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setGiftType = (
    id: string,
    form: GiftTypeForm,
    options?: GiftFlowActionOptions
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));

            if (options?.draft) {
                dispatch(_setStatus('saving-draft'));
            } else {
                dispatch(_setStatus('set-gift-type'));
            }
        });

        try {
            const {
                auth: { user },
                gift: { item }
            } = getState();

            if (user && user.brand_id) {
                const data = form.gift_type === 'PRODUCT' ? form : {
                    gift_type: form.gift_type
                };
                await API.Gift.setGiftType(
                    user.brand_id,
                    id,
                    data
                );

                batch(() => {
                    dispatch({
                        type: Constants.Gift.Item,
                        payload: { ...item, ...form }
                    });

                    if (options?.callback) {
                        options.callback();
                        dispatch(_setLoading(false));
                    }

                    dispatch(_setStatus('success'));
                    if (!options?.callback) {
                        dispatch(_setLoading(false));
                    }
                });
            } else {
                throw new Error('An error occured.');
            }
        } catch (err) {
            batch(() => {
                //@ts-ignore
                appActions.setAlert(Helpers.createRequestError('Setting gift type', err))(dispatch);

                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

export const giftActions = {
    _setLoading,
    clearState,
    createGiftType,
    deleteGiftCreativeMedia,
    duplicateGift,
    getByType,
    getGift,
    deleteGift,
    getShopifyProducts,
    setFlowStep,
    setGiftAudience,
    setGiftCreative,
    setGiftCreativeMedia,
    setGiftCreativeMediaAndThumbnail,
    setGiftCreativeMediaOrder,
    setGiftCreativeMediaCrop,
    setGiftCreativeShopify,
    setGiftDeliverables,
    setGiftStatus,
    setGiftType
};
