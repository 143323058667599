import { Helpers } from './helpers';

/**
 * Converts the app specific format to API format.
 *
 * from
 * {
 *      ...otherAttributes,
 *      gender: [ { id: 1, name: 'Male' } ]
 * }
 *
 * to
 * {
 *      ...otherAttributes,
 *      gender: [1]
 * }
 *
 * @param obj the object that will be converted
 * @returns the new object
 */

const toApiGiftAttributes = (obj: Dynamic) => {
    return Object
        .entries(obj)
        .reduce<Dynamic>((acc, [key, value]) => {
            if (Array.isArray(value)) {
                acc[key] = value.map(({ id }) => id as object);
            } else {
                acc[key] = value ? value.id : null;
            }

            return acc;
        }, {});
}

/**
 * Converts the app specific format to API format.
 *
 * from
 * {
 *      ...otherLocations,
 *      gender: [ { id: 1, name: 'Male' } ]
 * }
 *
 * to
 * {
 *      ...otherLocations,
 *      gender: [1]
 * }
 *
 * @param obj the object that will be converted
 * @returns the new object
 */
const toApiGiftLocation = (obj: Generic<IdName> | null) => {
    if (obj) {
        return Object
            .entries(obj)
            .reduce<Generic<number>>((acc, [key, value]) => {
                if (
                    !key.includes('_id') &&
                    !Helpers.isEmpty(value)
                ) {
                    acc[`${key}_id`] = Number(value.id);
                }

                return acc;
            }, {});
    }

    return {};
}

/**
 * Converts the API response from gift specific endpoints
 * to the specific app format.
 *
 *  from
 * {
 *      ...otherAttributes,
 *      gender: { id: 1, name: 'Male' },
 *      gender: { id: 2, name: 'Female' },
 * }
 *
 * to
 * {
 *      ...otherAttributes,
 *      gender: [
 *          { id: 1, name: 'Male' },
 *          { id: 2, name: 'Female' },
 *      ]
 * }
 *
 * @param attr the attributes of the gift
 * @returns the new gift object attributes
 */
const toAppGiftAttributes = (attr: Dynamic[]) => {
    return attr
}

const toAppLocationObject = (location: Generic<IdName> | null) => {
    if (location) {
        return Object
            .entries(location)
            .reduce<Generic<IdName>>((acc, [key, value]) => {
                if (
                    !key.includes('_id') &&
                    !Helpers.isEmpty(value)
                ) {
                    acc[key] = {
                        id: value.id,
                        name: value.name
                    };
                }

                return acc;
            }, {});
    }

    return location;
}

/**
 * Converts the API response from some specific endpoints
 * to the specific app format.
 *
 * @param obj the object that will be converted
 * @returns the array of objects
 */
const toIdName = (obj: Dynamic) => {
    return Object
        .entries(obj)
        .map(([key, value]) => ({
            id: Number(key) || key,
            name: value
        }));
}

export const Converter = {
    toApiGiftAttributes,
    toApiGiftLocation,
    toAppGiftAttributes,
    toAppLocationObject,
    toIdName
}
