import styled from 'styled-components';
import {CheckIcon} from "../../icons";

export const CheckStyled = styled.span<{
    checked?: boolean;
}>`
    width: 28px;
    height: 28px;
    transition: border 150ms ease;
    border-radius: 6px;
    padding-top: 3px;
    padding-left: 2px;

    ${(props) => props.checked && `
        background-color: var(--grey-9);
    `}
    
    ${(props) => !props.checked && `
        border: 1px solid var(--grey-6);
    `}
    
    svg {
        height: 22px;
    }
    
    svg path {
        fill: #fff;
    }
`;

interface Props {
    checked?: boolean;
}

export const FormCheck = ({ checked }: Props) => {
    return (
        <CheckStyled checked={checked}>
            {checked && (<CheckIcon />)}
        </CheckStyled>
    );
};
