import {ReactNode, useMemo, useRef, useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';

import {Hooks} from 'utils';
import {CloseIcon} from 'app/icons';
import {Button} from '../Button';
import {FormTextarea} from '../FormTextarea';
import { API } from 'api';
import { FormSelectBasic } from '../FormSelectBasic';
import {Option} from "../FormSelect";
import errorReporter from 'services/errorReporter';
import ReactConfetti from 'react-confetti';
import subscriptionService from "../../../services/subscriptionService";
import { EmptyPlaceholder } from '../EmptyPlaceholder';

interface Props {
    brand?: BrandProfile;
    show?: boolean;
    loading?: boolean;
    subtext: string;
    endDate: string;
    onClose(): void;
    currentPlan?: string;
    onPause?(): void;
    onDowngrade?(): void;
    onSubmit(form: any): void;
}

const initialForm = {
    message: '',
    reason: {id: 0, name: ''},
};

enum PageType {
    ConfirmPage = 'confirmPage',
    FormPage = 'formPage',
    DiscountPage = 'discountPage',
    CompletePage = 'completePage',
    CouponRedeemedPage = 'couponRedeemedPage',
  }  

export function CancelModal({
    brand,
    show,
    endDate,
    loading,
    onClose,
    onPause,
    onDowngrade,
    currentPlan,
    onSubmit
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);

    const [form, setForm] = useState(initialForm);
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [currentPage, setCurrentPage] = useState<PageType>(PageType.ConfirmPage);
    const [showConfetti, setShowConfetti] = useState(false);
    const [reasonError, setReasonError] = useState('');
    const [formError, setFormError] = useState('');

    useEffect(() => {
        setCurrentPage(PageType.ConfirmPage);
    }, []);

    useEffect(() => {

        const getOptions = () => {
            API.Shared.getCancellationReasons()
            .then((res) => {
              const opts: Option[] = [];
              res.forEach((reason: WaveReport) => {
                opts.push({
                  id: reason.id,
                  name: reason.reason,
                });
              });
              setOptions(opts);
              handleChange('reason', opts[0]);
            })
            .catch((error) => {
              throw error;
            });
        }

        getOptions();
    }, [])

    const disabledSubmit = useMemo(() => {
        return !form.reason
    }, [form]);

    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    const handleSubmit = () => {

        if (form.reason.name === 'Other' && !form.message) {
            setReasonError('Please tell us why you are canceling');
            return
        } 

        setReasonError('');
        onSubmit(form);
        setCurrentPage(PageType.CompletePage);    

    }      

    const handleChange = (
        name: string,
        value: any
    ) => {
        if (!name) {
            return
        }

        if (value && name === 'reason') {
            setSelectedOption(value as Option)
        }

        setForm((prev) => ({
            ...prev,
            [name]: value,
          }));
    }

    const handleExitComplete = () => {
        setForm(initialForm);
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    const handleRedeemOffer = () => {
        if (brand) {
            API.Profile.applyCancelCouponToSubscription(brand).then(() => {
                setCurrentPage(PageType.CouponRedeemedPage);
                setShowConfetti(true);
            }).catch((error) => {
                errorReporter.report('could not apply cancel coupon to subscription', error);
            });
        }
    }

    const handleClose = () => {
        onClose();
    }

    type Page = {
        logo: string;
        title: string;
        subtitle?: string;
        body: () => JSX.Element;
        primaryBtnText?: string;
        secondaryBtnText?: string;
        primaryBtnOnClick: () => void;
        secondaryBtnOnClick: () => void;
        btnReverse?: boolean;
    };      

    type CancelModalPages = {
        confirmPage: Page;
        discountPage: Page;
        formPage: Page;
        completePage: Page;
        couponRedeemedPage: Page;
    };      

    const cancelModalPages: CancelModalPages = {
        confirmPage: {
            logo: 'sandwatch',
            title: 'Confirm subscription cancelation',
            body: () => (
                <>
                    <li>Your open gifts will be closed. you will no longer be able to see or contact new creators or those who have applied for your gift.</li>
                    <li>Your subscription will be canceled at the end of your cycle, {endDate}.</li>
                    <li>You will not be billed again. You can continue to use your #gifted subscription until then.</li>
                </>
            ),
            primaryBtnText: `No, I'm not ready to cancel`,
            secondaryBtnText: `Confirm cancelation`,
            primaryBtnOnClick: () => handleClose(),
            secondaryBtnOnClick: () => setCurrentPage(PageType.DiscountPage),
        },
        discountPage: {
            logo: '',
            title: 'Get 50% off your next month.',
            subtitle: 'Keep getting all the benefits of your #gifted subscription with our 50% offer',
            body: () => (
                <>
                    <li>Post {brand?.subscription?.subscription && subscriptionService.features(brand?.subscription?.subscription as Subscription).get('gifts.create.limit')} campaign every month</li>
                    <li>Gift up to {brand?.subscription?.subscription && subscriptionService.features(brand?.subscription?.subscription as Subscription).get('influencers.accept.limit')} creators every campaign</li>
                    <li>Match with up to {brand?.subscription?.subscription && subscriptionService.features(brand?.subscription?.subscription as Subscription).get('influencers.matches.limit')} creators per campaign</li>
                    {/* <li>Shopify integration</li> */}
                    {/* <li>Auto capture of Instagram posts & reels</li>
                    <li>Auto capture of TikTok videos</li>
                    <li>Auto capture of YouTube videos & shorts</li> */}
                </>
            ),
            primaryBtnText: `Redeem offer`,
            secondaryBtnText: `Cancel my subscription`,
            primaryBtnOnClick: () => handleRedeemOffer(),
            secondaryBtnOnClick: () => setCurrentPage(PageType.FormPage),
            btnReverse: true
        },
        formPage: {
            logo: '',
            title: 'We are sad to see you go :(',
            subtitle: `We'd love to know why you're leaving`,
            body: () => (
                    <>
                        <div>
                            <Label>Reason:</Label>
                            <FormSelectBasic
                                options={options}
                                selected={selectedOption || form.reason}
                                onChange={(name, id) => handleChange('reason', {id, name})}
                                disabled={false}
                                fullWidth={true}
                            />
                        </div>

                        <FormTextarea
                            name={'message'}
                            label={'Anything else we should know?'}
                            placeholder={'Type report info for gifted team...'}
                            value={form.message}
                            limit={500}
                            required={true}
                            onChange={handleChange}
                        />
                    </>
            ),
            primaryBtnText: 'Keep my subscription',
            secondaryBtnText: 'Cancel my subscription',
            primaryBtnOnClick: () => onClose(),
            secondaryBtnOnClick: () => handleSubmit(),
        },
        completePage: {
            logo: 'lightning',
            title: 'Cancelation completed',
            body: () => (
                <>
                    <p>We are sorry to see you go.</p>
                    <p>Your subscription will be cancelled at the end of your cycle, {endDate}</p>
                    <p>You can continue to use your #gifted subscription until then.</p>
                </>
            ),
            primaryBtnText: `Go to dashboard`,
            primaryBtnOnClick: () => onClose(),
            secondaryBtnText: '',
            secondaryBtnOnClick: () => {},
        },
        couponRedeemedPage: {
            logo: '',
            title: 'Thanks for staying with us!',
            subtitle: 'You will be billed 50% off your next month.',
            body: () => (<></>),
            primaryBtnText: `Go to dashboard`,
            primaryBtnOnClick: () => onClose(),
            secondaryBtnText: '',
            secondaryBtnOnClick: () => {},
        }
    }


    const activePage: Page = useMemo(() => {
        return cancelModalPages[currentPage];
    }, [currentPage, form])

    const downgradeToOptions = useMemo(() => {
        console.log(currentPlan)
        if (currentPlan === 'Gifted Starter') {
            return false
        }

        if (currentPlan === 'Gifted Pro') {
            return 349
            
        }

        if (currentPlan === 'Gifted Grow') {
            return  149
            
        }

    }, [currentPlan])

    return renderToPortal(
        <>
        <AnimatePresence onExitComplete={handleExitComplete}>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'review-modal-component'}
                >
                    <CancelModalDiv>
                        {currentPage === PageType.ConfirmPage ? (
                            <>
                                <h1>Cancel or change your #gifted plan</h1>
                                <p style={{fontSize: '20px'}}>Whatever you choose, it'll take effect on {endDate}. You'll still be able to gift until then.</p>

                                <CancelColumns>
                                    <div>
                                        <CancelBox>
                                            <button onClick={() => setCurrentPage(PageType.DiscountPage)} className='danger'>Finish Cancelation</button>
                                            <p>Your open gifts will be closed and you will no longer be able to see or contact new creators or those who have applied for your gift.</p>
                                        </CancelBox>

                                        {downgradeToOptions ? (
                                            <CancelBox>
                                                <button onClick={() => {
                                                    onDowngrade && onDowngrade();
                                                    setTimeout(() => {
                                                        onClose();
                                                    }, 1000);
                                                }} className='primary'>Downgrade to #gifted Starter</button>
                                                <p>${downgradeToOptions} {'->'} $99</p>                                        
                                            </CancelBox>
                                        ) : null}

                                        {/* <CancelBox>
                                            <button onClick={() => onPause && onPause()} className='secondary'>Pause for one month</button>
                                            <p>Instead of canceling, take a break for one month.</p>
                                            <p>You will be able to use #gifted until {endDate}, then your subscription will pause for one month and resume one month later.</p>
                                        </CancelBox> */}
                                    </div>

                                    <div>
                                        <h1>Want to stick around?</h1>
                                        <p style={{marginTop: '12px', marginBottom: '18px', fontSize: '20px'}}>Your plan will remain the same.</p>
                                        <button onClick={() => handleClose()} style={{width: '500px'}} className='primary'>Back to #gifted</button>
                                        <EmptyPlaceholder cancelModel image={'girl-1'} />
                                    </div>
                                </CancelColumns>

                            </>
                        ) : null }

                        {currentPage === PageType.DiscountPage ? (
                            <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '100%', alignItems: 'center'}}>
                                <h1>Get 50% off your next month</h1>
                                <p style={{fontSize: '20px'}}>Keep getting all the benefits of your #gifted subscription with our 50% offer</p>
                                
                                <div style={{marginTop: '24px', display: 'flex', flexDirection: 'column', gap: '12px', width: '35%', alignItems: 'center'}}>
                                    <button onClick={() => handleRedeemOffer()} style={{width: '500px'}} className='primary'>Redeem and keep my subscription</button>
                                    <button onClick={() => setCurrentPage(PageType.FormPage)} style={{width: '500px'}} className='danger'>Cancel my subscription</button>
                                    <button onClick={() => setCurrentPage(PageType.ConfirmPage)} style={{width: '500px'}} className='secondary'>See other options</button>
                                    <EmptyPlaceholder cancelModel image={'girl-1'} />
                                </div>
                            </div>
                        ) : null}

                        {
                            currentPage === PageType.CouponRedeemedPage ? (
                                <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '100%', alignItems: 'center'}}>
                                    <h1>Thanks for staying with us!</h1>
                                    <p style={{fontSize: '20px'}}>You will be billed 50% off your next month.</p>

                                    <div style={{marginTop: '24px', display: 'flex', flexDirection: 'column', gap: '12px', width: '35%', alignItems: 'center'}}>
                                        <button onClick={() => onClose()} style={{width: '500px'}} className='primary'>Back to #gifted</button>
                                        <EmptyPlaceholder cancelModel image={'girl-2'} />
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            currentPage === PageType.FormPage ? (
                                <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '100%', alignItems: 'center'}}>
                                    <h1>We are sad to see you go :(</h1>
                                    <p style={{fontSize: '20px'}}>We'd love to know why you're leaving</p>

                                    <Label>Reason:</Label>
                                    <FormSelectBasic
                                        options={options}
                                        selected={selectedOption || form.reason}
                                        onChange={(name, id) => handleChange('reason', {id, name})}
                                        disabled={false}
                                        fullWidth={true}
                                    />

                                    {formError === 'Required field' && !form.message.length ? <Label style={{color: 'red'}}>{formError}</Label> : null}
                                    <FormTextarea
                                        className='full-width'
                                        name={'message'}
                                        label={'Anything else we should know?'}
                                        placeholder={'Type report info for gifted team...'}
                                        value={form.message}
                                        limit={500}
                                        required={true}
                                        onChange={handleChange}
                                    />

                                    {form.message.length ? (
                                        <button onClick={() => handleSubmit()} style={{width: '500px'}} className='danger'>Submit</button>
                                    ) : (
                                        <button onClick={() => setFormError('Required field')} style={{width: '500px'}} className='danger'>Submit</button>
                                    )
                                }
                                    <button onClick={() => setCurrentPage(PageType.ConfirmPage)} style={{width: '500px'}} className='secondary'>Back</button>
                                </div>
                            ) : null
                        }

                        {
                            currentPage === PageType.CompletePage ? (
                                <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '100%', alignItems: 'center'}}>
                                    <h1>We are sorry to see you go.</h1>
                                    <p style={{fontSize: '20px'}}>Your subscription will be cancelled at the end of your cycle, {endDate}</p>

                                    <div style={{marginTop: '24px', display: 'flex', flexDirection: 'column', gap: '12px', width: '35%', alignItems: 'center'}}>
                                        <button onClick={() => onClose()} style={{width: '500px'}} className='primary'>Back to #gifted</button>
                                        <EmptyPlaceholder cancelModel image={'girl-2'} />
                                    </div>
                                </div>

                            ) : null
                        }
                    </CancelModalDiv>
                </Container>
            )}
        </AnimatePresence>

        {showConfetti && (
            <ConfettiContainer>
                <ReactConfetti recycle={false} numberOfPieces={200} initialVelocityY={0} gravity={.35} onConfettiComplete={() => setShowConfetti(false)} />
            </ConfettiContainer>
        )}

        </>
    );
}

const CancelModalDiv = styled.div`
    height: 70vh;
    min-height: 900px;
    // width: 80vw;
    width: 1440px;
    background: white;
    padding: 24px;
    text-align: center;
    overflow: hidden;

    h1 {
        color: var(--blue);
    }

    button {
        border: none;
        padding 4px 0px;
        text-align: center;
        margin-bottom: 12px;
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.primary {
            background: var(--blue);
            color: white;
            font-weight: bold;
        }

        &.danger {
            background: var(--error-active);
            color: white;
            font-weight: bold;
        }

        &.secondary {
            background: white;
            color: var(--blue);
            border: solid 1px var(--blue);
            font-weight: bold;
        }
    }

    .full-width {
        width: 500px !important;
    }


    @media (max-width:900px) and (min-width:0px) {
        width: 100vw;
        overflow: scroll;
    }

`

const CancelColumns = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-top: 70px;
    flex-wrap: wrap;

    h1 {
        margin: 0;
        padding: 0;
        color: var(--blue);
    }
`

const CancelBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    padding-bottom: 16px;
    margin-bottom: 16px; 
    box-shadow: 1px 1px 4px rgba(0,0,0,.3);
    border-radius: 14px;
    width: 614px;

    p {
        margin-top: 12px !important;
        font-size: 20px;
    }


    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
    }

`

const StyledButton = styled(Button)`
    position: absolute;
    right: -10px;
    top: -10px;
`;

const Title = styled.span`
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--blue);
    font-size: 38px;
    line-height: 48px;

    @media (max-width:900px) and (min-width:0px) {
        font-size: 27px;
    }
`;

const Image = styled.img`
    width: 220px;
    height: 220px;

    @media (max-height:711px) and (min-height:0px) {
        display: none;
    }

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const Subtext = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--grey-7);
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    row-gap: 4px;
    padding-top: 24px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 28px;

    canvas {
        height: 100% !important;
        width: 100% !important;
    }

    @media (max-width:900px) and (min-width:0px) {
        overflow: visible;
    }
    
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;

    &.reverse {
        flex-direction: column-reverse;
    }
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);

    @media (max-width:900px) and (min-width:0px) {
        height: 95vh;
        overflow-y: scroll;
    }
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }

    li, p {
        color: #9299BE;
        text-align: center;
    }

    p {padding: 0px; margin: 0px; color: var(--grey-9);}
`;

const Label = styled.div`
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    line-height: 24px;
    color: var(--grey-9);
`

const Centered = styled.div`
    display: flex;
    justify-content: center;
`

const ConfettiContainer = styled.div`
    position: absolute;
    top: 0; left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000000;
    height: 200%;
    width: 100%;
`