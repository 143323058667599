import { combineReducers } from 'redux';

import { Constants } from '../constants';
import {AnyAction, PayloadAction} from "@reduxjs/toolkit";

export const initialState: CompletedReducer = {
    list: [],
    view: {
        influencer: {
            address: "",
            attributes: [],
            bio: "",
            city: {
                id: '',
                name: ""
            },
            country: {
                id: '',
                name: ""
            },
            dob: "",
            headshot_image: '',
            insights: undefined,
            last_seen_at: '',
            mobile: "",
            name: "",
            rating: 0,
            reviews: [],
            response_time_bucket: '',
            social_profiles: [],
            state: {
                id: 1,
                name: ""
            },
            status: "",
            tags: [],
            uid: ""
        },
        influencerPosts: []
    },
    loading: false,
    loadingView: false,
    loadingReviews: false
}

const list = (
    state = initialState.list,
    action: PayloadAction<SocialMediaContent[]>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Completed.List:
            return payload;
        case Constants.Completed.Clear:
            return initialState.list;
        default: return state;
    }
}

const view = (
    state = initialState.view,
    action: AnyAction|PayloadAction<CompletedContentView>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Completed.View:
            return payload as CompletedContentView;
        case Constants.Completed.Clear:
            return initialState.view;
        default: return state;
    }
}


const loading = (
    state = initialState.loading,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Completed.Loading:
            return payload;
        case Constants.Completed.Clear:
            return initialState.loading;
        default: return state;
    }
}

const loadingView = (
    state = initialState.loadingView,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Completed.LoadingView:
            return payload;
        case Constants.Completed.Clear:
            return initialState.loadingView;
        default: return state;
    }
}

const loadingReviews = (
    state = initialState.loadingReviews,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Completed.LoadingReviews:
            return payload;
        case Constants.Completed.Clear:
            return initialState.loadingReviews;
        default: return state;
    }
}

export default combineReducers<CompletedReducer>({
    list,
    view,
    loading,
    loadingView,
    loadingReviews
});
