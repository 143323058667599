import styled from 'styled-components';

import {
    Button,
    FormSwitch,
    PictureFrame,
} from 'app/components';
import { Mapping } from 'utils';
import {useState,useMemo} from "react";
import {GiftTabType} from "../index";
import {useAppSelector} from "../../../../store";
import { useMediaQuery } from 'usehooks-ts'
import Tabs from 'react-responsive-tabs';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../../services/settingsService';
import 'react-responsive-tabs/styles.css';
import {CopyLinkIcon} from "../../../icons";


interface Props {
    name: string | null;
    image?: string | null;
    status?: GiftStatus;
    paramStatus?: string;
    selectedTab: string;
    disableActions?: boolean;
    loading?: boolean;
    onChangeTab(tabId: GiftTabType): void;
    onDuplicate(): void;
    onHideToggle?(hide: boolean): void;
    onPrimaryActionClick?(status?: string): void;
    onSecondaryActionClick?(): void;
    completedContentEnabled: boolean;
    handleSaveButtonClick?(): void;
    uid: string | undefined
}

export function GiftViewPageHeader({
    name,
    image,
    status,
    paramStatus,
    selectedTab,
    disableActions,
    loading,
    onChangeTab,
    onDuplicate,
    onHideToggle,
    onPrimaryActionClick,
    onSecondaryActionClick,
    completedContentEnabled,
    handleSaveButtonClick,
    uid
}: Props) {

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const smallDevice = useMediaQuery('(max-width: 900px)')

    const tabItems = useMemo(() => {
        const _tabItems: {id: string; name: string, title: string, key: string}[] = [];
        switch (status) {
            case 'ACTIVE':
            case 'CLOSED':
            case 'SCHEDULED':
            case 'COMPLETED':
                _tabItems.push({ id: 'waves', name: 'Matches', title: 'Matches', key: 'waves' });
                break;
        }
        _tabItems.push({ id: 'creative', name: 'Creative', title: 'Creative', key: 'creative' });
        _tabItems.push({ id: 'audience', name: 'Audience', title: 'Audience', key: 'audience' });
        _tabItems.push({ id: 'platforms', name: 'Platforms', title: 'Platforms', key: 'platforms' });
        if (settings?.features["completed.content"] && completedContentEnabled) {
            switch (status) {
                case 'ACTIVE':
                case 'CLOSED':
                case 'COMPLETED':
                    _tabItems.push({ id: 'completed-content', name: smallDevice ? 'Content' : 'Completed content', title: 'Completed content', key: 'completed-content' });
                    break;
            }
        }
        if (settings?.features.yotpo) {
            switch (status) {
                case 'ACTIVE':
                case 'CLOSED':
                case 'COMPLETED':
                    _tabItems.push({ id: 'reviews', name: 'Reviews', title: 'Reviews', key: 'reviews' });
                    break;
            }
        }
        return _tabItems;
    }, [completedContentEnabled, settings, status]);

    const [previousTab, setPreviousTab] = useState<string | null>(null);
    const [currentTab, setCurrentTab] = useState<string>(tabItems[0].id);
    const [copied, setCopied] = useState(false);

    const handleChangeTab = (newTab: GiftTabType) => {
        setPreviousTab(currentTab);
        setCurrentTab(newTab);

        // prevent tabs from keep clicking
        if (currentTab !== previousTab) {
            analyticsEvents.giftManageGiftTypeOpened(newTab);
        }
        onChangeTab(newTab as GiftTabType);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://hashgifted.com/link/gifts/${uid}`)
            .then(() => {
                setCopied(true);
                setTimeout(()=>{
                    setCopied(false);
                }, 1500)
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <Container $loading={loading}>
            {smallDevice ? (
                <>
                    <Top className='small-device'>
                        <Desc>
                            <Info>
                                <span>{!loading && name}</span>
                            </Info>
                        </Desc>
                    </Top>


                    <Actions className='small-device'>
                        {paramStatus === 'active' && (
                            <HideActions>
                                <FormSwitch
                                    name={'hide'}
                                    label={smallDevice ? 'Stop' : 'Stop applications'}
                                    checked={status === 'CLOSED'}
                                    disabled={disableActions}
                                    onChange={(_, value) => onHideToggle?.(value)}
                                />
                            </HideActions>
                        )}

                        {paramStatus !== 'past' && (
                            <DefaultActions>
                                {(paramStatus === 'draft' || paramStatus === 'declined')  && (
                                    <Button
                                        size={'small'}
                                        theme={'danger'}
                                        disabled={disableActions}
                                        onClick={onSecondaryActionClick}
                                    >
                                        Delete
                                    </Button>
                                )}

                                {handleSaveButtonClick && (
                                    <Button
                                        theme={'outline'}
                                        size={'small'}
                                        disabled={disableActions}
                                        onClick={handleSaveButtonClick}
                                    >Save</Button>
                                )}



                                <Button
                                    theme={'aqua'}
                                    size={'small'}
                                    disabled={disableActions}
                                    onClick={() => onPrimaryActionClick?.(status as string)}
                                >
                                    {status && Mapping.GiftButtonStatusMappig[status]}
                                </Button>
                            </DefaultActions>
                        )}
                    </Actions>
                </>

            ) : (
                <Top>
                    <Desc>
                        <StyledPictureFrame
                            image={image}
                            width={64}
                            height={64}
                            isGift={true}
                            loading={loading}
                        />

                        <Info>
                            <span>{!loading && name}</span>
                            <p style={shareLinkStyles} onClick={handleCopy} >
                                <CopyLinkIcon style={{height: '12px', width: '12px', marginBottom: '-3px'}}/> {!copied ? "Copy link to gift" : "Copied"}
                            </p>

                            {/* <span onClick={() => onDuplicate()}>
                                {!loading && 'Repost this gift'} 
                            </span> */}
                        </Info>
                    </Desc>

                    <Actions>
                        {paramStatus === 'active' && (
                            <HideActions>
                                <FormSwitch
                                    name={'hide'}
                                    label={smallDevice ? 'Stop' : 'Stop applications'}
                                    checked={status === 'CLOSED'}
                                    disabled={disableActions}
                                    onChange={(_, value) => onHideToggle?.(value)}
                                />
                            </HideActions>
                        )}


                            <DefaultActions>
                                {(paramStatus === 'draft' || paramStatus === 'declined' || paramStatus === 'past' || paramStatus === 'submitted') && (
                                    <Button
                                        size={'medium'}
                                        theme={'danger'}
                                        disabled={disableActions}
                                        onClick={onSecondaryActionClick}
                                    >
                                        Delete
                                    </Button>
                                )}


                                {paramStatus !== 'past' && (
                                    handleSaveButtonClick && (
                                        <>
                                            <Button
                                                theme={'outline'}
                                                size={'medium'}
                                                disabled={disableActions}
                                                onClick={handleSaveButtonClick}
                                            >
                                                Save
                                            </Button>
                                            
                                            {status !== 'SCHEDULED' && (
                                                <Button
                                                    theme={'aqua'}
                                                    size={'medium'}
                                                    disabled={disableActions}
                                                    onClick={() => onPrimaryActionClick?.(status as string)}
                                                >
                                                    {status && Mapping.GiftButtonStatusMappig[status]}
                                                </Button>
                                            )}
                                        </>
                                    )
                                )}
                            </DefaultActions>
                    </Actions>
                </Top>

            )}

            <Tabs
                items={tabItems}
                showMore={true}
                transform={false}
                transformWidth={0}
                onChange={(e: GiftTabType) => {
                    console.log(e)
                    handleChangeTab(e)
                }}
                showMoreLabel={'More...'}
            />

            {/* <InlineTab>
                {tabItems.map(({ id, name }) => (
                    <TabItem
                        key={id}
                        $active={selectedTab === id}
                        onClick={() => onChangeTab(id as GiftTabType)}
                    >
                        {name}
                    </TabItem>
                ))}
            </InlineTab> */}
        </Container>
    );
}

const shareLinkStyles = {
    fontSize: '10px',
    marginTop: '1px',
    marginBottom: '0px',
    marginLeft: '3px',
    color: 'var(--grey-9)',
    textDecoration: 'underline',
    display: 'inline',
    cursor: 'pointer',
};

const StyledPictureFrame = styled(PictureFrame)`
    box-shadow: var(--shadow-1);

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const Info = styled.div`
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 10px;

    span {
        &:first-child {
            font-size: 20px;
            font-weight: 600;
            display: -webkit-box;
            align-items: center;
            padding-right: 10px;
            letter-spacing: -0.75px;
            word-break: break-word;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            color: var(--grey-9);
        }

        // &:last-child {
        //     font-size: 14px;
        //     font-weight: 600;
        //     line-height: 24px;
        //     text-decoration: underline;
        //     text-underline-position: under;
        //     text-underline-offset: 1px;
        //     color: var(--grey-6);
        //     display: -webkit-box;
        //     word-break: break-word;
        //     -webkit-line-clamp: 2;
        //     -webkit-box-orient: vertical;
        //     text-overflow: ellipsis;
        //     overflow: hidden;
        //     cursor: pointer;
        // }
    }
    
    p {
        margin-top: 2px;
        display: flex;
        align-items: center;
    }
    
    p, svg {
        
        &:hover {
            opacity: 0.8;
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: row;
        align-items: start;
        // width: 120px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: nowrap;
        overflow: hidden;
        width: 130px;

        span {
            display: block !important;
            width: 100%;
            text-overflow: initial;
            box-orient: horizontal;
            -webkit-box-orient: horizontal;
            font-size: 18px !important;
            text-overflow: ellipsis;
            word-break: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-align: center !important;
            color: var(--grey-9) !important;
            text-decoration: none !important;
        }
    }
`;

const Desc = styled.div`
    display: flex;
    align-items: center;
    column-gap: 18px;

    
`;

const DefaultActions = styled.div`
    flex-shrink: 0;
    display: flex;
    column-gap: 8px;

    button {
        &:first-child {
            width: 100px;
        }

        &:last-child {
            width: 120px;
        }
    }
`;

const HideActions = styled.div`
  width: 176px
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-left: auto;
    margin-top: -12px;

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column;
        align-items: flex-end;
        margin-top: 0px;

        button {
            width: 100px !important;
            font-size: 12px !important;
            padding: 0px 8px;
            margin-top: 4px;
        }
    }

    &.small-device {
        flex-direction: row;
        justify-content: space-between;
        margin-left: 0px;
        align-items: center;
        padding: 4px 20px;
        border-bottom: 1px solid var(--grey-3);
    }

`;

const Top = styled.div`
    display: flex;
    align-items: center;
    height: 102px;
    padding: 20px;

    &.small-device {
        height: auto;
        width: 100%;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0px;
        ${Desc} {
            width: 100%;

            ${Info} {
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
            }

        }
    }
`;

const Container = styled.div<{
    $loading?: boolean;
}>`
    grid-area: header;
    display: flex;
    flex-direction: column;
    height: 142px;
    // overflow: hidden;

    ${(props) => !props.$loading && `
        ${Info} span:first-child:empty:before {
            content: '#';
            visibility: hidden;
        }
    `}

    ${(props) => props.$loading && `
        pointer-events: none;

        ${Info} span,
        ${HideActions},
        ${DefaultActions} button {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            &:before {
                content: '#################';
                visibility: hidden;
            }
        }

        ${HideActions} {
            width: 86px;
            height: 24px;
            border-radius: 8px;

            > div {
                display: none;
            }

            &:before {
                content: '##';
            }
        }

        ${DefaultActions} button {
            border: none;
            border-radius: 16px;
            color: transparent;
            transition: none;

            &:before {
                content: '##';
            }
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }

    .RRT__panel {
        display: none !important;
    }

    .RRT__container {
        border-bottom: 2px solid var(--grey-3);
    }

    .RRT__tab {
        background: white;
        border: none;
        color: var(--grey-6);
        font-size: 14px;
        padding: 7px 24px;
        height: 40px;

        @media (max-width:900px) and (min-width:0px) {
            font-size: 12px;
            padding: 14px 12px;
        }
    }

    .RRT__tab--selected {
        color: var(--blue);
        border-bottom: 2px solid var(--blue);
    }

    .RRT__showmore {
        background: white;
        border: none;
        color: var(--grey-6);
        font-size: 12px;
        padding-top: 4px;
    }

    .RRT__showmore-label--selected {
        color: var(--blue);
        background: white;
    }

    .RRT__showmore--selected {
        color: var(--blue);
        border-bottom: 2px solid var(--blue);
    }

    .RRT__showmore-list {
        box-shadow: var(--shadow-1);
        border-radius: 8px;

        .RRT__tab {
            border-radius: 8px;
        }

        .RRT__tab--selected {
            color: var(--blue);
            font-weight: 600;
            border-bottom: none;
        }    
    }

    @media (max-width:900px) and (min-width:0px) {
        overflow: initial;
    }

`;
