
export const compactNumber = (number: number): string => {
    return Intl
        .NumberFormat('en-US', {
            notation: 'compact'
        })
        .format(Number(number))
}

export const formatEngagementRate = (value: number): string => {
    const percent = value * 100.0;
    const formatted = percent % 1 === 0 ? percent.toFixed(0) : percent.toFixed(2);

    console.log(value, percent, formatted);

    // Check if the value is less than 0.003 (0.3% as a decimal)
    // if (value <= 0.03) {
    //     return '0.30%';
    // }

    // if (value >= .22) {
    //     return '22%';
    // }

    
    return `${formatted}%`;
};

export const formatFollowerCount = (number: number): string => {
    return compactNumber(number);
}
