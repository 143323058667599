import {ReactNode, useRef} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {createPortal} from 'react-dom';
import styled from 'styled-components';

import {Hooks} from 'utils';
import {Button} from '../Button';

interface Props {
    type?: 'completed'
    | 'card-updated'
    | 'canceled';
    subscriptionName?: string;
    show?: boolean;
    onClose(): void;
    onClick(): void;
}

const Image = styled.img`
    width: 220px;
    height: 220px;
`;

const Title = styled.span`
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    margin-top: -20px;
    margin-bottom: 20px;
    letter-spacing: -1.25px;
    color: var(--grey-9);
`;

const Desc = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.25px;
    color: var(--grey-6);
    white-space: break-spaces;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }
`;

export function SubscriptionModal({
    type,
    subscriptionName,
    show,
    onClose,
    onClick
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);

    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    const renderContent = () => {
        let title: string;
        let caption: string;

        switch (type) {
            case 'canceled':
                title = 'Cancelled';
                caption = `Your ${subscriptionName} plan has been\nsuccessfully cancelled.`;
                break;

            case 'card-updated':
                title = 'Card Updated';
                caption = `Your card has been\nsuccessfully updated.`;
                break;

            case 'completed':
                title = 'Completed';
                caption = `Your ${subscriptionName} plan has been\nstarted successfully.`;
                break;

            default: return null;
        }

        return (
            <Body>
                <Image
                    src={require(`../../../images/pig.png`)}
                    alt={`${type} subscription`}
                />

                <Title>
                    {title}
                </Title>

                <Desc>
                    {caption}
                </Desc>
            </Body>
        );
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'gift-modal-component'}
                >
                    <Content
                        {...contentAnimation}
                        ref={contentRef}
                    >
                        {renderContent()}

                        <Actions>
                            <Button onClick={onClick}>
                                Done
                            </Button>
                        </Actions>
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
}
