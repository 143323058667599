import { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Helpers } from 'utils';
import {Alert, FormInput} from 'app/components';
import { NextLineIcon, SearchIcon } from 'app/icons';
import { GiftListItem } from '../GiftListItem';
import {
    useAppSelector
 } from 'store';

interface Props {
    items: Gift[];
    loading?: boolean;
    onClick(giftId: string, status: GiftStatus): void;
    brand: BrandProfile;
    refresh(): Promise<void>;
}

export function GiftList({
    items,
    loading,
    onClick,
    brand,
    refresh
}: Props) {
    const {
        profile: {representative},
    } = useAppSelector((store) => store);

    const [search, setSearch] = useState<string>('');

    const filteredItems = useMemo(() => {
        if (!search) return items;

        return items.filter(({ name, description }) =>
            name?.includes(search) || description?.includes(search)
        );
    }, [items, search]);

    const list = loading ? placeholder : filteredItems;

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    return (
        <Container $loading={loading}>
            {(items.length > 0 || loading) && (
                <Header>
                    <StyledInput
                        type={'search'}
                        name={'search'}
                        label={''}
                        placeholder={'Search gifts...'}
                        value={search}
                        onChange={(_, value) => setSearch(value)}
                    >
                        <SearchIcon />
                    </StyledInput>

                    <NextLineIcon />
                </Header>
            )}

            {settings?.features.feedback_form_url && representative?.auth.email_verified_at ? (
                <Alert type={'info'} title={'Welcome to the #gifted beta'} message={'We are so happy for you to join us in the #gifted beta program. During the beta you are free to post as many gifts as you wish.'} style={{ backgroundColor: "white" }}>
                    {/*<span>If you have any questions, suggestions or encounter issues please send us your feedback using the link below.</span>*/}
                </Alert>
            ) : null}

            <Body>
                {list.map((item) => (
                    <GiftListItem
                        {...item}
                        key={item.uid}
                        id={item.uid}
                        waveCount={item.unreadBubbleCount}
                        status={item.status}
                        loading={loading}
                        brand={brand}
                        refresh={refresh}
                        onClick={onClick}
                    />
                ))}
            </Body>
        </Container>
    );
}

const placeholder: Array<Gift> = Array(9).fill('').map(() => ({
    uid: Helpers.generateId(),
    name: '',
    tags: [],
    media: [],
    unreadBubbleCount: 0,
    status: 'ACTIVE',
    admin_notes: "",
    cover_media: {
        display_order: 0,
        gallery_path: "",
        is_cover: 0,
        metadata: {
            crop: undefined
        },
        mime: "",
        name: "",
        original_path: "",
        primary_color: 'undefined',
        secondary_color: 'undefined',
        thumbnail_path: "",
        type: "",
        uid: ""
    },
    description: '',
    gift_type: 'PRODUCT',
    is_content_enabled: false,
    is_rank_enabled: false,
    locations: [],
    max_age: 0,
    max_followers: 0,
    min_age: 0,
    min_followers: 0,
    slug: 'hello',
    status_updated_at: "",
    value: 0
}));

const StyledInput = styled(FormInput)`
    flex: 1;

    div {
        border: none;
        background-color: var(--background-alt);
    }
`;

const Header = styled.div`
    flex: 0 0 48px;
    align-self: center;
    display: flex;
    align-items: center;
    width: 446px;
    padding-right: 12px;
    border: 1px solid var(--grey-6);
    border-radius: 12px;
    background-color: var(--background-alt);
    transition: border-color 300ms ease;
    overflow: hidden;

    &:focus-within {
        border-color: var(--grey-9);
    }

    @media (max-width:900px) and (min-width:0px) {
        width:90%;
    }

`;

const Body = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    column-gap: 28px;
    row-gap: 28px;

    @media (max-width:900px) and (min-width:0px) {
        justify-content: center;
    }

`;

const Container = styled.div<{
    $loading?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 1037px;
    padding-top: 20px;
    margin: auto;

    ${(props) => props.$loading && `
        pointer-events: none;

        ${Header} {
            border: none;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            animation: animate 1s infinite;

            div, svg {
                display: none;
            }
        }

        @keyframes animate {
            from: {
                background-position: 100% 0, 0 0;
            }
            to {
                background-position: -100% -100%;
            }
        }
    `};

    @media (max-width:900px) and (min-width:0px) {
        width: 88%;
    }

`;
