import styled from 'styled-components';
import {
    InstagramEmbed,
    TikTokEmbed,
    YouTubeEmbed
} from 'react-social-media-embed';
import { NoResult, StatusLoader } from "../../../components";
import {useMemo, useState} from "react";
import completedContentService from "../../../../services/completedContentService";
import {useAppSelector} from "../../../../store";
import axios from 'axios';
import { DownloadIcon } from '../../../icons';
import EmptyState from '../WavesTabs/emptyState';

interface Props {
    post: SocialMedia;
}

const Media = styled.div`
    position: relative;
    margin: auto 0;
`;

const Container = styled.div`
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    padding: 20px;
    height: calc(fit-content + 50px);

    .instagram-media {
        border: none !important;
    }

    .instagram-media-rendered {
        border: 1px solid rgb(219, 219, 219) !important;
    }
`;

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
    flex-shrink: 0;
    max-width: 60%;
    border-radius: 28px;
`;

const DownloadButton = styled.div`
    position: absolute;
    bottom: 41px;
    right: 24px;
    cursor: pointer;
    background-color: var(--aqua);
    width: fit-content;
    color: var(--blue);
    padding: 8px 24px;
    border-radius: 16px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        margin-right: 8px;
        transform: translateY(-1px);
        path, line, polyline {
            stroke: var(--blue);
        }
    }
    
    &:hover {
        background-color: rgba(117, 214, 194, .8);
        color: var(--blue);    
    }
`

export function InfluencerPostInfo({
    post
}: Props) {

    const platform = post.platform.toLowerCase();
    const first = post.items[0];
    const items = post.items;

    const {
        global: {
            settings
        },
        profile: {
            brand
        }
    } = useAppSelector((state) => state);

    const [showSpinner, setShowSpinner] = useState(false);
    const [spinnerState, setSpinnerState] = useState('spin');

    const mediaUrl = useMemo(() => settings && completedContentService.getMediaUrl(settings, first), [settings, first]);
    const mediaUrls = useMemo(() => settings && completedContentService.getMediaUrls(settings, post.items), [settings, items]);


    const extensions = ['png', 'jpg', 'jpeg', 'missing'];
    const isImageComingInAsVideo = extensions.some(extension => mediaUrl?.includes(extension));


    const externalUrl = useMemo(() => {
        if (!first.externalUrl) {
            return '';
        }
        const url = new URL(first.externalUrl);
        url.search = '';
        return url.toString();
    }, [post])

    const renderMedia = () => {
        if (!mediaUrl) {
            return null;
        }


        switch (platform) {
            case 'instagram':
                const item = post.items[0];
                if (post.type === 'STORY') {
                    if (item.stored) {
                        if (item.type === 'VIDEO' && !isImageComingInAsVideo) {
                            return (
                                <MediaContainer>
                                    <video width="67%" src={mediaUrl} controls={true} autoPlay={false}/>
                                </MediaContainer>
                            )
                        } else {
                            return (
                                <MediaContainer>
                                    <Image alt={''} src={isImageComingInAsVideo && settings ? (mediaUrl || settings.media.endpoint + (post.items[0].mediaThumbnailUrl)) : mediaUrl}></Image>
                                </MediaContainer>
                            );
                        }
                    } else {
                        return (
                            <MediaContainer>
                                <NoResult
                                    image={'camera'}
                                    title={'This post is being processed'}
                                    message={'Don\'t worry! You won\'t miss a thing. \nCheck back in a few minutes.'}
                                />
                            </MediaContainer>
                        )
                    }
                } else {
                    return (
                        <InstagramEmbed
                            key={post.external_id}
                            url={externalUrl}
                            width={'100%'}
                        />
                    );
                }

            case 'tiktok':
                return (
                    <TikTokEmbed
                        key={post.external_id}
                        url={externalUrl}
                        width={'100%'}
                    />
                );

            case 'youtube':
                return (
                    <YouTubeEmbed
                        key={post.external_id}
                        url={first.externalUrl}
                        width={'100%'}
                    />
                );

            default: return null;
        }
    }

    const downloadImage = () => {
        if(!mediaUrls){
            return;
        }
        let socialMediaUrl = '';
        items?.forEach((item) => {
            if(!settings?.media?.endpoint){
                return;
            }
            socialMediaUrl =  item.mediaUrl;
            setShowSpinner(true);
            axios.get((settings?.media?.endpoint + socialMediaUrl as string ?? settings?.media?.endpoint + item?.mediaThumbnailUrl),
                { responseType: 'arraybuffer' }
            ).then((res) => {
                const contentType = res.headers['content-type'];
                const blob = new Blob([res.data], {type: contentType});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = '';
                link.click();
                window.URL.revokeObjectURL(url);

                setSpinnerState('success');
                removeSpinner();
            }).catch(() => {
                setSpinnerState('error');
                removeSpinner();
            })
        });
    }

    const removeSpinner = () => {
        setTimeout(() => {
            setShowSpinner(false);
            setSpinnerState('spin');
        }, 3000)
    }

    console.log(brand?.subscription?.subscription.name.includes('Pro'),brand?.subscription?.subscription )

    return (
        <>
            <Container>
                <Media>
                    {externalUrl ? (
                        renderMedia()
                    ) : (
                        <NoResult image={'camera'} title={'There was a problem loading this post'} message={'This post has expired.'}></NoResult>
                    )}
                </Media>



            </Container>

            {mediaUrl && (post.platform !== 'YOUTUBE') && (
                <DownloadButton onClick={() => downloadImage()}>
                    <DownloadIcon height="20" width="20" /> Download
                </DownloadButton>
            )}

            <StatusLoader show={showSpinner} status={spinnerState} />

        </>
    );
}
