import {ReactNode, useMemo, useRef, useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {Hooks} from 'utils';
import {CloseIcon} from 'app/icons';
import {Button} from '../Button';
import {FormTextarea} from '../FormTextarea';
import { API } from 'api';
import { FormSelectBasic } from '../FormSelectBasic';
import {Option} from "../FormSelect";
import errorReporter from 'services/errorReporter';
import ReactConfetti from 'react-confetti';
import subscriptionService from "../../../services/subscriptionService";
import { EmptyPlaceholder } from '../EmptyPlaceholder';
import { FormInput } from '../FormInput';
import { ReactMultiEmail, isEmail } from 'react-multi-email';

import handshakeSvg from '../../../images/handshake.svg';

interface Props {
    representativeId?: string;
    onClose(): void;
    show: boolean;
    refresh(): any;
    signUpForm?: boolean;
}

const initialForm = {
    email: '',
};


export function TeamsModal({
    representativeId,
    onClose,
    show,
    refresh,
    signUpForm,
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };
    const navigate = useNavigate();

    const contentRef = useRef<HTMLDivElement>(null);

    Hooks.useOnClickOutside(contentRef, () => onClose());

    const handleSubmit = () => {
        refresh()
        onClose();
    }      

    const handleExitComplete = () => {
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    const navigateToTeams = () => {
        setTimeout(() => {
            navigate('/teams');
        }, 0);
        onClose();
    }

    return renderToPortal(
        <AnimatePresence onExitComplete={handleExitComplete}>
        {show && (
            <Container
                {...containerAnimation}
                data-testid={'review-modal-component'}
            >
                <CancelModalDiv>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '100%', alignItems: 'center'}}>

                        <img src={handshakeSvg} />

                        <h1>
                            <strong>New:</strong> Invite team members or clients to collaborate
                        </h1>

                        <p>
                            More people can use #gifted, see applicants, help with shortlisting, send outs and more.
                        </p>
                        <div style={{position: 'absolute', bottom: '36px', marginTop: '24px', display: 'flex', flexDirection: 'column', gap: '12px', width: '100%', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '90%'}}>
                                <p className='blue-text' style={{color: "#262161 !important", fontSize: '23px'}}>Free and secure</p>
                                <button onClick={() => navigateToTeams()} style={{width: '100%', borderRadius: '14px'}} className='primary'>Build your team</button>
                                <button onClick={() => onClose()} style={{width: '100%', borderRadius: '14px'}} className='secondary'>Dismiss</button>
                            </div>
                        </div>
                    </div>

                    
                </CancelModalDiv>
            </Container>
        )}
    </AnimatePresence>

    )
}


const CancelModalDiv = styled.div`
    height: 80vh;
    max-height: 920px;
    width: 642px;
    background: white;
    padding: 24px;
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 24px;

    img {
        height: 22vh;
        max-height: 200px;
    }

    .gifted-has-teams {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.sign-up-form {
        width: 100%;
        height: auto;
    }

    h1 {
        color: var(--blue);
        font-size: 58px;
        font-weight: 500;

        @media (max-height: 1090px) and (min-height: 0px) {
            font-size: 38px;
        }

        @media (max-height: 890px) and (min-height: 0px) {
            font-size: 26px;
        }

    }

    p {
        font-size: 26px;
        color: #9299BE !important;

        &.blue-text {
            color: #262161 !important;
        }

        @media (max-height: 1090px) and (min-height: 0px) {
            font-size: 20px;
        }

        @media (max-height: 890px) and (min-height: 0px) {
            font-size: 20px;
        }

        @media (max-height: 710px) and (min-height: 0px) {
            display: none;
        }

    }

    button {
        border: none;
        padding 4px 0px;
        text-align: center;
        margin-bottom: 12px;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        
        &.primary {
            background: var(--blue);
            color: white;
            font-weight: bold;
        }

        &.danger {
            background: var(--error-active);
            color: white;
            font-weight: bold;
        }

        &.secondary {
            background: white;
            color: var(--blue);
            border: solid 1px var(--blue);
            font-weight: bold;
        }
    }


    .form-input {
        width: 93%;
    }

    .vertical-buttons-con {
        width: 100% !important;
        align-items: flex-start !important;
    }

    .vertical-buttons {
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        gap: 12px !important;
        width: 100% !important;

        button {
            width: 100% !important;
        }
    }

    .react-multi-email {
        width: 78%;
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100vw;
        overflow: scroll;
    }

`

const CancelColumns = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-top: 70px;
    flex-wrap: wrap;

    h1 {
        margin: 0;
        padding: 0;
        color: var(--blue);
    }
`

const CancelBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    padding-bottom: 16px;
    margin-bottom: 16px; 
    box-shadow: 1px 1px 4px rgba(0,0,0,.3);
    border-radius: 14px;
    width: 614px;

    p {
        margin-top: 12px !important;
        font-size: 20px;
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
    }

`

const StyledButton = styled(Button)`
    position: absolute;
    right: -10px;
    top: -10px;
`;

const Title = styled.span`
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--blue);
    font-size: 38px;
    line-height: 48px;

    @media (max-width:900px) and (min-width:0px) {
        font-size: 27px;
    }
`;

const Image = styled.img`
    width: 220px;
    height: 220px;

    @media (max-height:711px) and (min-height:0px) {
        display: none;
    }

    @media (max-width:900px) and (min-width:0px) {
        display: none;
    }
`;

const Subtext = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--grey-7);
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    row-gap: 4px;
    padding-top: 24px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 28px;

    canvas {
        height: 100% !important;
        width: 100% !important;
    }

    @media (max-width:900px) and (min-width:0px) {
        overflow: visible;
    }
    
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;

    &.reverse {
        flex-direction: column-reverse;
    }
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);

    @media (max-width:900px) and (min-width:0px) {
        height: 95vh;
        overflow-y: scroll;
    }
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }

    li, p {
        color: #9299BE;
        text-align: center;
    }

    p {padding: 0px; margin: 0px; color: var(--grey-9);}

    &.sign-up-form {
        width: 100%;
        position: static;
        opacity: 1;
    }
`;

const Label = styled.div`
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    line-height: 24px;
    color: var(--grey-9);
`

const Centered = styled.div`
    display: flex;
    justify-content: center;
`

const ConfettiContainer = styled.div`
    position: absolute;
    top: 0; left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000000;
    height: 200%;
    width: 100%;
`