import styled from 'styled-components';

import { SubscriptionItem } from '../SubscriptionItem';

interface Props {
    name: string;
    items: Subscription[];
    selected?: string;
    onChange(name: string, value: string): void;
}

const Container = styled.div`
    flex: 1 0 100%;
    display: flex;
    flex-wrap: nowrap;
    column-gap: 12px;
    padding: 0 10px 20px;
    overflow: auto hidden;
    overflow: overlay hidden;
`;

export function SubscriptionList({
    name,
    items,
    selected,
    onChange
}: Props) {
    return (
        <Container>
            {items.map(({
                code,
                name: subName,
                description,
                amount,
                frequency
            }) => (
                <SubscriptionItem
                    key={code}
                    code={code}
                    name={subName}
                    description={description}
                    amount={amount}
                    frequency={frequency}
                    selected={selected === code}
                    onClick={() => onChange(name, code)}
                />
            ))}
        </Container>
    );
}
