import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import {
    CloseIcon,
    StarFilledIcon
} from 'app/icons';
import { Button } from '../Button';
import { ProfileFrame } from '../ProfileFrame';
import { Spinner } from '../Spinner';
import SocialProfileButtons from "../SocialProfileButtons";
import {Mapping} from "../../../utils";
import {
    useAppSelector
} from 'store'

interface Props {
    show?: boolean;
    influencer: Influencer;
    reviews?: Review[];
    loadingReviews?: boolean;
    onClose(): void;
}

const personalAttributes = [
    'gender',
    'sexuality',
    'marital_status',
    'children',
    'pets'
];

const otherAttributes = [
    'hair_color',
    'hair_type',
    'build',
    'skin_type',
    'diet'
];

export function InfluencerCard({
    show,
    influencer,
    reviews,
    loadingReviews,
    onClose
}: Props) {
    const animation = {
        initial: { opacity: 0, x: '100%' },
        animate: { opacity: 1, x: 0 },
        exit: { x: '100%' },
        transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.5
        }
    };

    const {
        profile: {
            brand,
        }
    } = useAppSelector((state) => state);


    const [showOtherInfo, setShowOtherInfo] = useState<
        'reviews' |
        undefined
    >('reviews');

    useEffect(() => {
        if (influencer) {
            setShowOtherInfo(reviews? 'reviews' : undefined);
        }
    }, [influencer, reviews]);

    const handleOnClose = () => {
        setShowOtherInfo(undefined);
        onClose();
    }


    const handleShowReviews = () => {
        if (!loadingReviews && influencer) {
            setShowOtherInfo('reviews');
        }
    }

    const getAttributeValue = (key: string) => {
        if (!influencer || !influencer.attributes) {
            return null;
        }
        return influencer.attributes.find((attr) => key === attr.name)?.producer_label
    }

    return (
        <AnimatePresence>
            {show && (
                <Container
                    {...animation}
                    data-testid={'influencer-card-component'}
                >

                    <Scroller>
                        <Content>
                            <ScrollerInner>
                                <Actions>
                                    <span>
                                        <CloseIcon onClick={handleOnClose} />
                                    </span>
                                </Actions>

                                <Header>
                                    {influencer?.uid && (
                                        <ProfileFrame
                                            id={influencer.uid}
                                            image={influencer?.headshot_image}
                                            width={112}
                                            height={112}
                                        />
                                    )}

                                    <Desc>
                                        <Name>
                                            {influencer?.name}
                                        </Name>

                                        <Location>
                                            {`${influencer?.address_state || influencer?.city?.name || ''}${influencer?.address_state || influencer?.city?.name ? ',' : ''}`} {influencer?.address_country || influencer?.country?.name || ''}
                                        </Location>



                                        {showOtherInfo === 'reviews' && influencer?.reviews ? (
                                            <>
                                                <History>

                                                    {loadingReviews ?
                                                        (
                                                            <StyledSpinner />
                                                        ) : (
                                                            null
                                                        )
                                                    }


                                                    {!loadingReviews && influencer.reviews && influencer?.reviews?.length > 0 && (
                                                        <>
                                                            <span>
                                                                {/* @ts-ignore */}
                                                                <span onClick={handleShowReviews}>{loadingReviews ? '' : influencer.reviews?.length}
                                                                    {reviews ? (
                                                                        ' reviews'
                                                                    ) : 'Reviews'}
                                                                </span>
                                                            </span>

                                                        </>
                                                    )}
                                                </History>

                                                {influencer?.reviews ? influencer?.reviews?.map((review) => (


                                                    <ReviewBox>
                                                        <div className="info-box">
                                                            <div className="name">{review.is_anonymous ? 'Anonymous' : review?.brand?.name}</div>
                                                            <div className="date">{new Date(review.reviewed_at).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</div>
                                                        </div>
                                                        <div>
                                                            {[...Array(review.rating)].map(() => (
                                                                <StyledStarFilledIcon />
                                                            ))}
                                                        </div>

                                                        <p>{review.review}</p>
                                                    </ReviewBox>
                                                )) : null}
                                            </>

                                        ) : null}

                                        {loadingReviews && reviews && !influencer?.reviews?.length && (
                                            <Center>
                                                <StyledSpinner />
                                            </Center>
                                        )}

                                        {!loadingReviews && reviews && !influencer?.reviews?.length && (
                                            <Center>
                                                <p style={{color: 'var(--grey-6)'}}>This creator has no reviews</p>
                                            </Center>
                                        )}


                                        {!reviews && influencer.social_profiles && (
                                            <>
                                                <SocialProfileButtons loading={false} influencer={influencer} subscription={brand?.subscriptionv2}/>

                                                <Bio>
                                                    {influencer?.bio}
                                                </Bio>
                                            </>
                                        )}
                                    </Desc>
                                </Header>

                                {!reviews && (
                                    <>
                                        <Areas>
                                            <span>
                                                Area of expertise
                                            </span>

                                            <BadgeList>
                                                {influencer?.tags?.map((tag) => (
                                                    <Button
                                                        key={tag.id}
                                                        theme={'outline'}
                                                        size={'small'}
                                                    >
                                                        {tag.name}
                                                    </Button>
                                                ))}
                                            </BadgeList>
                                        </Areas>

                                        <List>
                                            <Item>
                                                <span>Age</span>

                                                <span>{influencer?.dob}</span>
                                            </Item>

                                            {personalAttributes.map((k) => (
                                                <Item key={k}>
                                                    <span>{Mapping.Attributes[k].name}</span>

                                                    <span>
                                                    {getAttributeValue(k)}
                                                </span>
                                                </Item>
                                            ))}

                                            {otherAttributes.map((k) => (
                                                <Item key={k}>
                                                    <span>{Mapping.Attributes[k].name}</span>

                                                    <span>
                                                    {getAttributeValue(k)}
                                                </span>
                                                </Item>
                                            ))}
                                        </List>
                                    </>
                                )}
                            </ScrollerInner>
                        </Content>
                    </Scroller>
                </Container>
            )}
        </AnimatePresence>
    );
}

const Center = styled.div`
    display: flex;
    justify-content: center;
`

const Actions = styled.div`
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    left: 16px;
    z-index: 10;

    span {
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
            
            path {
                fill: var(--grey-6);
                transition: fill 300ms ease;
            }
        }

        &:last-child {
            margin-left: auto;
        }

        &:hover {
            svg path {
                fill: var(--blue);
            }
        }
    }
`;

const StyledStarFilledIcon = styled(StarFilledIcon)`
    path {
        fill: var(--yellow);
    }

`;

const Name = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--grey-9);
`;

const Location = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--grey-9);
    padding: 2px 0 12px;
`;

const StyledSpinner = styled(Spinner)`
    width: 20px;
    height: 20px;
`;

const History = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    line-height: 24px;

    span {
        display: flex;
        align-items: center;
        column-gap: 4px;
        color: var(--grey-6);
        transition: color 300ms ease;
        cursor: pointer;

        &:hover {
            color: var(--info-active);
        }
    }

    svg {
        width: 16px;
        height: 16px;
    }
`;

const Bio = styled.div`
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    color: var(--grey-7);
    padding: 12px 0 12px;
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
`;

const BadgeList = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 8px;

    button {
        text-transform: capitalize;
    }
`;

const Areas = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    span {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: var(--grey-9);
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    position: relative;
    width: 377px;
    margin: auto;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    overflow: auto;
    height: 95%;
    padding-top: 24px;
    padding-bottom: 24px;
`;

const Scroller = styled.div`
    overflow: auto;
    height: 100%;
    padding: 24px;

    // @media (max-width:900px) and (min-width:0px) {
        // padding: 0px;
    // }

`;

const ScrollerInner = styled.div`
    // overflow: auto;
    height: 100%;
    ::hover {
     overflow: auto;
     padding-right: 1px;
    }
`;

const Container = styled(motion.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 60px;
    background-color: var(--background-alt);
    z-index: var(--card-index);

    @media (max-width:900px) and (min-width:0px) {
        left: -20px;
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 16px;
    padding: 24px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 8px 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--grey-4);
    }

    span {
        &:first-child {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            color: var(--grey-6);
        }

        &:last-child {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            margin-top: -1px;
            color: var(--grey-9);
        }
    }
`;

const ReviewBox = styled.div`
    margin-top: 6px;
    margin-bottom: 6px;
    background: var(--grey-2);
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 300px;

    .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 4px;

        .name {
            color: var(--blue);
            font-weight: bold;
        }

        .date {
            color: var(--grey-7);
            font-size: 14px;
            font-weight: medium;
        }
    
    }

    p {
        color: var(--grey-7);
        margin: 4px 0px;
        font-size: 16px;
    }
`
