import {ReactNode, useMemo, useRef, useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';

import {Hooks} from 'utils';
import {CloseIcon} from 'app/icons';
import {Button} from '../Button';
import {FormTextarea} from '../FormTextarea';
import { API } from 'api';
import { FormSelectBasic } from '../FormSelectBasic';
import {Option} from "../FormSelect";

interface Props {
    influencer?: Influencer | null;
    show?: boolean;
    loading?: boolean;
    onClose(): void;
    onSubmit(form: any): void;
}

const StyledButton = styled(Button)`
    align-self: flex-end;
`;

const Title = styled.span`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.75px;
    color: var(--blye);
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 28px;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 80px;
`;

const Content = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    width: 439px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    background-color: var(--background-alt);
`;

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 33, 97, 0.5);
    opacity: 0;
    backdrop-filter: blur(8px);
    z-index: var(--modal-index);

    button {
        margin-top: auto;
    }
`;

const Label = styled.div`
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    line-height: 24px;
    color: var(--grey-9);
`

const initialForm = {
    message: '',
    reason: {id: 0, name: ''},
};

export function ReportModal({
    influencer,
    show,
    loading,
    onClose,
    onSubmit
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
    };

    const contentAnimation = {
        initial: { opacity: 0, y: '-100%' },
        animate: { opacity: 1, y: 0 },
        exit: { y: '100%' },
        transition: {
            type: 'spring',
            bounce: 0
        }
    };

    const contentRef = useRef<HTMLDivElement>(null);

    const [form, setForm] = useState(initialForm);
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        if (!show) {
            return;
        }
        const getOptions = () => {
            API.Shared.getReportOptions()
            .then((res) => {
              const opts: Option[] = [];
              res.forEach((reason: WaveReport) => {
                opts.push({
                  id: reason.id,
                  name: reason.reason,
                });
              });
              setOptions(opts);
              handleChange('reason', opts[0]);
            })
            .catch((error) => {
              throw error;
            });
        }

        getOptions();
    }, [show])

    const disabledSubmit = useMemo(() => {
        return !form.reason
    }, [form]);

    Hooks.useOnClickOutside(contentRef, () => show && onClose());

    const handleSubmit = () => {
        onSubmit(form);
    }

    const handleChange = (
        name: string,
        value: any
    ) => {
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const handleExitComplete = () => {
        setForm(initialForm);
    }

    const renderToPortal = (el: ReactNode) => {
        return createPortal(el, document.body);
    }

    return renderToPortal(
        <AnimatePresence onExitComplete={handleExitComplete}>
            {show && (
                <Container
                    {...containerAnimation}
                    data-testid={'review-modal-component'}
                >
                    <Content
                        {...contentAnimation}
                        ref={contentRef}
                    >
                        <Header>
                            <StyledButton
                                theme={'aqua'}
                                size={'small'}
                                iconOnly={true}
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </StyledButton>


                                    <>
                                        <Title>
                                            I need help with {influencer?.name}
                                        </Title>
                                    </>


                        </Header>

                            <Body>
                                <div>
                                    <Label>Reason:</Label>
                                    <FormSelectBasic
                                        options={options}
                                        selected={form.reason}
                                        onChange={handleChange}
                                        disabled={false}
                                        fullWidth={true}
                                    />
                                </div>

                                <FormTextarea
                                    name={'message'}
                                    label={'Anything else we should know?'}
                                    placeholder={'Type report info for gifted team...'}
                                    value={form.message}
                                    limit={500}
                                    required={false}
                                    onChange={handleChange}
                                />
                            </Body>

                        <Actions>
                            <Button
                                disabled={disabledSubmit}
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                Submit report
                            </Button>
                        </Actions>
                    </Content>
                </Container>
            )}
        </AnimatePresence>
    );
}
