import {
    PropsWithChildren, useEffect,
    useState
} from 'react';
import styled from 'styled-components';

import {Button, InviteModal } from 'app/components';
import {Actions, useAppDispatch, useAppSelector} from "../../../store";
import {API} from "../../../api";
import ShopifyLogo from '../../../images/shopify-logo-svgrepo-com.svg'
import errorReporter from "../../../services/errorReporter";
import HyperLink from "../../components/HyperLink";

interface Props {
    loading?: boolean;
    onGiftFlow(): void;
}

export function InviteTeamForm({
       loading,
       children,
       onGiftFlow
   }: PropsWithChildren<Props>) {

    const dispatcher = useAppDispatch();

    const teamInvite = localStorage.getItem('teamInvite')

    const {
        global: {
            settings
        },
        profile: {
            brand,
            representative
        }
    } = useAppSelector((state) => state);

    if (teamInvite) {
        onGiftFlow()
    }

    return (
        <Container>
            <InviteModal refresh={() => onGiftFlow()} show={true} representativeId={representative?.uid} onClose={() => onGiftFlow} signUpForm={true} />

            <ButtonRow>
                <Button theme={"outline"} onClick={() => onGiftFlow()}>Skip</Button>
            </ButtonRow>

        </Container>
    )

}

const Container = styled.div`

    @media (max-width:900px) and (min-width:0px) {
        span:first-child {
            font-size: 18px !important;
        }

        span {
            font-size: 14px !important;
            line-height: 24px !important;
        }

        img {
            width: 100px;
            margin-bottom: 0px;
        }
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: column;

    button {
        margin-bottom: 12px;
    }
`

const Centered = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 42px;
    
    &.column {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        
        h2 {
            font-size: 28px;
            font-weight: 600;
            line-height: 24px;
            -webkit-letter-spacing: -0.75px;
            -moz-letter-spacing: -0.75px;
            -ms-letter-spacing: -0.75px;
            letter-spacing: -0.75px;
            text-align: center;
            color: var(--grey-9);
            width: 100%;
            margin: 0;
        }
        
        h4 {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            -webkit-letter-spacing: -0.75px;
            -moz-letter-spacing: -0.75px;
            -ms-letter-spacing: -0.75px;
            letter-spacing: -0.75px;
            text-align: center;
            color: var(--grey-9);
            width: 100%;     
            margin-bottom: 0;   
        }
    }
`
