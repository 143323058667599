import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Testimonial = styled.div`
    box-shadow: 0 0 2px rgb(65 62 62 / 63%);
    background: white;
    border-radius: 14px;
    padding: 21px 25px;
    margin-bottom: 12px;

    &:last-of-type {
        margin-bottom: 0px;
    }
`

const TestimonialTopRow = styled.div`
    display: flex;
    align-items: center;

    img {
        height: 60px;
        width: 60px;
        margin-right: 16px;
        border-radius: 16px;
        box-shadow: 1px 1px 2px rgb(209 209 209 / 77%);
    }
`

const TestimonialBody = styled.div`
    width: 100%;
    color: var(--blue);
`

const TestimonialName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
        color: var(--blue);
        font-size: 18px;
    }

    p {
        color: #545A77;
        font-size: 14px;
    }

    h4, p {
        margin: 0;
        padding: 0
    }
`

const Hashtags = styled.div`
    display: flex;

    a {
        margin-right: 4px;
        cursor: pointer;
        text-decoration: underline;
        color: var(--blue);
    }
`

export function TestimonialsBox() {
    return (
        <Container>
            <Testimonial>
                <TestimonialTopRow>
                    <img src="https://assets.website-files.com/61680ad9715bb107814baee7/63e061e51693d130941115b0_Bloch-p-500.jpeg" />
                    <TestimonialName>
                        <h4>We Are Bloch</h4>
                        <p>Brand</p>
                    </TestimonialName>
                </TestimonialTopRow>

                <TestimonialBody>
                    <p>We are so impressed with the influencers we have gifted so far! #Gifted is a really great platform that has made it easy for us to find more influencers & creators to work with.</p>

                    <Hashtags>
                        <a target="_blank" href="https://hashgifted.com/creators/activewear">#activewear</a>
                    </Hashtags>
                </TestimonialBody>
            </Testimonial>

            <Testimonial>
                <TestimonialTopRow>
                    <img src="https://assets.website-files.com/61680ad9715bb107814baee7/63e060f0e4e25f4f6c15e44e_Justice%20Professiona-p-500.jpeg" />
                    <TestimonialName>
                        <h4>Justice Professional</h4>
                        <p>Brand</p>
                    </TestimonialName>
                </TestimonialTopRow>

                <TestimonialBody>
                    <p>#gifted is user friendly and simple to set up. I found the response time was significantly faster than previous interactions with creators via a platform. Love it!</p>

                    <Hashtags>
                        <a target="_blank" href="https://hashgifted.com/creators/beauty">#beauty</a>
                        <a target="_blank" href="https://hashgifted.com/creators/haircare">#haircare</a>
                    </Hashtags>
                </TestimonialBody>
            </Testimonial>


            <Testimonial>
                <TestimonialTopRow>
                    <img src="https://assets.website-files.com/61680ad9715bb107814baee7/63e1c6924ee37e451a66c323_8-p-500.png" />
                    <TestimonialName>
                        <h4>Mujer Label</h4>
                        <p>Brand</p>
                    </TestimonialName>
                </TestimonialTopRow>

                <TestimonialBody>
                    <p>#Gifted takes the hassle out of finding creators online yourself for contra collabs. As a small business when you're the only one doing everything, I often push socials aside as the least important with everything else that I have to do. #Gifted has changed that and made the process easier for me to find and select creators I want to collaborate with.</p>

                    <Hashtags>
                        <a target="_blank" href="https://hashgifted.com/creators/fashion">#fashion</a>
                        <a target="_blank" href="https://hashgifted.com/creators/sustainable">#sustainable</a>
                    </Hashtags>
                </TestimonialBody>
            </Testimonial>

        </Container>
    );
}
