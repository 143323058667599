import styled from 'styled-components';

import { AppLogo } from '../AppLogo';
import { Spinner } from '../Spinner';

const StyledAppLogo = styled(AppLogo)`
    transform: scale(1.25);
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    bottom: 50px;
    width: 30px;
    height: 30px;
    border-width: 1px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blue);

    svg {
        width: 200px;
        height: auto;

        path {
            fill: var(--text-alt);
        }
    }
`;

export function Splashscreen() {
    const spinnerAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: {
            delay: 3
        }
    };

    return (
        <Container
            data-testid={'splashscreen-component'}
        >
            <StyledAppLogo />

            <StyledSpinner {...spinnerAnimation} />
        </Container>
    );
}