import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Link = styled(NavLink)`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-underline-position: under;
    text-underline-offset: 1px;
    color: var(--aqua);
`;