import styled from 'styled-components';

import { useAppSelector } from 'store';
import {
    Breadcrumbs,
    InfoCard,
    StatusLoader
} from 'app/components';
import { PrivateLayout } from 'app/layouts';
import { useEffect, useMemo, useState } from 'react';
import { FormSwitch } from 'app/components/FormSwitch';
import { API } from 'api';
import { Loader } from 'app/components/Loader';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';


const Centered = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 398px;
    margin: 0 auto;
    min-height: 500px;

    h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.75px;
        text-align: center;
        color: var(--grey-9);        
    }

    @media (max-width:900px) and (min-width:0px) {
        width: auto !important;
    }
`;

const NotificationSetting = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--grey-2);
    padding: 24px 24px;
    border-radius: 24px;
    font-family: 'Mont', sans-serif;
`

const NotificationTitle = styled.div`
    font-weight: bold;
    color: var(--grey-9);
    font-size: 16px;
`

const NotificationDescription = styled.div`
    margin-top: 4px;
    color: var(--grey-8);
    font-size: 14px;
    line-height: 16px;
    margin-right: 18px;
`


const Container = styled.div`
    
    margin-top: 24px;
`;

const Line = styled.div`
    height: 2px;
    width: 100%;
    background: var(--grey-3);
    margin-bottom: -20px;
`

interface NotificationType {
    [prop: string]: NotificationSetting
}

export function NotificationsPage() {

    const {
        profile: {
            representative,
        },
        global: { settings },
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const [notificationsData, setNotificationsData] = useState<any>({
        newApplications: false,
        newMessages: true,
        newMatches: true,
        unreadMessages: true,
        allGiftNotifications: true
    })

    const [loading, setLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false)
    const [messageContent, setMessageContent] = useState('')
    const [messageStatus, setMessageStatus] = useState('')

    useEffect(() => {
        if (!representative?.uid) {
            return
        }

        API.Profile.getNotificationSettings(representative.uid).then((res: any) => {
            const valueMap: any = {
                tx_influencer_gift_waved: 'newApplications',
                tx_brand_received_message: 'newMessages',
                tx_brand_unread_messages: 'unreadMessages',
                tx_brand_unread_messages_weekly: 'unreadMessagesWeekly',
                tx_brand_new_matches: 'newMatches',
                tx_brand_new_matches_weekly: 'newMatchesWeekly',
                all_gift_notifications: 'allGiftNotifications',
                tx_influencer_has_reviewed_brand: 'creatorReviews',
                tx_influencer_withdrawn_from_gifting: 'creatorWithdraws'
            }

            const data = {...notificationsData}

            for (let i = 0; i < res.length; i++) {
                data[valueMap[res[i].notification_type]] = !!res[i].value
            }

            setNotificationsData(data)
            setLoading(false);
        }).catch((error: any) => {
            console.error(error);
        });
    }, [])

    const handleToggleChange = (name: string) => {

        if (!representative?.uid) {
            return
        }

        setShowMessage(false)


        const data = {...notificationsData};
        data[name] = !data[name]

        let frequency;

        if (name === 'newMatchesWeekly') {
            data.newMatches = false
            frequency = 'WEEKLY'
        } else if (name === 'newMatches') {
            data.newMatchesWeekly = false
        }

        if (name === 'unreadMessagesWeekly') {
            data.unreadMessages = false
            frequency = 'WEEKLY'
        } else if (name === 'unreadMessages') {
            data.unreadMessagesWeekly = false
        }

        const valueMap: NotificationType = {
            newApplications: {notification_type: 'tx_influencer_gift_waved', value: data.newApplications},
            newMessages: {notification_type: 'tx_brand_received_message', value: data.newMessages},
            newMatches: {notification_type: 'tx_brand_new_matches', value: data.newMatches},
            newMatchesWeekly: {notification_type: 'tx_brand_new_matches_weekly', value: data.newMatchesWeekly},
            unreadMessages: {notification_type: 'tx_brand_unread_messages', value: data.unreadMessages},
            unreadMessagesWeekly: {notification_type: 'tx_brand_unread_messages_weekly', value: data.unreadMessagesWeekly},
            allGiftNotifications: {notification_type: 'all_gift_notifications', value: data.allGiftNotifications},
            creatorReviews: {notification_type: 'tx_influencer_has_reviewed_brand', value: data.creatorReviews},
            creatorWithdraws: {notification_type: 'tx_influencer_withdrawn_from_gifting', value: data.creatorWithdraws},
        }

        API.Profile.updateNotificationSetting(representative.uid, valueMap[name], frequency || undefined).then(() => {

            setMessageStatus('success')
            setMessageContent('Success!')
            setShowMessage(true)

            // hide notification after 3 seconds
            setTimeout(() => {
                setShowMessage(false)
            }, 3000)
        }).catch((error: any) => {
            setMessageStatus('error')
            setMessageContent(error?.message as string || 'Failed to update preference')
            setShowMessage(true)
        });

        setNotificationsData(data)
    }

    useEffect(() => {
        analyticsEvents.settingsNotificationsOpened();
    }, []);    

    return (
        <PrivateLayout>
            <Container data-testid={'notifications-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'notifications', name: 'Notifications' }
                    ]}
                />

                <InfoCard>
                    <Body>
                        {!loading ? (
                            <>
                                {/* <h1 style={{marginBottom: '-5px', marginTop: 0}}>Daily Summaries</h1> */}

                                <h1 style={{marginBottom: '-5px', marginTop: 0, textAlign: 'left'}}>I want to be alerted about new applicants:</h1>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>As they are sent</NotificationTitle>
                                        <NotificationDescription>
                                            Receive an email every time a new Creator applies for your Gift.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newApplications" label="" onChange={() => {handleToggleChange('newApplications')}} checked={notificationsData.newApplications} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per day
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a daily summary email notifying you of any new applications from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newMatches" label="" onChange={() => handleToggleChange('newMatches')} checked={notificationsData.newMatches} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per week
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a weekly summary email notifying you of any new applications from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newMatches" label="" onChange={() => handleToggleChange('newMatchesWeekly')} checked={notificationsData.newMatchesWeekly} />
                                </NotificationSetting>



                                <Line />

                                <h1 style={{marginBottom: '-5px', textAlign: 'left'}}>I want to be alerted about new messages:</h1>


                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            As they are sent
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive an email every time a Creator messages you.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="newMessages" label="" onChange={() => {handleToggleChange('newMessages')}} checked={notificationsData.newMessages} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per day
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a daily summary email notifying you of any unread messages from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="unreadMessages" label="" onChange={() => {handleToggleChange('unreadMessages')}} checked={notificationsData.unreadMessages} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Once per week
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive a weekly summary email notifying you of any unread messages from Creators.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="unreadMessages" label="" onChange={() => {handleToggleChange('unreadMessagesWeekly')}} checked={notificationsData.unreadMessagesWeekly} />
                                </NotificationSetting>

                                <Line />

                                <h1 style={{marginBottom: '-5px', textAlign: 'left'}}>I want to receive all notifications for gifts posted by my brand:</h1>


                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Gifts posted by others in my organization
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive emails for gifts not posted by you.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="allGiftNotifications" label="" onChange={() => {handleToggleChange('allGiftNotifications')}} checked={notificationsData.allGiftNotifications} />
                                </NotificationSetting>

                                <Line />

                                <h1 style={{marginBottom: '-5px', textAlign: 'left'}}>
                                    Miscellaneous Notifications
                                </h1>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Every time a creator I've accepted withdraws from gifting
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive emails every time a creator you've accepted for a gift decides to opt out.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="creatorWithdraws" label="" onChange={() => {handleToggleChange('creatorWithdraws')}} checked={notificationsData.creatorWithdraws} />
                                </NotificationSetting>

                                <NotificationSetting>
                                    <div>
                                        <NotificationTitle>
                                            Every time a creator reviews my brand
                                        </NotificationTitle>
                                        <NotificationDescription>
                                            Receive emails every time a creator leaves a review for your brand.
                                        </NotificationDescription>
                                    </div>
                                    <FormSwitch name="creatorReviews" label="" onChange={() => {handleToggleChange('creatorReviews')}} checked={notificationsData.creatorReviews} />
                                </NotificationSetting>



                            </>
                        ) : (
                            <Centered>
                                <Loader status="spin" />
                            </Centered>
                        )}

                    </Body>
                </InfoCard>
            </Container>

            <StatusLoader
                show={showMessage}
                status={messageStatus}
                title={'Notification Update'}
                message={messageContent}
            />

        </PrivateLayout>
    );
}
