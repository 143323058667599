import styled from 'styled-components';

interface Props {
    theme?: 'reverse';
}

export const Badge = styled.div<Props>`
    font-size: 9px;
    font-weight: 700;
    padding: 4px 8px;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: var(--grey-4);
    color: var(--grey-7);
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    ${(props) => props.theme === 'reverse' && `
        background-color: var(--grey-9);
        color: var(--text-alt);
    `}
`;
