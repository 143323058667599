import {
    PropsWithChildren, useEffect,
    useState
} from 'react';
import styled from 'styled-components';

import {Button } from 'app/components';
import {Actions, useAppDispatch, useAppSelector} from "../../../store";
import {API} from "../../../api";
import ShopifyLogo from '../../../images/shopify-logo-svgrepo-com.svg'
import errorReporter from "../../../services/errorReporter";
import HyperLink from "../../components/HyperLink";

interface Props {
    loading?: boolean;
    setShopifyProfile(): void;
    onGiftFlow?: boolean;
}

export function ConnectShopifyForm({
       loading,
       children,
       setShopifyProfile,
        onGiftFlow
   }: PropsWithChildren<Props>) {

    const dispatcher = useAppDispatch();

    const {
        global: {
            settings
        },
        profile: {
            brand,
        }
    } = useAppSelector((state) => state);

    const [shopifyLoading, setShopifyLoading] = useState(false)
    const [showCongrats, setShowCongrats] = useState(false)

    const teamInvite = localStorage.getItem('teamInvite')

    const shopifyConnected = brand?.shopify &&
        brand.shopify.status === 'CONNECTED';

    const handleShopifyConnectionToggle = () => {
        setShopifyLoading(true);
        if (brand && shopifyConnected) {
            API.Shopify.disconnect(brand.uid)
                .then(() => {
                    dispatcher(Actions.Profile.setShopifyConnection(false));
                })
                .catch((error) => {
                    errorReporter.report('Could not disconnect shopify', error);
                });
        } else {
            const newTab = window.open('', '_blank');
            const storeUrl = settings?.shopify.storeUrl;
            if (newTab && storeUrl) {
                newTab.location.href = storeUrl;
            }
        }
    };

    const getShopify = (interval?: NodeJS.Timer) => {
        if (brand?.uid) {
            API.Shopify.getShopifyStatus(brand?.uid)
            .then((connect) => {
                console.log(connect);
                if (connect.status === 'CONNECTED') {
                    setShowCongrats(true);
                    setShopifyLoading(false);
                    if (interval) {
                        clearInterval(interval);
                    }
                }
            })
            .catch((error: any) => {
                errorReporter.report('Could get shopify status', error);
            });
        }
    }

    useEffect(() => {
        if (shopifyLoading && brand?.uid) {
            const fetchShopifyInfo = setInterval(() => {
                getShopify(fetchShopifyInfo)
            }, 3000);

            return () => {
                clearInterval(fetchShopifyInfo);
            };
        }
    }, [shopifyLoading, brand?.uid]);

    // let's also check if they're connected on first load for the signup flow
    // when they get redirected back here.
    useEffect(() => {
        getShopify();
    }, [])

    const handleIDontUseShopify = () => {
        if (!brand) {
            return;
        }

        console.log(brand)

        API.Profile.setBrandProfile(brand?.uid, {
            brand_type: brand.brand_type,
            name: brand.name,
            description: brand.description,
            website: brand.website,
            tags: brand.tags.map(tag => tag.id),
            doesnt_shopify: true
        }).then(() => {
            if (!onGiftFlow) {
                setShopifyProfile();
            }
        }).catch((error) => {
            errorReporter.report('Could not set brand profile', error);
        });
    }

    if (teamInvite) {
        setShopifyProfile();
    }

    return (
        <Container>
            {(showCongrats) ? (
                <Centered className={"column"}>
                    <h2>Your Shopify store is connected!</h2>
                    <h4>Gift products directly from your Shopify store.</h4>
                    <h4>Orders are automatically created when you select the Creator for gifting and they confirm options.</h4>
                </Centered>
            ) : (
                <>
                    {children}
                </>
            )}

            <Centered>
                <img width={"150px"} src={ShopifyLogo} />
            </Centered>

            {!showCongrats ? (
                <ButtonRow>
                    <Button theme={onGiftFlow ? 'aqua' : undefined} loading={shopifyLoading} onClick={() => handleShopifyConnectionToggle()}>{shopifyLoading ? 'Waiting for app installation ...' : 'Connect your Shopify Store'}</Button>
                    {!onGiftFlow && <Button theme={"outline"} onClick={() => setShopifyProfile()}>I'll do this later</Button>}
                    <Button theme={'outline'} loading={shopifyLoading} onClick={() => handleIDontUseShopify()}>I don't use Shopify</Button>


                </ButtonRow>

            ) :
                onGiftFlow ? null : (
                    <ButtonRow>
                        <Button loading={loading} onClick={() => setShopifyProfile()}>Next</Button>
                    </ButtonRow>
                )

            }


            <Centered>
                <HyperLink href={'https://hashgifted.com/help/shopify-integration'} target={"_blank"}>Learn more</HyperLink>
            </Centered>
            {!onGiftFlow && (
                <Centered className="column">
                    <h4>You can still use #gifted without Shopify! Just select I'll do this later.</h4>
                </Centered>
            )}
        </Container>
    )

}

const Container = styled.div`

    @media (max-width:900px) and (min-width:0px) {
        span:first-child {
            font-size: 18px !important;
        }

        span {
            font-size: 14px !important;
            line-height: 24px !important;
        }

        img {
            width: 100px;
            margin-bottom: 0px;
        }
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: column;

    button {
        margin-bottom: 12px;
    }
`

const Centered = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 42px;
    
    &.column {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        
        h2 {
            font-size: 28px;
            font-weight: 600;
            line-height: 24px;
            -webkit-letter-spacing: -0.75px;
            -moz-letter-spacing: -0.75px;
            -ms-letter-spacing: -0.75px;
            letter-spacing: -0.75px;
            text-align: center;
            color: var(--grey-9);
            width: 100%;
            margin: 0;
        }
        
        h4 {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            -webkit-letter-spacing: -0.75px;
            -moz-letter-spacing: -0.75px;
            -ms-letter-spacing: -0.75px;
            letter-spacing: -0.75px;
            text-align: center;
            color: var(--grey-9);
            width: 100%;     
            margin-bottom: 0;   
        }
    }
`
