import {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import {
    Breadcrumbs,
    StatusLoader
} from 'app/components';
import { PrivateLayout } from 'app/layouts';
import { API } from 'api';
import { ReviewList } from 'app/components/InfluencerCard/ReviewList';
import { NoResult } from 'app/components/NoResult';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';

const Container = styled.div`
    
    margin-top: 24px;
`;

const ReviewContainer = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
`

const NoReviewContainer = styled.div`
    background: white;
    border-radius: 20px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: var(--shadow-1);
    height: fit-content;
    display: flex;
    align-items: center;

    h1 {
        font-size: 20px;
        font-weight: 600;
        padding: 0px;
        margin: 0px; 
        color: var(--blue);
    }
`

export function BrandReviewsPage() {
    const dispatcher = useAppDispatch();

    const {
        profile: { brand, status },
        global: { settings },
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    const [brandReviews, setBrandReviews] = useState<Review[]>([])
    const [loadingReviews, setLoadingReviews] = useState(true)

    useEffect(() => {
        return () => {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [dispatcher]);


    useEffect(() => {
        if (!brand?.uid) {
            setLoadingReviews(false)
            return
        }

        const fetchReviews = async () => {
            const reviews = await API.Profile.getBrandReviews(brand.uid);

            setBrandReviews(reviews)

            setTimeout(() => {
                setLoadingReviews(false)
            }, 250);
        }

        fetchReviews().catch(console.error)

    }, [brand])

    const getMessage = () => {
        switch (status) {
            case 'success':
                return 'Request successful';

            case 'error':
                return 'Request failed';

            case 'set-brand-logo':
                return 'Updating brand logo';

            case 'set-brand-profile':
                return 'Updating brand info';

            default: return '';
        }
    }

    useEffect(() => {
        analyticsEvents.settingsBrandReviewOpened();
    }, []);    

    return (
        <PrivateLayout>
            <Container data-testid={'brand-reviews-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'brand-reviews', name: 'Brand Reviews' }
                    ]}
                />

                    <ReviewContainer>
                        {brandReviews?.length > 0 ? (
                            <ReviewList items={brandReviews} show={true} brandReviews={true} />
                        ) : (

                            loadingReviews ? (
                                <NoReviewContainer>
                                    <NoResult
                                        image={'sandwatch'}
                                        title="Loading brand reviews..."
                                    />
                                </NoReviewContainer>
                            ) : (
                                <NoReviewContainer>
                                    <NoResult
                                        image={'search'}
                                        title="Your brand doesn't have any reviews yet."
                                    />
                                </NoReviewContainer>
                            )


                        )}
                    </ReviewContainer>


            </Container>

            <StatusLoader
                show={status !== 'idle'}
                status={status}
                title={'My Brand'}
                message={getMessage()}
            />
        </PrivateLayout>
    );
}
