import axios from './axios';
import {Crop} from "react-image-crop";
import subscriptionService from 'services/subscriptionService';
import {ClientSettings} from "../services/settingsService";

const createGiftType = (
    brandId: string,
    data: GiftTypeForm
) => {
    const url = `/producer/brands/${brandId}/gifts`;

    return new Promise<GiftItem>((resolve, reject) => {
        axios.post(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as GiftItem);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => {

                reject(err)
            });
    });
}

const deleteGiftCreativeMedia = (
    brandId: string,
    giftId: string,
    mediaId: string
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/media/${mediaId}`;

    return new Promise<number>((resolve, reject) => {
        axios.delete(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const duplicateGift = (brandId: string, giftId: string) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/duplicate`;

    return new Promise<GiftItem>((resolve, reject) => {
        axios.post(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as GiftItem);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getByType = (brandId: string, status: string) => {
    let query: string;
    if (status === 'active') {
        query = 'status[]=ACTIVE&status[]=SUBMITTED&status[]=REJECTED&status[]=CLOSED&status[]=DRAFT&status[]=SCHEDULED';
    } else {
        if (status === 'past') {
            query = 'status[]=completed&status[]=canceled';
        } else {
            query = `status=${status}`;
        }
    }

    const url = `/producer/brands/${brandId}/gifts?${query}`;

    return new Promise<Gift[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Gift[]);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getGift = (brandId: string, giftId: string) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}`;

    return new Promise<GiftItem>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as GiftItem);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const deleteGift = (brandId: string, giftId: string) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}`;

    return new Promise<boolean>((resolve, reject) => {
        axios.delete(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as boolean);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}


const getShopifySelectionDetails = (
    brandId: string,
    giftId: string,
    selections: ShopifySelection[]
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/shopify/products/set`;
    return new Promise<ShopifyItemDetails[]>((resolve, reject) => {
        axios.post(url, {
            selections: selections
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(JSON.stringify(response.data))
                    resolve(response.data as ShopifyItemDetails[]);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getUnreadWaveCounts = (giftId: string) => {
    const url = `/producer/gifts/${giftId}/waves/unread`;

    return new Promise<Dynamic>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Dynamic);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });

}

const setGiftAudience = (
    brandId: string,
    giftId: string,
    data: AudienceForm
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/attributes`;

    if (!data.min_followers) {
        data.min_followers = 0;
    }

    if (!data.max_followers) {
        data.max_followers = 2147483647;
    }
    return new Promise<number>((resolve, reject) => {
        axios.put(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setGiftAudienceLocations = (
    brandId: string,
    giftId: string,
    data: GiftLocation[]
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/locations`;
    return axios.put(url, JSON.stringify(data))
        .then((response) => {
            if (response.status === 200) {
                return response.data as number;
            } else {
                throw new Error('An error occurred. Please try again');
            }
        });
}

const setGiftCreative = (
    brandId: string,
    giftId: string,
    data: CreativeForm
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}`;

    return new Promise<number>((resolve) => {
        axios.put(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => {
                resolve(err.response as number)
            });
    });
}

const setGiftCreativeMedia = (
    brandId: string,
    giftId: string,
    position: number,
    file: Blob,
    crop: Crop,
    workerApiUrl: string,
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/media`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const data = new FormData();
    data.append('media', file);
    data.append('position', position.toString());

    data.append('crop', JSON.stringify(crop));

    return axios.post(url, data, { headers: headers, baseURL: workerApiUrl })
        .then((response) => {
            return response.data as UploadMedia;
        })
}

const startGiftMediaUpload = (
    brandId: string,
    giftId: string,
    original_mime: string,
    thumb_mime: string,
): Promise<RequestMediaUploadResponse> => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/media/start`;
    return axios.post(url, {
        'original_mime' : original_mime,
        'thumb_mime' : thumb_mime
    })
        .then((response) => {
            return response.data as RequestMediaUploadResponse;
        })
}

const completeGiftMediaUpload = (
    brandId: string,
    giftId: string,
    originalPath: string,
    thumbPath: string,
    crop: Crop,
    position: number,
): Promise<Media> => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/media/complete`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const data = new FormData();
    data.append('original_path', originalPath);
    data.append('thumb_path', thumbPath);
    data.append('position', position.toString());
    data.append('crop', JSON.stringify(crop));
    return axios.post(url, data, {
        headers: headers
    }).then((response) => {
        return response.data as Media;
    })
}

const setGiftCreativeMediaOrder = (
    brandId: string,
    giftId: string,
    mediaIds: Array<string>
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/media/order`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const data = new FormData();
    data.append('mediaIds', mediaIds.join(','));

    return new Promise<UploadMedia>((resolve, reject) => {
        axios.post(url, data, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as UploadMedia);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setGiftCreativeMediaCrop = (
    brandId: string,
    giftId: string,
    mediaId: string,
    crop: Crop | undefined
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/media/update`;
    const data = new FormData();

    data.append('mediaId', mediaId);
    data.append('crop', JSON.stringify(crop))

    return new Promise<UploadMedia>((resolve, reject) => {
        axios.post(url, {mediaId: mediaId, crop: JSON.stringify(crop)})
            .then((response) => {
                if (response.status === 200) {
                    console.log('no more', response.data)
                    resolve(response.data as UploadMedia);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}


const setGiftDeliverables = (
    brandId: string,
    giftId: string,
    data: { deliverables: (string | number)[] }
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/deliverables`;

    return new Promise<number>((resolve, reject) => {
        axios.put(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const setGiftShopify = (
    brandId: string,
    giftId: string,
    data: ShopifyForm
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/shopify/products/update`;

    return new Promise<number>((resolve, reject) => {
        axios.post(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}


const setGiftShopifyMedia = (
    brandId: string,
    giftId: string,
    image_link: string,
    media_links: string[],
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/shopify/products/media`;

    return new Promise<number>((resolve, reject) => {
        axios.post(url, JSON.stringify({ image_link: image_link, media_links: media_links }))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const inviteCreators = (
    brandId: string,
    emails: string[],
    inviteEmail: string
) => {
    const url = `/producer/brands/${brandId}/gifts/invite`;

    const emailArrayToString = emails.join(',');

    let messages: string[] = []
    if (inviteEmail.includes('\n')) {
        messages = inviteEmail.split('\n')
    } else {
        messages.push(inviteEmail)
    }

    return new Promise<number>((resolve, reject) => {
        axios.post(url, JSON.stringify({
            emails: emailArrayToString,
            message: messages
        }))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}


const setGiftStatus = (
    brandId: string,
    giftId: string,
    status: string
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}/${status}`;
    return new Promise<GiftItem>((resolve, reject) => {
        axios.put(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as GiftItem);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => {
                reject(err)
            });
    });
}

const setGiftType = (
    brandId: string,
    giftId: string,
    data: GiftTypeForm
) => {
    const url = `/producer/brands/${brandId}/gifts/${giftId}`;

    return new Promise<number>((resolve, reject) => {
        axios.put(url, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as number);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const requiresSubscription = (giftType: GiftType, brand: BrandProfile, settings: ClientSettings): boolean => {

    if (!settings.features.subscriptions) {
        return false;
    }

    if (settings.gifts.paid_types.find(value => value === giftType)) {
        return true;
    }

    return subscriptionService.isSubscribed(brand.subscription);
};

export const giftAPI = {
    createGiftType,
    deleteGiftCreativeMedia,
    duplicateGift,
    getByType,
    getGift,
    deleteGift,
    getShopifySelectionDetails,
    getUnreadWaveCounts,
    setGiftAudience,
    setGiftAudienceLocations,
    setGiftCreative,
    setGiftCreativeMedia,
    setGiftCreativeMediaOrder,
    setGiftCreativeMediaCrop,
    startGiftMediaUpload,
    completeGiftMediaUpload,
    setGiftDeliverables,
    inviteCreators,
    setGiftShopify,
    setGiftShopifyMedia,
    setGiftStatus,
    setGiftType,
    requiresSubscription
};
