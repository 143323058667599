import axios from './axios';


const getCountries = () => {
    const url = `/shared/countries`;

    return new Promise<Country[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Country[]);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getShowcase = (categories: string[]) => {
    const url = `/shared/creators/showcase`;

    return new Promise<ShowcaseResponse>((resolve, reject) => {
        axios.post(url, categories[0].length ? {categories: categories} : null)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as ShowcaseResponse);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getGiftTypes = () => {
    const url = `/shared/gifts/types`;

    return new Promise<Dynamic>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Dynamic);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getStates = (id: number) => {
    const url = `/shared/countries/${id}/states`;

    return new Promise<State[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as State[]);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getSubscriptions = () => {
    const url = `/shared/subscriptions`;

    return new Promise<Subscription[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as Subscription[]);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getLinkPreview = (url :string) => {
    return new Promise<any>((resolve, reject) => {
        axios.get(`/shared/link/preview?url=${url}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data );
                } else {
                    resolve(response.data)
                }
            })
            .catch((err) => reject(err));
    });
}

const verifyEmail = (token: string): Promise<VerifyEmailResponse> => {
    const url = `/shared/verify/email`;
    const form = new FormData();
    form.append('token', token)
    return new Promise<VerifyEmailResponse>((resolve, reject) => {
        axios.post(url, form, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as VerifyEmailResponse);
                } else {
                    throw new Error('An error occurred. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getReportOptions = () => {
    return new Promise<WaveReport[]>((resolve, reject) => {
        axios.get(`/shared/reports/reasons/brand`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as WaveReport[]);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    })
}



const getCancellationReasons = () => {
    return new Promise<CancellationReason[]>((resolve, reject) => {
        axios.get(`/shared/cancellation/reasons`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as CancellationReason[]);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    })
}


const sendReport = (gift: string, wave: string, data: any) => {
    return new Promise<void>((resolve, reject) => {
        const reason = data.reason.id;
        const message = data.message;
        axios.post(`producer/gifts/${gift}/waves/${wave}/report`, {
            reason_id: reason, message
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    })
}

const sendCancellation = (brandId: string, data: any) => {
    return new Promise<void>((resolve, reject) => {
        const reason = data.reason.id;
        const message = data.message;
        axios.post(`producer/brands/${brandId}/subscription/cancellation`, {
            cancellation_reason_id: reason, message
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    })
}


const getCaseStudies = () => {
    return new Promise<any>((resolve, reject) => {
        axios.get(`/shared/articles/case-studies`).then((res) => {
            if (res.status === 200) {
                resolve(res.data);
            } else {
                throw new Error('An error occured. Please try again');
            }
        }).catch((err) => reject(err));
    })
}

const getBlogArticles = () => {
    return new Promise<any>((resolve, reject) => {
        axios.get(`/shared/articles/brands`).then((res) => {
            if (res.status === 200) {
                resolve(res.data);
            } else {
                throw new Error('An error occured. Please try again');
            }
        }).catch((err) => reject(err));
    })
}


export const sharedAPI = {
    getCountries,
    getGiftTypes,
    getStates,
    getSubscriptions,
    getLinkPreview,
    getShowcase,
    verifyEmail,
    getReportOptions,
    getCancellationReasons,
    sendReport,
    sendCancellation,
    getCaseStudies,
    getBlogArticles
};
