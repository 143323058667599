import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Color } from 'utils';
import { Badge, FormRadio, PictureFrame } from 'app/components';
import { FormCheck } from 'app/components/FormCheck';

interface Props {
    item: ShopifyItem;
    selected?: boolean;
    loading?: boolean;
    onClick(): void;
    mode: 'GIFT_CARD' | 'PRODUCT';
}

const Image = styled(PictureFrame)`
    ${(props) => !props.loading && `
        background-color: var(--grey-2);
    `}
`;

const Name = styled.span`
    font-size: 14px;
    line-height: 24px;
    color: var(--grey-9);
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Status = styled(Badge)`
    margin-left: auto;
    background-color: ${() => Color.hexVarToRGBA('--success-active', 0.25)};
    color: var(--success-active);
`;

const Container = styled(motion.div) <{
    $type: string;
    $selected?: boolean;
    $loading?: boolean;
}>`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    column-gap: 12px;
    height: 80px;
    padding: 8px 16px;
    border: 1px solid var(--grey-5);
    border-radius: 12px;
    transition: border 300ms ease;
    user-select: none;
    cursor: pointer;

    &:hover {
        border: 1px solid var(--blue);
    }

    &:active:not(:focus-within) {
        transition: transform 300ms ease;
        transform: scale(0.95) !important;
    }

    ${(props) => props.$type === 'PRODUCT' && props.$selected && `
        border-color: var(--blue);
        background-color: var(--grey-2);
    `}

    ${(props) => props.$loading && `
        pointer-events: none;

        ${Name},
        ${Status} {
            border-radius: 4px;
            background: linear-gradient(
                to right,
                var(--grey-3),
                var(--grey-2) 50%,
                var(--grey-3) 80%
            ), var(--grey-3);
            background-position: 100% 0, 0 0;
            background-repeat: repeat-y;
            background-size: 200% 200%;
            color: transparent;
            animation: animate 1s infinite;

            &:before {
                content: '######';
                visibility: hidden;
            }
        }

        ${Name}:before {
            content: '#################';
        }
    `}

    @keyframes animate {
        from: {
            background-position: 100% 0, 0 0;
        }
        to {
            background-position: -100% -100%;
        }
    }
`;

const GiftCardValue = styled.div`
    font-size: 14px;
    line-height: 24px;
    color: var(--blue);
    background-color: var(--aqua);
    height: 64px;
    min-width: 64px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: bold;
    border-radius: 8px;
    font-size: 16px;
`;

export function ProductListItem({
    item,
    selected,
    loading,
    onClick,
    mode
}: Props) {
    const animation = {
        transition: {
            type: 'spring',
            duration: 0.8
        },
        variants: {
            loading: { opacity: 1, scale: 1, y: 0 },
            hidden: { opacity: 0, scale: 0.9, y: 60 },
            show: { opacity: 1, scale: 1, y: 0 }
        }
    };

    console.log(item)

    return (
        <Container
            {...animation}
            $type={item.type}
            $selected={selected}
            $loading={loading}
            onClick={onClick}
        >
            {item.type === 'GIFTCARD' ?
                (
                    <>
                        <FormRadio checked={selected} />
                        <GiftCardValue>
                            ${item.variant_price}
                        </GiftCardValue>
                    </>
                ) : (
                    <>
                        <FormCheck checked={selected} />
                        <Image
                            width={64}
                            height={64}
                            image={item.image}
                            loading={loading}
                        />
                    </>
                )
            }

            <Name>
                <span>
                    {item.title}
                </span>
                {mode === 'PRODUCT' && (
                    <span>
                        <strong>In stock</strong>: {item.totalInventory || 0}
                    </span>
                )}
            </Name>
            {/* <Name style={{float: "right"}}></Name> */}
        </Container>
    );
}
