import styled from 'styled-components';

interface Props {
    title: string;
    desc?: string;
    extraDesc?: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    text-align: center;
    
    span {
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: var(--grey-6);
    
        &:first-child {
            font-size: 40px;
            font-weight: 300;
            line-height: 48px;
            letter-spacing: -1.25px;
            color: var(--grey-9);
            padding-bottom: 24px;
        }
    
        &:last-child {
            margin-bottom: 8px;
          padding-bottom: 24px;
        }
    }

    @media (max-width:900px) and (min-width:0px) {
        span {

            &:first-child {
                font-size: 24px;
                padding-bottom: 0;
            }

            &:last-child {
                font-size: 14px;
                padding-bottom: 0;
            }
        }
    }    

`;

export function SignUpPageHeader({
    title,
    desc,
    extraDesc
}: Props) {
    return (
        <Container>
            <span>{title}</span>
            {desc ? (
                <span>{desc}</span>
            ) : null}

            {extraDesc ? (
                <span>{extraDesc}</span>
            ) : null}
        </Container>
    );
}
