import axios from './axios';

const getInfluencer = (id: string) => {
    const url = `/producer/influencer/${id}`;

    return new Promise<{
        influencer: Influencer,
        insights: Analytics
    }>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data as {
                        influencer: Influencer,
                        insights: Analytics
                    });
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const getInfluencerReviews = (id: string) => {
    const url = `/producer/influencer/${id}/reviews`;

    return new Promise<Review[]>((resolve, reject) => {
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data as Review[]);
                } else {
                    throw new Error('An error occured. Please try again');
                }
            })
            .catch((err) => reject(err));
    });
}

const neverShowAgain = (brandId: string, influencerId: string) => {
    const url = `/producer/brands/${brandId}/influencer/preference`;

    return new Promise((resolve, reject) => {
        axios.post(url, {
            influencer_id: influencerId,
            is_banned: true
        }).then((response) => {
            if (response.status === 200) {
                resolve(response.data)
            } else {
                throw new Error('An error occured. Please try again');
            }
        }).catch((err) => reject(err))
    })
}

export const influencerAPI = {
    getInfluencer,
    getInfluencerReviews,
    neverShowAgain
};
