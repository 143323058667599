import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';

import { FormLabel } from '../FormLabel';
import { Helpers } from 'utils';
import { useAppSelector } from 'store';

interface Props {
    name: string;
    label: string;
    placeholder?: string;
    value: string | number;
    error?: string;
    thousandSeparator?: boolean;
    leadingZero?: boolean;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    onChange(name: string, value: string): void;
}

const Input = styled(NumericFormat)`
    font-size: 14px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    outline: none;

    &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: var(--grey-6);
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: var(--grey-6);
    }
    
    ::-ms-input-placeholder {
        color: var(--grey-6);
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }

    [type=number] {
        -moz-appearance: textfield;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    height: 56px;
    padding: 0 12px;
    border: 1.5px solid var(--grey-2);
    border-radius: 14px;
    background-color: var(--grey-1);
    color: var(--grey-9);
    transition: border-color 300ms ease;
    overflow: hidden;

    svg {
        flex-shrink: 0;
        margin-left: -1.5px;
    }
`;

const Container = styled.div<{
    $error?: boolean;
    $focus?: boolean;
    $disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    ${(props) => props.$error && `
        ${Content} {
            border-color: var(--error-active);

            svg path {
                fill: var(--error-active);
            }
        }
    `}

    ${(props) => props.$focus && `
        ${Content} {
            border-color: var(--blue);
        }
    `}

    ${(props) => props.$disabled && `
        pointer-events: none;

        ${Content} {
            background-color: var(--grey-3);

            ${Input} {
                color: var(--grey-6);
            }
        }
    `}
`;

const Error = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--error-active);
`;

export function FormMaskInput({
    name,
    label,
    placeholder,
    value,
    error,
    thousandSeparator,
    leadingZero,
    required,
    disabled,
    className,
    children,
    onChange
}: PropsWithChildren<Props>) {
    const {
        global: {
            inputError
        },
    } = useAppSelector((state) => state);

    const id = `mask-input-${name}`;

    const [focus, setFocus] = useState<boolean>(false);

    const handleBlur = () => {
        setFocus(false);
    }

    const handleFocus = () => {
        setFocus(true);
    }

    return (
        <Container
            data-testid={'form-mask-input-component'}
            $error={!Helpers.isNullOrUndefined(error)}
            $focus={focus}
            $disabled={disabled}
            className={className}
        >
            {label && (
                <FormLabel
                    labelFor={id}
                    required={required}
                >
                    {label}
                </FormLabel>
            )}

            <Content>

                <Input
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    thousandSeparator={thousandSeparator ? ',' : undefined}
                    allowLeadingZeros={leadingZero}
                    allowNegative={false}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onValueChange={(values: { value: string }) => onChange(name, values.value)}
                />
            </Content>

            {children}

            {inputError[name] && (
                <Error>{inputError[name][0]}</Error>
            )}

            {error && (
                <Error>{error}</Error>
            )}

        </Container>
    );
}
