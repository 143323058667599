import styled from 'styled-components';

interface Props {
    title: string;
    desc?: string;
}

export const Container = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.75px;
    text-align: center;
    color: var(--grey-9);

    span {
        font-weight: 500;
        font-size: 14px;
    }
`;

export function GiftFlowPageHeader({
    title,
    desc
}: Props) {
    return (
        <Container>
            {title}
            {desc ? (
                <span>
                    <br />
                    {desc}
                </span>
            ) : null}
        </Container>
    );
}