import { batch } from 'react-redux';

import { API } from 'api';
import { Constants } from '../constants';
import settingsService from "../../services/settingsService";

let timeoutHandler: NodeJS.Timeout;

const _setStatus = (status: string) => ({
    type: Constants.Completed.Status,
    payload: status
});

const _setLoading = (loading: boolean) => ({
    type: Constants.Completed.Loading,
    payload: loading
});

const _setLoadingView = (loading: boolean) => ({
    type: Constants.Completed.LoadingView,
    payload: loading
});

const _setLoadingReviews = (loading: boolean) => ({
    type: Constants.Completed.LoadingReviews,
    payload: loading
});

const clearState = () => {
    return (dispatch: AppDispatch) => {
        dispatch({ type: Constants.Completed.Clear, payload: null });
    }
}

const getContent = (giftId: string) => {
    return async (dispatch: AppDispatch) => {
        clearTimeout(timeoutHandler);

        dispatch(_setLoading(true));

        try {
            const result = await API.Completed.getContent(giftId);

            batch(() => {
                dispatch({
                    type: Constants.Completed.List,
                    payload: result
                });

                dispatch(_setLoading(false));
            });
        } catch {
            batch(() => {
                dispatch(_setLoading(false));
                dispatch(_setStatus('error'));
            });

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

const getInfluencerData = (
    giftId: string,
    influencerId: string
) => {
    return async (dispatch: AppDispatch) => {
        clearTimeout(timeoutHandler);

        dispatch(_setLoadingView(true));

        try {
            const [
                influencer,
                influencerPosts
            ] = await Promise.all([
                API.Influencer.getInfluencer(influencerId),
                API.Completed.getInfluencerPosts(giftId, influencerId)
            ])

            batch(() => {
                dispatch({
                    type: Constants.Completed.View,
                    payload: {
                        influencer,
                        influencerPosts: influencerPosts
                    }
                });

                dispatch(_setLoadingView(false));
            });
        } catch {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoadingView(false));
            });

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

const getInfluencerReviews = (influencerId: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const { completed: { view } } = getState();

        if (view) {
            dispatch(_setLoadingReviews(true));

            try {
                const reviews = await API.Influencer.getInfluencerReviews(influencerId);

                batch(() => {
                    dispatch({
                        type: Constants.Completed.View,
                        payload: {
                            ...view,
                            influencer: {
                                ...view.influencer,
                                reviews: reviews
                            }
                        }
                    });

                    dispatch(_setLoadingReviews(false));
                });
            } catch {
                batch(() => {
                    dispatch(_setStatus('error'));
                    dispatch(_setLoadingReviews(false));
                });
            }

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

export const completedActions = {
    clearState,
    getContent,
    getInfluencerData,
    getInfluencerReviews
};
