import styled from "styled-components";
import instagramLogo from "../../../images/instagram.svg";
import tiktokLogo from "../../../images/tiktok.svg";
import youtubeLogo from "../../../images/youtube.svg";
import giftedLogo from "../../../images/gifted.svg";
import {PropsWithChildren} from "react";
import {
    Actions,
    useAppDispatch,
} from 'store';


interface Props {
    influencer: Influencer;
    onViewGiftedProfile?(influencer: Influencer): void;
    loading: boolean;
    subscription: BrandSubscription|undefined
}

interface ProfileButtonProps {
    profile: Analytics;
    subscription: BrandSubscription|undefined
}

const ProfileButtonRow = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
`

const ProfileButtonContainer = styled.span`
    border-radius 12px;
    border: solid 1px #E7EBFB;
    box-shadow: 0px 6px 12px rgba(41, 43, 50, 0.12);
    margin-right: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms;

    &:hover {
        transform: translateY(-1px);
    }

    a {
        padding: 0;
        margin: 0;
        margin-bottom: -3px;
    }
`

const logo: any = {
    INSTAGRAM: instagramLogo,
    TIKTOK: tiktokLogo,
    YOUTUBE: youtubeLogo,
    GIFTED: giftedLogo
}

const ProfileButton = ({profile, subscription}: ProfileButtonProps) => {
    console.log(subscription)
    const dispatcher = useAppDispatch();

    const handleClick = (e:any)=>{
        e.preventDefault()
        if(subscription?.status === 'active'){
            if (profile.platform === 'TIKTOK') {
                window.open(getProfileUrl(profile) as string, '_blank')
            } else {
                window.open(getProfileUrl(profile) as string, '_blank')
            }
        }else{
            dispatcher(Actions.App.setShowPaymentModal(true, true))
        }
    }

    const getProfileUrl = (profile: Analytics) => {
        // if (profile.platform === 'TIKTOK') {
        //     if (profile.username) {
        //         // replace spaces with _
        //         return `https://www.tiktok.com/@${profile.username.replace(/\s+/g, '_')}`
        //     } else {
        //         return profile.profile_url as string;
        //     }
        // } else {
            return profile.profile_url as string;
        // }
    }

    return (
        <>
            <ProfileButtonContainer>
                <a target="_blank" href={getProfileUrl(profile)} rel="noreferrer" onClick={(e)=> handleClick(e)}>
                    <img alt={profile.platform} src={`${logo[profile.platform]}`} />
                </a>
            </ProfileButtonContainer>
        </>
    )
};

const SocialProfileButtons = ({ influencer, onViewGiftedProfile, loading, children, subscription }: PropsWithChildren<Props>) => {
    return (
        <ProfileButtonRow>
            {!loading && influencer?.social_profiles && influencer?.social_profiles.map((profile) => {
                if (profile.status === 'CONNECTED') {
                    return (<ProfileButton key={profile.profile_url} profile={profile} subscription={subscription}/>);
                }
            })}

            {!!onViewGiftedProfile && !loading && (
                <ProfileButtonContainer>
                    <a target="_blank" href='#' onClick={(event) => {
                        onViewGiftedProfile(influencer);
                        event.preventDefault();
                    }}>
                        <img height={21} width={20} alt={'gifted'} src={`${logo['GIFTED']}`} />
                    </a>
                </ProfileButtonContainer>
            )}

            {children}
        </ProfileButtonRow>
    );
};

export default SocialProfileButtons;
