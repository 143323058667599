import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import styled from 'styled-components';
import {
    getCountries,
    getCountryCallingCode,
    parsePhoneNumber
} from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';

import { FormLabel } from '../FormLabel';
import { FormMaskInput } from '../FormMaskInput';
import { FormSelect, Option as OptionProps } from '../FormSelect';
import {CountryCode} from "libphonenumber-js";

interface Props {
    name: string;
    label: string;
    placeholder?: string;
    value: string;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    onChange(name: string, value: string): void;
}

const StyledFormSelect = styled(FormSelect)`
    margin-left: -12px;

    > div {
        border: none;
    }
`;

const Code = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 1px;
    color: var(--grey-6);
`;

const StyledFormMaskInput = styled(FormMaskInput)`
    flex: 1;
    margin-left: -4px;

    > div {
        padding-left: 0;
        border: none;
    }
`;

const OptionRenderer = styled.span`
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-right: auto;
    width:fit-content
    height: fit-content;
`;

const Image = styled.img`
    width: 24px;
    border-radius: 4px;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    height: 56px;
    padding: 0 12px;
    border: 1.5px solid var(--grey-2);
    border-radius: 14px;
    background-color: var(--grey-1);
    color: var(--grey-9);
    transition: border-color 300ms ease;
    overflow: hidden;

    svg {
        flex-shrink: 0;
        margin-left: -1.5px;
    }
`;

const Container = styled.div<{
    $error?: boolean;
    $focus?: boolean;
    $disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    ${(props) => props.$error && `
        ${Content} {
            border-color: var(--error-active);

            svg path {
                fill: var(--error-active);
            }
        }
    `}

    ${(props) => props.$focus && `
        ${Content} {
            border-color: var(--blue);
        }
    `}

    ${(props) => props.$disabled && `
        pointer-events: none;

        ${Content} {
            background-color: var(--grey-3);
        }
    `}
`;

const countries = getCountries()
    .map((country) => ({
        id: country,
        name: en[country]
    }));

export function FormPhoneInput({
    name,
    label,
    placeholder,
    value,
    error,
    required,
    disabled,
    className,
    children,
    onChange
}: PropsWithChildren<Props>) {
    const id = `phone-input-${name}`;

    const [country, setCountry] = useState<OptionProps | undefined>(
        countries.find(({ id }) => id === 'AU')
    );

    const [focus, setFocus] = useState<boolean>(false);

    const getCallingCode = useCallback((code: CountryCode) => {
        const callingCode = getCountryCallingCode(code);

        return `+${callingCode}`;
    }, []);

    const inputValue = useMemo(() => {
        return value.substring(getCallingCode(country?.id as CountryCode).length);
    }, [value, country, getCallingCode]);

    useEffect(() => {
        const phoneInfo = parsePhoneNumber(value);

        if (phoneInfo && phoneInfo.country) {
            setCountry(
                countries.find(({ id }) => id === phoneInfo.country)
            );
        }
    }, [value]);

    const handleBlur = () => {
        setFocus(false);
    }

    const handleFocus = () => {
        setFocus(true);
    }

    const handleInputChange = (name: string, value: string) => {
        const code = getCountryCallingCode(country?.id as CountryCode)
        const nextValue = `+${code}${value}`;

        onChange(name, nextValue);
    }

    const handleSelectChange = (option: OptionProps) => {
        const value = getCallingCode(option.id as CountryCode);

        setCountry(option);
        onChange(name, value);
    }

    return (
        <Container
            data-testid={'form-phone-input-component'}
            $focus={focus}
            $error={!!error}
            $disabled={disabled}
            className={className}
        >
            {label && (
                <FormLabel
                    labelFor={id}
                    required={required}
                >
                    {label}
                </FormLabel>
            )}

            <Content
                onBlur={handleBlur}
                onFocus={handleFocus}
            >
                {children}

                <StyledFormSelect
                    name={'flags'}
                    label={''}
                    noClearBtn
                    options={
                        countries.slice(0)
                            .sort((a, b) => a.name.localeCompare(b.name))
                    }
                    selected={country}
                    optionRenderer={({ id, name }) => (
                        <OptionRenderer>
                            <Image
                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${id}.svg`}
                            />

                            <span>{`${name} +${getCountryCallingCode(id as CountryCode)}`}</span>
                        </OptionRenderer>
                    )}
                    selectedRenderer={({ id }) => (
                        <Image
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${id}.svg`}
                        />
                    )}
                    onChange={(_, option) => handleSelectChange(option)}
                />

                <Code>
                    {getCallingCode(country?.id as CountryCode)}
                </Code>

                <StyledFormMaskInput
                    name={name}
                    label={''}
                    placeholder={placeholder}
                    value={inputValue}
                    leadingZero={false}
                    onChange={handleInputChange}
                />
            </Content>

            {error && (
                <small>{error}</small>
            )}
        </Container>
    );
}
