import {
    FormEvent,
    PropsWithChildren,
    useEffect,
    useState
} from 'react';
import styled from 'styled-components';

import {
    Button,
    FormInput,
    FormLabel,
    FormMedia,
    FormPhoneInput,
} from 'app/components';
import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
  } from 'libphonenumber-js'

import { Loader } from 'app/components/Loader';
import { isEmail } from 'commons-validator-es';
import { Option } from 'app/components/FormSelect';
import { FormSelectBasic } from 'app/components/FormSelectBasic';


interface Props {
    data?: RepresentativeForm;
    headshotImage?: string;
    errors?: Dynamic;
    buttonLabel?: string;
    showEmail?: boolean;
    loading?: boolean;
    onSubmit(data: RepresentativeForm): void;
    onChangeImage(file: File): void;
    signUpFlow: boolean;
}

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }
`;

const ImageLoading = styled.div`
    position: relative;

    .imageLoadingDiv {
        position: absolute;
        height: 124px;
        width: 100%;
        background: rgba(255,255,255,.4);
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Error = styled.p`
    color: #ff647c;
`

const Container = styled.div``;

const referredByOptions: Option[] = [
    {"id": 1, "name": "Referred by someone else"},
    {"id": 2, "name": "Contacted by #gifted"},
    {"id": 3, "name": "Social media"},
    {"id": 4, "name": "Heard about it (podcast, webinar, or news)"},
    {"id": 5, "name": "Google / search"},
    {"id": 6, "name": "Other"}
]
  

export function RepresentativeProfileForm({
    data,
    headshotImage,
    errors,
    buttonLabel,
    showEmail,
    loading,
    children,
    onSubmit,
    onChangeImage,
    signUpFlow
}: PropsWithChildren<Props>) {
    const [form, setForm] = useState<RepresentativeForm>(data || {
        name: '',
        mobile: '',
        ...(showEmail && { email: '' }),
        referral_reason_id: 1,
        referral_message: 'none'
    });

    const teamInvite = localStorage.getItem('teamInvite')

    const [profileImage, setProfileImage] = useState<string>(headshotImage || '');

    const [pfpUploaded, setPfpUploaded] = useState('');

    const [preventSubmition, setPreventSubmition] = useState(false);

    const [referredBy, setReferredBy] = useState<Option>({id: 1, name: ''});
    const [referredByOther, setReferredByOther] = useState('');

    useEffect(() => {
        if (headshotImage) {
            setProfileImage(headshotImage);
            setPfpUploaded('')
        }
    }, [headshotImage]);

    useEffect(() => {
        if (referredBy.name !== 'Other') {
            setReferredByOther('')
        }

        setForm((prev) => ({
            ...prev,
            referral_reason_id: parseInt(referredBy.id as string),
            referral_message: referredByOther || 'none'
        }));


    }, [referredBy])

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            referral_message: referredByOther || 'none'
        }));

    }, [referredByOther])

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!preventSubmition) {
            onSubmit(form);
        }
    }

    const handleChange = (
        name: string,
        value: string | boolean
    ) => {
        setPreventSubmition(false)
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const checkFields = () => {

        if (showEmail && form.email && !isEmail(form.email)) {
            setPreventSubmition(true)
            setPfpUploaded('Email is invalid')
        }

        if (
            !isPossiblePhoneNumber(form.mobile) || !isValidPhoneNumber(form.mobile)
        ) {
            setPreventSubmition(true)
            setPfpUploaded('Phone number is invalid')
            return
        }

        if (!teamInvite && ((referredBy.name === 'None') || !referredBy.name) && signUpFlow) {
            setPreventSubmition(true)
            setPfpUploaded('Select how you were referred')
            return
        }


        if (preventSubmition) {
            setPreventSubmition(false)
        }

        if (pfpUploaded.length) {
            setPfpUploaded('')
        }
    }

    return (
        <Container>
            {children}

            <Form onSubmit={handleSubmit}>
                <FormInput
                    type={'text'}
                    name={'name'}
                    label={'Your Full Name'}
                    placeholder={'Enter your full name'}
                    value={form.name}
                    error={errors?.name}
                    required={true}
                    onChange={handleChange}
                />

                {showEmail && (
                    <FormInput
                        type={'text'}
                        name={'email'}
                        label={'Email'}
                        placeholder={'Enter your email'}
                        value={form.email || ''}
                        error={errors?.email}
                        required={true}
                        onChange={handleChange}
                    />
                )}


                <FormPhoneInput
                    name={'mobile'}
                    label={'Mobile'}
                    value={form.mobile}
                    error={errors?.mobile}
                    required={true}
                    onChange={handleChange}
                />

                {signUpFlow && !teamInvite ? (
                    <>
                        <FormLabel required>How did you hear about us?</FormLabel>
                        <FormSelectBasic placeholder='Select an option' signup fullWidth disabled={false} onChange={(name, id) => setReferredBy({id, name})} selected={referredBy} options={referredByOptions} />

                        {referredBy.name === 'Other' && (
                            <FormInput
                                type={'text'}
                                name={'referred_by_other'}
                                label={'Please specify'}
                                placeholder={'Please specify'}
                                value={referredByOther}
                                error={errors?.referred_by_other}
                                required={true}
                                onChange={(e,v) => setReferredByOther(v)}
                            />
                        )}
                    </>
                ) : null}

                <StyledButton
                    onClick={() => checkFields()}
                    loading={loading}
                >
                    {buttonLabel || 'Next'}
                </StyledButton>

                {pfpUploaded && <Error>{pfpUploaded}</Error>}
            </Form>
        </Container>
    );
}
