import { combineReducers } from 'redux';

import { Constants } from '../constants';
import {AnyAction, PayloadAction} from "@reduxjs/toolkit";

export const initialState: AuthReducer = {
    forgotPassword: { step: 0 },
    signup: {
        step: 0,
        user: null
    },
    user: null,
    status: 'idle',
    loading: false
}

const forgotPassword = (
    state = initialState.forgotPassword,
    action: AnyAction|PayloadAction<ForgotPasswordFlow>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Auth.ForgotPassword:
            return payload as ForgotPasswordFlow;
        default: return state;
    }
}

const signup = (
    state = initialState.signup,
    action: AnyAction|PayloadAction<SignUpFlow>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Auth.SignUp:
            return payload as SignUpFlow;
        case Constants.Auth.Logout:
            return initialState.signup;
        default: return state;
    }
}

const user = (
    state = initialState.user,
    action: PayloadAction<Auth>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Auth.Login:
        case Constants.Auth.Verify:
            return payload;
        case Constants.Auth.Logout:
            return initialState.user;
        default: return state;
    }
}

const status = (
    state = initialState.status,
    action: PayloadAction<string>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Auth.Status:
            return payload;
        case Constants.Auth.Logout:
            return initialState.status;
        default: return state;
    }
}

const loading = (
    state = initialState.loading,
    action: PayloadAction<boolean>
) => {
    const { payload, type } = action;

    switch (type) {
        case Constants.Auth.Loading:
            return payload;
        default: return state;
    }
}

export default combineReducers<AuthReducer>({
    forgotPassword,
    user,
    signup,
    status,
    loading
});
