import { authAPI } from './auth';
import { completedAPI } from './completed';
import { giftAPI } from './gift';
import { influencerAPI } from './influencer';
import { profileAPI } from './profile';
import { sharedAPI } from './shared';
import { shopifyAPI } from './shopify';
import { waveAPI } from './wave';

export const API = {
    Auth: authAPI,
    Completed: completedAPI,
    Gift: giftAPI,
    Influencer: influencerAPI,
    Profile: profileAPI,
    Shared: sharedAPI,
    Shopify: shopifyAPI,
    Wave: waveAPI
}