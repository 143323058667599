import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import AngleDown from '../../icons/angle-down.svg'
import ReactTooltip from "react-tooltip";

export interface Option {
    id: string | number;
    name: string;
}

interface Props {
    options: Option[],
    selected: Option,
    onChange: (name: string, id: string | number) => void;
    disabled: boolean | undefined;
    tooltip?: string;
    fullWidth?: boolean;
    placeholder?: string;
    signup?: boolean;
}

const Between = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`


const Container = styled.div<{
    $focus?: boolean;
}>`
    position: relative;
    background: var(--grey-1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px 24px;
    height: 56px;
    width: 190px;
    cursor: pointer;
    border: 1.5px solid var(--grey-2);
    transition: border-color 300ms ease;
    span {
        color: var(--blue);

        &.placeholder {
            color: var(--grey-6);
        }
    }

    font-size: 14px;

    &.disabled {
        background: var(--grey-3);
        cursor: not-allowed;

        span {
            color: var(--grey-6);
            font-weight: bold;
        }
    }

    &.full-width {
        width: auto;
    }

    ${(props) => props.$focus && `
            border-color: var(--blue);
        }
    `}
    &.signup {
        border: solid 1.5px var(--grey2);
        border-radius: 14px;
        height: 56px;

        span {
            color: var(--grey-9) !important;
        }
    }
`;

const OptionList = styled.div`
    position: absolute;
    top: 115%;
    right: 0;
    background: white;
    padding: 8px 8px;
    border-radius: 16px;
    z-index: 10;
    height: 200px;
    overflow-y: scroll;
    width: 190px;
    align-items:center;
    justify-content:center;
    box-shadow: var(--shadow-1);
    flex-direction: column;
    row-gap: 12px;

    &.full-width {
        width: 100%;
        height: auto;
    }

    &.signup {
        top: -600%;
    }
`

const OptionComponent = styled.div`
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 16px;
    transition: all 100ms ease;
    will-change: background;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--grey-9);
    font-size: 14px;

    &:hover {
        background: var(--grey-3);
    }

    &.active {
        background: #74d7c2;
        color: white;

        span {
            color: white;
        }
    }
`

export function FormSelectBasic({
    options,
    selected,
    onChange,
    disabled,
    tooltip,
    placeholder,
    fullWidth,
    signup
}: PropsWithChildren<Props>) {

    const [showMenu, setShowMenu] = useState(false)

    const [selectedValue, setSelectedValue] = useState(selected)

    const handleChange = (opt: Option) => {
        if (disabled) {return}

        setShowMenu(false)
        setSelectedValue(opt)
        onChange(opt.name, opt.id)
    }

    const toggleOptions = () => {
        if (disabled) {return}

        setShowMenu(!showMenu)
    }

    return (
        <Container onClick={() => toggleOptions()} data-tip={tooltip} className={`${disabled ? 'disabled' : ''} ${fullWidth ? 'full-width' : ''} ${signup ? 'signup' : ''}`}>
            {!!tooltip && (
                <ReactTooltip />
            )}
            <Between>
                <span className={!selectedValue.name ? 'placeholder' : ''} style={{ paddingLeft: 1, fontWeight: 500, }}>
                    {selectedValue.name ? selectedValue.name : placeholder}
                </span> 
                <img alt="expand" height="13" src={AngleDown} />
            </Between>

            {showMenu && (
                <OptionList className={`${fullWidth ? 'full-width' : ''} ${signup ? 'signup' : ''}`}>
                    {options.map((opt) => (
                        <OptionComponent className={selectedValue.id === opt.id ? 'active' : ''} onClick={() => handleChange(opt)}>
                            <span style={{ paddingLeft: 3 }}>{opt.name}</span>
                        </OptionComponent>
                    ))}
                </OptionList>
            )}
        </Container>
    );
}
