import {PropsWithChildren, useEffect} from 'react';
import styled from 'styled-components';

import { Helpers } from 'utils';
import { Table, TableColumn } from 'app/components';
import {Actions, useAppDispatch, useAppSelector} from "../../../../store";

interface Props {
    className?: string;
}

const StyledTableColumn = styled(TableColumn)`
    text-decoration: underline;
    text-underline-offset: 2px;
    color: var(--info-active);
    transition: color 300ms ease;
    cursor: pointer;

    &:hover {
        color: var(--aqua);
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 499px;
`;

export function BillingInfo({
    className,
    children
}: PropsWithChildren<Props>) {

    const {
        profile: {
            invoices,
            loadingInvoices
        }
    } = useAppSelector((state) => state);

    const dispatcher = useAppDispatch();

    useEffect(() => {
        dispatcher(Actions.Profile.getInvoices()).catch((error: any) => {throw error});
    }, [dispatcher])


    return (
        <Container
            className={className}
        >
            {children}

            <Table
                rows={invoices}
                columns={[
                    {
                        id: 'invoice_date',
                        name: 'DATE',
                        renderer: (data, { key }) => {
                            return (
                                <TableColumn key={key}>
                                {Helpers.formatDate(data, 'dd MMMM, Y')}
                            </TableColumn>
                            )
                        }
                    },
                    { id: 'type', name: 'TYPE' },
                    {
                        id: 'link',
                        name: 'RECEIPT',
                        renderer: (data, { key }) => (
                            <StyledTableColumn
                                key={key}
                                onClick={() => window.open(data as string)}
                            >
                                Download PDF
                            </StyledTableColumn>
                        )
                    }
                ]}
                loading={loadingInvoices}
            />
        </Container>
    );
}
