import { batch } from 'react-redux';

import { API } from 'api';
import { Helpers } from 'utils';
import { Constants } from '../constants';
import { appActions } from './appActions';
import { profileActions } from './profileActions';
import settingsService from "../../services/settingsService";
import {PayloadAction} from "@reduxjs/toolkit";
import AnalyticsEvents from 'services/analyticsEvents';

let timeoutHandler: NodeJS.Timeout;

const _setLoading = (loading: boolean): PayloadAction<boolean> => ({
    type: Constants.Auth.Loading,
    payload: loading
});

const _setStatus = (status: string) => ({
    type: Constants.Auth.Status,
    payload: status
});

const _setSession = (payload: Auth) => {
    localStorage.setItem(
        settingsService.credentialsStorageKey,
        JSON.stringify(payload)
    );
}

const _unsetSession = () => {
    localStorage.removeItem(settingsService.credentialsStorageKey);
}

const _handleAuth = async (
    data: Auth,
    dispatch: AppDispatch
) => {
    // save session in localStorage
    // so all succeeding requests will be authenticated
    _setSession(data);

    function loadProfile() {
        if (data.brand_representative_id) {
            return profileActions.getProfiles(
                data.brand_representative_id,
                data.brand_id as string
            )(dispatch).then(() => {
                return dispatch(appActions.getPrivateSharedInfo(data.brand_id as string));
            }).then(() => {
                return dispatch({
                    type: Constants.Auth.Login,
                    payload: data
                });
            });
        }
    }

    if (data.brand_id && data.status !== 'INCOMPLETE') {
        return loadProfile();
    } else {
        return _handleOnBoarding(data, dispatch);
    }
}

const _handleOnBoarding = async (
    data: Auth,
    dispatch: AppDispatch
) => {
    await dispatch(appActions.getPublicSharedInfo());
    const teamInvite = localStorage.getItem('teamInvite');

    if (data.brand_id && data.brand_representative_id && data.status !== 'INCOMPLETE') {
        await profileActions.getProfiles(
            data.brand_representative_id,
            data.brand_id
        )(dispatch);

        dispatch({
            type: Constants.Auth.SignUp,
            payload: {
                user: data,
                step: 4 // switch to subscription selection form
            }
        });
    } else if (data.brand_representative_id) {
        // check if the user has already set-up
        // the representative profile info
        const representative = await API.Profile.getRepresentativeProfile(
            data.brand_representative_id
        );

        if (data.brand_id && data.brand_representative_id) {
            await profileActions.getProfiles(
                data.brand_representative_id,
                data.brand_id
            )(dispatch);
        }

        dispatch({
            type: Constants.Profile.Representative,
            payload: representative
        });

        dispatch({
            type: Constants.Auth.SignUp,
            payload: {
                user: data,
                step: data.status === 'INCOMPLETE' || !data.brand_id?
                    teamInvite ? 3 : 1 : 3 // switch to representative (1) or brand (2) form
            }
        });

        // if a user clicks the login button but is in th signup flow, we gotta put them on the root page
        if (window.location.pathname === '/login') {
            window.location.href = '/';
        }

    }
}

const clearForgotPassword = () => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Auth.ForgotPassword,
            payload: { step: 0 }
        });
    }
}

const clearSignUp = () => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: Constants.Auth.SignUp,
            payload: { user: null, step: 0 }
        });
    }
}

const changePassword = (
    data: NewPasswordForm,
    callback?: () => void
) => {
    return async (dispatch: AppDispatch) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('change-password'));
        });

        try {
            await API.Auth.changePassword(data);

            batch(() => {
                dispatch(_setStatus('success'));
                dispatch(_setLoading(false));
            });

            if (callback) {
                callback();
            }
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Change password', err))(dispatch);

                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, 2500);
    }
}

const createAccount = (form: SignUpForm, callback: (auth: Auth) => void, acceptInvite?: boolean) => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));

        try {
            const result = await API.Auth.createAccount(form);
            callback(result);

            batch(() => {
                dispatch({
                    type: Constants.Profile.Representative,
                    payload: {
                        uid: result.brand_representative_id,
                        auth: { email: form.email }
                    }
                });

                dispatch({
                    type: Constants.Auth.SignUp,
                    payload: {
                        step: acceptInvite ? 3 : 1,
                        user: { ...result, email: form.email }
                    }
                });

                dispatch(_setLoading(false));
            });
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Sign up', err))(dispatch);
                dispatch(_setLoading(false));
            });
        }
    }
}

const forgotPassword = (form: ForgotPasswordForm) => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));

        try {
            const result = await API.Auth.forgotPassword(form);

            batch(() => {
                dispatch({
                    type: Constants.Auth.ForgotPassword,
                    payload: {
                        step: 1,
                        email: form.email,
                        ...result
                    }
                });

                dispatch(_setLoading(false));
            });
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Forgot Password', err))(dispatch);

                dispatch(_setLoading(false));
            });
        }
    }
}

const fotgotPasswordUpdate = (
    form: NewPasswordForm,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        dispatch(_setLoading(true));

        try {
            const {
                auth: { forgotPassword },
            } = getState();

            await API.Auth.forgotPasswordUpdate(
                form,
                forgotPassword.reset_token || ''
            );

            dispatch(_setLoading(false));

            if (callback) {
                callback();
            }
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Forgot Password', err))(dispatch);

                dispatch(_setLoading(false));
            });
        }
    }
}

const forgotPasswordVerify = (form: CodeVerificationForm) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        dispatch(_setLoading(true));

        try {
            const {
                auth: { forgotPassword },
            } = getState();

            const result = await API.Auth.forgotPasswordVerify(
                { code: form.code.join(''), },
                forgotPassword.verify_token || ''
            );

            batch(() => {
                dispatch({
                    type: Constants.Auth.ForgotPassword,
                    payload: {
                        ...forgotPassword,
                        ...result,
                        step: 2
                    }
                });

                dispatch(_setLoading(false));
            });
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('My Brand', err))(dispatch);

                dispatch(_setLoading(false));
            });
        }
    }
}

const login = (form: LoginForm) => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));

        try {
            const result = await API.Auth.login(form);
            await _handleAuth(result, dispatch);
        } catch (ex) {
            appActions.setAlert(Helpers.createRequestError('Login', ex))(dispatch);
        } finally {
            dispatch(_setLoading(false));
        }
    }
}

const loginWithToken = (token: string) => {
    return async (dispatch: AppDispatch, getState: () => AppReducer) => {
        dispatch(_setLoading(true));

        const {
            auth: { user },
            global: { settings },
            profile: { brand, representative }
        } = getState();

        try {
            if (user) {
                await API.Auth.logout(user.access_token);
                _unsetSession();
            }
            const result = await API.Auth.loginWithToken(token);
            await _handleAuth(result, dispatch);

            if (settings && representative && brand) {
                new AnalyticsEvents(settings).login(representative, brand);
            } else {
                console.log('No settings found for analytics');
            }


        } catch (ex) {
            const errorResponse = ex as ErrorResponse;
            appActions.setAlert(errorResponse)(dispatch);
        } finally {
            dispatch(_setLoading(false));
        }
    }
}

const logout = () => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const {
            auth: { user },
            profile: { brand }
        } = getState();

        if (user && brand) {
            await API.Auth.logout(user.access_token);

            _unsetSession();
        }

        dispatch({ type: Constants.Auth.Logout, payload: null });
    }
}

const setForgotPasswordStep = (step: number) => {
    return (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const { auth: { forgotPassword } } = getState();

        dispatch({
            type: Constants.Auth.ForgotPassword,
            payload: { ...forgotPassword, step }
        });
    }
}

const setSignUpStep = (step: number) => {
    return (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const { auth: { signup } } = getState();

        dispatch({
            type: Constants.Auth.SignUp,
            payload: { ...signup, step }
        });
    }
}

const verify = (data: Auth) => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));

        await _handleAuth(data, dispatch);

        batch(() => {
            dispatch(_setLoading(false));
            dispatch(appActions.setBooting(false));
        });
    }
}

export const authActions = {
    changePassword,
    clearForgotPassword,
    clearSignUp,
    createAccount,
    forgotPassword,
    fotgotPasswordUpdate,
    forgotPasswordVerify,
    login,
    loginWithToken,
    logout,
    setForgotPasswordStep,
    setSignUpStep,
    verify,
    _handleAuth
};
