import {
    FormEvent,
    PropsWithChildren, useEffect,
    useMemo,
    useState
} from 'react';
import styled from 'styled-components';

import { useAppSelector } from 'store';
import { Helpers } from 'utils';
import { Button } from 'app/components';
import { SubscriptionList } from './SubscriptionList';

export interface SubscriptionFormProps {
    data?: SubscriptionForm;
    buttonLabel?: string;
    disabledAction?: boolean;
    loading?: boolean;
    className?: string;
    onSubmit?(data: SubscriptionForm): void;
    onCancel?(): void;
    onSelected?(data: SubscriptionForm): void;
}

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
`


const StyledButton = styled(Button)`
    margin-top: 8px;
    width: 47%
`;

const CancelButton =  styled(Button)`
    margin-top: 8px;
    width: 47%;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }
`;

const Container = styled.div`
    width: 100%;
`;

export function SubscriptionForm({
    data,
    buttonLabel,
    disabledAction,
    loading,
    className,
    children,
    onSubmit,
    onCancel,
    onSelected
}: PropsWithChildren<SubscriptionFormProps>) {
    const { subscriptions } = useAppSelector((state) => state.global);

    const [form, setForm] = useState<SubscriptionForm>(data || {
        code: ''
    });

    const disabledSubmit = useMemo(() => {
        if (Helpers.isEmptyObject(form, true)) {
            return true;
        }

        const disabled = !Helpers.isNullOrUndefined(disabledAction) &&
            Helpers.isEqual(data, form);

        return disabled || loading;
    }, [data, disabledAction, loading, form]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit && onSubmit(form);
    }

    const handleChange = (
        name: string,
        value: string
    ) => {
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    useEffect(() => {
        form?.code && onSelected && onSelected(form);
    }, [form, onSelected])

    return (
        <Container className={className}>
            {children}

            <Form onSubmit={handleSubmit}>
                <SubscriptionList
                    name={'code'}
                    items={subscriptions}
                    selected={form.code || ''}
                    onChange={handleChange}
                />


                <ActionRow>
                    {onCancel && (
                        <CancelButton theme="outline" onClick={() => onCancel()}>
                            Cancel
                        </CancelButton>
                    )}

                    {onSubmit && (
                        <StyledButton
                            disabled={disabledSubmit}
                            loading={loading}
                        >
                            {buttonLabel || 'Next'}
                        </StyledButton>
                    )}
                </ActionRow>
            </Form>
        </Container>
    );
}

export { SubscriptionItem } from './SubscriptionItem';
