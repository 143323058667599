import {useEffect,useMemo} from 'react';
import styled from 'styled-components';

import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import {
    Breadcrumbs,
    InfoCard,
    InfoCardHeader,
    StatusLoader
} from 'app/components';
import { BrandProfileForm } from 'app/forms';
import { PrivateLayout } from 'app/layouts';
import { Alert } from 'app/components/Alert';
import AnalyticsEvents from 'services/analyticsEvents';
import { ClientSettings } from '../../../services/settingsService';

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 463px;
    margin: 0 auto;

    @media (max-width:900px) and (min-width:0px) {
        width: auto !important;
    }
`;

const Container = styled.div`
    
    margin-top: 24px;
`;

export function BrandProfilePage() {
    const dispatcher = useAppDispatch();

    const {
        global: { alert, settings },
        profile: { brand, status, loading }
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(
        () => new AnalyticsEvents(settings as ClientSettings),
        [settings]
    );    

    useEffect(() => {
        return () => {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [dispatcher]);

    const setBrandProfile = (form: BrandForm) => {
        if (!form.doesnt_shopify) {
            form.doesnt_shopify = false;
        }
        dispatcher(Actions.Profile.setBrandProfile(form)).catch((error: any) => {throw error});
    }

    const setBrandProfileLogo = (file: File) => {
        dispatcher(Actions.Profile.setBrandProfileLogo(file)).catch((error: any) => {throw error});
    }

    const getMessage = () => {
        switch (status) {
            case 'success':
                return 'Request successful';

            case 'error':
                return 'Request failed';

            case 'set-brand-logo':
                return 'Updating brand logo';

            case 'set-brand-profile':
                return 'Updating brand info';

            default: return '';
        }
    }

    useEffect(() => {
        analyticsEvents.settingsMyBrandOpened();
    }, []);    

    return (
        <PrivateLayout>
            <Container data-testid={'brand-profile-page'}>
                <Breadcrumbs
                    items={[
                        { id: 'dashboard', name: 'Dashboard', route: '/' },
                        { id: 'brand-profile', name: 'My Brand' }
                    ]}
                />

                <InfoCard>
                    <Body>
                        {brand && (
                            <>
                                <BrandProfileForm
                                    data={{
                                        name: brand?.name || '',
                                        description: brand?.description || '',
                                        website: brand?.website || '',
                                        tags: brand?.tags || [],
                                        brand_type: brand?.brand_type || '',
                                        social_profiles: brand?.social_profiles || [],
                                    }}
                                    logo={brand?.logo}
                                    errors={alert?.errors}
                                    buttonLabel={'Save changes'}
                                    includeSocial={true}
                                    disabledAction={false}
                                    loading={loading}
                                    onSubmit={setBrandProfile}
                                    onChangeLogo={setBrandProfileLogo}
                                >
                                    <InfoCardHeader>
                                        My Brand
                                    </InfoCardHeader>

                                </BrandProfileForm>

                                {alert?.errors && (
                                    Object.keys(alert.errors).map((key) => {
                                        const errors = alert.errors;
                                        if (!errors) {
                                            return null;
                                        }
                                        const error = errors[key];
                                        return (
                                            <Alert key={key} type="error" title={error[0]}/>
                                        );
                                    })
                                )}

                            </>
                        )}
                    </Body>
                </InfoCard>
            </Container>

            <StatusLoader
                show={status !== 'idle'}
                status={status}
                title={'My Brand'}
                message={getMessage()}
            />
        </PrivateLayout>
    );
}
